import { Tooltip, TooltipProps } from "@mui/material";
import React from "react";
import { twMerge } from "tailwind-merge";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    value?: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    tooltipProps?: Omit<TooltipProps, "children">;
    classes?: {
        wrapper?: string;
        title?: string;
        value?: string;
        icon?: string;
    };
}

const StatisticCard = (props: Props) => {
    const wrapperClasses = twMerge("flex flex-col", props.classes?.wrapper);
    const titleClasses = twMerge("capitalize text-md leading-5 font-medium text-[#344054]", props.classes?.title);
    const valueClasses = twMerge("text-md leading-5 font-normal text-[#667085]", props.classes?.value);
    const iconClasses = props.classes?.icon;

    return (
        <Tooltip title="" placement="top" {...props.tooltipProps}>
            <div className={wrapperClasses} onClick={props.onClick}>
                <div className="flex flex-row items-center gap-x-3">
                    {props.icon && <div className={iconClasses}>{props.icon}</div>}
                    <div className="flex flex-col space-y-[2px]">
                        <span className={titleClasses}>{props.title}</span>
                        {props.value && <span className={valueClasses}>{props.value}</span>}
                    </div>
                </div>
                {props.children && <div className="mt-2">{props.children}</div>}
            </div>
        </Tooltip>
    );
};

export default StatisticCard;
