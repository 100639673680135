import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Field from "shared/components/ui/Field";
import FAvatarUploadPhoto from "shared/components/ui/Field/partials/FAvatarUploadPhoto";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import {
    EditProjectMutation,
    GetProjectsQuery,
    useEditProjectMutation,
    useGetProjectsQuery,
    useGetUserDetailsQuery,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import yup from "shared/utils/yup";
import { setSelectedProject } from "store/slices/projects";
import CPContent from "./components/headers";

const schema = yup.object().shape({
    name: yup.string().required(),
    thumbnail: yup.number().nullable(),
});

type FormValues = yup.InferType<typeof schema>;

const CompleteProfileProject = () => {
    const Images = useImages();
    const navigate = useNavigate();
    const { team } = useAuth();
    const dispatch = useAppDispatch();
    const selectedProject = useAppSelector((state) => state.projects.selected);
    // Create Project Api
    const [editProject, editRes] = useEditProjectMutation();
    const projectRes = useGetProjectsQuery({
        filters: {
            sort: "ascending",
            search_key: null,
        },
    });

    const detailRes = useGetUserDetailsQuery();

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            thumbnail: null,
        },
    });

    useEffect(() => {
        if (team) {
            form.setValue("name", `${team.name}'s Project`);
        }
    }, [team]);

    const onSubmit = async (data: FormValues) => {
        if (selectedProject)
            editProject({
                id: selectedProject.project_id,
                data: {
                    name: data.name,
                    description: "Dummy",
                    ...(data.thumbnail && { thumbnail_id: data.thumbnail }),
                },
            });
    };

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess(res: GetProjectsQuery) {
                const proDefault = res.projects.find((project) => project.default);

                if (!selectedProject) {
                    dispatch(setSelectedProject(proDefault));
                }
            },
        });
    }, [projectRes, selectedProject, navigate]);

    useEffect(() => {
        rtkHandler(editRes, {
            setError: form.setError,
            onSuccess(res: EditProjectMutation) {
                dispatch(setSelectedProject(res.edit_project));
                navigate(
                    routeWithParams("/complete-profile/project/:id/editor", {
                        id: res.edit_project.project_id,
                    })
                );
            },
        });
    }, [editRes, navigate]);

    return (
        <CPContent
            icon={Images.ModelIcon}
            title="Project Creation"
            description={
                "We're going to walk you through creating your first project with XSpada. Let's start with the basics"
            }
            loader={projectRes.isLoading || detailRes.isLoading}
            topHeader={
                <Button
                    className="!h-6 !rounded-[30px] text-[#5E5D5D] !text-[10px] !leading-5 !px-[14px] !min-w-0"
                    color="inherit"
                    variant="outlined"
                    onClick={() => {
                        navigate("/complete-profile/final");
                    }}
                >
                    Skip Step
                </Button>
            }
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="w-[360px] mx-auto text-left">
                <Field
                    name="thumbnail"
                    render={({ field }) => {
                        return (
                            <FAvatarUploadPhoto
                                classes={{
                                    container: "!w-full !bg-white h-[120px] !rounded-md",
                                    loader: "w-full top-0 left-0 !h-full !rounded-md",
                                    image: "!w-full !h-full !rounded-md !object-contain",
                                }}
                                placeholder="thumbnail for your project"
                                value={field.value}
                                onChange={(file) => field.onChange(file?.file_id || null)}
                            />
                        );
                    }}
                />
                <Field
                    label="My Project Name:"
                    name="name"
                    placeholder="Enter your project name"
                    inputTextProps={{ className: "!bg-white" }}
                />
                <LoaderButton
                    className="h-[44px]"
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={editRes.isLoading}
                >
                    Continue
                </LoaderButton>
            </ThemeFormProvider>
        </CPContent>
    );
};

export default CompleteProfileProject;
