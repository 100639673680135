import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import ThemeSwitch from "shared/components/ui/ThemeSwitch";
import { useShareProjectMutation } from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { copyTextToClipboard } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    visibility: yup.boolean(),
    emails: yup.array().when("visibility", {
        is: (visibility) => visibility,
        then: (schema) => schema.of(yup.string().email()),
    }),
});

type FormValues = yup.InferType<typeof schema>;

const ShareProjectModalContent = ({
    onClose,
    onSave,
    loading,
}: {
    onClose: () => void;
    onSave: () => void;
    onSuccess?: (data: any) => void;
    loading?: boolean;
}) => {
    const Images = useImages();
    const [shareProject, shareRes] = useShareProjectMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            visibility: false,
            emails: [""],
        },
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "emails",
    });

    const { setValue } = form;

    const project = useAppSelector((state) => state.projects.selected);

    useEffect(() => {
        setValue("visibility", project?.visible ?? false);
    }, [project?.visible]);

    useEffect(() => {
        rtkHandler(shareRes, {
            onSuccess() {
                successToast("Project shared successfully");
                onClose();
            },
        });
    }, [shareRes]);

    const onSubmit = (data: FormValues) => {
        if (project && data.emails && data.emails.filter((email) => email).length > 0) {
            shareProject({
                slug: project.slug,
                data: {
                    emails: data.emails as string[],
                },
            });
        } else {
            onClose();
        }
    };

    return (
        <div>
            {project?.visible && (
                <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left">
                    <div className="mb-4">
                        <div className="flex items-center justify-between text-lg text-[#101828] font-semibold pb-6 border-b border-[#EAECF0] mb-6">
                            <div className="">Publish Project</div>
                            <ThemeSwitch
                                checked={project?.visible ?? false}
                                onChange={() => {
                                    onSave();
                                }}
                            />
                        </div>
                        <div className="text-md text-[#344054] font-medium mb-[6px] text-left">Share link</div>
                        <div
                            className="flex items-center gap-x-3 rounded-lg h-[44px] w-full border border-[#D0D5DD] px-[14px] py-[10px] bg-white cursor-pointer hover:brightness-90"
                            onClick={() => {
                                if (project) {
                                    const link = `${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${project.slug}`;
                                    copyTextToClipboard(link);
                                    successToast("Link copied to clipboard");
                                }
                            }}
                        >
                            <span className="flex-1 text-lg text-[#101828] text-left whitespace-nowrap overflow-hidden">
                                {process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}
                                {project?.slug ?? ""}
                            </span>
                            <Images.CopyGrayIcon width={18} height={18} />
                        </div>
                        <div className="mt-6">
                            {fields.length > 0 ? (
                                fields.map((field, index) => (
                                    <Field
                                        key={field.id}
                                        containerClassName="!mb-2"
                                        name={`emails.${index}`}
                                        label={index === 0 ? "Email Address" : ""}
                                        placeholder="Enter email address"
                                        inputTextProps={{
                                            startAdornment: <Images.EmailIcon />,
                                            endAdornment:
                                                index !== 0 ? (
                                                    <ThemeIconButton
                                                        color="error"
                                                        icon={<Images.DeleteIcon />}
                                                        onClick={() => remove(index)}
                                                    />
                                                ) : null,
                                            ...field,
                                        }}
                                    />
                                ))
                            ) : (
                                <Field
                                    containerClassName="!mb-2"
                                    name={`emails.0`}
                                    label="Email Address"
                                    placeholder="Enter email address"
                                    inputTextProps={{
                                        startAdornment: <Images.EmailIcon />,
                                        // ...field,
                                    }}
                                />
                            )}
                            <Button
                                onClick={() => {
                                    append("");
                                }}
                            >
                                <Images.PlusWhite />
                                Add another
                            </Button>
                        </div>
                    </div>
                </ThemeFormProvider>
            )}
            <CommonModalActionButton
                className="flex-col"
                buttons={[
                    {
                        id: project?.visible ? "change-visibility-btn" : "share-project-btn",
                        children: project?.visible ? "Done" : "Publish Project",
                        type: "submit",
                        color: "primary",
                        loading: loading || shareRes.isLoading,
                        onClick: () => {
                            if (project?.visible) {
                                //! TODO - Replace with email sending functionality here
                                form.handleSubmit(onSubmit)();
                            } else {
                                onSave();
                            }
                        },
                    },
                ]}
                cancelBtnProps={{
                    onClick: onClose,
                }}
            />
        </div>
    );
};

export default ShareProjectModalContent;
