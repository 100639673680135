import { TableCell, TableHead, TableRow } from "@mui/material";
import { useDataTableContext } from "../context";
import { twMerge } from "tailwind-merge";

const DataTableHeader = () => {
    const { headers, classes } = useDataTableContext();

    return (
        <TableHead className={classes?.header}>
            <TableRow className={classes?.row}>
                {headers.map((item, index) => (
                    <TableCell
                        key={index}
                        width={item.width}
                        align={item.align}
                        className={twMerge("!text-sm !text-[#667085]", classes?.cell, classes?.headerCell)}
                    >
                        {item.title}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default DataTableHeader;
