import { Collapse } from "@mui/material";
import React, { useState } from "react";

import useImages from "assets/images";

interface ICollapseableListProps {
    title?: string;
    titleComponent?: React.ReactNode;
    children?: React.ReactNode;
    hasGroup?: boolean;
}

const CollapseableList = (props: ICollapseableListProps) => {
    const { title, titleComponent, children, hasGroup = true } = props;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { ArrowDownCircle } = useImages();

    return (
        <div className={`mb-5 last:mb-0 ${hasGroup && "group"}`}>
            <div className="inline-flex items-center gap-x-[6px] cursor-pointer relative mb-2">
                <div
                    className="absolute top-0 left-0 w-full h-full z-10"
                    onClick={() => {
                        setIsCollapsed((prev) => !prev);
                    }}
                />
                <ArrowDownCircle
                    width={16}
                    height={16}
                    color={"#5E5D5D"}
                    className={!isCollapsed ? "rotate-180" : ""}
                />
                {titleComponent ? (
                    <>{titleComponent}</>
                ) : (
                    <div className="text-xs font-medium mr-1 capitalize">{title}</div>
                )}
            </div>
            <Collapse in={!isCollapsed}>{children}</Collapse>
        </div>
    );
};

export default CollapseableList;
