import { RouteObject } from "react-router-dom";
import PublishableProjectScreen from "screens/Dashboard/PublishableProjects/PublishableProject";
import PublishableProjectsLayout from "screens/Dashboard/PublishableProjects/layout";
import URL from "shared/constants/navigator";

const publishableProjectsRoutes: RouteObject[] = [
    {
        path: URL.Publishable_Project,
        element: <PublishableProjectsLayout />,
        children: [
            {
                path: "",
                element: <PublishableProjectScreen />,
            },
        ],
    },
];

export default publishableProjectsRoutes;
