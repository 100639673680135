import { NavLink, Outlet } from "react-router-dom";
import useSettingScreenLayout from "./hook";

const SettingScreenLayout = () => {
    const { Tabs } = useSettingScreenLayout();

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            <div className="p-6 pb-0 border-b border-[#F9F9F9]">
                <h1 className="text-[20px] text-p font-semibold mb-6">Settings</h1>
                <div className="flex items-center space-x-6">
                    {Tabs.map((tab, index) => {
                        return (
                            <NavLink
                                to={tab.path}
                                key={index}
                                end={tab.name === "Accounts" ? false : true}
                                className={({ isActive }) => {
                                    return `!pb-3 inline-block border-b-2 font-medium ${
                                        isActive
                                            ? "border-[#7680FF] text-h1"
                                            : "border-transparent text-p text-opacity-75"
                                    }`;
                                }}
                            >
                                <span className="px-2">{tab.name}</span>
                            </NavLink>
                        );
                    })}
                </div>
            </div>
            <div className="p-4 bg-[#F9F9F9] w-full flex-1 overflow-auto">
                <Outlet />
            </div>
        </div>
    );
};

export default SettingScreenLayout;
