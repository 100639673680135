import { Add, Remove } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    defaultValue?: number;
    min?: number;
    max?: number;
    onChange?: (value: number) => void;
}

const NumberInput = (props: Props) => {
    const [value, setValue] = useState(props.defaultValue || props.min || 0);

    const handleChange = (type: "add" | "remove") => {
        if (type === "remove" && value === 0) {
            return;
        }

        if (props.min && type === "remove" && value === props.min) {
            return;
        }

        let changedValue = value;
        if (type === "add") {
            changedValue = value + 1;
        } else {
            changedValue = value - 1;
        }

        setValue(changedValue);
        props.onChange && props.onChange(changedValue);
    };

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setValue(props.defaultValue);
        } else {
            setValue(props.min || 0);
        }
    }, [props.min]);

    return (
        <div className="flex items-center max-w-[130px] ml-auto">
            <Tooltip title={props.min ? `Minimum ${props.min}` : ""} placement="top">
                <span>
                    <Button
                        variant="outlined"
                        className="!min-w-[auto] !w-6 !h-6 !p-0 rounded-md"
                        onClick={() => handleChange("remove")}
                        disabled={props.min && value === props.min ? true : false}
                    >
                        <Remove className="!text-[#344054] !h-4 w-4" />
                    </Button>
                </span>
            </Tooltip>
            <span className="flex-1 text-center font-semibold">{value}</span>
            <Tooltip title={props.max ? `Maximum ${props.max}` : ""} placement="top">
                <span>
                    <Button
                        variant="outlined"
                        className="!min-w-[auto] !w-6 !h-6 !p-0 rounded-md"
                        onClick={() => handleChange("add")}
                        disabled={props.max && value === props.max ? true : false}
                    >
                        <Add className="!text-[#344054] !h-4 w-4" />
                    </Button>
                </span>
            </Tooltip>
        </div>
    );
};

export default NumberInput;
