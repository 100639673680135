import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { LoginMutation, useLoginMutation } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

type LoginPayload = yup.InferType<typeof schema>;

const useLoginScreen = () => {
    const [doLogin, loginRes] = useLoginMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const form = useForm<LoginPayload>({
        resolver: yupResolver(schema),
        defaultValues:
            process.env.NODE_ENV === "development"
                ? {
                      email: "khakiu.uk@gmail.com",
                      password: "Kazuya@11",
                  }
                : {
                      email: "",
                      password: "",
                  },
    });

    useEffect(() => {
        rtkHandler(loginRes, {
            setError: form.setError,
            onSuccess: (res: LoginMutation) => {
                const { user_registration_history, default_profile_id } = res.login;
                if (user_registration_history && !user_registration_history.completed_steps)
                    return navigate(URL.GetStarted);

                if (
                    user_registration_history &&
                    (user_registration_history.completed_steps ?? 0) < user_registration_history.total_steps
                ) {
                    let token = "";
                    if (user_registration_history.completed_steps === 1) token = "identity";
                    else if (user_registration_history.completed_steps === 2) token = "team";
                    else if (user_registration_history.completed_steps === 3) {
                        return navigate(URL.ProfileSelection);
                    }

                    if (token)
                        navigate(
                            routeWithParams(URL.CompleteProfile, {
                                token,
                            })
                        );
                } else {
                    if (default_profile_id) {
                        navigate(URL.Dashboard);
                    } else navigate(URL.ProfileSelection);
                }
            },
        });
    }, [loginRes, navigate, dispatch]);

    const onSubmit = (data: LoginPayload) => {
        doLogin({
            data: {
                email: data.email,
                password: data.password,
            },
        });
    };

    return { form, loginRes, onSubmit };
};

export default useLoginScreen;
