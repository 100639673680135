import useImages from "assets/images";
import { useFormContext } from "react-hook-form";

const SRTitle = () => {
    const Images = useImages();
    const { watch } = useFormContext();
    const dataset_type = watch("dataset_type");

    return (
        <div className="flex flex-col items-center text-center pt-4 pb-8">
            <Images.VideoCameraLayerIcon height={50} width={50} />
            <h4 className="text-p text-[20px] leading-5 font-bold mb-[6px] mt-4">
                {dataset_type === "images"
                    ? "Zipped Photo Scans Upload"
                    : dataset_type === "video"
                    ? "Video Upload"
                    : dataset_type === "360"
                    ? "360 Video Upload"
                    : ""}
            </h4>
            <p className="text-p text-md leading-5 font-normal">
                {dataset_type === "images"
                    ? "Upload your zip file here"
                    : dataset_type === "video"
                    ? "Upload your video file here"
                    : dataset_type === "360"
                    ? "Upload your 360 video file here"
                    : ""}
            </p>
        </div>
    );
};

export default SRTitle;
