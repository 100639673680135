import { useGetStorageQuery } from "shared/graphql";
import { errorToast } from "shared/utils/toast";

const useHasStorage = () => {
    const storage = useGetStorageQuery();

    return async (size: number): Promise<boolean> => {
        try {
            const res = await storage.refetch();
            if (res.data?.storage) {
                const consumedSize = res.data?.storage.consumed + size;
                const totalSize = res.data?.storage.limit;
                if (consumedSize > totalSize) {
                    errorToast("File size larger than available free space in your team.");
                    return false;
                }
                return true;
            }
            errorToast("Error while checking storage.");
            return false;
        } catch (error) {
            errorToast("Error while checking storage.");
            return false;
        }
    };
};

export default useHasStorage;
