import moment from "moment";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    onSend: () => Promise<boolean>;
    disabled?: boolean;
    refresh?: boolean;
    render?: (props: { timerText: string; sendOtp: () => void; resendBtn: boolean }) => JSX.Element;
}

let timeInterval: any;

const ResendOTP = ({ onSend, disabled, render, refresh }: Props) => {
    const placeholderTimer = "03m 00s";
    const [enableBtn, setEnableBtn] = useState(false);
    const [timerText, setTimerText] = useState(placeholderTimer);

    const startTimer = () => {
        const eventTime = moment().add(180, "second").unix();
        const currentTime = moment().unix();
        const diffTime = eventTime - currentTime;
        let duration: any = moment.duration(diffTime * 1000, "milliseconds");
        const interval = 1000;
        timeInterval && clearInterval(timeInterval);

        timeInterval = setInterval(async function () {
            duration = moment.duration(duration - interval, "milliseconds");
            if (duration.seconds() === 0 && duration.minutes() === 0) {
                clearInterval(timeInterval);
                setEnableBtn(true);
            } else {
                setTimerText(`${duration.minutes()}m ${duration.seconds()}s`);
            }
        }, interval);
    };

    useEffect(() => {
        setEnableBtn(false);
        setTimerText(placeholderTimer);
        startTimer();

        return () => {
            clearInterval(timeInterval);
        };
    }, [timeInterval, refresh]);

    const onClickResend = async () => {
        const res = await onSend();
        if (res) {
            setEnableBtn(false);
            setTimerText(placeholderTimer);
            startTimer();
        }
    };

    if (render) {
        return render({ timerText, sendOtp: onClickResend, resendBtn: enableBtn });
    }

    if (enableBtn) {
        return (
            <span
                className={twMerge("underline text-primary cursor-pointer", disabled ? "text-opacity-40" : "")}
                onClick={onClickResend}
            >
                Resend
            </span>
        );
    }

    return (
        <span>
            Resend Code in: <span className="text-secondary">{timerText}</span>
        </span>
    );
};

export default ResendOTP;
