import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";

interface IPlaceholderProps {
    id: string;
}

const Placeholder = forwardRef((props: any, ref) => {
    const { id } = props.node.attrs as IPlaceholderProps;
    const dispatch = useAppDispatch();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    return (
        <NodeViewWrapper
            ref={ref}
            id={id}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "placeholder",
                        props: { id: props.node.attrs.id },
                    })
                );
            }}
            contentEditable={false}
            className={`editor-placeholder w-full h-full flex items-center justify-center cursor-pointer ${
                isSelected && "editor-block-selected"
            }`}
        >
            Drop content here
        </NodeViewWrapper>
    );
});

export default Placeholder;
