import { Node } from "@tiptap/core";

export const Div = Node.create({
    name: "paragraphDiv",

    priority: 1000,

    group: "block",

    content: "",

    renderHTML() {
        return ["div", { class: "editor-placeholder" }, "Drop content here"];
    },
});
