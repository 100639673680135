// CustomListNode.js
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import CodeBlock from "./CodeBlock";

export default Node.create({
    name: "codeBlockComponent",
    content: "inline*",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            index: {
                default: true,
            },
            size: {
                default: 3, // Set a default size
            },
            content: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.code-block-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    index: dom.getAttribute("index"),
                    size: parseInt(dom.getAttribute("size")) || 3,
                    content: dom.getAttribute("content"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "code-block-component",
                id: node.attrs.id,
                index: node.attrs.index,
                size: node.attrs.size,
                content: node.attrs.content,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(CodeBlock);
    },
});
