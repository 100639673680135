import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetUserPromoCode: {
            providesTags: ["UserPromoCode"],
        },
        UsePromoCode: {
            invalidatesTags: (result, error) => (error ? [] : ["UserPromoCode"]),
        },
        RemovePromoCode: {
            invalidatesTags: (result, error) => (error ? [] : ["UserPromoCode"]),
        },
    },
});

export const { useGetUserPromoCodeQuery, useUsePromoCodeMutation, useRemovePromoCodeMutation } = api;
