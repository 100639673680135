import useImages from "assets/images";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import { IHeaderMenuButtonProps } from "shared/components/HeaderActionBtns";
import { StudioLink } from "shared/constants/const";
import useAuth from "shared/hooks/useAuth";
import { extractLocationName } from "shared/utils/helpers";

const useSRSingleScanLayout = () => {
    const { scanId, type } = useParams<{ id: string; type: string; scanId: string }>();
    const { request, isRequestor } = useSingleSR();
    const Images = useImages();
    const { user, profile } = useAuth();
    const navigate = useNavigate();

    const application = request?.applications.find((req) =>
        req.submissions.find((sub) => sub.scan_id === parseInt(scanId!))
    );

    const scan = request?.applications
        .find((req) => req.submissions.find((sub) => sub.scan_id === parseInt(scanId!)))
        ?.submissions.find((sub) => sub.scan_id === parseInt(scanId!));

    const locationName = useMemo(() => {
        if (!request) return;
        let scanLocation = request.location.name;

        if (scanLocation) {
            const scanLocationSplit = scanLocation.split(",");

            if (scanLocationSplit.length >= 2) {
                scanLocation = scanLocationSplit.slice(0, 2).join(", ");
            }
        }

        return extractLocationName(scanLocation);
    }, [request]);

    const [isDownloading, setIsDownloading] = useState(false);
    const [play, setPlay] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const togglePlay = () => {
        setPlay((prev) => !prev);
    };

    const readyToPlay = () => {
        setIsReady(true);
    };

    const downloadVideo = () => {
        if (scan) {
            setIsDownloading(true);
            const video = scan.input_file.url;
            // download video here
            const a = document.createElement("a");
            a.href = video;
            a.download = "video.mp4";
            a.click();

            // remove a tag
            a.remove();
            setIsDownloading(false);
        }
    };

    const handleStartViewer = () => {
        // if (!Can_View_Scan_In_Viewer) {
        //     setOpenViewer(true);
        //     return;
        // }
        const url = `${StudioLink}?scan_id=${scan?.scan_id}&user_id=${user?.user_id}&user_email=${user?.email}&profile_id=${profile?.profile_id}`;
        window.open(url, "_blank");
    };

    const handleDeleteScan = () => {
        navigate(`delete`);
    };

    const myScan = application?.user.user_id === user?.user_id;

    const menus = [
        scan?.model &&
            scan?.model.status === "Completed" && {
                tooltipTitle: "Download",
                actionTitle: "Download",
                className: "!bg-[#949496] !bg-opacity-25",
                icon: <Images.DownloadIcon color="#3F3E3E" />,
                url: `/requests/${type}/detail/${request?.scan_request_id}/scans/${scan?.scan_id}/download`,
            },
        !isRequestor &&
            myScan && {
                tooltipTitle: "Edit",
                actionTitle: "Editing",
                className: "!bg-[#949496] !bg-opacity-25",
                icon: <Images.EditSquareIcon />,
                url: `/requests/${type}/detail/${request?.scan_request_id}/scans/${scan?.scan_id}/edit`,
            },
        !isRequestor &&
            myScan && {
                tooltipTitle: "Delete",
                actionTitle: "Delete",
                className: "!bg-error !bg-opacity-25",
                icon: <Images.DeleteIcon color="#EB617A" />,
                url: `/requests/${type}/detail/${request?.scan_request_id}/scans/${scan?.scan_id}/delete`,
            },
    ].filter(Boolean) as IHeaderMenuButtonProps[];

    return {
        play,
        scan,
        menus,
        isRequestor,
        isDownloading,
        handleDeleteScan,
        downloadVideo,
        togglePlay,
        handleStartViewer,
        isReady,
        readyToPlay,
        locationName,
        application,
        request,
    };
};

export default useSRSingleScanLayout;
