export enum ScanningDeviceCategory {
    "MobilePhone" = "Mobile Phone",
    "Tablet" = "Tablet",
    "Drones" = "Drones",
    "TypicalCamera" = "Typical Camera",
    "360Camera" = "360 Camera",
}

export enum ProfileTypes {
    Professional = "Professional",
    TeamMember = "Member",
    TeamManager = "Manager",
    Admin = "Admin",
    SuperAdmin = "Super Admin",
}

export enum MyScanVisibility {
    Public = "public",
    Private = "private",
    Shared = "shared",
}

export enum VerifyPersonalInfoModalType {
    Email = "email_address",
    Phone = "phone_number",
}

export enum VerifyPersonalInfoModalStep {
    OldOTP = "old_otp",
    New = "new",
    NewOTP = "new_otp",
    Success = "success",
}

export enum ScanRequestsType {
    New = "new",
    InProgress = "inprogress",
    Delivered = "delivered",
}

export enum ScanDataSetTypeNames {
    images = "Zipped Photo Scan",
    video = "Video Scan",
    video360 = "360 Video Scan",
}

export enum ScanModelTypes {
    gaussian = "gaussian-splatting",
    nerfacto = "nerfacto",
}

export enum ModelTypesNames {
    "gaussian-splatting" = "Focused",
    "nerfacto" = "Unbounded",
}
