import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import Loadable from "shared/components/Loadable";

const ProjectsScreen = Loadable(lazy(() => import("screens/Dashboard/Projects")));
const ProjectPostScreen = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Post")));
const ProjectDetail = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Single")));
const FolderSingle = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/Folder")));

const AssetSingleScreen = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/AssetSingle")));
const FolderAssetPost = Loadable(
    lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/Folder/screens/FolderAssetPost"))
);
const FolderScanPost = Loadable(
    lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/Folder/screens/FolderScanPost"))
);
const PostFolderScreen = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/PostFolder")));
const ScanSingleScreen = Loadable(lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/ScanSingle")));
const ScanSingleEmbedScreen = Loadable(
    lazy(() => import("screens/Dashboard/Projects/screens/Single/screens/ScanSingle/EmbedScan"))
);

import URL from "shared/constants/navigator";

const projectsRoutes: RouteObject[] = [
    {
        path: URL.Projects,
        children: [
            {
                path: "",
                element: <ProjectsScreen />,
                children: [
                    {
                        path: URL.Projects_Post,
                        element: <ProjectPostScreen />,
                    },
                ],
            },
            {
                path: URL.Projects_Detail,
                children: [
                    {
                        path: "",
                        element: <ProjectDetail />,
                        children: [
                            {
                                path: URL.Projects_Post,
                                element: <ProjectPostScreen />,
                            },
                            {
                                path: URL.Projects_Folder_Post,
                                element: <PostFolderScreen />,
                            },
                        ],
                    },
                    {
                        path: URL.Projects_Folder_Detail,
                        children: [
                            {
                                path: "",
                                element: <FolderSingle />,
                                children: [
                                    {
                                        path: URL.Projects_Folder_Post,
                                        element: <PostFolderScreen />,
                                    },
                                    {
                                        path: URL.Projects_Folder_Scan_Post,
                                        element: <FolderScanPost />,
                                    },
                                    {
                                        path: URL.Projects_Folder_Asset_Post,
                                        element: <FolderAssetPost />,
                                    },
                                ],
                            },
                            {
                                path: URL.Projects_Folder_Asset_Detail,
                                element: <AssetSingleScreen />,
                                children: [
                                    {
                                        path: URL.Projects_Folder_Asset_Post,
                                        element: <FolderAssetPost />,
                                    },
                                ],
                            },
                            {
                                path: URL.Projects_Scan_Detail,
                                element: <ScanSingleScreen />,
                                children: [
                                    {
                                        path: URL.Projects_Folder_Scan_Post,
                                        element: <FolderScanPost />,
                                    },
                                    {
                                        path: URL.Projects_Scan_Detail_Embed,
                                        element: <ScanSingleEmbedScreen />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default projectsRoutes;
