const Permissions = {
    // My Scans
    Can_Create_Scan: "scans.submit.overall",
    Can_Submit_Scan_Video: "scans.submit.video",
    Can_Submit_Scan_Video_360: "scans.submit.video_360",
    Can_Submit_Scan_Zipped_Photos: "scans.submit.zipped_photos",

    Can_Edit_Scan: "scans.edit",
    Can_Delete_Scan: "scans.delete",
    Can_Use_Embeded_Scan: "scans.embeds",

    Can_View_Snapshots: "scans.snapshots",
    Can_Share_Scan_In_Public: "scans.share",
    Can_View_Scan_In_Viewer: "scans.model.view_scan_in_viewer",
    Can_Download_Scan_Model: "scans.model.download_model",
    Can_Invite_Scan_By_Email: "scans.model.invite_by_email",
    Can_Have_Watermark_Toggle: "scans.model.watermark_toggle",

    // Projects
    Can_Create_project: "projects.create",
    Can_Edit_Project: "projects.edit",
    Can_Delete_Project: "projects.delete",
    Can_Create_Folder: "projects.folder.create",

    // Teams
    Can_Edit_Team: "team.edit",
    Can_Delete_Team: "team.delete",
    Can_Leave_Team: "team.leave",
    Can_View_Team_Members: "team.members.get",
    Can_Invite_Team_Members: "team.members.invite",
    Can_Remove_Team_Members: "team.members.remove",
    Can_Transfer_Team_Ownership: "team.members.transfer_ownership",
    Can_Approve_Team_Members: "team.members.approve_request",
    Can_Do_Chat: "team.chat",

    Can_View_Scan_Request_Requestor: "scan_requests.requestor",
    Can_View_Scan_Request_Requestee: "scan_requests.requestee",
};

export default Permissions;
