import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedTemplate } from "store/slices/publishable_project";

export interface IPublishedDateBlockProps {
    id: string;
    date_str: string;
    template: number;
}

const PublishedDateBlock = forwardRef((props: any, ref) => {
    const { id, date_str, template } = props.node.attrs as IPublishedDateBlockProps;
    const dispatch = useAppDispatch();

    const dateRef = useRef(null);

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);

    const { team } = useAuth();

    const styling = useMemo(() => {
        let style = "text-lg text-[#667085] font-semibold";
        if (template == 0 || template == 1) {
            style += " text-center";
        }
        return style;
    }, [template]);

    function formatToday() {
        const today = new Date();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const day = today.getDate();
        const month = months[today.getMonth()];
        const year = today.getFullYear();

        return `${day} ${month} ${year}`;
    }

    const formattedDateString = () => {
        let formattedDate = "Published " + formatToday();

        if (template == 0 || template == 1) {
            formattedDate += ` By <span style="color: #7680FF">${team?.name}</span>`;
        }

        return formattedDate;
    };

    useEffect(() => {
        if (selectedTemplate != -1 && selectedTemplate != template) {
            let pos = 0;
            let nodeAttrs: any = null;

            props.editor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    nodeAttrs = node.attrs;
                    return false;
                }
            });

            const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                ...nodeAttrs,
                template: selectedTemplate,
                date_str: null,
            });

            props.editor!.view.dispatch(transaction);
        } else if (selectedTemplate == -1 && selectedTemplate != template) {
            setTimeout(() => {
                dispatch(setSelectedTemplate(template));
            }, 0);
        }
    }, [selectedTemplate]);

    useEffect(() => {
        if (!date_str) {
            let pos = 0;
            let nodeAttrs: any = null;

            props.editor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    nodeAttrs = node.attrs;
                    return false;
                }
            });

            const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                ...nodeAttrs,
                date_str: formattedDateString(),
            });

            props.editor!.view.dispatch(transaction);

            (dateRef.current as any).innerHTML = formattedDateString();
        } else {
            (dateRef.current as any).innerHTML = date_str;
        }
    }, [date_str, template, selectedTemplate]);

    return (
        <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
            <div ref={dateRef} className={`published-date ${styling}`}></div>
        </NodeViewWrapper>
    );
});

export default PublishedDateBlock;
