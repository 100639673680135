import { ButtonProps } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { ILoaderButtonProps } from "../LoaderButton";
import ThemeModal, { ThemeModalProps } from "../ThemeModal";

interface Props {
    className?: string;
    title: string;
    content?: string | React.ReactNode;
    icon?: React.ReactNode;
    okBtnText?: string;
    okBtnProps: ILoaderButtonProps;
    modal?: Omit<ThemeModalProps, "open">;
    children: React.ReactNode;
    disabled?: boolean;
}

const ConfirmButton = (props: Props) => {
    const [modal, setModal] = useState(false);

    return (
        <div>
            <div className={props.className} onClick={() => !props.disabled && setModal(true)}>
                {props.children}
            </div>
            <ThemeModal
                open={modal}
                onClose={() => setModal(false)}
                title={props.title}
                content={props.content}
                icon={props.icon}
                cancelBtnProps={{
                    onClick: () => {
                        setModal(false);
                    },
                }}
                buttons={[
                    {
                        children: props.okBtnText || "Confirm",
                        onClick: async (e) => {
                            let close: any = true;
                            if (props.okBtnProps.onClick) {
                                close = await props.okBtnProps.onClick(e);
                            }
                            setModal(close ? !close : false);
                        },
                        ...(_.omit(props.okBtnProps, ["onClick"]) as ButtonProps),
                    },
                ]}
                {...props.modal}
            />
        </div>
    );
};

export default ConfirmButton;
