import DummyImage from "assets/images/dummy/dummy-pic-horse-with-girl.png";

const BoardItem = () => {
    return (
        <div>
            <div className="h-[288px] w-full rounded-md overflow-hidden mb-2">
                <img src={DummyImage} alt="dummy" className="w-full h-full object-cover object-center" />
            </div>
            <h5 className="text-[#3F3E3E] text-lg leading-6 font-medium">Project Test HAB 5</h5>
        </div>
    );
};

export default BoardItem;
