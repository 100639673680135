import { createTheme } from "@mui/material/styles";

import COLOR_PALLETE from "./colorPallete";

const theme = createTheme({
    palette: {
        primary: {
            main: COLOR_PALLETE.primary.DEFAULT,
        },
        error: {
            main: COLOR_PALLETE.error.DEFAULT,
        },
        success: {
            main: COLOR_PALLETE.success.DEFAULT,
        },
        info: {
            main: COLOR_PALLETE.primary.DEFAULT,
        },
    },
    typography: {
        fontFamily: "Inter, sans-serif",
    },

    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    textAlign: "center",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {},
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    "& > .MuiBackdrop-root": {
                        backgroundColor: "rgba(132, 137, 147, 0.7)",
                        backdropFilter: "blur(4px)",
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    "& > .MuiBackdrop-root": {
                        backgroundColor: "rgba(132, 137, 147, 0.7)",
                        backdropFilter: "blur(4px)",
                    },
                },
                paperWidthSm: {
                    maxWidth: "512px",
                },
                paperWidthXs: {
                    maxWidth: "400px",
                },
                paper: {
                    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
                    borderRadius: 12,
                    textAlign: "center",
                    width: "100%",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 24,
                    paddingBottom: 16,

                    "& .MuiDialogActions-root": {
                        padding: 0,
                        paddingTop: 8,
                        paddingBottom: 16,
                    },

                    // "& .MuiDialogActions-root": {
                    //     padding: 0,
                    // },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 24,
                    paddingTop: 16,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    color: "#667085",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ".MuiAutocomplete-inputRoot": {
                        padding: "0 !important",
                    },
                    ".MuiInputBase-input": {
                        paddingLeft: "10px !important",
                        paddingRight: "10px !important",
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    ".MuiInputBase-input": {
                        height: "44px",
                        padding: "6px 10px",
                        boxSizing: "border-box",
                        fontSize: 14,

                        "::placeholder": {
                            color: "#AEADAD",
                        },
                    },
                    ".MuiInputBase-multiline textarea": {
                        padding: "0",
                    },
                    ".Mui-focused.MuiInputBase-readOnly .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#CBD5E1 !important",
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    fontSize: 14,
                },
                icon: {
                    top: "50%",
                    transform: "translateY(-50%)",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderColor: "#CBD5E1 !important",
                        borderBottomWidth: "1px !important",
                    },
                    "&:after": {
                        borderBottomWidth: "1px !important",
                    },
                    "&:hover:not(.Mui-focused):not(.Mui-disabled)": {
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#CBD5E1 !important",
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EAECEF !important",
                    },
                },
                notchedOutline: {
                    borderColor: "#CBD5E1",
                    borderRadius: 6,
                    borderWidth: "1px !important",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                type: "button",
            },
            styleOverrides: {
                root: {
                    borderRadius: 7,
                    boxShadow: "none !important",
                    textTransform: "none",
                    whiteSpace: "nowrap",

                    "&.MuiButton-colorInherit": {
                        border: "1px solid #CBD5E1",
                        color: "#344054",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: "auto",
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: "400",
                    lineHeight: "18px",
                    fontSize: 14,
                    padding: 0,
                    minWidth: "auto",
                    minHeight: "auto",
                    paddingTop: 16,
                    paddingBottom: 13,

                    "& + .MuiTab-root": {
                        marginLeft: 24,
                    },
                },
            },
        },
    },
});

export default theme;
