import { NodeViewWrapper } from "@tiptap/react";
import useImages from "assets/images";
import React, { useEffect, useRef, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";

import DummyProjectBanner from "assets/images/dummy/dummy-template-project-banner.png";
import useFileUploader from "shared/hooks/useFileUploader";
import { useEditProjectMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { setProjectRefetchApi } from "store/slices/projects";
import { CircularProgress } from "@mui/material";
import useAuth from "shared/hooks/useAuth";
import _ from "lodash";

export interface IProjectBannerBlockProps {
    id: string;
    image: string | null;
    template: number;
    user_name: string | null;
    user_img: string | null;
    team_name: string | null;
    team_bio: string | null;
    team_img: string | null;
    facebook: string | null;
    instagram: string | null;
    linkedin: string | null;
    twitter: string | null;
}

const ProjectBannerBlock = (props: any) => {
    const {
        id,
        image,
        template,
        user_name,
        user_img,
        team_name,
        team_bio,
        team_img,
        facebook,
        instagram,
        linkedin,
        twitter,
    } = props.node.attrs as IProjectBannerBlockProps;
    const dispatch = useAppDispatch();

    const {
        CameraIcon,
        SocialInstagramIcon,
        SocialFacebookIcon,
        SocialLinkedInIcon,
        SocialNewTwitterIcon,
    } = useImages();

    const { uploadFile, loading: uploadLoading, loader_id: uploadId } = useFileUploader();

    const uploadRef = useRef(null);
    const imageUrlRef = useRef(null);

    // Update Project Mutation
    const [updateProject, updateRes] = useEditProjectMutation();

    const project = useAppSelector((state) => state.projects.selected);

    const { user, team } = useAuth();

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);
    const isSelectingTemplate = useAppSelector((state) => state.publishable_project.isSelectingTemplate);

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            if (file)
                uploadFile(file, "project-banner-image-upload").then((res: any) => {
                    imageUrlRef.current = res.url;

                    updateProject({
                        id: project!.project_id,
                        data: {
                            thumbnail_id: res.file_id,
                        },
                    });
                });
        };
        reader.readAsText(file);
    };

    useEffect(() => {
        let timer: any = null;
        if (project && user && team && props.editor) {
            timer = setInterval(() => {
                const data = {
                    id,
                    image: project.thumbnail.url,
                    template,
                    user_name: user.name,
                    user_img: user.profile_image.url,
                    team_name: team.name,
                    team_bio: team.bio,
                    team_img: team.logo.url,
                    facebook: team.social_accounts.facebook,
                    linkedin: team.social_accounts.linkedin,
                    instagram: team.social_accounts.instagram,
                    twitter: team.social_accounts.twitter,
                };

                let pos = 0;
                let nodeAttrs: any = null;

                props.editor!.view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == id) {
                        pos = position;
                        nodeAttrs = node.attrs;
                        return false;
                    }
                });

                if (!_.isEqual(data, nodeAttrs)) {
                    const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, data);

                    props.editor!.view.dispatch(transaction);
                }
            }, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [project, image, user, team, selectedTemplate, template, props.editor]);

    const templateExtraUI = useMemo(() => {
        let ui = <></>;

        if (template == 1) {
            ui = (
                <div className="w-full relative pt-[40px] -mt-[30px]">
                    <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[90px] h-[90px]">
                        <img src={team_img ?? undefined} className="h-[80px] w-[80px] rounded-md object-cover" />
                        <img
                            src={user_img ?? undefined}
                            className="h-[40px] w-[40px] rounded-full absolute bottom-0 right-0 border border-[#E9E9E9] object-cover"
                        />
                    </div>
                    <div className="w-full px-8 pt-[72px]">
                        <div className="w-full text-center text-[#101828] text-xl mb-2 font-medium">
                            {user_name} from <span className="text-[#7680FF]">{team_name}</span>
                        </div>
                        <div className="w-full text-center text-xl text-[#475467] mb-16">{team_bio}</div>
                        <div className="w-fit flex items-center justify-center gap-x-[18px] absolute top-[55px] right-0">
                            {instagram && (
                                <a href={instagram}>
                                    <SocialInstagramIcon width={24} height={24} />
                                </a>
                            )}
                            {facebook && (
                                <a href={facebook}>
                                    <SocialFacebookIcon width={24} height={24} />
                                </a>
                            )}
                            {linkedin && (
                                <a href={linkedin}>
                                    <SocialLinkedInIcon width={24} height={24} />
                                </a>
                            )}
                            {twitter && (
                                <a href={twitter}>
                                    <SocialNewTwitterIcon width={24} height={24} />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else if (template == 2) {
            ui = (
                <div className="flex items-start justify-between mt-8 mb-[40px]">
                    <div className="flex gap-x-12">
                        <div>
                            <div className="text-md font-semibold text-[#7680FF] mb-3">Written by</div>
                            <div className="text-xl font-medium text-[#101828]">{user_name}</div>
                        </div>
                        <div>
                            <div className="text-md font-semibold text-[#7680FF] mb-3">Team</div>
                            <div className="text-xl font-medium text-[#101828]">{team_name}</div>
                        </div>
                    </div>
                    <div className="w-fit flex items-center justify-center gap-x-[18px]">
                        {instagram && (
                            <a href={instagram}>
                                <SocialInstagramIcon width={24} height={24} />
                            </a>
                        )}
                        {facebook && (
                            <a href={facebook}>
                                <SocialFacebookIcon width={24} height={24} />
                            </a>
                        )}
                        {linkedin && (
                            <a href={linkedin}>
                                <SocialLinkedInIcon width={24} height={24} />
                            </a>
                        )}
                        {twitter && (
                            <a href={twitter}>
                                <SocialNewTwitterIcon width={24} height={24} />
                            </a>
                        )}
                    </div>
                </div>
            );
        }

        return ui;
    }, [template, user_name, user_img, team_name, team_bio, team_img, facebook, instagram, linkedin, twitter]);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Project banner updated successfully",
            onSuccess() {
                dispatch(setProjectRefetchApi(true));

                let pos = 0;
                let nodeAttrs: any = null;

                props.editor!.view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == id) {
                        pos = position;
                        nodeAttrs = node.attrs;
                        return false;
                    }
                });

                const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                    ...nodeAttrs,
                    image: imageUrlRef.current,
                });

                props.editor!.view.dispatch(transaction);
            },
        });
    }, [updateRes]);

    useEffect(() => {});

    return (
        <NodeViewWrapper id={id} className={`project-banner-component w-full`}>
            <div className="w-full h-0 pb-[24%] relative">
                <div className="absolute w-full h-full top-0 left-0">
                    <img
                        src={isSelectingTemplate ? DummyProjectBanner : image ?? DummyProjectBanner}
                        className="w-full h-full rounded-lg object-cover"
                    />
                    <div className="absolute bottom-4 right-4">
                        <div
                            className="project-banner-btn py-2 px-3 text-sm text-[#344054] font-semibold select-none cursor-pointer border border-[#D0D5DD] bg-[#FFFFFF] hover:brightness-90 rounded-[4px]"
                            style={{
                                boxShadow: "0px 0.6457383036613464px 1.2914766073226929px 0px rgba(16, 24, 40, 0.05)",
                            }}
                            onClick={() => {
                                if (
                                    !(
                                        (uploadLoading && uploadId == "project-banner-image-upload") ||
                                        updateRes.isLoading
                                    )
                                ) {
                                    (uploadRef.current as any).click();
                                }
                            }}
                        >
                            <div
                                className={
                                    (uploadLoading && uploadId == "project-banner-image-upload") || updateRes.isLoading
                                        ? "opacity-0"
                                        : "flex items-center gap-x-[6px]"
                                }
                            >
                                <CameraIcon width={17} height={17} />
                                <span>Change Thumbnail</span>
                            </div>
                            {((uploadLoading && uploadId == "project-banner-image-upload") || updateRes.isLoading) && (
                                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <CircularProgress
                                        variant="indeterminate"
                                        size="12px"
                                        thickness={2}
                                        color="primary"
                                    />
                                </div>
                            )}
                            <input
                                ref={uploadRef}
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={handleImageUpload}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {templateExtraUI}
        </NodeViewWrapper>
    );
};

export default ProjectBannerBlock;
