import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useInviteMembersMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";
import yup from "shared/utils/yup";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";
import { InviteMemberModalForm } from ".";

const schema = yup.object().shape({
    link: yup.string().required(),
    invite_code: yup.string().required(),
    invitee_count: yup.number(),
    email1: yup.string().email().required(),
    email2: yup.string().email(),
    email3: yup.string().email(),
});

type FormValues = yup.InferType<typeof schema>;

const useInviteMemberForm = ({ closeModal }: InviteMemberModalForm) => {
    const { team } = useAuth();
    const members = team?.members;
    const dispatch = useAppDispatch();

    const [inviteTeam, inviteRes] = useInviteMembersMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            link: "",
            invite_code: "",
            invitee_count: 1,
            email1: "",
            email2: "",
            email3: "",
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (team) {
            form.setValue("invite_code", team.invite_code);
            form.setValue("link", `${window.origin}/request/teams/${team.invite_code}`);
        }
    }, [team]);

    const inviteeCount = form.watch("invitee_count");

    const addInvitee = () => {
        const count = inviteeCount ?? 0;

        if (count < 3) {
            form.setValue("invitee_count", count + 1);
        }
    };

    const onSubmit = (data: any) => {
        const { email1, email2, email3 } = data;

        const emails: any[] = [email1, email2, email3].filter((email: any) => email != "");

        if (members && members?.length > 0) {
            const memberEmails = members.map((member) => member.email);
            const duplicateEmails = emails.filter((email) => memberEmails.includes(email));

            if (duplicateEmails.length > 0) {
                errorToast(`${duplicateEmails.join(", ")} already a member or invited to this team`);
                return;
            }
        }

        if (emails.length > 0 && team?.team_id) {
            inviteTeam({
                data: {
                    emails,
                },
            });
        }
    };

    useEffect(() => {
        rtkHandler(inviteRes, {
            successMessage: "Invitation sent successfully",
            onSuccess() {
                closeModal();
            },
            onError(res) {
                if (res.status === "ValidationError") {
                    errorToast(Object.values(res.error)[0] as string);
                }
            },
        });
        dispatch(setCommonModalLoadingBtn(inviteRes.isLoading ? "invite_users_btn" : ""));
    }, [inviteRes]);

    return { form, inviteeCount, addInvitee, onSubmit };
};

export default useInviteMemberForm;
