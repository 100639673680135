import { Badge, IconButton, Tooltip } from "@mui/material";

import useAppIconButton from "./hook";
import { IAppIconButtonProps } from "./type";

const AppIconButton = (props: IAppIconButtonProps) => {
    const { styles, badegStyles } = useAppIconButton(props);

    const {
        icon,
        size = "medium",
        color = "default",
        badgeCount,
        badgeColor = "primary",
        className,
        onClick,
        tooltipTitle,
        tooltipPosition = "top",
        disabled = false,
        badgeCustomStyles,
    } = props;

    return (
        <Tooltip title={tooltipTitle} placement={tooltipPosition}>
            <div>
                <IconButton
                    onClick={onClick}
                    size={size}
                    color={color}
                    sx={styles}
                    className={className}
                    disabled={disabled}
                >
                    <Badge color={badgeColor} badgeContent={badgeCount} sx={{ ...badegStyles, ...badgeCustomStyles }}>
                        {icon}
                    </Badge>
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default AppIconButton;
