import { Button } from "@mui/material";
import { EditorContent, useEditor as useTipTapEditor } from "@tiptap/react";
import Copy03 from "@untitled-ui/icons-react/build/esm/Copy03";
import PlaceholderProject from "assets/files/placeholder_project.json";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { editorExtensions } from "screens/Dashboard/PublishableProjects/PublishableProject/partials/Editor/useEditor";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { copyTextToClipboard } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";
import { enablePreview } from "store/slices/publishable_project";
import OnboardRating from "./components/OnboardRating";
import CPContent from "./components/headers";

const CompleteProfileFinalScreen = () => {
    const dispatch = useAppDispatch();
    const Images = useImages();
    const navigate = useNavigate();
    const project = useAppSelector((state) => state.projects.selected);
    const savedProjectContent = useAppSelector((state) => state.publishable_project.defaultContent);

    const form = useForm({
        defaultValues: {
            projectName: `${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${project?.slug}`,
        },
    });
    const editor = useTipTapEditor({
        extensions: editorExtensions,
        content: null,
        editorProps: {
            attributes: {
                id: "root",
            },
        },
    });

    const onQuit = () => {
        successToast("Welcome to XSpada!");
        navigate(URL.Dashboard, { replace: true });
    };

    useEffect(() => {
        if (editor) {
            dispatch(enablePreview());

            if (project) {
                if (savedProjectContent) {
                    editor?.commands.setContent(JSON.parse(savedProjectContent!));
                } else {
                    editor?.commands.setContent(PlaceholderProject);
                }
            } else {
                editor?.commands.setContent(PlaceholderProject);
            }
        }
    }, [project, editor, savedProjectContent]);

    return (
        <CPContent
            topHeader={
                <div className="flex items-center space-x-4">
                    <OnboardRating />
                    <Button variant="contained" onClick={onQuit}>
                        Go to Dashboard
                    </Button>
                </div>
            }
            icon={Images.LogoMono}
            title={
                <>
                    That was all! <br /> Enjoy Preview Of Your Trial Project
                </>
            }
            description={"In the meantime we are finalizing your account, check out your trial project."}
        >
            {project && savedProjectContent && (
                <ThemeFormProvider
                    form={form}
                    onSubmit={() => {}}
                    className="w-[450px] mx-auto flex items-center space-x-3"
                >
                    <div
                        className="w-full !rounded-[4px] overflow-hidden hover:cursor-pointer hover:brightness-95"
                        onClick={() => copyTextToClipboard(form.getValues("projectName"), true)}
                    >
                        <Field
                            name="projectName"
                            containerClassName="bg-white pointer-events-none"
                            formGroup={false}
                            inputTextProps={{
                                disabled: false,
                                endAdornment: (
                                    <ThemeIconButton icon={<Copy03 height={16} width={16} color="#667085" />} />
                                ),
                            }}
                        />
                    </div>
                    {/* <ThemeIconButton
                    className="!border-[#D0D5DD] !border !border-solid !h-10 !w-10"
                    icon={<X_Twitter_02 height={20} width={20} />}
                    onClick={() => {}}
                />
                <ThemeIconButton
                    className="!border-[#D0D5DD] !border !border-solid !h-10 !w-10"
                    icon={<Facebook_02 height={20} width={20} />}
                    onClick={() => {}}
                />
                <ThemeIconButton
                    className="!border-[#D0D5DD] !border !border-solid !h-10 !w-10"
                    icon={<LinkedIn_02 height={20} width={20} />}
                    onClick={() => {}}
                /> */}
                </ThemeFormProvider>
            )}

            <div className="w-3/4 mx-auto bg-white border border-[#98A2B3] rounded-xl my-8 editor-preview p-8">
                <EditorContent editor={editor} disabled={true} />
            </div>
        </CPContent>
    );
};

export default CompleteProfileFinalScreen;
