import { RouteObject } from "react-router-dom";

import TeamChat from "screens/Dashboard/TeamChat";
import PlaygroundScreen from "screens/Playground";
import URL from "shared/constants/navigator";
import DashboardLayout from "shared/layouts/DashboardLayout";
import Auth from "shared/middleware/Auth";
import HasProfileSelected from "shared/middleware/HasProfileSelected";

import LoginLayout from "screens/Auth/layouts/LoginLayout";
import AcceptJoinTeamRequestScreen from "screens/Auth/screens/AcceptJoinTeamRequest";
import CompleteProfileFinalScreen from "screens/Auth/screens/CompleteProfileScreen/final";
import CompleteProfileIdentityScreen from "screens/Auth/screens/CompleteProfileScreen/identity";
import CompleteProfileLayout from "screens/Auth/screens/CompleteProfileScreen/layout";
import PersonalDetailScreen from "screens/Auth/screens/CompleteProfileScreen/personal";
import CompleteProfileProject from "screens/Auth/screens/CompleteProfileScreen/project";
import TeamAccountScreen from "screens/Auth/screens/CompleteProfileScreen/team";
import GetStartedScreen from "screens/Auth/screens/GetStarted";
import ProfileSelectionScreen from "screens/Auth/screens/ProfileSelection";
import PublishableProjectScreen from "screens/Dashboard/PublishableProjects/PublishableProject";
import PublishableProjectsLayout from "screens/Dashboard/PublishableProjects/layout";
import HasPermission from "shared/components/HasPermission";
import Permissions from "shared/components/HasPermission/Permissions";
import dashboardRoutes from "./dashboard/dashboardRoutes";
import projectsRoutes from "./dashboard/projectsRoutes";
import publishableProjectsRoutes from "./dashboard/publishableProjectsRoutes";
import scanRequestRoutes from "./dashboard/scanRequestRoutes";
import settingRoutes from "./dashboard/settingRoutes";

const dashboardScreenRoutes = [
    ...dashboardRoutes,
    ...scanRequestRoutes,
    ...projectsRoutes,
    ...settingRoutes,
    ...publishableProjectsRoutes,
];

const authRoutes: RouteObject[] = [
    {
        element: <Auth />,
        children: [
            {
                path: URL.ProfileSelection,
                element: <LoginLayout logout={true} />,
                children: [
                    {
                        path: "",
                        element: <ProfileSelectionScreen />,
                    },
                ],
            },
            {
                path: URL.GetStarted,
                element: <GetStartedScreen />,
            },
            {
                path: "/complete-profile",
                element: <CompleteProfileLayout />,
                children: [
                    {
                        path: "personal",
                        element: <PersonalDetailScreen />,
                    },
                    {
                        path: "identity",
                        element: <CompleteProfileIdentityScreen />,
                    },
                    {
                        path: "team",
                        element: <TeamAccountScreen />,
                    },
                    {
                        path: "project",
                        children: [
                            {
                                path: "",
                                element: <CompleteProfileProject />,
                            },
                            {
                                path: ":id/editor",
                                element: <PublishableProjectsLayout />,
                                children: [
                                    {
                                        path: "",
                                        element: <PublishableProjectScreen />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "final",
                        element: <CompleteProfileFinalScreen />,
                    },
                ],
            },
            {
                element: <HasProfileSelected />,
                children: [
                    {
                        element: <DashboardLayout />,
                        children: [
                            ...dashboardScreenRoutes,
                            {
                                path: URL.Playground,
                                element: <PlaygroundScreen />,
                            },
                            {
                                path: URL.Messages,
                                element: (
                                    <HasPermission permission={Permissions.Can_Do_Chat}>
                                        <TeamChat />
                                    </HasPermission>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: URL.Accept_Join_Team_Request,
                element: <AcceptJoinTeamRequestScreen />,
            },
        ],
    },
];

export default authRoutes;
