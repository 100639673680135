import React from "react";

const TermsAndCondition = () => {
    return (
        <>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    This Privacy Policy applies to all users of app.xspada.io (“XSpada,” “we,” or “us,” formerly d/b/a
                    Soar3D) and its associated websites; subdomains; mobile versions; any associated applications; all
                    services; and through other interactions, communications and correspondence with you (collectively,
                    the “Services”), which are operated by XSpada Inc.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    XSpada understands that your privacy is important to you and that you care about how your Personal
                    Information is used and shared online. XSpada respects and values the privacy of everyone who visits
                    or uses the Services, and will only collect and use Personal Information in ways that are described
                    here, and in a manner that is consistent with our obligations and your rights under the law.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    “Personal Information” means any information relating to an identified or identifiable individual,
                    such as, but not limited to, an individual’s name, address, telephone number or email address.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    In conjunction with the
                </span>
                <a
                    href="https://docs.google.com/document/d/1Mlv3FFTl3vXdzanv_xy0qlQSFR-bpUXD/edit?usp=sharing&ouid=103422548691707556859&rtpof=true&sd=true"
                    style={{ textDecoration: "none" }}
                >
                    <span
                        style={{
                            fontSize: "14px",
                            color: "#1155cc",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "underline",
                            WebkitTextDecorationSkip: "none",
                            textDecorationSkipInk: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {" "}
                        Terms of Service
                    </span>
                </a>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    , this Privacy Policy sets out the basis on which any Personal Information you provide will be
                    collected, processed, used and stored by XSpada. Please read it carefully. By visiting or using the
                    Services, you are accepting and consenting to the practices described in this Privacy Policy, which
                    may be amended at our sole discretion from time to time. If you visit or use the Services after
                    XSpada has published such changes, you will be agreeing to be bound by those changes.{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If you do not agree to be bound by this Privacy Policy at any time, you should not use or visit the
                    Services.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    This Privacy Policy explains the following:
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1. What Personal Information we collect;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2. How we use your Personal Information;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    3. How your Personal Information may be shared;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4. How we store and process information about you;&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    5. When and how we communicate with you;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    6. How you can opt-out from receiving communications from us;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7. How long we may keep Personal Information about you;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    8. Cookies and our Analysis Tools;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    9. Information over the Internet and other websites;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10. Third-party services
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    11. How we protect your personal information&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    12. Children’s privacy
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    13. Legal disclaimer;&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    14. Your California Privacy Rights;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    15. California Consumer Privacy Act; and
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    16. Updates to this policy
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    17. How to contact us
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            What information we collect
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We collect Personal Information either when you provide it to us on our website or through the use
                    of our Services, or in some cases, when other sources provide it to us.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Personal Information you provide to us:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Contact details
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as your first and last name, physical address(es), phone number, mobile phone model
                            (if applicable), email address(es) and any additional information you choose to include in
                            your profile.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Authentication Credentials
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            that let you access your accounts and your information via other service providers, such as
                            your in-app username, password, and any username(s) utilized for in-app integration accounts
                            (e.g., Github, ImagineX, etc.).
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Content
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as any photograph uploaded onto the Services, any video uploaded onto the Services,
                            map data, sensor data, telemetry data, location data, in-app sent messages, notes taken
                            in-app and other content using the Services’ functionality.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Communications
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            that we exchange with you, including when you contact us via our Services with questions,
                            feedback or otherwise.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Usage{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            information, such as your user access logs, in-app search history, user activity logs,
                            in-app purchases, and billing history.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Payment information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            required by our payment processor, Stripe, Inc., needed to complete your transactions to use
                            our Services, including, name, payment card information and billing information. We do not
                            store payment card numbers.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Marketing information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as your preferences for receiving our marketing communications, and details about
                            your engagement with those communications.
                        </span>
                    </p>
                </li>
            </ul>
            <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }}>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    &nbsp;
                </span>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Records of data in any synced third-party apps.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Other data{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            not specifically listed here, which we will use as described in this Privacy Policy or as
                            otherwise disclosed at the time of collection.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If your Personal Information changes, or if you no longer choose to use our Services, you can email
                    us at [info@xspada.com] to assist you with changes to your account. To the extent your account
                    closes or becomes inactive, we will continue to protect your information as described by this
                    Privacy Policy.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Information we obtain from third parties:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Social media information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We may maintain pages on social media platforms, such as Facebook, Instagram, LinkedIn and
                            Twitter, and other third-party platforms. When you visit or interact with our pages on those
                            platforms, the platform provider’s privacy policy will apply to your interactions and their
                            collection, use and processing of your personal information. You or the platforms may
                            provide us with information through the platform and we will treat such information in
                            accordance with this Privacy Policy.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Third-party login information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . When you link, connect or login to the Services with a third-party service, you direct the
                            service to send us information, such as your registration, friends list and profile
                            information as controlled by that service or as authorized by you via your privacy settings
                            at that service.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Other sources
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We may obtain your personal information from other third-parties, such as marketing
                            partners, publicly-available sources and data providers.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Automatic data collection
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We and our service providers may automatically log information about you, your computer or
                            mobile device, and your interaction over time with our Services, our communications and
                            other online services, such as:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Device data
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as your computer or mobile device’s operating system type and version, manufacturer
                            and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g.,
                            phone or tablet), IP address, unique identifiers (including identifiers used for advertising
                            purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi,
                            LTE, 4G, 5G), and general location information such as city, state or geographic area.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Online activity data
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as pages or screens you viewed, how long you spent on a page or screen, browsing
                            history, navigation paths between pages or screens, information about your activity on a
                            page or screen, access times and duration of access, and whether you have opened our
                            marketing emails or clicked links within them.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Location information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as geolocation information when you use our mobile application. This information may
                            include your physical location, such as street address, latitude and longitude, and IP
                            address, and the time of recording the location or the duration at that location.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Unmanned ground or aerial vehicle (“UAV”) information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , such as information identifying the vehicle, traversal or flight path, traversal or flight
                            logs, location data, telemetry data and sensor data, where applicable. When you operate a
                            UAV via the Services, we also collect imagery, map data and video taken during the traversal
                            or flight.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "54pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We use the following tools for automatic data collection:
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Cookies
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , which are text files that websites store on a visitor’s device to uniquely identify the
                            visitor’s browser or to store information or settings in the browser for the purpose of
                            helping you navigate between pages efficiently, remembering your preferences, enabling
                            functionality, helping us understand user activity and patterns, and facilitating online
                            advertising.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Local storage technologies
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , like HTML5, that provide cookie-equivalent functionality but can store larger amounts of
                            data, including on your device outside of your browser in connection with specific
                            applications.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Web beacons
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or
                            email was accessed or opened, or that certain content was viewed or clicked.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Non-Personal Information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Some of the information that we acquire cannot identify, distinguish or trace you, your
                            computer or your device, even if combined with other identifying information, and some
                            information that could be considered Personal Information when combined with other
                            identifying information is not used in a way that identifies, distinguishes or traces you,
                            your computer or your device, but is instead used in an anonymous way, often aggregated with
                            other anonymous information about other users
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How we use your personal information
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To operate our Services
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            so as to:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Provide, operate, and improve our Services and our business;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Process your payments and complete transactions with you;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Quickly process user requests for information and to allow us to respond efficiently to user
                            questions;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Monitor Services statistics;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Administer a contest or other promotion, survey or other features;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Communicate with you about our Services, including by sending announcements, updates,
                            security alerts and support and administrative messages; and
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Provide support, and respond to requests, questions and feedback.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For customer relationship management purposes.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For research and development so as to:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To understand and analyze the usage trends and preferences of our users, to improve the
                            Services, and to develop new products, services, features and functionalities.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For marketing and advertising purposes, including:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Direct marketing: We or our advertising partners may from time-to-time send you direct
                            marketing communications as permitted by law, including, but not limited to, notifying you
                            of special promotions, offers and events via email and in-app notifications. You may opt out
                            of our marketing communications as described in the “Opt out of marketing communications”
                            section below.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Interest-based advertising: We engage our advertising partners, including third-party
                            advertising companies and social media companies, to display ads around the web. These
                            companies may use cookies and similar technologies to collect information about your
                            interaction (including the data described in the “Automatic data collection” section above)
                            over time across our Services, our communications and other online services, and use that
                            information to serve online ads that they think will interest you. This is called
                            interest-based advertising. We may also share information about our users with these
                            companies to facilitate interest-based advertising to those or similar users on other online
                            platforms.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For legal purposes, such as:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Complying with applicable laws, lawful requests and legal processes;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Responding to subpoenas or requests from government authorities; and
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Helping prevent the instigation of a crime, facilitating an investigation related to public
                            safety, helping protect the safety of a child using our Services, helping protect the
                            security or integrity of our Services, or to enable us to take precautions against
                            liability.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To address any administrative or legal issues pertaining to XSpada, including but not
                            limited to intellectual property infringement, defamation, rights of privacy issues, to
                            enforce our Terms of Service or other legal rights, or as may be required by applicable laws
                            or regulations or requested by any judicial process or governmental agency.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For compliance, fraud prevention and safety to:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Protect our, your or others’ rights, privacy, safety or property (including by making and
                            defending legal claims;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Enforce the terms and conditions that govern our Services; and
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or
                            illegal activity.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "54pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To keep our Services secure and to identify and prevent fraud in the use of our
                            Services.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "upper-alpha",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "18pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To create anonymous data from your personal information and other individuals whose personal
                            information we collect. We make personal information into anonymous data by removing
                            information that makes the data personally identifiable to you. We may use this anonymous
                            data and share it with third-parties for our lawful business purposes, including, but not
                            limited to analyzing and improving our Services, conducting research and promoting our
                            business.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How your personal information may be shared
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Except as set forth below, we do not sell, trade, or otherwise transfer to third-parties our users’
                    Personal Information unless we receive users’ consent. That being said, in the following situations,
                    we may share Personal Information with outside parties:
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To other users
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : We may share images of the picture(s) or video(s) you upload onto the Services with other
                            users of our Services or with third-party service providers.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To service providers
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : We may share your Personal Information with third-party companies and individuals that
                            provide services on our behalf or help us operate our Services (such as, but not limited to,
                            customer support, hosting, analytics, email delivery, marketing, payment processing, and
                            database management services). We limit the Personal Information provided to these service
                            providers to that which is reasonably necessary for them to perform their functions, and we
                            require them to agree to maintain the confidentiality of such Personal Information.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Professional advisors
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : We may disclose your personal information to professional advisors, such as lawyers,
                            bankers, auditors and insurers, where necessary in the course of the professional services
                            that they render to us.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            For compliance, fraud prevention and safety
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : We may share your Personal Information for the compliance, fraud prevention and safety
                            purposes described above.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Business transfers
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : We may sell, transfer or otherwise share some or all of our business assets, including
                            your Personal Information, in connection with a business transaction or potential business
                            transaction, such as but not limited to, a corporate divestiture, merger, consolidation,
                            acquisition, reorganization or sale of assets, or in the event of bankruptcy or
                            dissolution.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Affiliates and sponsors
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            : In connection with our content and advertisements, which you would only receive after
                            opting in, our affiliates and sponsors may collect Personal Information.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            To help protect users of our Services
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . In the event that the safety of our users is threatened, and we are able to do something
                            about that by sharing Personal Information with third-parties, we reserve the right to do
                            so. This may be done to prevent the instigation of a crime, to help facilitate an
                            investigation related to public safety, to help protect the safety of a child using our
                            Services, to help protect the security or integrity of our Services, or to enable us to take
                            precautions against liability.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            As otherwise allowed by law
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We may transfer Personal Information to third-parties where we are expressly authorized by
                            applicable law to do so.&nbsp;
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Aggregated information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We may aggregate information that you provide with information provided by other
                            individuals in such a manner that the information is not personally identifiable to you, and
                            we may transfer that aggregated information to third-parties.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Blogs, message boards, comments and chat functionality
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . We may make available services such as blogs, message boards, comments and chat
                            functionality to which you are able to post information and materials. Please note that any
                            information you disclose through such services or otherwise on the Services becomes public
                            information and may be available to other users of the Services and to the general public.
                            In addition, when you choose to make a posting on such services certain Personal Information
                            will be available for other users to view. We urge you to exercise discretion and caution
                            when deciding to disclose Personal Information about you, or any other information, on the
                            Services. WE ARE NOT RESPONSIBLE FOR THE USE BY ANY THIRD-PARTY OF ANY PERSONAL INFORMATION
                            YOU VOLUNTARILY DISCLOSE THROUGH ANY SUCH SERVICES OR OTHERWISE ON THE SERVICES.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "disc",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "36pt",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            With your express consent.
                        </span>
                    </p>
                </li>
            </ul>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How we store and process information about you
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The Services are hosted in the United States. The information that we collect from you may be
                    transferred to, stored, used and processed by XSpada in the United States or one of our service
                    providers based in the United States our outside of the United States. Such entities may be engaged
                    in, among other things: (a) the processing of payment details; and (b) the provision of support
                    services.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If you are visiting from the European Union or other regions with laws governing information
                    collection and use that may differ from United States law, please note that you are transferring
                    your Personal Information to the United States. The laws of some countries may not provide the same
                    levels of protection of Personal Information as your home country, particularly if you are a
                    resident of the European Union. By submitting information, you agree to the collection, transfer,
                    storage, use and processing of information about you for the purposes described in this Privacy
                    Policy. When you provide Personal Information to us, our service providers or vendors, you consent
                    to the processing of your information in the United States as the Services are operated in the
                    United States. We will take reasonable steps to ensure that information about you is treated
                    securely in accordance with this Privacy Policy, and that all information you provide to us is
                    stored on our secure servers or those of our service providers.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    YOU ACKNOWLEDGE THAT THE LAWS OF THE UNITED STATES TREAT YOUR INFORMATION IN A MANNER THAT MAY BE
                    SUBSTANTIALLY DIFFERENT FROM, AND LESS PROTECTIVE THAN, THE TREATMENT REQUIRED UNDER THE LAWS OF
                    OTHER COUNTRIES AND JURISDICTIONS. IF YOU DO NOT WANT YOUR INFORMATION TRANSFERRED TO THE UNITED
                    STATES, YOU SHOULD NOT SHARE YOUR INFORMATION WITH US OR MAKE USE OF THE SERVICES.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    TO THE EXTENT ALLOWED BY THE LAW OF THE COUNTRY IN WHICH YOU ARE LOCATED, YOU EXPRESSLY WAIVE ANY
                    RIGHT YOU MAY HAVE TO REQUIRE US TO TREAT YOUR IDENTIFYING INFORMATION IN ACCORDANCE WITH THE LAWS
                    OF ANY COUNTRY OR JURISDICTION OTHER THAN THE UNITED STATES. HOWEVER, THE FOREGOING WAIVER MAY NOT
                    BE LEGALLY BINDING IN SOME COUNTRIES, SUCH AS THE MEMBER STATES OF THE EUROPEAN UNION. TO THE EXTENT
                    IT IS NOT LEGALLY BINDING IN THE COUNTRY IN WHICH YOU ARE LOCATED, THIS FOREGOING WAIVER DOES NOT
                    APPLY TO YOU.&nbsp;&nbsp;&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            When and how we communicate with you
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    You may unsubscribe from promotional emails and other commercial communications by following the
                    instructions contained within the communication or emailing us at: [info@xspada.com]. Please note
                    that due to marketing production schedules you may receive messages already in production.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How you can opt-out from receiving communications from us
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    At any time, you can add or remove your name from a XSpada list by emailing [info@xspada.com].
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We will endeavor to comply with your request as soon as reasonably practicable. Please keep in mind
                    that if you opt-out of receiving promotional messages from XSpada, we will continue to send you
                    transactional messages and important account-related information regarding the Services or content
                    or services offered through the Services.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={7}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How long we will hold Personal Information about you
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We will hold Personal Information about you on our systems for as long as is necessary for the
                    relevant service, subject to any applicable laws and/or regulations. In the case that you wish to
                    remove your Personal Information from our list, once the Personal Information is deleted, we will
                    not use the Personal Information about you other than for administrative purposes.&nbsp;&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={8}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Cookies and our analysis tools
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We may use cookies, log files, clear gifs, and/or similar devices to identify you, to keep track of
                    your interactions with the Services, and to enhance certain activities available on the Services.
                    These devices are small text or other electronic files that are used for user identification
                    purposes, record keeping purposes, and to increase the ease of use of the Services. We may use
                    persistent or non-persistent cookies. Users can remove or reject persistent cookies by following the
                    directions provided in their Internet browser’s “help” file. If a user removes a persistent cookie
                    or rejects persistent cookies, the functionality of the Services or any part thereof may be impaired
                    or unavailable.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={9}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Information over the Internet and other websites
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The Internet is a global network and, therefore, there are times when information about you travels
                    globally and may not always be completely secure. If you provide us with information over the
                    Internet, this will be at your own risk. By using the Services, you agree and authorize us to
                    process your information this way. However, please be advised that we use commercially reasonable
                    procedures and security features to prevent unauthorized access to information about you.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={10}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Third-party services
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The Services may contain features or links to websites and services provided by third-parties. Any
                    information you provide on third-party sites or services is provided directly to the operators of
                    such services and is subject to those operators’ policies, if any, governing privacy and security,
                    even if accessed through the Services. We are not responsible for the content or privacy and
                    security practices and policies of third-party sites or services to which links or access are
                    provided through the Services. We encourage you to learn about third-parties’ privacy and security
                    policies before providing them with information.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={11}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How we protect your Personal Information
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We implement reasonable security measures to secure and protect your Personal Information. Our
                    services incorporate privacy by design, including minimizing the data collected from customers, and
                    technical and organizational measures to ensure we are providing the confidentiality and integrity
                    which your data deserves. If we experience a data breach compromising Personal Information, we will
                    notify you as quickly as possible, as required by regulatory requirements.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={12}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Children’s privacy
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Children’s safety is important to us, and we encourage parents and guardians to take an active
                    interest in the online activities of their children. Our Services are not directed to children under
                    the age of 16, and we do not knowingly collect Personal Information from children under the age of
                    16. If we learn we have collected Personal Information from a child under the age of 16 on our
                    Services, we will delete that information as quickly as possible. If you believe we have collected
                    any such Personal Information on our Services, please notify us at [info@xspada.com].&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={13}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Legal disclaimer
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We are not responsible nor will we be liable to you in any way for events beyond our direct control.
                    Because of the complex and constantly changing nature of our technology and business, we cannot
                    guarantee nor do we represent that our performance will be error free, and to the maximum extent
                    permitted by law we will not be liable for any direct, indirect, incidental, consequential or
                    punitive damages or other loss suffered by you and relating to the use or release of your Personal
                    Information.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={14}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Your California privacy rights
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Shine the Light Law
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    A California privacy law that went into effect on January 1, 2005, the Shine the Light Law allows
                    California residents to obtain a list of third-parties to whom a business has disclosed personal
                    information it shares such information with for direct marketing purposes. However, if a business
                    adopts and discloses in its privacy policy a policy of not disclosing customers’ personal
                    information to third-parties for their direct marketing purposes unless the customer first
                    affirmatively agrees to the disclosure, the business may comply with the law by notifying the
                    customer of their right to prevent disclosure of personal information and by providing a cost-free
                    means to exercise that right.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    As stated earlier in this Privacy Policy, Personal Information submitted at the time of registration
                    or submission may be used for marketing and promotional purposes only with the user’s permission.
                    Whenever a user agrees to allow us to collect Personal Information, we provide the user with an
                    option to prevent such information from being shared for marketing and promotional purposes with
                    third-parties. Furthermore, a user may exercise this cost-free option at any time by sending an
                    email to [info@xspada.com].
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Because these Services have a comprehensive privacy policy and provide you with details on how you
                    may opt-in or opt-out of the use of your Personal Information by third-parties for direct marketing
                    purposes, we are not required to provide you with the third-party list.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={15}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            California Consumer Privacy Act (the “CCPA”)
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    XSpada’s{" "}
                </span>
                <a
                    href="https://docs.google.com/document/d/1FeVOh41uI50uei9XJI8rY8u0cWGgS4se/edit?usp=sharing&ouid=103422548691707556859&rtpof=true&sd=true"
                    style={{ textDecoration: "none" }}
                >
                    <span
                        style={{
                            fontSize: "14px",
                            color: "#1155cc",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "underline",
                            WebkitTextDecorationSkip: "none",
                            textDecorationSkipInk: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        California Specific Privacy
                    </span>
                </a>
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    Notice can be accessed here.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={16}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Updates to this policy
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We occasionally update this Privacy Policy. When we do, we will also revise the “last modified” date
                    at the beginning of the Privacy Policy. Your continued use of our Services after such changes will
                    be subject to the then-current Privacy Policy. We encourage you to periodically review this Privacy
                    Policy to stay informed about how we collect, use and disclose your information.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={17}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p dir="ltr" style={{ marginTop: "0pt", marginBottom: "0pt" }} role="presentation">
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            How to contact us
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Unless otherwise indicated, XSpada is the entity responsible for processing your Personal
                    Information as described in this Privacy Policy. If you have questions about this Privacy Policy or
                    XSpada, please contact us by email at [info@xspada.com] or at:
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    [127 Maritime Dr.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Port Angeles, WA
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    marginLeft: "36pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    98362]
                </span>
            </p>
        </>
    );
};

export default TermsAndCondition;
