import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetScanningDevice: {
            providesTags: ["ScanningDevices"],
        },
        AddScanningDevices: {
            invalidatesTags: (_, error) => (error ? [] : ["ScanningDevices"]),
        },
        AddUserScanningDevices: {
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
        RemoveUserScanningDevices: {
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
    },
});

export const {
    useGetScanningDeviceQuery,
    useAddScanningDevicesMutation,
    useAddUserScanningDevicesMutation,
    useRemoveUserScanningDevicesMutation,
} = api;
