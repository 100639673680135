import useImages from "assets/images";
import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useMatch, useNavigate } from "react-router-dom";
import AppIconButton from "../ui/AppIconButton";
import { IAppIconButtonProps } from "../ui/AppIconButton/type";
import TextIconButton from "../ui/TextIconButton";

const HeaderActionBtns = (props: IHeaderMenuButtonsProps) => {
    const { buttons, closeUrl, mergeLastButton = false } = props;
    const navigate = useNavigate();
    const { CloseIcon } = useImages();
    const [selected, setSelected] = useState<IHeaderMenuButtonProps | null>(null);
    const location = useLocation();

    const urlMatched = useMatch(closeUrl);

    useEffect(() => {
        urlMatched && setSelected(null);
    }, [urlMatched]);

    useEffect(() => {
        if (!selected) {
            // setSelected where buttons.url === currentUrl by using matchPath
            const selectedButton = buttons.find((button) => {
                if (!button.url) return false;
                return matchRoutes(
                    [
                        {
                            path: button.url,
                            caseSensitive: false,
                            end: true,
                        },
                    ],
                    location.pathname
                );
            });

            selectedButton && setSelected(selectedButton);
        }
    }, [navigate]);

    const onSelect = (button: IHeaderMenuButtonProps) => {
        button.onClick && button.onClick(undefined as any);
        button.url && navigate(button.url);
    };

    const lastButton = buttons[buttons.length - 1];
    let customButton = buttons;

    if (mergeLastButton) {
        customButton = buttons.slice(0, buttons.length - 1);
    }

    return (
        <>
            {customButton.length || selected ? (
                <div className="scan-btn-container">
                    {selected ? (
                        <ShowSelectedButton {...selected} />
                    ) : (
                        customButton.map((button, index) => {
                            return (
                                <AppIconButton
                                    key={index}
                                    variant="filled"
                                    {...button}
                                    onClick={() => onSelect(button)}
                                />
                            );
                        })
                    )}
                </div>
            ) : (
                <></>
            )}
            {selected ? (
                <div className="scan-btn-container">
                    <AppIconButton
                        tooltipTitle="Close"
                        className="!bg-[#949496] !bg-opacity-25"
                        variant="filled"
                        onClick={() => {
                            navigate(closeUrl);
                        }}
                        icon={<CloseIcon color="#5E5D5D" />}
                    />
                </div>
            ) : (
                mergeLastButton &&
                lastButton && (
                    <div className="scan-btn-container">
                        {lastButton.text ? (
                            <TextIconButton
                                text={lastButton.text}
                                {...lastButton}
                                onClick={() => onSelect(lastButton)}
                            />
                        ) : (
                            <AppIconButton {...lastButton} onClick={() => onSelect(lastButton)} />
                        )}
                    </div>
                )
            )}
        </>
    );
};

export default HeaderActionBtns;

const ShowSelectedButton = (props: IHeaderMenuButtonProps) => {
    const { actionTitle = "", ...others } = props;
    return (
        <div className="flex items-center space-x-4">
            <div className="text-right">
                <h4 className="text-md font-medium text-p duration-300 transition-all header-action-btn-title">
                    {actionTitle}
                </h4>
                <h5 className="text-p text-[10px] text-opacity-50 duration-300 transition-all header-action-btn-desc">
                    Mode
                </h5>
            </div>
            <AppIconButton {...others} disabled />
        </div>
    );
};

export interface IHeaderMenuButtonsProps {
    buttons: IHeaderMenuButtonProps[];
    closeUrl: string;
    mergeLastButton?: boolean;
}

export interface IHeaderMenuButtonProps extends IAppIconButtonProps {
    text?: string;
    actionTitle: string;
    url?: string;
    onClick?: (e: any) => void;
}
