import { Button } from "@mui/material";
import useImages from "assets/images";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";

const TrailPeriodEndedScreen = () => {
    const { ArrowChevronRight } = useImages();

    return (
        <ThemeScreenCard>
            <div className="card-dashed-border flex items-center justify-center text-center h-full">
                <div className="max-w-[500px] flex items-center justify-center flex-col">
                    <h4 className="text-[20px] font-bold mb-[10px] text-[#5E5D5D]">Your trial period has ended.</h4>
                    <p className="text-md font-normal mb-4 text-[#667085]">
                        Your team’s trial period for 7 days has ended. Buy a teams subscription in settings to resume
                        activity through your team.
                    </p>
                    <Button variant="text">
                        Activate subscription
                        <ArrowChevronRight color="#7680FF" />
                    </Button>
                </div>
            </div>
        </ThemeScreenCard>
    );
};

export default TrailPeriodEndedScreen;
