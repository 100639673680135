import { useState } from "react";
import { IRenderImageProps } from ".";

const useRenderImage = (props: IRenderImageProps) => {
    const { image } = props;
    const [loaded, setLoaded] = useState(false);

    const onImageLoad = () => {
        setLoaded(true);
    };

    return { loaded, image, onImageLoad };
};

export default useRenderImage;
