import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import ProjectLinksColumnBlock from "./ProjectLinksColumnBlock";

export default Node.create({
    name: "projectLinksColumnComponent",
    content: "",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: "projectLinks",
            },
            link: {
                default: null,
            },
            facebook: {
                default: null,
            },
            linkedin: {
                default: null,
            },
            twitter: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.project-links-column", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    link: dom.getAttribute("link"),
                    facebook: dom.getAttribute("facebook"),
                    linkedin: dom.getAttribute("linkedin"),
                    twitter: dom.getAttribute("twitter"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "project-links-column",
                id: node.attrs.id,
                link: node.attrs.link,
                facebook: node.attrs.facebook,
                linkedin: node.attrs.linkedin,
                twitter: node.attrs.twitter,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ProjectLinksColumnBlock);
    },
});
