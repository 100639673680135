import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { ICodeBlockProps } from "./CodeBlock";
import useImages from "assets/images";

const CodeBlockProperties = ({ editor, props }: { editor: Editor | null; props: ICodeBlockProps }) => {
    const { id, index, size } = props as ICodeBlockProps;

    const { EditorTextSizeIcon } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        index: "yes" | "no";
        size: 1 | 2 | 3 | 4 | 5;
    }>({
        defaultValues: {
            id,
            index: index ? "yes" : "no",
            size,
        },
    });

    const index_val = watch("index");
    const size_val = watch("size");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            index: index_val == "yes",
            size: size_val,
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const node = parentEditor!.view.state.schema.nodes.codeBlockComponent.create(data);

        const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [index_val, size_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                <div className="text-sm text-[#667085] mt-4 mb-2">Index</div>
                <ToggleButtonGroup
                    value={index_val}
                    exclusive
                    fullWidth
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setValue("index", newValue);
                        }
                    }}
                    sx={{
                        // Applying styles using the sx prop
                        ".MuiToggleButtonGroup-grouped": {
                            height: 34,
                            flexGrow: 1, // Makes each toggle button grow to fill the available space
                            borderRadius: "8px", // Rounded corners (you can adjust this value)
                            border: "1px solid #D0D5DD", // Removes the border
                            color: "#667085",
                            fontSize: "12px",
                            textTransform: "capitalize",

                            "&.Mui-selected": {
                                color: "#7680FF",
                                background: "#F2F3FD",
                            },
                        },
                    }}
                >
                    <ToggleButton value={"yes"} aria-label="Yes">
                        Yes
                    </ToggleButton>
                    <ToggleButton value={"no"} aria-label="No">
                        No
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className="w-full pt-4 px-5">
                <div className="text-sm text-[#667085] mb-2">Size</div>
                <ToggleButtonGroup
                    value={size_val}
                    exclusive
                    fullWidth
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setValue("size", newValue);
                        }
                    }}
                    sx={{
                        // Applying styles using the sx prop
                        ".MuiToggleButtonGroup-grouped": {
                            height: 34,
                            flexGrow: 1, // Makes each toggle button grow to fill the available space
                            borderRadius: "8px", // Rounded corners (you can adjust this value)
                            border: "1px solid #D0D5DD !important", // Removes the border
                            color: "#667085",
                            fontSize: "12px",
                            textTransform: "capitalize",

                            "&.Mui-selected": {
                                color: "#7680FF",
                                background: "#F2F3FD",
                            },
                        },
                    }}
                >
                    <ToggleButton value={1} aria-label="1">
                        <EditorTextSizeIcon width={14} height={14} />
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="1">
                        <EditorTextSizeIcon width={12} height={12} />
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="1">
                        <EditorTextSizeIcon width={10.75} height={10.75} />
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="1">
                        <EditorTextSizeIcon width={9.5} height={9.5} />
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="1">
                        <EditorTextSizeIcon width={8} height={8} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </ThemeFormProvider>
    );
};

export default CodeBlockProperties;
