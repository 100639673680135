import useImages from "assets/images";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { successToast } from "shared/utils/toast";
import { setCloseCommonModal, setCommonModal, setCommonModalLoadingBtn } from "store/slices/commonModal";
import { setToken } from "store/slices/token";
import { useAppDispatch } from "./useRedux";
import { logout } from "store/slices/user";

const useLogout = () => {
    const Images = useImages();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let logoutInterval: any;

    const confirmLogout = (
        title: string = "Are you sure you want to sign out?",
        content: string = "You can always continue again from where you left off."
    ) => {
        dispatch(
            setCommonModal({
                icon: <Images.LogoutLayerIcon height={48} width={48} />,
                title,
                content,
                PaperProps: {
                    className: "!max-w-[360px]",
                },
                dialogueActionClassName: "flex-col",
                buttons: [
                    {
                        children: "Sign Out",
                        id: "logout-modal",
                        loadingText: "Signing out...",
                        onClick: () => {
                            dispatch(setCommonModalLoadingBtn("logout-modal"));
                            logoutInterval = setTimeout(() => {
                                successToast("Logout Successfully");
                                dispatch(setCommonModalLoadingBtn(""));
                                dispatch(setCloseCommonModal());
                                clearInterval(logoutInterval);
                                doLogout();
                            }, 1500);
                        },
                    },
                ],
            })
        );
    };

    const doLogout = () => {
        dispatch(setToken(null));
        dispatch(logout());
        navigate(URL.Login, { replace: true });
    };

    return { confirmLogout, doLogout };
};

export default useLogout;
