import useImages from "assets/images";
import { useState } from "react";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import CheckboxCard from "shared/components/ui/CheckboxCard";

export type AccountTabSteps = "community" | "new" | "join";

const AccountPostModalContent = (props: { onClose: () => void; onClickNext: (key: AccountTabSteps | "") => void }) => {
    const [tab, setTab] = useState<AccountTabSteps>("new");
    const Images = useImages();

    const TabOptions = [
        // {
        //     key: "community",
        //     title: "Community Profile",
        //     content: "A community profile is independent of any team and helps create personalized content.",
        //     icon: <Images.UserLayerIcon height={40} width={40} />,
        // },
        {
            key: "new",
            title: "Create A New Team",
            content: "With a new team, you can invite people and collaborate on your scans.",
            icon: <Images.TeamLayerIcon />,
        },
        {
            key: "join",
            title: "Join Another Team",
            content: "You can join another team to collaborate on their scans using team invite codes.",
            icon: <Images.JoinTeamLayerIcon />,
        },
    ];

    return (
        <>
            <div>
                <div className="flex space-y-3 flex-col mb-5">
                    {TabOptions.map((tabOption, index) => (
                        <CheckboxCard
                            key={index}
                            onClick={() => setTab(tabOption.key as AccountTabSteps)}
                            checked={tab === tabOption.key}
                            value={tabOption.key}
                        >
                            <div className="flex flex-col">
                                <div className="font-medium text-[#344054] text-md leading-5">{tabOption.title}</div>
                                <div className="font-normal text-[#667085] text-sm">{tabOption.content}</div>
                            </div>
                        </CheckboxCard>
                    ))}
                </div>
                <CommonModalActionButton
                    cancelBtnProps={{
                        onClick: props.onClose,
                    }}
                    buttons={[
                        {
                            children: "Next",
                            disabled: false,
                            onClick: () => {
                                props.onClose();
                                props.onClickNext(tab);
                            },
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default AccountPostModalContent;
