import { useEffect, useState } from "react";
import { IGeocoderProps } from "./type";

const useGeocoder = (props: IGeocoderProps) => {
    const [value, setValue] = useState(props.value || "");
    const [suggestions, setSuggestion] = useState([]);

    const onChange = (newValue: any) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (value.length > 0) {
            const getSuggestions = async () => {
                try {
                    const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true`;
                    const response = await fetch(endpoint);
                    const results = await response.json();

                    if (results?.features) {
                        setSuggestion(results.features);
                    }
                } catch (e) {
                    return [];
                }
            };

            getSuggestions();
        } else if (value.length == 0 && suggestions.length > 0) {
            setSuggestion([]);
        }
    }, [value]);

    return { value, onChange, suggestions };
};

export default useGeocoder;
