import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    color?: "primary" | "danger" | "default";
    iconColor?: string;
    className?: React.HTMLAttributes<HTMLDivElement>["className"];
    iconClassName?: React.HTMLAttributes<SVGSVGElement>["className"];
}

const LayerIcon = (props: Props) => {
    const Icon = props.icon;
    const color = props.color || "primary";

    return (
        <div
            className={twMerge(
                "h-10 w-10 flex items-center justify-center rounded-full border-[6px] border-solid",
                color === "primary" && "bg-[#E4E6FF] border-[#F2F3FD]",
                color === "danger" && "bg-[#FFE2E7] border-[#FFF8F9]",
                color === "default" && "bg-[#F2F4F7] border-[#F9FAFB]",
                props.className
            )}
        >
            <Icon
                height={18}
                width={18}
                color={props.iconColor}
                className={twMerge(
                    !props.iconColor ? color === "primary" && "text-primary" : color === "danger" ? "!text-error" : "",
                    props.iconClassName
                )}
            />
        </div>
    );
};

export default LayerIcon;
