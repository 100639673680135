import useImages from "assets/images";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { toggleCustomerSupportModal } from "store/slices/utils";
import LayerIcon from "../ui/LayerIcon";
import ThemeModal from "../ui/ThemeModal";
import CustomerSupportModalContent from "./cotent";

const CustomerSupportModal = () => {
    const Images = useImages();
    const dispatch = useAppDispatch();
    const customerSupportModal = useAppSelector((state) => state.utils.customerSupportModal);

    return (
        <ThemeModal
            open={customerSupportModal}
            icon={<LayerIcon icon={Images.Announcement} className="!h-[48px] !w-[48px]" iconClassName="h-6 w-6" />}
            maxWidth="sm"
            onCloseModal={() => dispatch(toggleCustomerSupportModal())}
            title="Customer Support"
            content={
                <p>
                    Please fill in the form below for customer support. If you do not find your use case here, send us
                    an email at{" "}
                    <a className="text-primary" href="mailto:info@xspada.com">
                        info@xspada.com
                    </a>
                </p>
            }
            contentComponent={<CustomerSupportModalContent onClose={() => dispatch(toggleCustomerSupportModal())} />}
        />
    );
};

export default CustomerSupportModal;
