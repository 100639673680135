import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { RegisterMutation, useRegisterMutation } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setVerificationData } from "store/slices/verifyUser";

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    invite_code: yup.string(),
    terms: yup.boolean().oneOf([true], "The terms and conditions must be accepted."),
});

type RegisterPayload = yup.InferType<typeof schema>;

const useRegisterScreen = () => {
    const navigate = useNavigate();
    const [doRegister, response] = useRegisterMutation();
    const dispatch = useAppDispatch();
    const form = useForm<RegisterPayload>({
        resolver: yupResolver(schema),
        defaultValues:
            process.env.NODE_ENV === "development"
                ? {
                      invite_code: "",
                      terms: true,
                      password: "Kazuya@11",
                      email: "khakiu.uk@gmail.com",
                  }
                : {
                      invite_code: "",
                      terms: false,
                  },
    });

    useEffect(() => {
        rtkHandler(response, {
            onSuccess: (res: RegisterMutation) => {
                dispatch(
                    setVerificationData({
                        email: res.signup.data,
                        email_verification_id: res.signup.verification_id,
                    })
                );
                navigate(URL.RegisterSuccess, {
                    replace: true,
                });
            },
        });
    }, [response]);

    const onSubmit = (data: RegisterPayload) => {
        doRegister({
            data: {
                email: data.email,
                password: data.password,
                ...(data.invite_code ? { invite_code: data.invite_code } : {}),
            },
        });
    };

    return { form, response, onSubmit };
};

export default useRegisterScreen;
