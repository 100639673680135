import useImages from "assets/images";
import ChipBox from "shared/components/ChipBox";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { ScanningDeviceCategory } from "shared/utils/enums";

interface Props {
    type: ScanningDeviceCategory;
    onAdd: () => void;
    devices: ISelectMenuList[];
    onDelete: (id: string) => void;
}

const ScanningDeviceDetails = (props: Props) => {
    const Images = useImages();

    return (
        <div className="border-[#EAECF0] border rounded-lg p-4 flex items-start space-x-4">
            <div>
                {props.type === ScanningDeviceCategory.MobilePhone ? (
                    <Images.MobileLayerIcon />
                ) : props.type === ScanningDeviceCategory.Drones ? (
                    <Images.DroneLayerIcon />
                ) : props.type === ScanningDeviceCategory.Tablet ? (
                    <Images.TabletLayerIcon />
                ) : props.type === ScanningDeviceCategory.TypicalCamera ? (
                    <Images.TypicalCameraLayerIcon />
                ) : (
                    <Images.VideoLayerIcon />
                )}
            </div>
            <div className="flex-1">
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-md leading-5 text-[#344054] font-medium">{props.type}</h3>
                    <ThemeIconButton
                        className="!h-6 !w-6 !bg-[#F2F3FD] !rounded-full"
                        icon={<Images.PlusGrey height={18} width={18} style={{ color: "#7680FF" }} />}
                        onClick={props.onAdd}
                    />
                </div>
                <p className="text-[#667085] font-normal leading-5 text-md mb-[14px]">
                    A community profile is independent of any team and helps create personalized content.
                </p>
                <div className="flex items-center gap-2 flex-wrap">
                    {props.devices.map((item, index) => (
                        <ChipBox
                            onDelete={() => props.onDelete(item.value)}
                            className="!bg-[#F2F4F7] !text-[#667085]"
                            key={index}
                            label={item.label}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScanningDeviceDetails;
