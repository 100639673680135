import React from "react";
import Logo from "assets/images/logo.png";

const ErrorBoundaryContent = () => {
    return (
        <div className="w-full h-screen p-8 flex flex-col justify-between">
            <div>
                <img src={Logo} className="w-[150px]" alt="logo" />
            </div>
            <div className="w-[395px] text-center mx-auto">
                <div className="text-[20px] text-[#5E5D5D] mb-3">Something went wrong!</div>
                <div className="text-md text-[#667085]">
                    If you think this is some kind of error, send us an email at{" "}
                    <a href="mailto:info@xspada.com" className="text-[#7680FF]">
                        info@xspada.com
                    </a>{" "}
                    for immediate support.
                </div>
            </div>
            <div className="text-md text-[#667085]">© XSpada 2023</div>
        </div>
    );
};

export default ErrorBoundaryContent;
