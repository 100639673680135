import { ErrorMessage } from "@hookform/error-message";
import { FormHelperText } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

import CheckboxWithLabel from "../CheckboxWithLabel";
import FieldLabel from "../FieldLabel";
import FieldContext from "./Field.context";
import { IFieldProps } from "./Field.type";
import Dropify from "./partials/Dropify/Dropify";
import FAutoComplete from "./partials/FAutoComplete";
import FSelect from "./partials/FSelect";
import FTextField from "./partials/FTextField";

const Field = (props: IFieldProps) => {
    const {
        defaultValue = "",
        name,
        label,
        labelType = "standard",
        labelClassName = "",
        formGroup = true,
        type = "text",
        containerClassName = "",
        inputContainerClassName = "",
        sx = {},
        helperText,
        render = null,
        textFieldProps,
    } = props;

    const { control } = useFormContext();

    const methods = useController({
        control,
        name,
        defaultValue,
    });

    const {
        field: { value, onChange },
    } = methods;

    return (
        <FieldContext.Provider
            value={{
                methods,
                props,
            }}
        >
            <div className={`w-full ${formGroup ? "mb-5" : ""} ${containerClassName}`}>
                {label && labelType == "standard" && !["checkbox-label"].includes(type) && (
                    <FieldLabel className={labelClassName}>{label}</FieldLabel>
                )}
                {render ? (
                    render(methods)
                ) : (
                    <div
                        className={`flex ${textFieldProps ? "items-start" : "items-center"} ${inputContainerClassName}`}
                    >
                        <div className="flex-1">
                            {["text", "email", "password", "number"].includes(type) && <FTextField />}
                            {type === "select" && <FSelect />}
                            {type === "auto-complete" && <FAutoComplete />}
                            {type === "image-picker" && <Dropify />}
                            {type === "checkbox-label" && (
                                <CheckboxWithLabel
                                    name={name}
                                    sxStyle={sx}
                                    onChange={onChange}
                                    value={value}
                                    label={label ?? "Remember me"}
                                />
                            )}
                        </div>
                    </div>
                )}
                {helperText && <FormHelperText className="!mt-2">{helperText}</FormHelperText>}
                <ErrorMessage
                    errors={methods.formState.errors}
                    name={name}
                    render={({ message }) => <p className="text-red-500 font-normal text-sm mt-1">{message}</p>}
                />
            </div>
        </FieldContext.Provider>
    );
};

export default Field;
