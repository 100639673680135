import { Skeleton } from "@mui/material";
import useRenderImage from "./hook";

export interface IRenderImageProps {
    image: string;
    imgClassName?: HTMLImageElement["className"];
    containerClassName?: HTMLDivElement["className"];
    loading?: boolean;
}

const RenderImage = (props: IRenderImageProps) => {
    const { loaded, image, onImageLoad } = useRenderImage(props);
    const { imgClassName = "", containerClassName = "" } = props;

    return (
        <div className={`relative overflow-hidden ${containerClassName} pointer-events-none`}>
            {(!loaded || props.loading) && (
                <Skeleton variant="rectangular" className={`w-full !h-full absolute top-0 left-0 ${imgClassName}`} />
            )}
            <img
                src={image}
                onLoad={onImageLoad}
                className={`w-full h-full object-cover object-center ${imgClassName} ${!loaded && "hidden"}`}
                alt=""
            />
        </div>
    );
};

export default RenderImage;
