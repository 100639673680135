import { useEffect, useRef, useState } from "react";

import useImages from "assets/images";
import { useLocation } from "react-router-dom";
import { useTalkJS } from "shared/components/TalkJSProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { twMerge } from "tailwind-merge";
import Talk from "talkjs";
import SendNewMessageModal from "./components/SendNewMessageModal";

const TeamChat = () => {
    const { user, team } = useAuth();
    const [newMessageModal, setNewMessageModal] = useState(false);
    const { talkjs } = useTalkJS();
    const chatboxEl = useRef<HTMLDivElement>(null);
    const [isChatLoaded, setIsChatLoaded] = useState(false);
    const Images = useImages();
    const { state } = useLocation();

    useEffect(() => {
        if (talkjs) {
            if (state?.user) {
                initChatConverstation(state.user);
                window.history.replaceState({}, document.title);
            } else {
                const talkjsInbox = talkjs.createInbox({
                    showFeedHeader: false,
                    theme: process.env.REACT_APP_TALKJS_THEME,
                });
                talkjsInbox.mount(chatboxEl.current).then(() => {
                    setIsChatLoaded(true);
                });
            }
        }
    }, [talkjs, chatboxEl]);

    if (!user || !team) return <></>;

    const onCloseModal = (member?: TeamMember) => {
        setNewMessageModal(false);
        if (member) {
            initChatConverstation(member);
        }
    };

    const initChatConverstation = (member: TeamMember) => {
        const me = team.members.find((m) => m.user?.user_id === user.user_id);
        if (!me || !me.user) return;
        const currentUser = new Talk.User({
            id: me.member_id,
            name: me.user.name ?? "",
            email: me.user.email,
            photoUrl: me.user.profile_image.url,
            role: "default",
        });
        const newUser = new Talk.User({
            id: member.member_id,
            name: member.user?.name ?? "",
            email: member.user?.email ?? "",
            photoUrl: member.user?.profile_image.url ?? "",
            role: "default",
        });
        const conversation = talkjs?.getOrCreateConversation(Talk.oneOnOneId(newUser, currentUser));
        if (conversation) {
            conversation.setParticipant(currentUser);
            conversation.setParticipant(newUser);
            const inbox = talkjs?.createInbox({
                selected: conversation,
                showFeedHeader: false,
                theme: process.env.REACT_APP_TALKJS_THEME,
            });
            inbox?.mount(chatboxEl.current);
            setIsChatLoaded(true);
        }
    };

    return (
        <>
            <div className="chatbox-container relative h-full">
                <div className="w-[400px] flex items-center absolute top-0 left-0 h-[81px]">
                    <div className={twMerge("flex items-center justify-between px-6 bg-white w-full")}>
                        <h4 className="text-p text-[20px] font-semibold leading-6">TeamChat</h4>
                        {isChatLoaded && (
                            <ThemeIconButton
                                icon={<Images.PlusWhite color="#ffffff" />}
                                variant="filled"
                                color="primary"
                                onClick={() => setNewMessageModal(true)}
                            />
                        )}
                    </div>
                </div>
                <div id="talkjs-container" ref={chatboxEl} className="h-full">
                    <i className="mt-[90px] block absolute text-p text-center w-[400px]">Loading chat...</i>
                </div>
            </div>
            <SendNewMessageModal open={newMessageModal} onClose={onCloseModal} />
        </>
    );
};

export default TeamChat;
