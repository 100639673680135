import useImages from "assets/images";
import ITDCard from "shared/components/ui/ITDCard";
import useDownloadSrModel from "./hook";
import { CircularProgress } from "@mui/material";
import { ScanModelTypes } from "shared/utils/enums";

const DownloadSrModel = () => {
    const { Options, selected, scan, downloadQuery, onClickITD, startDownload } = useDownloadSrModel();
    const { ArrowRightWhite, Info } = useImages();

    const method = scan?.method;
    const isNerf = method === ScanModelTypes.nerfacto;

    return (
        <div className="card-dashed-border">
            <h4 className="text-p font-bold text-lg leading-6 mb-2">Download Files</h4>
            <p className="text-p text-opacity-50 text-md leading-4 font-normal mb-2">
                Click the button below to start your downloading of model files.
            </p>
            <p className="text-p text-md leading-4 font-normal text-opacity-50">
                <Info color="#FF8A36" className="mr-1" />
                {isNerf
                    ? "To get a PLY file or Splat model files for this scan, retrain this scan with Focused instead of Unbounded."
                    : "To get a Unbounded Zipped model file for this scan, retrain this scan with Unbounded instead of Focused."}
            </p>
            <div className="grid grid-cols-4 gap-4 mt-6">
                {Options.map((option, index) => (
                    <ITDCard
                        key={index}
                        iconWrapperClassName="flex-initial"
                        onClick={() => onClickITD(option.value)}
                        selected={selected === option.value}
                        value={option.value}
                        icon={option.icon}
                        title={option.title}
                        desc={option.desc}
                    />
                ))}
                <div
                    className={`flex flex-col justify-between h-full bg-[#5E5D5D] bg-opacity-20 shadow-card rounded-[10px] text-white p-4 text-lg leading-6 transition-all ${
                        !downloadQuery.isLoading ? "cursor-pointer !bg-primary" : ""
                    }`}
                    onClick={() => startDownload()}
                >
                    {downloadQuery.isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <ArrowRightWhite />
                            <p>Download {isNerf ? "NeRF Zip File" : "Ply and Splat Files"}</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DownloadSrModel;
