import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useSwitchProfileMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";

interface Props {
    doNavigate?: boolean;
}

const useSwitchProfileApi = (props: Props) => {
    const [switchProfile, profileRes] = useSwitchProfileMutation();
    const navigate = useNavigate();

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess() {
                props.doNavigate && navigate(URL.Dashboard, { replace: true });
            },
        });
    }, [profileRes]);

    const doSwitchProfile = (id: number, setDefault?: boolean) => {
        if (!id) {
            errorToast("Please select a profile");
            return;
        }
        return switchProfile({ id, setDefault });
    };

    return { doSwitchProfile, isLoading: profileRes.isLoading };
};

export default useSwitchProfileApi;
