import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetAssets: {
            providesTags: (result) =>
                result
                    ? [
                          ...result.assets.map(({ asset_id }) => ({ type: "Assets", id: asset_id } as const)),
                          { type: "Assets", id: "LIST" },
                      ]
                    : [{ type: "Assets", id: "LIST" }],
        },
        GetAssetByID: {
            providesTags: (result, error, { asset_id }) => (error ? [] : [{ type: "Assets", id: asset_id }]),
        },
        AddAsset: {
            invalidatesTags: (success, error) =>
                error
                    ? []
                    : [
                          { type: "Assets", id: "LIST" },
                          { type: "Projects", id: success?.create_asset.project_id },
                          "Storage",
                      ],
        },
        EditAsset: {
            invalidatesTags: (_, error, { asset_id, data: { project_id } }) => [
                { type: "Assets", id: "LIST" },
                { type: "Assets", id: asset_id },
                { type: "Projects", id: project_id ?? 0 },
            ],
        },
        DeleteAsset: {
            invalidatesTags: (success, error) =>
                error
                    ? []
                    : [
                          { type: "Assets", id: "LIST" },
                          { type: "Projects", id: "LIST" },
                          { type: "Projects", id: success?.delete_asset.project_id ?? "0" },
                      ],
        },
    },
});

export const {
    useGetAssetsQuery,
    useGetAssetByIDQuery,
    useAddAssetMutation,
    useEditAssetMutation,
    useDeleteAssetMutation,
} = api;
