import useImages from "assets/images";
import { useFormContext } from "react-hook-form";
import RenderIf from "shared/components/RenderIf";
import { SRNewSteps } from "../hook";

const ScanRequestTitle = () => {
    const Images = useImages();
    const { watch } = useFormContext();
    const step = watch("step");

    const isBasicStep = step === SRNewSteps.Basic;
    const isDetailStep = step === SRNewSteps.Details;
    const isLocationStep = step === SRNewSteps.Location;

    return (
        <div className="flex flex-col items-center text-center pt-4 pb-8">
            <RenderIf condition={isBasicStep || isDetailStep}>
                <Images.SquareCheckLayerIcon height={50} width={50} />
                <h4 className="text-p text-[20px] leading-5 font-bold mb-[6px] mt-4">Project Request Details</h4>
                <p className="text-p text-md leading-5 font-normal">
                    Here you will add the main details of your project request.
                </p>
            </RenderIf>
            <RenderIf condition={isLocationStep}>
                <Images.LocationLayerIcon height={50} width={50} />
                <h4 className="text-p text-[20px] leading-5 font-bold mb-[6px] mt-4">Scan Location</h4>
                <p className="text-p text-md leading-5 font-normal">
                    Select the location where this scan was recorded.
                </p>
            </RenderIf>
        </div>
    );
};

export default ScanRequestTitle;
