import { RouteObject } from "react-router-dom";

import BetaApplicationAgreement from "screens/Agreements/BetaApplicationAgreement";
import EndUserAgreement from "screens/Agreements/EndUserAgreement";
import AgreementLayout from "screens/Agreements/layout";
import TermsAndConditions from "screens/Agreements/TermsAndConditions";
import URL from "shared/constants/navigator";

const agreementRoutes: RouteObject[] = [
    {
        element: <AgreementLayout />,
        children: [
            {
                path: URL.Terms_And_Conditions,
                element: <TermsAndConditions />,
            },
            {
                path: URL.Beta_Application_Agreement,
                element: <BetaApplicationAgreement />,
            },
            {
                path: URL.End_User_License_Agreement,
                element: <EndUserAgreement />,
            },
        ],
    },
];

export default agreementRoutes;
