import { omit } from "lodash";
import React from "react";
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";
import { twMerge } from "tailwind-merge";

interface Props extends React.PropsWithChildren {
    classes?: {
        root?: string;
        header?: string;
        body?: string;
    };
    showHeader?: boolean;
    headerComponent?: React.ReactNode;
    scrollbarProps?: ScrollBarProps;
}

const ThemeScreenCard = (props: Props) => {
    return (
        <div className={twMerge("border-r border-[#D0D5DD] border-opacity-25 w-full", props.classes?.root)}>
            {(props.showHeader ?? true) && (
                <div
                    className={twMerge(
                        "p-6 flex items-center border-b bg-white border-[#D0D5DD] border-opacity-25 h-[80px]",
                        props.classes?.header
                    )}
                >
                    {props.headerComponent}
                </div>
            )}
            {props.children && (
                <div className={twMerge(`bg-[#F9F9F9] h-[calc(100vh-80px)]`, props.classes?.body)}>
                    <PerfectScrollbar
                        className={twMerge("p-6", props.scrollbarProps?.className)}
                        options={{
                            suppressScrollX: true,
                            ...props.scrollbarProps?.options,
                        }}
                        {...omit(props.scrollbarProps, ["options", "className"])}
                    >
                        {props.children}
                    </PerfectScrollbar>
                </div>
            )}
        </div>
    );
};

export default ThemeScreenCard;
