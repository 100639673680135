import { CircularProgress } from "@mui/material";
import useImages from "assets/images";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import useEditSRScan from "./hook";

const EditSRScan = () => {
    const { isLoading, isFetching, request } = useSingleSR();
    const hook = useEditSRScan();
    const Images = useImages();

    return (
        <ThemeFormProvider form={hook.form} onSubmit={hook.onSubmit}>
            {(isLoading || isFetching) && (
                <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                    <CircularProgress />
                </div>
            )}
            {request && (
                <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                    <div className="bg-white p-4 rounded-2xl h-full w-full flex flex-col relative">
                        <div className="overflow-auto overflow-x-hidden flex-1 flex flex-col">
                            <div className="flex flex-col items-center text-center pt-4 pb-8">
                                <Images.VideoCameraLayerIcon height={50} width={50} />
                                <h4 className="text-p text-[20px] leading-5 font-bold mb-[6px] mt-4">Scan Details</h4>
                                <p className="text-p text-md leading-5 font-normal">
                                    Here you will add the main details of your scan
                                </p>
                            </div>
                            <div className="flex space-x-4">
                                <Field name="name" placeholder="Introduce your scan with a name" label="Scan Name" />
                                <Field
                                    type="select"
                                    name="scanning_device_id"
                                    options={hook.scanningDevices}
                                    selectFieldProps={{
                                        noOptionsText:
                                            "No devices to select. Please add at least one device in profile settings.",
                                    }}
                                    label="Scanning Device:"
                                    placeholder="Enter your device type you used to take this scan"
                                />
                            </div>
                            <div className="text-right mt-4">
                                <LoaderButton variant="contained" type="submit" loading={hook.updateRes.isLoading}>
                                    Submit
                                </LoaderButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ThemeFormProvider>
    );
};

export default EditSRScan;
