import { useFormContext } from "react-hook-form";
import Field from "shared/components/ui/Field";

const ScanRequestBasicForm = () => {
    const {
        options = {
            urgency: [],
        },
    }: any = useFormContext();

    return (
        <>
            <Field name="title" label="Request Title:" placeholder="Introduce your request with team" />
            <Field
                name="description"
                label="Request Description:"
                placeholder="Explain your request clearly for your team"
                inputTextProps={{
                    multiline: true,
                    rows: 6,
                    classes: {
                        root: "!p-3 !rounded-[24px]",
                    },
                }}
            />
            <Field
                name="urgency"
                label="Request Urgency:"
                placeholder="Scanners you are looking for"
                type="select"
                options={options.urgency}
            />
        </>
    );
};

export default ScanRequestBasicForm;
