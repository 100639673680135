import { Collapse, Tab, Tabs } from "@mui/material";
import React from "react";
import { twMerge } from "tailwind-merge";
import AppIconButton from "../AppIconButton";

interface TabsProps {
    tabsRef?: any;
    tabs: { label: string; children: React.ReactNode }[];
    selectedTab?: number;
    className?: string;
    tabContentClassName?: string;
    iconButtons?: {
        icon: any;
        label: any;
        onClick: any;
    }[];
    hasCollapsibleTabs?: boolean;
    isCollapsed?: boolean;
    collapseClassName?: string;
    onTabChange?: (label: string) => void;
}

const ThemeTabs = ({
    tabs,
    selectedTab = 0,
    className,
    tabContentClassName = "",
    iconButtons,
    hasCollapsibleTabs = false,
    isCollapsed = false,
    collapseClassName = "",
    onTabChange,
}: TabsProps) => {
    const [value, setValue] = React.useState(selectedTab);

    const labels = tabs.map((item) => item.label);
    const children = tabs.map((item) => item.children);

    const handleChange = (_: any, newValue: number) => {
        setValue(newValue);
        if (onTabChange) {
            onTabChange(labels[newValue]);
        }
    };

    return (
        <>
            <div className={hasCollapsibleTabs ? "sticky top-0 z-[10]" : ""}>
                {hasCollapsibleTabs && (
                    <Collapse in={isCollapsed} collapsedSize={80} className={collapseClassName}>
                        <div className="w-full h-[236px] bg-[#f9f9f9]"></div>
                    </Collapse>
                )}
                <div className={className}>
                    <Tabs value={value} onChange={handleChange} className="-mb-[1px]">
                        {labels.map((item, index) => {
                            return <Tab key={index} label={item} className="!px-2" {...a11yProps(index)} />;
                        })}
                    </Tabs>
                    {iconButtons && (
                        <div className="flex flex-row items-center gap-x-1">
                            {iconButtons.map((button, i) => (
                                <AppIconButton
                                    key={i}
                                    icon={button.icon}
                                    tooltipTitle={button.label}
                                    onClick={button.onClick}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {children.length ? (
                <div className={twMerge(`p-4 z-0`, tabContentClassName)}>
                    {children.map((item, index) => {
                        return (
                            <TabPanel value={value} index={index} key={index}>
                                {item}
                            </TabPanel>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default ThemeTabs;

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

interface TabPanelProps {
    children?: any;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div id={`simple-tabpanel-${index}`} {...other} className={twMerge(index === value ? "block" : "hidden")}>
            {children}
        </div>
    );
};
