import useImages from "assets/images";
import { useMemo } from "react";
import BlockMenu from "./partials/BlockMenu/BlockMenu";

import PerfectScrollbar from "react-perfect-scrollbar";
import useHasPermission from "shared/components/HasPermission/hook";
import Permissions from "shared/components/HasPermission/Permissions";

const ComponentsSidebar = () => {
    const {
        EditorAssetsScanIcon,
        EditorAssetsSnapshotIcon,
        EditorTextTitleIcon,
        EditorTextTextIcon,
        EditorTextBoxIcon,
        EditorTextListIcon,
        EditorTextQuoteIcon,
        EditorTextCodeblockIcon,
        EditorTextToCIcon,
        EditorMediaCarouselIcon,
        EditorMediaTableIcon,
        EditorMediaImageIcon,
        EditorMediaVideoIcon,
        EditorMediaEmbedIcon,
        EditorMediaYTIcon,
    } = useImages();

    const Can_View_Snapshot_Admin = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "admin" });
    const Can_View_Snapshot_Manager = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "manager" });
    const Can_View_Snapshot_Member = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "member" });

    const menu = useMemo<{ [key: string]: { id: string; Icon: any; label: string; onAction: any }[] }>(() => {
        const canViewSnapshot = Can_View_Snapshot_Admin || Can_View_Snapshot_Manager || Can_View_Snapshot_Member;

        return {
            assets: [
                {
                    id: "scan",
                    Icon: EditorAssetsScanIcon,
                    label: "Scan",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "scanComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                canViewSnapshot
                    ? {
                          id: "snapshot",
                          Icon: EditorAssetsSnapshotIcon,
                          label: "Snapshot",
                          onAction: (event: any) => {
                              const dragData = {
                                  type: "snapshotComponent",
                              };
                              (event?.dataTransfer as any).setData(
                                  "application/x-tiptap-drag",
                                  JSON.stringify(dragData)
                              );
                          },
                      }
                    : null,
            ].filter((item) => item) as any[],
            text: [
                {
                    id: "title",
                    Icon: EditorTextTitleIcon,
                    label: "Title",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "styledTitle",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "text",
                    Icon: EditorTextTextIcon,
                    label: "Text",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "styledText",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "box",
                    Icon: EditorTextBoxIcon,
                    label: "Box",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "box",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "list",
                    Icon: EditorTextListIcon,
                    label: "List",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "listComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "quote",
                    Icon: EditorTextQuoteIcon,
                    label: "Quote",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "quoteComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "codeblock",
                    Icon: EditorTextCodeblockIcon,
                    label: "Codeblock",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "codeBlockComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "toc",
                    Icon: EditorTextToCIcon,
                    label: "Table of Content",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "tocComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
            ],
            media: [
                {
                    id: "carousel",
                    Icon: EditorMediaCarouselIcon,
                    label: "Carousel",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "carousel",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));

                        // (event?.dataTransfer as any).setData("text/html", `<carousel-component></carousel-component>`);
                    },
                },
                { id: "table", Icon: EditorMediaTableIcon, label: "Table", onAction: () => {} },
                {
                    id: "image",
                    Icon: EditorMediaImageIcon,
                    label: "Image",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "imageComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "video",
                    Icon: EditorMediaVideoIcon,
                    label: "Video",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "videoComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "embed",
                    Icon: EditorMediaEmbedIcon,
                    label: "Embed",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "embedComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
                {
                    id: "yt",
                    Icon: EditorMediaYTIcon,
                    label: "Youtube Link",
                    onAction: (event: any) => {
                        const dragData = {
                            type: "youtubeComponent",
                        };
                        (event?.dataTransfer as any).setData("application/x-tiptap-drag", JSON.stringify(dragData));
                    },
                },
            ],
        };
    }, [Can_View_Snapshot_Admin, Can_View_Snapshot_Manager, Can_View_Snapshot_Member]);

    return (
        <div className="w-[240px] h-full rounded-xl bg-[#FFFFFF] border border-[#EAECF0] flex flex-col">
            <div className="text-[#667085] font-semibold text-md mb-4 pt-5 px-5">Blocks</div>
            <div className="flex-1 overflow-hidden">
                <PerfectScrollbar>
                    {Object.keys(menu).map((menuBlock) => (
                        <BlockMenu
                            key={menuBlock}
                            title={menuBlock}
                            items={menu[menuBlock]}
                            // items={menu[menuBlock].map((item) => ({
                            //     ...item,
                            //     onAction: () => {
                            //         dispatch(setSelectedBlock({ type: item.id }));
                            //         item.onAction();
                            //     },
                            // }))}
                        />
                    ))}
                </PerfectScrollbar>
            </div>
        </div>
    );
};

export default ComponentsSidebar;
