import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetNotifications: {
            providesTags: ["Notification"],
        },
    },
});

export const { useGetNotificationsQuery, useMarkNotificationsAsReadMutation } = api;
