import { Badge, IconButton, Tooltip, styled } from "@mui/material";
import { twMerge } from "tailwind-merge";

import COLOR_PALLETE from "shared/utils/colorPallete";
import { IThemeIconButtonProps } from "./type";

const ThemeIconButton = (props: IThemeIconButtonProps) => {
    const {
        icon,
        size = "medium",
        color = "default",
        badgeCount,
        badgeColor = "primary",
        className,
        onClick,
        variant = "default",
        disabled = false,
    } = props;

    return (
        <Tooltip title={props.tooltipTitle} placement={props.tooltipPosition}>
            <div>
                <CustomIconButton
                    onClick={onClick}
                    size={size}
                    color={color}
                    className={twMerge("whitespace-nowrap", className)}
                    disabled={disabled}
                    variant={variant}
                    bgColor={props.bgColor}
                >
                    <CustomBadge color={badgeColor} badgeContent={badgeCount}>
                        {icon}
                    </CustomBadge>
                </CustomIconButton>
            </div>
        </Tooltip>
    );
};

export default ThemeIconButton;

const CustomIconButton = styled(IconButton)<Omit<IThemeIconButtonProps, "icon">>((props) => {
    return {
        backgroundColor:
            (props.variant === "filled" || props.disabled) && props.color
                ? `${COLOR_PALLETE[props.color]?.DEFAULT}`
                : "transparent",
        border: `${
            props.variant === "filled"
                ? `1px solid ${props.color}.main`
                : props.variant === "outlined"
                ? `1px solid ${COLOR_PALLETE.border2}`
                : ""
        }`,
        opacity: props.disabled ? 0.5 : 1,
        boxShadow: props.variant !== "default" ? "0px 1px 2px rgba(16, 24, 40, 0.05)" : "none",
        borderRadius: "7px",
        width: 32,
        height: 32,
        ":hover": {
            backgroundColor:
                props.variant === "filled" && props.color ? `${COLOR_PALLETE[props.color]?.DEFAULT}` : "transparent",
        },
    };
});

const CustomBadge = styled(Badge)({
    "& > .MuiBadge-badge": {
        height: 12,
        minWidth: 12,
        padding: 0,
        fontSize: 9,
        right: 5,
    },
});
