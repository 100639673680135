import useImages from "assets/images";
import { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { errorToast } from "shared/utils/toast";
import { UppyFileUploaderPickerProps } from "..";

const GooglePicker = (props: UppyFileUploaderPickerProps) => {
    const Images = useImages();
    const [openPicker, authResponse] = useDrivePicker();
    const [file, setFile] = useState<any>(null);

    const handleOpenPicker = () => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || "",
            developerKey: process.env.REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY || "",
            viewId: props.type === "images" ? "DOCS" : props.type === "video" ? "DOCS_VIDEOS" : "DOCS_IMAGES",
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: props.multiple || false,
            callbackFunction: async (data) => {
                if (data.action === "picked") {
                    setFile(data.docs[0]);
                }
            },
        });
    };

    useEffect(() => {
        if (file && authResponse) {
            if (process.env.NODE_ENV === "production" && file.sizeBytes > 1610612736) {
                errorToast("File too large to be submitted from Google Drive. Please manually upload.");
                return;
            }

            props.onFileSelect &&
                props.onFileSelect({
                    name: file.name,
                    size: file.sizeBytes,
                    pickerType: "google-drive",
                    video_id: file.id,
                    access_token: authResponse.access_token,
                });
        }
    }, [file, authResponse]);

    return (
        <div>
            <ThemeIconButton
                tooltipTitle="Google Drive"
                tooltipPosition="top"
                icon={<LayerIcon icon={Images.GoogleDriveIcon} iconColor="#475467" color="default" />}
                onClick={() => {
                    handleOpenPicker();
                }}
            />
        </div>
    );
};

export default GooglePicker;
