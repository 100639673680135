import useImages from "assets/images";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import { useDeleteScanMutation } from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";

const useDeleteSRScan = () => {
    const [selected, setSelected] = useState("");
    const { DeleteBlue, FolderIcon, VideoDarkIcon } = useImages();
    const [deleteScan, scanRes] = useDeleteScanMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { scanId } = useParams();
    const { request } = useSingleSR();

    const onClickITD = (val: any) => {
        setSelected((prev) => (prev === val ? "" : val));
    };

    useEffect(() => {
        if (!scanId) {
            navigate(-1);
        }
    }, [scanId]);

    const Options = useMemo(() => {
        return [
            {
                value: "video",
                icon: <VideoDarkIcon />,
                title: "Delete Video",
                desc: "This will delete your scan video of this scan from XSpada application.",
            },
            {
                value: "model",
                icon: <FolderIcon />,
                title: "Delete Model",
                desc: "This will delete your model of this scan from XSpada application.",
            },
            {
                value: "everything",
                icon: <DeleteBlue />,
                title: "Delete Viewer",
                desc: "This will delete the 3d viewer of this scan from XSpada application.",
            },
        ];
    }, [VideoDarkIcon, FolderIcon, DeleteBlue]);

    const onClickDeleteScan = () => {
        deleteScan({
            id: parseInt(scanId!),
        });
    };

    useEffect(() => {
        rtkHandler(scanRes, {
            onSuccess() {
                navigate(`/requests/in-progress/detail/${request?.scan_request_id}/scans`);
                dispatch(
                    api.util.invalidateTags([
                        { type: "ScanRequests", id: "LIST" },
                        { type: "ScanRequests", id: request?.scan_request_id },
                    ])
                );
            },
        });
    }, [scanRes]);

    return { isLoading: scanRes.isLoading, onClickDeleteScan, Options, selected, onClickITD };
};

export default useDeleteSRScan;
