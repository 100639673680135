import * as yup from "yup";

yup.setLocale({
    mixed: {
        required: "This field is required",
    },
    string: {
        email: "Email is not valid",
        min: "Password must be at least 8 characters",
    },
});

export default yup;
