import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import ScansOrSnapshotsBlock from "./ScansOrSnapshotsBlock";

export default Node.create({
    name: "scansOrSnapshotsBlockComponent",
    content: "",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.scans-snapshots-block", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "scans-snapshots-block",
                id: node.attrs.id,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ScansOrSnapshotsBlock);
    },
});
