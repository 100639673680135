import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { File as FileType } from "shared/graphql";

import FileItemContainer from "./FileItemContainer";

const CHUNK_SIZE = 1024 * 1024 * 10;
const ENDPOINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT || "";
const uppy = new Uppy({ debug: true, autoProceed: true });

const InputFileItem = () => {
    const context = useFormContext();
    const file: File = context.watch("input_videos");
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (file) {
            uppy.addFile({
                name: file?.name,
                type: file?.type,
                data: file,
            });

            uppy.use(Tus, {
                endpoint: ENDPOINT,
                chunkSize: CHUNK_SIZE,
                removeFingerprintOnSuccess: true,
                headers: {
                    "file-name": file?.name,
                    "file-type": file?.type,
                },
                onShouldRetry: () => {
                    return false;
                },
            });

            uppy.on("complete", async (result: any) => {
                if (result.successful && result.successful.length) {
                    let uuid = result.successful[0].uploadURL.split("/").pop();
                    uuid = uuid?.split("?").shift() ?? "";
                    if (uuid && file) {
                        const res = await axios.get<FileType>(`${ENDPOINT}/metadata/${uuid}`);
                        if (res.status === 200) {
                            const data = res.data;
                            context.setValue("filePreview", {
                                name: data.name,
                                size: data.size,
                                url: data.url,
                            });
                            context.setValue("scan_video_id", data.file_id);
                        }
                    }
                }
            });

            uppy.on("progress", (e: any) => {
                setProgress(e);
            });
        }
        return () => {
            uppy.removeFile(uppy.getFiles()[0].id);
            uppy.close();
        };
    }, [file]);

    const onClickRemove = () => {
        context.setValue("input_videos", null);
    };

    return (
        <FileItemContainer
            onRemove={onClickRemove}
            name={file.name}
            size={file.size}
            showProgress
            progress={progress || 0}
        />
    );
};

export default InputFileItem;
