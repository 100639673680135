import { useLocation } from "react-router-dom";

import { useAppDispatch } from "shared/hooks/useRedux";
import { dismissTutorial } from "store/slices/tutorial";

const useWalkthroughLayout = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    // const { openAuthModal } = useTwoFactorAuthentication();

    const onTutorialClose = () => {
        let type = location.pathname.substring(1).split("/")[0];
        type = type.replace("-", "_");

        if (type == "feed") {
            type = "home";
        }

        // setTutorialStatus({
        //     application: "web",
        //     tab: type,
        // });

        dispatch(dismissTutorial());

        // if (isPhoneVerified != null && !isPhoneVerified && !isPhoneVerificationShown) {
        // openAuthModal("phone_number");
        // dispatch(setPhoneVerificationShown());
        // }
    };

    // useEffect(() => {
    //     if (data) {
    //         dispatch(setInitialTutorial(data.data.tutorials["web"]));
    //     }
    // }, [data]);

    return { onTutorialClose };
};

export default useWalkthroughLayout;
