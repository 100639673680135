import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Notification } from "shared/graphql";

interface NotificationsState {
    count: number;
    is_panel_open: boolean;
    invitation_requests: Notification[];
}

const initialState: NotificationsState = {
    count: 0,
    is_panel_open: false,
    invitation_requests: [],
};

const notifications = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotificationCount: (state, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        setNotificationPanel: (state, action: PayloadAction<boolean>) => {
            state.is_panel_open = action.payload;
        },
        setInvitationRequests: (state, action: PayloadAction<Notification[]>) => {
            state.invitation_requests = action.payload;
        },
    },
});

export const { setNotificationCount, setInvitationRequests, setNotificationPanel } = notifications.actions;

export default notifications.reducer;
