interface IBlockMenuProps {
    title: string;
    items: {
        id: string;
        Icon: any;
        label: string;
        onAction: any;
    }[];
}

const BlockMenu = (props: IBlockMenuProps) => {
    const { title, items } = props;
    return (
        <div className="mb-6">
            <div className="mb-2 text-[#475467] text-md opacity-50 px-5 capitalize">{title}</div>
            <div className="flex flex-col w-full px-3">
                {items.map((item, i) => (
                    <div
                        key={i}
                        className="flex items-center gap-x-4 py-2 select-none cursor-pointer px-2 rounded-sm"
                        draggable={true}
                        // onClick={() => item.onAction()}
                        onDragStart={(e) => item.onAction(e)}
                    >
                        <item.Icon width={16} height={16} />
                        <span className="text-[#667085] text-md">{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlockMenu;
