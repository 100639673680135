import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field/Field";
import FAvatarUploadPhoto from "shared/components/ui/Field/partials/FAvatarUploadPhoto";
import FColorPicker from "shared/components/ui/Field/partials/FColorPicker";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useCreateTeamMutation } from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";

const schema = yup.object().shape({
    photo: yup
        .object()
        .shape({
            file_id: yup.number().required(),
        })
        .typeError("This field is required")
        .required(),
    team_name: yup.string().required(),
    bio: yup.string().required(),
    color: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const CreateTeamAccountModal = ({ onClose }: { onClose: () => void }) => {
    const dispatch = useAppDispatch();
    const [createTeam, createRes] = useCreateTeamMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            photo: undefined,
            team_name: "",
            bio: "",
            color: "#cccccc",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        createTeam({
            data: {
                name: data.team_name || "",
                logo: data.photo.file_id,
                bio: data.bio || "",
                brand_color: data.color || "",
            },
        });
    };

    const formLoading = createRes.isLoading;

    useEffect(() => {
        dispatch(setCommonModalLoadingBtn(formLoading ? "create-team-account-btn" : ""));
    }, [formLoading]);

    useEffect(() => {
        rtkHandler(createRes, {
            setError: form.setError,
            successMessage: "Team account created successfully",
            onSuccess() {
                onClose();
                dispatch(api.util.invalidateTags(["Profiles"]));
            },
        });
    }, [createRes]);

    const bioLength = form.watch("bio")?.length || 0;

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left">
            <div className="flex items-center justify-center">
                <Field
                    containerClassName="justify-center text-center"
                    name="photo"
                    render={({ field }) => {
                        return (
                            <FAvatarUploadPhoto
                                classes={{ container: "mx-auto" }}
                                value={field.value}
                                onChange={(file) => field.onChange(file)}
                            />
                        );
                    }}
                />
            </div>
            <Field label="Team Name" placeholder="Select name for your team" name="team_name" />
            <Field
                label="Branding Color"
                name="color"
                render={({ field }) => {
                    return <FColorPicker {...field} />;
                }}
            />
            <Field
                label="Bio:"
                placeholder="Write a bio for your team"
                name="bio"
                render={({ field }) => {
                    return (
                        <TextField
                            type={"text"}
                            name={"bio"}
                            value={field.value}
                            variant={"outlined"}
                            onChange={(e) => {
                                field.onChange(e.target.value.slice(0, 24));
                            }}
                            placeholder={"Write a bio for your team"}
                            InputProps={{
                                endAdornment: <span className="text-md leading-6 text-[#667085]">{bioLength}/24</span>,
                            }}
                        />
                    );
                }}
            />
            <CommonModalActionButton
                buttons={[
                    {
                        id: "create-team-account-btn",
                        children: "Create",
                        type: "submit",
                        onClick: () => {},
                    },
                ]}
                cancelBtnProps={{
                    onClick: onClose,
                }}
            />
        </ThemeFormProvider>
    );
};

export default CreateTeamAccountModal;
