import { ThemeProvider } from "@mui/material";
import "assets/styles/index.scss";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import CommonModal from "shared/components/CommonModal";
import CustomerSupportModal from "shared/components/CustomerSupportModal";
import ErrorBoundary from "shared/components/ErrorBoundary";
import AppRoutes from "shared/routes/routes";
import theme from "shared/utils/theme";
import { persistor, store } from "store";

function App() {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor as any}>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <AppRoutes />
                            <CommonModal />
                            <CustomerSupportModal />
                            <Toaster />
                        </Router>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
