import { createSlice } from "@reduxjs/toolkit";
import { GetProjectByIdQuery } from "shared/graphql";

interface Props {
    showLoading: boolean;
    refetchApi: boolean;
    selected: GetProjectByIdQuery["project"] | null;
    is_post_article_visible: boolean;
}

const initialState: Props = {
    showLoading: false,
    refetchApi: false,
    selected: null,
    is_post_article_visible: false,
};

const projects = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setSelectedProject(state, action) {
            state.selected = action.payload;
        },
        setProjectShowLoading(state, action) {
            state.showLoading = action.payload;
        },
        setProjectRefetchApi(state, action) {
            state.refetchApi = action.payload;
        },
        showPostArticleDialog(state) {
            state.is_post_article_visible = true;
        },
        hidePostArticleDialog(state) {
            state.is_post_article_visible = false;
        },
    },
});

export default projects.reducer;

export const {
    setSelectedProject,
    setProjectShowLoading,
    setProjectRefetchApi,
    showPostArticleDialog,
    hidePostArticleDialog,
} = projects.actions;
