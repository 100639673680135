import useImages from "assets/images";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

interface Props {
    value: string;
    onChange: (val: string) => void;
}

const PhoneNumberPicker: React.FunctionComponent<Props> = ({ value, onChange }) => {
    const { ArrowDown } = useImages();

    return (
        <PhoneInput
            className="border border-[#D0D5DD] rounded-lg h-11 py-[10px] px-[14px]"
            international
            defaultCountry="US"
            placeholder="Enter phone number"
            value={value}
            countryCallingCodeEditable={false}
            onChange={(val: string) => onChange(val)}
            countrySelectProps={{
                arrowComponent: () => (
                    <div className="ml-2">
                        <ArrowDown width={10} />
                    </div>
                ),
            }}
        />
    );
};

export default PhoneNumberPicker;
