import React, { PropsWithChildren } from "react";
import PageLoader from "shared/components/ui/PageLoader";

interface Props extends PropsWithChildren {
    icon?: any;
    title?: string | React.ReactNode;
    description?: string | React.ReactNode;
    topHeader?: React.ReactNode;
    leftHeader?: React.ReactNode;
    loader?: boolean;
}

const CPContent = (props: Props) => {
    const Icon = props.icon;

    return (
        <div className="relative pt-[110px] text-center">
            {props.loader && <PageLoader />}
            {props.leftHeader && <div className="absolute top-7 left-8">{props.leftHeader}</div>}
            {props.topHeader && <div className="absolute top-7 right-8">{props.topHeader}</div>}
            <div className="shadow-[0px_1px_2px_0px_#1018280D] border border-[#EAECF0] h-12 w-12 rounded-xl flex items-center justify-center mx-auto !bg-white">
                <Icon heigth={24} width={24} color="#344054" />
            </div>
            <h4 className="text-[#344054] leading-[38px] text-[30px] mt-8 mb-3 font-semibold">{props.title}</h4>
            <p className="text-[#475467] text-[16px] leading-7 mb-8 max-w-[400px] mx-auto">{props.description}</p>
            <div>{props.children}</div>
        </div>
    );
};

export default CPContent;
