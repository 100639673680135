import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { twMerge } from "tailwind-merge";

export interface ILoaderButtonProps extends ButtonProps {
    type?: any;
    loading?: boolean;
    loadingText?: string;
    showCircularProgress?: boolean;
}

const LoaderButton = (props: ILoaderButtonProps) => {
    const {
        type = "submit",
        loading = false,
        showCircularProgress = true,
        loadingText = "Loading...",
        className = "flex items-center justify-center",
        ...others
    } = props;

    return (
        <Button type={type} disabled={loading} className={twMerge(className)} {...others}>
            {showCircularProgress && (
                <CircularProgress
                    size={20}
                    color="inherit"
                    sx={{ mr: 1 }}
                    style={{ display: loading ? "inline-block" : "none" }}
                />
            )}
            {loading ? loadingText : props.children}
        </Button>
    );
};

export default LoaderButton;
