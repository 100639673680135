import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useMemo } from "react";
import { useAppSelector } from "shared/hooks/useRedux";
import ScanOrSnapshotCard from "./partials/ScanOrSnapshotCard";

export interface IScansOrSnapshotsBlockProps {
    id: string;
}

const ScansOrSnapshotsBlock = forwardRef((props: any, ref) => {
    const { id } = props.node.attrs as IScansOrSnapshotsBlockProps;

    const project = useAppSelector((state) => state.projects.selected);

    const scans = useMemo(() => {
        if (!project) return [];

        let scansList: any[] = [];

        project.folders.forEach((folder) => {
            scansList = [
                ...scansList,
                ...folder.scans.filter((scan) => scan.visibility == "public" && scan.method == "gaussian-splatting"),
            ];
        });

        return scansList;
    }, [project]);

    const snapshots = useMemo(() => {
        if (!project) return [];

        let snapshotsList: any[] = [];

        project.folders.forEach((folder) => {
            folder.scans.forEach((scan) => {
                if (scan.visibility == "public")
                    snapshotsList = [
                        ...snapshotsList,
                        ...scan.snapshots.map((snapshot) => ({ scan_id: scan.scan_id, ...snapshot })),
                    ];
            });
        });

        return snapshotsList;
    }, [project]);

    return (
        <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
            <div className="scans-or-snapshots w-[72%] mx-auto mt-12">
                <div className="editor-scans-grid w-full grid grid-cols-3 gap-8 pb-16">
                    {scans.map((card: any, index) => (
                        <ScanOrSnapshotCard key={`scan_${index}`} type={"scans"} data={card} />
                    ))}
                    {scans.length == 0 && (
                        <div className="w-full">
                            <div className="w-full h-0 pb-[76%] relative bg-transparent"></div>
                        </div>
                    )}
                </div>
                <div className="editor-snapshots-grid w-full grid grid-cols-3 gap-8 pb-16">
                    {snapshots.map((card: any, index) => (
                        <ScanOrSnapshotCard key={`snapshot_${index}`} type={"snapshots"} data={card} />
                    ))}
                    {scans.length == 0 && (
                        <div className="w-full">
                            <div className="w-full h-0 pb-[76%] relative bg-transparent"></div>
                        </div>
                    )}
                </div>
            </div>
        </NodeViewWrapper>
    );
});

export default ScansOrSnapshotsBlock;
