import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Placeholder from "./Placeholder";

export default Node.create({
    name: "placeholder",

    group: "block",

    content: "",

    addAttributes() {
        return {
            id: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.editor-placeholder",
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "editor-placeholder",
                id: node.attrs.id,
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Placeholder);
    },
});
