import { Badge, Button, Tooltip, Typography } from "@mui/material";

import useTextIconButton from "./hook";
import { ITextIconButtonProps } from "./type";

const TextIconButton = (props: ITextIconButtonProps) => {
    const { styles, badegStyles } = useTextIconButton(props);

    const {
        text,
        icon,
        size = "medium",
        badgeCount,
        badgeColor = "primary",
        className,
        onClick,
        tooltipTitle,
        tooltipPosition = "top",
        badgeCustomStyles,
        disabled = false,
    } = props;

    const Wrapper = ({ children }: { children: any }) => {
        if (disabled) {
            return <div>{children}</div>;
        }
        return (
            <Tooltip title={tooltipTitle} placement={tooltipPosition}>
                {children}
            </Tooltip>
        );
    };

    return (
        <Wrapper>
            <Button onClick={onClick} size={size} sx={styles} className={"!w-fit " + className} disabled={disabled}>
                <Badge color={badgeColor} badgeContent={badgeCount} sx={{ ...badegStyles, ...badgeCustomStyles }}>
                    <div className="flex items-center justify-center pl-1 gap-2">
                        <Typography style={{ fontSize: "12px", fontFamily: "inherit" }}>{text}</Typography>
                        {icon}
                    </div>
                </Badge>
            </Button>
        </Wrapper>
    );
};

export default TextIconButton;
