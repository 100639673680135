import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { UseControllerReturn } from "react-hook-form";
import FieldContext from "../Field.context";
import { IFieldProps } from "../Field.type";

const FTextField = () => {
    const { methods, props }: any = useContext(FieldContext);

    const {
        field: { name, value, onChange },
    }: UseControllerReturn = methods;
    const { placeholder, type, inputVariant = "outlined", inputTextProps, textFieldProps, icon }: IFieldProps = props;

    const [inputTypeProp, setInputTypeProp] = useState(type);

    const toggleType = () => {
        setInputTypeProp((prev) => {
            return prev === "text" ? "password" : "text";
        });
    };

    return (
        <TextField
            type={inputTypeProp}
            name={name}
            value={value ? (typeof value === "object" ? value[name] : value) : ""}
            variant={inputVariant}
            onChange={onChange}
            placeholder={placeholder || ""}
            InputProps={{
                startAdornment: icon || null,
                endAdornment: type === "password" && (
                    <IconButton onClick={toggleType}>
                        {inputTypeProp === "text" ? (
                            <Visibility color="primary" className="!h-4 !w-4" />
                        ) : (
                            <VisibilityOff color="primary" className="!h-4 !w-4" />
                        )}
                    </IconButton>
                ),
                ...inputTextProps,
            }}
            {...textFieldProps}
        />
    );
};

export default FTextField;
