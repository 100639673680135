import styled from "@emotion/styled";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import moment from "moment";
import { useMemo } from "react";

import { DataTableHeaderProps } from "shared/components/ui/DataTable/type";
import { GetScanRequestsQuery } from "shared/graphql";

type Request = GetScanRequestsQuery["team_scan_requests"][0];

const useSRDeliveredScreen = () => {
    const Headers = useMemo<DataTableHeaderProps[]>(() => {
        return [
            {
                title: "Project Request",
                selector: "title",
            },
            {
                title: "Created Date",
                align: "center",
                selector(item: Request) {
                    return moment(item.created_at).format("DD MMM, YYYY");
                },
            },
            {
                title: "Completed Date",
                align: "center",
                selector() {
                    return moment().format("DD MMM, YYYY");
                },
            },
            {
                title: "Total Scans",
                align: "center",
                selector(item: Request) {
                    return item.applications.reduce((acc, curr) => {
                        return acc + curr.submissions.filter((s) => s.model.status === "Completed").length;
                    }, 0);
                },
            },
            {
                title: "Location",
                align: "center",
                selector(item: Request) {
                    return item.location.name;
                },
            },
            {
                title: "Total Scanners",
                align: "center",
                selector(item: Request) {
                    return item.applications.length;
                },
            },
            {
                title: "Scanners",
                align: "center",
                selector(item: Request) {
                    return item.applications.length ? (
                        <AvatarGroup
                            max={5}
                            className="justify-center"
                            componentsProps={{
                                additionalAvatar: {
                                    classes: {
                                        root: "!bg-[#E4E6FF] !text-p !text-opacity-50 cursor-pointer !text-lg !w-8 !h-8",
                                    },
                                },
                            }}
                        >
                            {item.applications.map((requestee, i) => {
                                return (
                                    <Tooltip title={requestee.user.name} arrow key={i}>
                                        <CustomAvatar
                                            alt={requestee.user.name || ""}
                                            src={requestee.user.profile_image.url}
                                        />
                                    </Tooltip>
                                );
                            })}
                        </AvatarGroup>
                    ) : (
                        <></>
                    );
                },
            },
            // {
            //     title: "Scanning Devices",
            //     align: "center",
            //     selector(item: Request) {
            //         const scanningDevices = item.applications.reduce((acc, curr) => {
            //             let devices = curr.user.scanning_devices;
            //             return acc.concat(devices);
            //         }, [] as ScanningDevices);

            //         const maxDevices = 2;
            //         const first4Devices = scanningDevices?.slice(0, maxDevices);
            //         const isLengthExceed = first4Devices && scanningDevices?.length! > first4Devices.length;

            //         return (
            //             <Tooltip
            //                 title={
            //                     isLengthExceed
            //                         ? scanningDevices?.map((device) => {
            //                               return (
            //                                   <div
            //                                       className="text-[#344054] text-sm font-medium leading-[18px]"
            //                                       key={device.scanning_device_id}
            //                                   >
            //                                       {device.name}
            //                                   </div>
            //                               );
            //                           })
            //                         : ""
            //                 }
            //                 placement="top"
            //                 classes={{
            //                     tooltip: "!bg-white !py-2 !px-3 text-center shadow-[0px_12px_16px_-4px_#10182814]",
            //                     arrow: "!text-white",
            //                 }}
            //             >
            //                 <div className="flex flex-wrap justify-center gap-1">
            //                     {first4Devices && first4Devices.length ? (
            //                         first4Devices?.slice(0, maxDevices).map((device) => {
            //                             return (
            //                                 <div className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px] inline">
            //                                     {device.name}
            //                                 </div>
            //                             );
            //                         })
            //                     ) : (
            //                         <>-</>
            //                     )}
            //                     {isLengthExceed && (
            //                         <div className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px] inline">
            //                             +{scanningDevices?.length! - first4Devices.length}
            //                         </div>
            //                     )}
            //                 </div>
            //             </Tooltip>
            //         );
            //     },
            // },
        ] as DataTableHeaderProps[];
    }, []);

    return { Headers };
};

export default useSRDeliveredScreen;

const CustomAvatar = styled(Avatar)(() => ({
    width: "27px",
    height: "27px",
}));
