import { BubbleMenu, EditorContent } from "@tiptap/react";
import useImages from "assets/images";
import { useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PageLoader from "shared/components/ui/PageLoader";
import PropertiesSidebar from "../PropertiesSidebar/PropertiesSidebar";
import useEditor from "./useEditor";

const Editor = () => {
    const {
        ArrowRightNav,
        EditorBubbleBoldIcon,
        EditorBubbleItalicIcon,
        EditorBubbleStrikeIcon,
        EditorBubbleHighlightIcon,
        EditorBubbleUnderlineIcon,
    } = useImages();
    const {
        editor,
        isSidebarOpen,
        toggleComponentsSidebar,
        isSelectingTemplate,
        isPropertiesSidebarOpen,
        isPreviewOpen,
        isDownloadingContent,
        templateClass,
        deselectBlock,
    } = useEditor();

    const propsToolbar = useMemo(() => {
        return <PropertiesSidebar editor={editor} />;
    }, [editor]);

    return (
        <>
            {isDownloadingContent && <PageLoader />}
            <div className="h-full flex flex-1 gap-x-4">
                <div
                    className={
                        "flex-1 flex flex-col h-full rounded-xl bg-[#FFFFFF] border border-[#EAECF0] shadow-[0px_1px_2px_0px_#1018280D] p-4 " +
                        (isSelectingTemplate && "!border-2 !border-[#7680FF]")
                    }
                    onClick={() => deselectBlock()}
                >
                    <span
                        className={
                            "select-none cursor-pointer " +
                            (isSelectingTemplate && "hidden opacity-0 pointer-events-none ") +
                            (isPreviewOpen && " hidden ")
                        }
                        onClick={() => toggleComponentsSidebar()}
                    >
                        <ArrowRightNav
                            width={16}
                            height={16}
                            className={" -mt-1 " + (isSidebarOpen ? " rotate-180 " : "")}
                        />
                    </span>
                    <PerfectScrollbar
                        className={
                            "flex-1 " + ((isPreviewOpen || isSelectingTemplate) && " editor-preview ") + templateClass
                        }
                    >
                        <EditorContent
                            editor={editor}
                            className={"" + (isSelectingTemplate && "pointer-events-none")}
                            disabled={true}
                        />
                        <BubbleMenu editor={editor ?? undefined} className="editor-bubble-menu">
                            <div className="flex items-center bg-[#0C111D] rounded-lg gap-x-3 px-3 pt-1 pb-2">
                                <button
                                    onClick={() => editor?.chain().focus().toggleBold().run()}
                                    className={editor?.isActive("bold") ? "bubble-menu-item-active" : ""}
                                >
                                    <EditorBubbleBoldIcon width={12} height={12} />
                                </button>
                                <button
                                    onClick={() => editor?.chain().focus().toggleItalic().run()}
                                    className={editor?.isActive("italic") ? "bubble-menu-item-active" : ""}
                                >
                                    <EditorBubbleItalicIcon width={12} height={12} />
                                </button>
                                <button
                                    onClick={() => editor?.chain().focus().toggleStrike().run()}
                                    className={editor?.isActive("strike") ? "bubble-menu-item-active" : ""}
                                >
                                    <EditorBubbleStrikeIcon width={16} height={16} />
                                </button>
                                <button
                                    onClick={() => editor?.chain().focus().toggleHighlight({ color: "#C8CCFF" }).run()}
                                    className={editor?.isActive("highlight") ? "bubble-menu-item-active" : ""}
                                >
                                    <EditorBubbleHighlightIcon width={16} height={16} />
                                </button>
                                <button
                                    onClick={() => editor?.chain().focus().toggleUnderline().run()}
                                    className={editor?.isActive("underline") ? "bubble-menu-item-active" : ""}
                                >
                                    <EditorBubbleUnderlineIcon width={12} height={12} />
                                </button>
                            </div>
                        </BubbleMenu>
                    </PerfectScrollbar>
                </div>
                {isPropertiesSidebarOpen && propsToolbar}
            </div>
        </>
    );
};

export default Editor;
