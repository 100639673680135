import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetAssetByIdQuery, GetMyScansByIdQuery } from "shared/graphql";

interface Props {
    selected: GetMyScansByIdQuery["scan"] | null;
    asset: GetAssetByIdQuery["asset"] | null;
}

const initialState: Props = {
    selected: null,
    asset: null,
};

const assets = createSlice({
    name: "assets",
    initialState,
    reducers: {
        setSelectedScan(state, action: PayloadAction<Props["selected"]>) {
            state.selected = action.payload;
        },
        setSelectedAsset(state, action: PayloadAction<Props["asset"]>) {
            state.asset = action.payload;
        },
    },
});

export default assets.reducer;

export const { setSelectedScan, setSelectedAsset } = assets.actions;
