import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import { TeamMember, useRemoveMemberMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const RemoveMember = ({ data }: { data: TeamMember }) => {
    const { DeleteLayerIcon } = useImages();

    const [removeMember, removeRes] = useRemoveMemberMutation();

    useEffect(() => {
        rtkHandler(removeRes, {
            successMessage: "Team Member removed successfully",
        });
    }, [removeRes]);

    return (
        <ConfirmButton
            title="Remove Member"
            content={
                <p className="text-p">
                    Are you sure you want to remove <br />
                    <span className="text-primary">{!data.member_id ? data.user?.email : data.user?.name}</span> from
                    team?
                </p>
            }
            icon={<DeleteLayerIcon />}
            okBtnText="Remove Member"
            okBtnProps={{
                loading: removeRes.isLoading,
                onClick: () => {
                    removeMember({
                        member_id: data.member_id,
                    });
                },
                color: "error",
            }}
        >
            <Button variant="text" color="primary">
                Remove
            </Button>
        </ConfirmButton>
    );
};

export default RemoveMember;
