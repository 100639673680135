import { Button, CircularProgress } from "@mui/material";
import useImages from "assets/images";
import { capitalize } from "lodash";
import { Outlet } from "react-router-dom";
import PageLoader from "shared/components/ui/PageLoader";
import ThemeModal from "shared/components/ui/ThemeModal";
import ShareProjectModalContent from "./utils/ShareProjectModalContent";
import { twMerge } from "tailwind-merge";
import usePublishableProjectLayout from "./hook";

const PublishableProjectsLayout = () => {
    const {
        isSelectingTemplate,
        project,
        onOpenProject,
        onSaveAndPreviewClickedHandler,
        onClosePreviewClickedHandler,
        isShareDialogOpen,
        showShareDialog,
        hideShareDialog,
        isPreviewOpen,
        isSaving,
        isOnboardingFlow,
        onSkipOrNextStep,
        isSavedOnce,
        isShowPageLoader,
    } = usePublishableProjectLayout();

    const { ArrowChevronLeft, ShareLayerIcon } = useImages();

    return (
        <div className={twMerge("h-full", !isOnboardingFlow && "bg-[#F9F9F9]")}>
            {isShowPageLoader && <PageLoader />}
            <div className="h-[80px] px-6 flex items-center justify-between bg-[#FFFFFF] border-b border-b-[#D0D5DD40]">
                <span
                    className="text-[20px] font-semibold text-[#344054] flex items-center gap-x-[6px] select-none cursor-pointer"
                    onClick={() =>
                        !isOnboardingFlow
                            ? isSelectingTemplate
                                ? onOpenProject()
                                : onSaveAndPreviewClickedHandler("back")
                            : {}
                    }
                >
                    {!isOnboardingFlow && <ArrowChevronLeft height={20} />}
                    {capitalize(project?.name || "")}
                </span>
                <span className="flex items-center">
                    {!isSelectingTemplate && (
                        <>
                            {isPreviewOpen ? (
                                <Button
                                    variant="outlined"
                                    onClick={onClosePreviewClickedHandler}
                                    color="inherit"
                                    className={"h-8 !ml-0"}
                                >
                                    Close Preview
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => onSaveAndPreviewClickedHandler()}
                                        color="inherit"
                                        className={"h-8 !ml-0 !mr-3 !flex !items-center !justify-center"}
                                    >
                                        {isSaving ? <CircularProgress size={20} /> : "Preview"}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            onSaveAndPreviewClickedHandler("auto");
                                            showShareDialog();
                                        }}
                                        color="primary"
                                        className={"h-8 !ml-0"}
                                    >
                                        Share
                                    </Button>
                                </>
                            )}

                            <ThemeModal
                                icon={<ShareLayerIcon height={50} width={50} />}
                                open={isShareDialogOpen}
                                onClose={hideShareDialog}
                                title="Share Project"
                                content="Change your project sharing settings here. Switch between public and private sharing with the button below."
                                contentComponent={
                                    <ShareProjectModalContent
                                        onClose={hideShareDialog}
                                        onSuccess={() => {}}
                                        loading={isSaving}
                                        onSave={() => onSaveAndPreviewClickedHandler("share")}
                                    />
                                }
                            />
                        </>
                    )}
                    {!isSelectingTemplate && <div className="w-[1px] h-4 bg-[#BCBCBC] mx-3"></div>}
                    <Button
                        variant={isOnboardingFlow ? "contained" : "outlined"}
                        onClick={() =>
                            isOnboardingFlow
                                ? onSkipOrNextStep()
                                : isSelectingTemplate
                                ? onOpenProject()
                                : onSaveAndPreviewClickedHandler("back")
                        }
                        color={isOnboardingFlow ? "primary" : "inherit"}
                        className={"h-8 !ml-0 !text-[#344054] " + (isOnboardingFlow && "!font-semibold !text-white")}
                    >
                        {isOnboardingFlow ? (isSavedOnce ? "Next Step" : "Skip Step") : "Project"}
                    </Button>
                </span>
            </div>
            <div className="h-[calc(100%-80px)] p-4 ">
                <Outlet />
            </div>
        </div>
    );
};

export default PublishableProjectsLayout;
