import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import PageLoader from "shared/components/ui/PageLoader";
import URL from "shared/constants/navigator";
import { VerifyOtpMutation, useVerifyOtpMutation } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";

const VerifyEmailScreen = () => {
    const [verifyOtp, verifyRes] = useVerifyOtpMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // query params
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const verification_id = searchParams.get("verification_id");

    useEffect(() => {
        rtkHandler(verifyRes, {
            successMessage: "Email verified successfully",
            onSuccess({ verify_otp }: VerifyOtpMutation) {
                if (verify_otp.user.waiting_list) {
                    navigate(URL.Waiting_List, {
                        state: {
                            waiting_list: true,
                        },
                    });
                } else {
                    navigate(URL.GetStarted);
                }
            },
            onError() {
                navigate(URL.Login);
            },
        });
    }, [verifyRes, dispatch]);

    useEffect(() => {
        if (verification_id && token) {
            // call api to verify verification_id
            verifyOtp({
                data: {
                    email_otp: token,
                    email_verification_id: Number(verification_id),
                },
            });
        }
    }, [verification_id, token]);

    if (!token) {
        return <Navigate to={URL.Home} />;
    }

    return <PageLoader />;
};

export default VerifyEmailScreen;
