import { ButtonBase, Skeleton } from "@mui/material";
import React from "react";
import { twMerge } from "tailwind-merge";
import ThemeCheckbox from "../ThemeCheckbox";

interface ICheckboxCardProps {
    checked: boolean;
    onClick: () => void;
    value: string;
    startIcon?: React.ReactNode;
    children?: React.ReactNode;
    classes?: {
        root?: string;
    };
}

const CheckboxCard = (props: ICheckboxCardProps) => {
    const rootClasses = twMerge(
        "text-left !p-4 !border-[#EAECF0] !border !border-solid !rounded-md w-full",
        props.checked ? "!bg-[#F2F3FD] !border-[#C8CCFF]" : "bg-white",
        props.classes?.root
    );

    return (
        <ButtonBase onClick={props.onClick} className={rootClasses}>
            <div className="flex items-start space-x-4 w-full">
                <div className="w-full flex items-start space-x-[14px]">
                    {props.startIcon}
                    <div className="w-full">{props.children}</div>
                </div>
                <ThemeCheckbox variant="circle" checked={props.checked} value={props.value} onClick={props.onClick} />
            </div>
        </ButtonBase>
    );
};

export default CheckboxCard;

export const CheckboxCardSkeleton = () => {
    return (
        <ButtonBase disabled className="text-left !p-4 !border-[#EAECF0] !border !border-solid !rounded-md w-full">
            <div className="flex items-start space-x-4 w-full">
                <div className="w-full flex items-start space-x-[14px]">
                    <Skeleton variant="circular" width={40} height={40} style={{ minWidth: 40 }} />
                    <div className="w-full">
                        <Skeleton variant="text" width={"100%"} height={20} />
                        <Skeleton variant="text" width={"50%"} height={20} />
                    </div>
                </div>
                <Skeleton variant="circular" width={20} height={20} style={{ minWidth: 20 }} />
            </div>
        </ButtonBase>
    );
};
