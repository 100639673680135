import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import SRDeliveredScreen from "screens/Dashboard/ScanRequests/screens/Delivered";
import SRInProgressScreen from "screens/Dashboard/ScanRequests/screens/InProgress";
import SRNewScreen from "screens/Dashboard/ScanRequests/screens/New/SRNewScreen";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import useAuth from "shared/hooks/useAuth";

const SREntryPoint = () => {
    const { type } = useParams<{ type: string }>();
    const { isTeamMember } = useAuth();
    const [Component, setComponent] = useState(<></>);
    const UserHasRequesteeAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestee);
    const UserHasRequestorAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestor);

    let hasScanRequestAccess = false;
    if (UserHasRequesteeAccess || UserHasRequestorAccess) {
        hasScanRequestAccess = true;
    }
    if (isTeamMember && !UserHasRequesteeAccess) {
        hasScanRequestAccess = false;
    }

    useEffect(() => {
        if (!hasScanRequestAccess) {
            setComponent(<Navigate to="/404" />);
        } else if (type === "new") {
            setComponent(<SRNewScreen />);
        } else if (type === "in-progress") {
            setComponent(<SRInProgressScreen />);
        } else if (type === "delivered") {
            setComponent(<SRDeliveredScreen />);
        } else {
            setComponent(<Navigate to="/404" />);
        }
    }, [type, hasScanRequestAccess]);

    return Component;
};

export default SREntryPoint;
