import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import axios from "axios";
import { useEffect } from "react";
import { File as FileType } from "shared/graphql";
import { setAddUploadFile, setUploadFileData, setUploadFileLoading } from "store/slices/fileUpload";
import { useAppDispatch, useAppSelector } from "./useRedux";

const CHUNK_SIZE = 1024 * 1024 * 10;

const ENDPOINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT || "";

const useFileUploader = () => {
    const uppy = new Uppy({ debug: true, autoProceed: true });
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state) => state.fileUpload.loading);
    const loader_id = useAppSelector((state) => state.fileUpload.loader_id);

    useEffect(() => {
        return () => {
            uppy.cancelAll();
        };
    }, []);

    const uploadFile = (file?: File, id?: string) => {
        if (!file) return Promise.reject("No file provided");
        dispatch(setUploadFileLoading({ loading: true, loader_id: id }));
        return new Promise<FileType | null>((resolve, reject) => {
            try {
                dispatch(setAddUploadFile(file.name));
                dispatch(
                    setUploadFileData({
                        id: file.name,
                        progress: 0,
                        status: "uploading",
                    })
                );

                uppy.addFile({
                    name: file?.name,
                    type: file?.type,
                    data: file,
                });

                uppy.use(Tus, {
                    endpoint: ENDPOINT,
                    chunkSize: CHUNK_SIZE,
                    headers: {
                        "file-name": file?.name,
                        "file-type": file?.type,
                    },
                    onShouldRetry: () => {
                        return false;
                    },
                });

                uppy.on("complete", async (result: any) => {
                    if (result.successful && result.successful.length) {
                        let uuid = result.successful[0].uploadURL.split("/").pop();
                        uuid = uuid?.split("?").shift() ?? "";
                        if (uuid && file) {
                            const res = await axios.get<FileType>(`${ENDPOINT}/metadata/${uuid}`);
                            if (res.status === 200) {
                                dispatch(
                                    setUploadFileData({
                                        id: file.name,
                                        progress: 100,
                                        status: "success",
                                        data: res.data,
                                    })
                                );
                                resolve(res.data);
                            } else {
                                dispatch(
                                    setUploadFileData({
                                        id: file.name,
                                        progress: 0,
                                        status: "error",
                                        error: res.data,
                                        data: null,
                                    })
                                );
                                reject(null);
                            }
                        }
                    }
                    dispatch(setUploadFileLoading({ loading: false, loader_id: id }));
                });

                uppy.on("progress", (e: any) => {
                    dispatch(
                        setUploadFileData({
                            id: file.name,
                            progress: e,
                        })
                    );
                });

                uppy.on("error", (error: any) => {
                    reject(error);
                });
            } catch (error) {
                dispatch(setUploadFileLoading({ loading: false, loader_id: id }));
                dispatch(
                    setUploadFileData({
                        id: file.name,
                        status: "error",
                        error: error,
                        data: null,
                    })
                );
                reject(error);
            }
        });
    };

    return { uploadFile, uppy, loading, loader_id };
};

export default useFileUploader;
