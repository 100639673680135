import { Button } from "@mui/material";
import useImages from "assets/images";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import CopyToClipboard from "shared/components/CopyToClipboard";
import Field from "shared/components/ui/Field/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import useAuth from "shared/hooks/useAuth";
import useInviteMemberForm from "./hook";

export interface InviteMemberModalForm {
    closeModal: () => void;
    isVisible: boolean;
}

const InviteMemberForm = (props: InviteMemberModalForm) => {
    const { form, inviteeCount, addInvitee, onSubmit } = useInviteMemberForm(props);

    const { EmailIcon, PlusWhite } = useImages();
    const { isTeamOwner } = useAuth();

    const link = form.watch("link");
    const inviteCode = form.watch("invite_code");

    return (
        <ThemeFormProvider form={form} className="text-left relative" onSubmit={onSubmit}>
            {isTeamOwner && (
                <div className="flex flex-col mb-[20px] gap-y-2 items-start">
                    {Array(inviteeCount)
                        .fill("")
                        .map((_, index) => (
                            <Field
                                key={index}
                                formGroup={false}
                                label={index == 0 && "Email Address"}
                                placeholder="Enter email address"
                                name={`email${index + 1}`}
                                inputTextProps={{
                                    startAdornment: <EmailIcon color="#667085" />,
                                }}
                            />
                        ))}

                    {(inviteeCount ?? 0) < 3 && (
                        <Button variant="text" startIcon={<PlusWhite color="#7680FF" />} onClick={() => addInvitee()}>
                            Add another
                        </Button>
                    )}
                </div>
            )}
            <Field
                label="Share Link"
                placeholder="Enter share link"
                name="link"
                inputTextProps={{
                    readOnly: true,
                    endAdornment: CopyToClipboard(link || ""),
                }}
            />
            <Field
                label="Invite Code"
                placeholder="Enter invite code"
                name="invite_code"
                inputTextProps={{
                    readOnly: true,
                    endAdornment: CopyToClipboard(inviteCode || ""),
                }}
            />
            <CommonModalActionButton
                buttons={
                    isTeamOwner
                        ? [
                              {
                                  id: "invite_users_btn",
                                  children: "Send Invites",
                                  onClick: form.handleSubmit(onSubmit),
                              },
                          ]
                        : []
                }
                cancelBtnProps={{
                    onClick: props.closeModal,
                }}
            />
        </ThemeFormProvider>
    );
};

export default InviteMemberForm;
