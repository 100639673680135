import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { CreateScanRequestMutation, useCreateScanRequestMutation } from "shared/graphql";
import { ScanDataSetTypeNames } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";

type FormValues = yup.InferType<typeof schema>;

const useSRNewPostScreen = () => {
    const [create, createRes] = useCreateScanRequestMutation();
    const [inviteModalId, setInviteModalId] = useState<number | null>(null);

    const [options] = useState({
        urgency: [
            { label: "Critical", value: "Critical" },
            { label: "High", value: "High" },
            { label: "Medium", value: "Medium" },
            { label: "Low", value: "Low" },
        ],
        estimated_time: [
            { label: "Under an hour", value: "Under an hour" },
            { label: "~2 hours", value: "~2 hours" },
            { label: "~4 hours", value: "~4 hours" },
            { label: "Half Day", value: "Half Day" },
            { label: "Full Day", value: "Full Day" },
        ],
        scan_focus: [
            { label: "Object", value: "Object" },
            { label: "Building", value: "Building" },
            { label: "Scene", value: "Scene" },
            { label: "Pose", value: "Pose" },
            { label: "Room", value: "Room" },
        ],
        dataset_type: [
            { label: ScanDataSetTypeNames.video, value: "video" },
            { label: ScanDataSetTypeNames.images, value: "images" },
        ],
        camera_type: [
            { label: "Mobile Phone", value: "Mobile Phone" },
            { label: "Tablet", value: "Tablet" },
            { label: "Drone", value: "Drone" },
            { label: "Typical Camera", value: "Typical Camera" },
            { label: "360 Camera", value: "360 Camera" },
            { label: "Other", value: "Other" },
        ],
    });

    const form = useForm<FormValues>({
        defaultValues: {
            step: SRNewSteps.Basic,
            urgency: "Critical",
            estimated_time: "Under an hour",
            scan_focus: "Object",
            dataset_type: "video",
            camera_type: "Mobile Phone",
            extra_notes: "",
            description: "",
            scanner_count: "Open For All",
            location: {
                latitude: "",
                longitude: "",
            },
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(createRes, {
            setError: form.setError,
            onSuccess(res: CreateScanRequestMutation) {
                const { scanner_count } = form.getValues();
                const all = scanner_count === "Open For All" ? "all" : scanner_count;
                successToast(`Your request is submitted and open for request for ${all} scanners.`, {
                    duration: 5000,
                });
                setInviteModalId(res.publish_scan_request.scan_request_id);
            },
        });
    }, [createRes]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                form.setValue("location.latitude", position.coords.latitude.toString());
                form.setValue("location.longitude", position.coords.longitude.toString());
            });
        }
    }, []);

    const onSubmit = (data: FormValues) => {
        create({
            data: {
                camera_type: data.camera_type!,
                dataset_type: data.dataset_type!,
                description: data.description!,
                estimated_time: data.estimated_time!,
                location: {
                    latitude: data.location.latitude!,
                    longitude: data.location.longitude!,
                },
                scan_focus: data.scan_focus!,
                scanner_count: data.scanner_count === "Open For All" ? 0 : parseInt(data.scanner_count!),
                title: data.title!,
                urgency: data.urgency!,
                extra_notes: data.extra_notes,
            },
        });
    };

    const onStepChange = async (move: "next" | "back") => {
        if (move === "next" && !(await form.trigger())) return;

        const steps = Object.values(SRNewSteps);
        const currentStep = steps.indexOf(form.getValues("step"));
        const nextStep = steps[currentStep + (move === "next" ? 1 : -1)];
        form.setValue("step", nextStep);
    };

    return { form, options, createRes, inviteModalId, setInviteModalId, onSubmit, onStepChange };
};

export enum SRNewSteps {
    Basic = "Basic",
    Details = "Details",
    Location = "Location",
}

const schema = yup.object().shape({
    step: yup.string().oneOf(Object.values(SRNewSteps)).required(),
    title: yup.string().when("step", {
        is: SRNewSteps.Basic,
        then: (schema) => schema.required(),
    }),
    description: yup.string().when("step", {
        is: SRNewSteps.Basic,
        then: (schema) => schema.required(),
    }),
    urgency: yup.string().when("step", {
        is: SRNewSteps.Basic,
        then: (schema) => schema.required(),
    }),
    scanner_count: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    estimated_time: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    scan_focus: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    dataset_type: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    camera_type: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    extra_notes: yup.string().when("step", {
        is: SRNewSteps.Details,
        then: (schema) => schema.required(),
    }),
    location: yup.object().shape({
        longitude: yup.string().when("step", {
            is: SRNewSteps.Location,
            then: (schema) => schema.required(),
        }),
        latitude: yup.string().when("step", {
            is: SRNewSteps.Location,
            then: (schema) => schema.required(),
        }),
    }),
});

export default useSRNewPostScreen;
