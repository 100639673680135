import styled from "@emotion/styled";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { GetScanRequestsQuery } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { ScanDataSetTypeNames } from "shared/utils/enums";
import { setSelectScanRequest } from "store/slices/scan_requests";

import ChipBox from "../ChipBox";
import { RequestorTypes } from "../RequestorSwitch";

interface I {
    mode: RequestorTypes;
    onClick?: () => void;
    url?: string;
    end?: boolean;
    request: GetScanRequestsQuery["team_scan_requests"][0];
}

const ScanRequestListItem = (props: I) => {
    const dispatch = useAppDispatch();
    const request = useAppSelector((state) => state.scan_request.selected);
    const createdAt = props.request.created_at;
    const scansCreatedToday = moment().diff(createdAt, "days") === 0;

    return (
        <NavLink
            onClick={() => {
                props.onClick && props.onClick();
                if (request?.scan_request_id !== props.request.scan_request_id) dispatch(setSelectScanRequest(null));
            }}
            to={props.url || "/"}
            className={({ isActive }) => {
                return `rounded-lg bg-grey-light py-2 px-4 flex items-center space-x-4 cursor-pointer border border-transparent hover:border-border2 ${
                    isActive ? "!bg-[#7680FF] !bg-opacity-20 !border-[#7680FF]" : ""
                } ${props.request.status === "Archived" ? "opacity-80" : ""}`;
            }}
            end={props.end || false}
        >
            {props.mode === "requestee" && props.request.requestor.profile_image && (
                <img
                    src={props.request.requestor.profile_image.url}
                    className="!w-[40px] !min-w-[40px] !h-[40px] rounded-full object-cover object-center"
                    alt="dummy-scan"
                />
            )}
            <div
                className={twMerge(
                    "flex items-center justify-between space-x-4 whitespace-nowrap text-ellipsis overflow-hidden w-full"
                    // props.mode === "requestee" ? "w-[calc(100%-50px)]" : "w-full"
                )}
            >
                <div className={"flex-1 whitespace-nowrap text-ellipsis overflow-hidden"}>
                    <h6 className="text-p font-medium text-xs mb-[2px] overflow-hidden whitespace-nowrap text-ellipsis">
                        {props.mode === "requestee"
                            ? props.request.requestor.name
                            : `${props.request.applications.length} Requestees`}
                    </h6>
                    <div
                        className={twMerge(
                            "flex items-center space-x-2 mb-1",
                            props.mode === "requestee" ? "justify-between" : ""
                        )}
                    >
                        <h4 className="text-h1 text-lg font-medium capitalize whitespace-nowrap overflow-hidden text-ellipsis leading-6">
                            {props.request.title}
                        </h4>
                        {scansCreatedToday && (
                            <ChipBox
                                color="primary"
                                className="!border-none !text-sm"
                                classes={{
                                    label: "!pr-3",
                                }}
                                label={"New"}
                            />
                        )}
                    </div>
                    <h4 className="text-p font-medium text-xs text-ellipsis overflow-hidden whitespace-nowrap">
                        {`${props.request.urgency} - ${ScanDataSetTypeNames[props.request.dataset_type]}`}
                    </h4>
                </div>
                {props.mode === "requestor" && props.request.applications ? (
                    <AvatarGroup
                        max={5}
                        className="justify-end"
                        componentsProps={{
                            additionalAvatar: {
                                classes: {
                                    root: "!bg-[#E4E6FF] !text-p !text-opacity-50 cursor-pointer !text-lg !w-8 !h-8",
                                },
                            },
                        }}
                    >
                        {props.request.applications.map((requestee, i) => {
                            return (
                                <Tooltip title={requestee.user.name} key={i}>
                                    <CustomAvatar
                                        alt={requestee.user.name ?? ""}
                                        src={requestee.user.profile_image.url}
                                    />
                                </Tooltip>
                            );
                        })}
                    </AvatarGroup>
                ) : (
                    <></>
                )}
            </div>
        </NavLink>
    );
};

export default ScanRequestListItem;

const CustomAvatar = styled(Avatar)(() => ({
    width: "27px",
    height: "27px",
}));
