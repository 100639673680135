import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetScanRequests: {
            providesTags: (result) =>
                result
                    ? [
                          ...result.team_scan_requests.map(
                              ({ scan_request_id }) =>
                                  ({
                                      type: "ScanRequests",
                                      id: scan_request_id,
                                  } as const)
                          ),
                          { type: "ScanRequests", id: "LIST" },
                      ]
                    : [{ type: "ScanRequests", id: "LIST" }],
        },
        GetScanRequestById: {
            providesTags: (result, error, { scan_request_id }) =>
                error ? [] : [{ type: "ScanRequests", id: scan_request_id }],
        },
        CreateScanRequest: {
            invalidatesTags: (result, error) => (error ? [] : [{ type: "ScanRequests", id: "LIST" }]),
        },
        EndScanRequest: {
            invalidatesTags: (result, error, { request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: request_id },
                      ],
        },
        InviteMembersToScanRequest: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
        DeleteScanRequest: {
            invalidatesTags: (result, error, { request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: request_id },
                      ],
        },
        AcceptScanRequest: {
            invalidatesTags: (result, error, { request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: request_id },
                      ],
        },
        RejectScanRequest: {
            invalidatesTags: (result, error, { request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: request_id },
                      ],
        },
        AddSubmissionToScanRequest: {
            invalidatesTags: (result, error, { data: { scan_request_id } }) =>
                error
                    ? []
                    : [{ type: "ScanRequests", id: "LIST" }, { type: "ScanRequests", id: scan_request_id }, "Storage"],
        },
        SendSubmissionReminder: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
        AcceptSubmission: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
        RequestRevision: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
        GiveFeedbackToSubmission: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
        RequestReview: {
            invalidatesTags: (result, error, { scan_request_id }) =>
                error
                    ? []
                    : [
                          { type: "ScanRequests", id: "LIST" },
                          { type: "ScanRequests", id: scan_request_id },
                      ],
        },
    },
});

export const {
    useGetScanRequestsQuery,
    useGetScanRequestByIdQuery,
    useCreateScanRequestMutation,
    useDeleteScanRequestMutation,
    useInviteMembersToScanRequestMutation,
    useEndScanRequestMutation,
    useAcceptScanRequestMutation,
    useRejectScanRequestMutation,
    useAddSubmissionToScanRequestMutation,
    useSendSubmissionReminderMutation,
    useAcceptSubmissionMutation,
    useRequestRevisionMutation,
    useGiveFeedbackToSubmissionMutation,
    useRequestReviewMutation,
} = api;
