import { CircularProgress } from "@mui/material";
import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import useImages from "assets/images";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface ICarouselProps {
    id: string;
    images: {
        id: string;
        url: string;
        type: "upload" | "asset" | "snapshot";
        redirecting_url: string | null;
        is_redirecting: false;
    }[];
    image_switch: boolean;
    justify_arrows: "left" | "center" | "right";
    current: number;
    uploading: number;
}

const Carousel = (props: any) => {
    const { id, images, image_switch, justify_arrows, current, uploading } = props.node.attrs as ICarouselProps;
    const dispatch = useAppDispatch();

    const { EditorCarouselArrowRightIcon, EditorCarouselArrowLeftIcon, ArrowUpRight } = useImages();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);

    const parentRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const [currentImage, setCurrentImage] = useState(0);
    const currentImageRef = useRef(currentImage);
    currentImageRef.current = currentImage;

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const arrowStyles = useMemo(() => {
        let style = "flex mt-6 ";

        if (justify_arrows == "left") {
            style += "justify-start";
        } else if (justify_arrows == "right") {
            style += "justify-end";
        } else {
            style += "justify-center";
        }

        return style;
    }, [justify_arrows]);

    const imageSwitcherStyles = useMemo(() => {
        return `text-${justify_arrows}`;
    }, [justify_arrows]);

    const prevImg = () => {
        if (images.length > 0)
            setCurrentImage(currentImageRef.current - 1 >= 0 ? currentImageRef.current - 1 : images.length - 1);
    };

    const nextImg = () => {
        if (images.length > 0) setCurrentImage((currentImageRef.current + 1) % images.length);
    };

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    // useEffect(() => {
    //     if (currentImage <= images.length - 1 && images[currentImage].url) setIsLoading(true);
    // }, [images, currentImage]);

    useEffect(() => {
        if (current != -1) setCurrentImage(current);
    }, [current]);

    useEffect(() => {
        if (uploading != -1 && uploading == currentImage) setIsLoading(true);
    }, [uploading, currentImage]);

    return (
        <NodeViewWrapper
            id={id}
            className={`editor-block carousel-component ${isSelected && "editor-block-selected"} bg-white relative`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "carousel",
                        props: { id: props.node.attrs.id, attrs: props.node.attrs },
                    })
                );
            }}
        >
            <div ref={parentRef} className="w-full">
                <div className="w-full h-0 pb-[70%] relative rounded-lg">
                    {images.length > 0 ? (
                        images[currentImage]?.url ?? images[0].url ? (
                            <>
                                <img
                                    src={images[currentImage]?.url ?? images[0].url}
                                    className="w-full h-full absolute top-0 left-0 object-cover rounded-lg"
                                    onLoad={() => {
                                        setIsLoading(false);
                                    }}
                                />
                                {(images[currentImage]?.type ?? images[0].type) == "snapshot" &&
                                    images[currentImage].redirecting_url &&
                                    images[currentImage].is_redirecting && (
                                        <div
                                            className="h-9 w-9 rounded-lg bg-[#FFFFFFB2] absolute top-4 right-4 flex items-center justify-center cursor-pointer hover:brightness-90"
                                            onClick={() => {
                                                window.open(
                                                    images[currentImage]!.redirecting_url!.substring(
                                                        0,
                                                        images[currentImage]!.redirecting_url!.indexOf("&embed")
                                                    ),
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <ArrowUpRight width={18} height={18} />
                                        </div>
                                    )}
                                {images[currentImage]?.url && isLoading && (
                                    <div className="w-full h-full absolute top-0 left-0 z-10 flex items-center justify-center bg-[#F2F3FD] rounded-md">
                                        <CircularProgress
                                            variant="indeterminate"
                                            size="30px"
                                            thickness={2}
                                            color="primary"
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="w-full h-full absolute top-0 left-0 rounded-lg bg-[#F2F3FD]"></div>
                        )
                    ) : (
                        <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center text-md text-[#5E5D5D] opacity-50">
                            Upload Image(s)
                        </div>
                    )}
                </div>
                {images.length > 0 && (
                    <>
                        {image_switch && (
                            <div className={"mt-3 overflow-x-auto whitespace-nowrap " + imageSwitcherStyles}>
                                {images.map((image, index) => (
                                    <div
                                        key={image.id}
                                        className="inline-block w-[calc(100%/9)] h-0 pb-[calc(100%/9)] relative rounded-[4px] mr-3 cursor-pointer"
                                        onClick={() => {
                                            setCurrentImage(index);

                                            let pos = 0;
                                            let nodeAttrs: any = null;

                                            parentEditor!.view.state.doc.descendants((node, position) => {
                                                if (node.attrs.id == id) {
                                                    pos = position;
                                                    nodeAttrs = node.attrs;
                                                    return false;
                                                }
                                            });

                                            const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
                                                ...nodeAttrs,
                                                current: index,
                                            });

                                            parentEditor!.view.dispatch(transaction);

                                            setTimeout(() => {
                                                (parentRef!.current as any).click();
                                            }, 0);
                                        }}
                                    >
                                        {image.url ? (
                                            <img
                                                src={image.url}
                                                className={`w-full h-full absolute top-0 left-0 object-cover rounded-lg ${
                                                    index == currentImage && "border-2 border-[#7680FF]"
                                                }`}
                                            />
                                        ) : (
                                            <div
                                                className={`w-full h-full absolute top-0 left-0 rounded-lg bg-[#F2F3FD] ${
                                                    index == currentImage && "border-2 border-[#7680FF]"
                                                }`}
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className={arrowStyles}>
                            <div className="flex items-center gap-x-5">
                                <div
                                    className="w-14 h-14 rounded-full bg-white border border-[#EAECF0] flex items-center justify-center cursor-pointer hover:brightness-90"
                                    onClick={prevImg}
                                >
                                    <EditorCarouselArrowLeftIcon width={14} height={14} />
                                </div>
                                <div
                                    className="w-14 h-14 rounded-full bg-white border border-[#EAECF0] flex items-center justify-center cursor-pointer hover:brightness-90"
                                    onClick={nextImg}
                                >
                                    <EditorCarouselArrowRightIcon width={14} height={14} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {parentEditor && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
};

export default Carousel;
