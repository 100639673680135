import LoaderButton from "shared/components/ui/LoaderButton";
import { PlanCardHeaderProps, SubscriptionCardProps } from "./type";
import { twMerge } from "tailwind-merge";

export const CardHeader = (props: PlanCardHeaderProps) => {
    return (
        <div className="text-center pb-2">
            <div className="mb-5 h-10 w-10 rounded-md flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D] border border-[#EAECF0] mx-auto">
                {props.icon}
            </div>
            <h5 className="text-primary text-[20px] leading-[30px] font-semibold mb-2">{props.title}</h5>
            <h4 className="text-[#101828] leading-[60px] text-[48px] font-semibold mb-2">
                {props.currency}
                {props.price}
            </h4>
            <p className="text-[#475467] text-lg leading-6 mb-8">{props.description}</p>
            <LoaderButton variant="contained" fullWidth {...props.buttonProps} />
        </div>
    );
};

export const SubscriptionCard = (props: SubscriptionCardProps) => {
    return (
        <div
            className={twMerge(
                "p-4 border border-[#EAECF0] shadow-[0px_1px_2px_0px_#1018280D] rounded-xl",
                props.active && "border-primary"
            )}
        >
            <div className="mb-5 h-10 w-10 rounded-md flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D] border border-[#EAECF0]">
                {props.icon}
            </div>
            <h5
                className={twMerge(
                    "text-[24px] leading-8 font-medium mb-2 text-[#101828]",
                    props.active && "!text-primary"
                )}
            >
                {props.title}
            </h5>
            <h5 className="text-[#667085] leading-5 font-medium text-md">{props.description}</h5>
        </div>
    );
};
