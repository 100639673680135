import { pick } from "lodash";
import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import URL from "shared/constants/navigator";
import { GetProfilesQuery, useGetProfilesQuery } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setSelectedAccount } from "store/slices/accounts";

const useAccountScreens = () => {
    const selectedProfile = useAppSelector((state) => state.user.profile);
    const currentLocation = useLocation();
    const dispatch = useAppDispatch();
    const { id: accountDetailId } = useParams();
    const isAccountDetailPage = matchRoutes(
        [
            {
                path: `${URL.Settings}/${URL.Accounts}/:id`,
            },
        ],
        currentLocation
    );

    const [accounts, setAccounts] = useState<GetProfilesQuery["profiles"]>([]);

    const profileRes = useGetProfilesQuery(undefined);

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess(res: GetProfilesQuery) {
                setAccounts(res.profiles);
                if (isAccountDetailPage && accountDetailId) {
                    const selectedProfile = res.profiles.find(
                        (profile) => profile.profile_id === parseInt(accountDetailId)
                    );
                    if (selectedProfile) dispatch(setSelectedAccount(selectedProfile));
                }
            },
        });
    }, [profileRes]);

    return { accounts, ...pick(profileRes, ["isLoading", "isFetching"]), selectedProfile };
};

export default useAccountScreens;
