import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface ISnapshotBlockProps {
    id: string;
    scan: string | null;
    snapshot: string | null;
    snapshot_link: string | null;
    width: number;
    justify: "left" | "center" | "right";
    caption: boolean;
    caption_str: string | null;
    redirecting: boolean;
}

const SnapshotBlock = (props: any) => {
    const { id, scan, snapshot, snapshot_link, width, justify, caption, caption_str, redirecting } = props.node
        .attrs as ISnapshotBlockProps;
    const dispatch = useAppDispatch();

    const { LinkGrayIcon, ArrowUpRight } = useImages();

    const captionRef = useRef(null);

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const alignmentStyles = useMemo(() => {
        let style = "flex ";

        if (justify == "left") {
            style += "justify-start";
        } else if (justify == "right") {
            style += "justify-end";
        } else {
            style += "justify-center";
        }

        return style;
    }, [justify]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (captionRef.current) {
            (captionRef.current as any).innerText = caption_str ?? "Add caption here";

            (captionRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, [caption]);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    return (
        <NodeViewWrapper
            id={id}
            className={`editor-block snapshot-component relative ${isSelected && "editor-block-selected"} bg-white`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "snapshotComponent",
                        props: { id: props.node.attrs.id, attrs: props.node.attrs },
                    })
                );
            }}
        >
            <div className={"flex " + alignmentStyles}>
                <div style={{ width: width < 25 ? "25%" : width > 100 ? "100%" : width + "%" }}>
                    <div className="w-full h-0 pb-[44.4%] relative rounded-xl">
                        {snapshot ? (
                            <>
                                <img
                                    src={snapshot}
                                    className="w-full h-full absolute top-0 left-0 object-cover rounded-xl"
                                />
                                {redirecting && snapshot_link && (
                                    <div
                                        className="h-9 w-9 rounded-lg bg-[#FFFFFFB2] absolute top-4 right-4 flex items-center justify-center cursor-pointer hover:brightness-90"
                                        onClick={() => {
                                            window.open(
                                                snapshot_link.substring(0, snapshot_link.indexOf("&embed")),
                                                "_blank"
                                            );
                                        }}
                                    >
                                        <ArrowUpRight width={18} height={18} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center text-md text-[#5E5D5D] opacity-50">
                                {`Select ${scan ? "Snapshot" : "Scan"}`}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {caption && (
                <div className={"flex items-start mt-4 w-full " + alignmentStyles}>
                    <div
                        className="flex items-start text-md gap-x-2"
                        style={{ width: width < 25 ? "25%" : width > 100 ? "100%" : width + "%" }}
                    >
                        <span className="h-[20px] flex items-center">
                            <LinkGrayIcon width={13} height={13} />
                        </span>
                        <div
                            ref={captionRef}
                            contentEditable={!isPreviewOpen}
                            className="text-[#475467] leading-[20px]"
                            onInput={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                let pos = 0;
                                let nodeAttrs: any = null;

                                parentEditor!.view.state.doc.descendants((node, position) => {
                                    if (node.attrs.id == id) {
                                        pos = position;
                                        nodeAttrs = node.attrs;
                                        return false;
                                    }
                                });

                                const node = parentEditor!.view.state.schema.nodes.snapshotComponent.create({
                                    ...nodeAttrs,
                                    caption_str: (e.target as any).innerText,
                                });

                                const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

                                parentEditor!.view.dispatch(transaction);
                            }}
                        ></div>
                    </div>
                </div>
            )}
            {parentEditor && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
};

export default SnapshotBlock;
