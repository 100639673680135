import { Button } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import LoaderButton from "../ui/LoaderButton";
import StripePaymentContext from "./StripePayment.context";

const CheckoutForm = () => {
    const { clientSecret, currency, onClose, price } = useContext(StripePaymentContext);
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret);
    }, [stripe]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error }: any = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.origin}/settings/shop/payment/success`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: "tabs",
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <div className={"mt-4 mb-6"}>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
            </div>
            <div className="flex flex-col space-y-4 mb-3">
                <LoaderButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isLoading || !stripe || !elements}
                    className="h-11"
                >
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now ${price} ${currency}`}
                    </span>
                </LoaderButton>
                {onClose && (
                    <Button variant="outlined" fullWidth className="h-11" onClick={onClose}>
                        Close
                    </Button>
                )}
            </div>
            {message && (
                <div id="payment-message" className="text-error">
                    {message}
                </div>
            )}
        </form>
    );
};

export default CheckoutForm;
