import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetUserDetailsQuery, Team } from "shared/graphql";

interface Props {
    current: GetUserDetailsQuery["team"] | Team | null;
}

const initialState: Props = {
    current: null,
};

const teams = createSlice({
    name: "teams",
    initialState,
    reducers: {
        setTeam(state, action: PayloadAction<Props["current"]>) {
            state.current = action.payload
                ? {
                      ...state.current,
                      ...action.payload,
                  }
                : null;
        },
    },
});

export default teams.reducer;

export const { setTeam } = teams.actions;
