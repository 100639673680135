import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: StateInterface = {
    verificationData: {
        email: "",
        email_verification_id: null,
        phone: "",
        phone_verification_id: null,
        phone_token: "",
    },
    accountVerified: false,
};

const verifyUser = createSlice({
    name: "verifyUser",
    initialState,
    reducers: {
        setVerificationData(state: StateInterface, action: PayloadAction<Partial<StateInterface["verificationData"]>>) {
            state.verificationData = { ...state.verificationData, ...action.payload };
        },
        setAccountVerified(state: StateInterface, action: PayloadAction<boolean>) {
            state.accountVerified = action.payload;
        },
    },
});

export default verifyUser.reducer;
export const { setVerificationData, setAccountVerified } = verifyUser.actions;

interface StateInterface {
    verificationData: {
        email: string;
        email_verification_id: number | null;
        phone: string;
        phone_verification_id: number | null;
        phone_token: string;
    };
    accountVerified: boolean;
}
