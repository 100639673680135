import { BenefitType } from "../partials/PlanCards";

export const BenefitItemName: Record<BenefitType, string> = {
    __typename: "Benefit",
    devices_per_seat: "Devices per seat",
    no_credit_limits: "No credit limits",
    projects: "Projects",
    scan_requests: "Scan requests",
    snapshots: "Snapshots",
    storage: "Storage",
    team_seat: "Team seat",
    video_renders: "Video renders",
    xspada_studio: "Xspada Studio",

    discord: "Discord",
    chat: "Chat",
    phone: "Phone",
    dedicated_representative: "Dedicated representative",
    private_onboarding: "Private onboarding",

    // Roles
    admins: "Admins",
    guests: "Guests",
    managers: "Managers",
    members: "Members",
};
