// import Swiper core and required modules
import { A11y } from "swiper/modules";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import "swiper/css";

interface Props<T> {
    data: T[];
    renderItem: (item: T) => JSX.Element;
}

const SwiperSlider = <T,>(props: Props<T>) => {
    return (
        <Swiper modules={[A11y]} spaceBetween={24} slidesPerView={3}>
            <ButtonNavs data={props.data} />
            {props.data.map((scan, i) => (
                <SwiperSlide key={i}>{props.renderItem(scan)}</SwiperSlide>
            ))}
        </Swiper>
    );
};

export default SwiperSlider;

const ButtonNavs = <T,>(props: { data: T[] }) => {
    const Images = useImages();
    const swiper = useSwiper();
    const [isEnd, setIsEnd] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        swiper.on("init", () => {
            setCurrentSlide(0);
        });

        // on
        swiper.on("slideChange", () => {
            setCurrentSlide(swiper.activeIndex);
        });

        swiper.on("beforeSlideChangeStart", () => {
            setIsEnd(false);
        });

        // if last slide
        swiper.on("reachEnd", () => {
            setIsEnd(true);
        });
    }, [swiper]);

    return (
        <>
            {currentSlide !== 0 && (
                <Button
                    onClick={() => swiper.slidePrev()}
                    className="!bg-white left-2 top-[100px] !absolute z-10 !min-w-[auto]"
                >
                    <Images.ArrowChevronLeft />
                </Button>
            )}
            {props.data.length > 3 && !isEnd && (
                <Button
                    onClick={() => swiper.slideNext()}
                    className="!bg-white right-2 top-[100px] !absolute z-10 !min-w-[auto]"
                >
                    <Images.ArrowChevronRight color="#7680FF" />
                </Button>
            )}
        </>
    );
};
