import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import { TeamMember, useApproveRequestMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const AcceptInvitation = (props: { data: TeamMember }) => {
    const Images = useImages();
    const [approveMember, approveRes] = useApproveRequestMutation();

    useEffect(() => {
        rtkHandler(approveRes, {
            successMessage: "Member approved successfully",
        });
    }, [approveRes]);

    return (
        <ConfirmButton
            title="Approve Member"
            content="Are you sure you want to approve this member?"
            icon={<Images.TeamMembersLayerIconLightMode />}
            okBtnText="Approve"
            okBtnProps={{
                loading: approveRes.isLoading,
                onClick: () =>
                    approveMember({
                        member_id: props.data.member_id,
                    }),
            }}
        >
            <Button variant="text" color="primary">
                Accept
            </Button>
        </ConfirmButton>
    );
};

export default AcceptInvitation;
