import styled from "@emotion/styled";
import { Table } from "@mui/material";
import { DataTableProvider } from "./context";
import { DataTableProps } from "./type";
import DataTableBody from "./components/Body";
import DataTableFooter from "./components/Footer";
import DataTableHeader from "./components/Header";
import CardLoader from "../CardLoader";
import { twMerge } from "tailwind-merge";

const DataTable = (props: DataTableProps) => {
    return (
        <DataTableProvider
            value={{
                ...props,
            }}
        >
            <div
                className={twMerge(
                    "rounded-lg border border-[#EAECF0] shadow-[-4px_4px_12px_0px_#7680FF0D] overflow-hidden",
                    props.classes?.root
                )}
            >
                <div className="relative">
                    {
                        <CardLoader
                            className={props.pagination ? "top-[55px]" : ""}
                            loaderProps={{
                                size: 30,
                            }}
                            loading={props.loading || false}
                        />
                    }
                    <CustomTable>
                        <DataTableHeader />
                        <DataTableBody />
                    </CustomTable>
                </div>
                {props.pagination && <DataTableFooter />}
            </div>
        </DataTableProvider>
    );
};

const CustomTable = styled(Table)(() => ({
    "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
            borderColor: "#EAECF0",
        },
    },
}));

export default DataTable;
