import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import yup from "shared/utils/yup";

interface Props {
    onCloseModal: () => void;
}

const schema = yup.object().shape({
    username: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const OpenStarterAccountModal = (props: Props) => {
    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            username: "",
        },
    });

    return (
        <ThemeFormProvider form={form} className="text-left" onSubmit={() => {}}>
            <Field name="username" label="Username" placeholder="Enter username" />
            <CommonModalActionButton
                buttons={[
                    {
                        children: "Create",
                        id: "submit-button",
                    },
                ]}
                cancelBtnProps={{
                    onClick: props.onCloseModal,
                }}
            />
        </ThemeFormProvider>
    );
};

export default OpenStarterAccountModal;
