import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import { TeamMember, useResendInvitationMutation } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setCloseCommonModal, setCommonModalLoadingBtn } from "store/slices/commonModal";

const ResendInviteMember = ({ data }: { data: TeamMember }) => {
    const dispatch = useAppDispatch();
    const { UserSquareIcon } = useImages();

    const [resendInvite, resendRes] = useResendInvitationMutation();

    useEffect(() => {
        rtkHandler(resendRes, {
            successMessage: "Invitation has been sent successfully",
            onSuccess() {
                dispatch(setCloseCommonModal());
            },
        });
        dispatch(setCommonModalLoadingBtn(resendRes.isLoading ? "resend-member-btn" : ""));
    }, [resendRes]);

    if (!data.email) {
        return null;
    }

    return (
        <ConfirmButton
            title="Resend Invite"
            content={
                <p className="text-p">
                    Are you sure you want to resend invite to <br />
                    <span className="text-primary">{data.email}</span> from team?
                </p>
            }
            icon={<UserSquareIcon height={40} width={40} />}
            okBtnText="Resend"
            okBtnProps={{
                loading: resendRes.isLoading,
                onClick: () =>
                    resendInvite({
                        email: data.email,
                    }),
            }}
        >
            <Button variant="text" color="primary">
                Resend Invite
            </Button>
        </ConfirmButton>
    );
};

export default ResendInviteMember;
