import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetStorage: {
            providesTags: ["Storage"],
        },
    },
});

export const { useGetStorageQuery } = api;
