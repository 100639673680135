import useImages from "assets/images";

interface I {
    title?: string;
    desc?: string;
    status?: string;
    className?: string;
    hasSperator?: boolean;
}

const ScanRequestStatusCard = (props: I) => {
    const Images = useImages();
    const { title, status, className = "", hasSperator = false } = props;

    let IconElement;
    let iconType;
    let titleText;
    let descText;

    if (status) {
        switch (status) {
            case "Archived":
                iconType = "triangle-failed";
                titleText = "Archived Request";
                break;
            case "New":
                iconType = "green-check-square";
                titleText = "Open Request";
                break;
            case "Awaiting Submission":
                iconType = "time-circle";
                titleText = "Awaiting Submission";
                break;
            case "In Progress":
                iconType = "refresh-icon";
                titleText = "In Progress";
                break;
            case "Deleted":
                iconType = "triangle-failed";
                titleText = "Deleted";
                break;
            case "Rejected":
                iconType = "triangle-failed";
                titleText = "Rejected";
                break;
            case "Submit New":
                iconType = "check-square";
                titleText = "Submit New Request";
                descText = "Add details to submit a new request";
                break;
            case "In Review":
                iconType = "refresh-icon";
                titleText = "In Review";
                descText = "Request Submission";
                break;
            case "Accepted":
                iconType = "green-check-square";
                titleText = "Accepted";
                descText = "Request Submission";
                break;
            case "Delivered":
                iconType = "green-check-square";
                titleText = "Delivered";
                break;
        }
    }

    if (typeof iconType === "string") {
        switch (iconType) {
            case "triangle-failed":
                IconElement = <Images.DangerTriangleIcon height={24} width={22} color="#EB617A" />;
                break;
            case "green-check-square":
                IconElement = <Images.SquarePlusIcon height={30} width={30} color="#15AA2C" />;
                break;
            case "time-circle":
                IconElement = <Images.TimeCircleIcon height={24} width={22} color="#FF8A36" />;
                break;
            case "refresh-icon":
                IconElement = <Images.ReturnIcon height={30} width={30} color="#7680FF" />;
                break;
            case "check-square":
                IconElement = <Images.TickSquareGreen height={30} width={30} color="#7680FF" />;
                break;
            default:
                break;
        }
    }

    return (
        <div
            className={`flex items-center space-x-3 ${className} ${
                hasSperator ? "border-l border-[#949496] border-opacity-25 pl-3" : ""
            }`}
        >
            {IconElement}
            <div>
                <h4 className="text-h1 text-[12px] font-medium leading-4">{title || titleText}</h4>
                <p className="text-p font-light text-xs leading-3">{descText || "Project Request Status"}</p>
            </div>
        </div>
    );
};

export default ScanRequestStatusCard;
