import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";
import { successToast } from "shared/utils/toast";

const HasProfileSelected = () => {
    const [Component, setComponent] = useState(<></>);
    const { token, profile } = useAuth();

    useEffect(() => {
        if (token && profile) {
            if (profile.type !== "Default") {
                setComponent(<Outlet />);
            } else {
                successToast("Please select a profile");
                // setComponent(<Navigate to={URL.Profile_Selection} />);
            }
        }
    }, []);

    return Component;
};

export default HasProfileSelected;
