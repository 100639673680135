import { Table, TableBody, TableCell, TableHead, TableRow, styled } from "@mui/material";
import useImages from "assets/images";
import React, { useEffect, useRef, useState } from "react";
import CardLoader from "shared/components/ui/CardLoader";
import LoaderButton from "shared/components/ui/LoaderButton";
import {
    GetPlansQuery,
    PlanPeriod,
    useGetPlansQuery,
    useGetSubscriptionsQuery,
    useGetUserPromoCodeQuery,
} from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { twMerge } from "tailwind-merge";
import { CardHeader } from "../components";
import { BenefitItemName } from "../utils/BenefitName";
import EnterprisePlanModal from "./EnterprisePlanModal";
import PurchasePlanModal from "./PurchasePlanModal";

type PlanCard = GetPlansQuery["plans"]["plans"][0];

interface PlanCardInteractions extends PlanCard {
    image?: React.ReactNode;
    active?: boolean;
    btnText?: string;
    currency: string;
    onClick?: () => void;
    disabled?: boolean;
}

const PlanCards = (props: { interval: PlanPeriod }) => {
    const Images = useImages();
    const userPromoCode = useGetUserPromoCodeQuery();
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<PlanCardInteractions>();
    const [enterpriseModalOpen, setEnterpriseModalOpen] = useState(false);
    const [plans, setPlans] = useState<PlanCardInteractions[]>([]);
    const [selectedDivHeight, setSelectedDivHeight] = useState(0);

    const tableRef = useRef<HTMLTableElement>(null);

    let currentPlan = useGetSubscriptionsQuery().data?.subscriptions[0] || null;
    currentPlan = currentPlan?.status === "Active" ? currentPlan : null;

    const plansRes = useGetPlansQuery();

    const enableEnterprisePlan = userPromoCode.error
        ? false
        : userPromoCode.data?.user_promo_code.configuration.enable_enterprise || false;

    const storageIncrease = userPromoCode.error
        ? 0
        : userPromoCode.data?.user_promo_code.configuration.storage_increase_by_times || 0;

    useEffect(() => {
        rtkHandler(plansRes, {
            onSuccess(response: GetPlansQuery) {
                setPlans(
                    response.plans.plans.map((plan) => ({
                        ...plan,
                        image: ["Free"].includes(plan.name) ? (
                            <Images.Layer />
                        ) : plan.name === "Enterprise" ? (
                            <Images.TripleLayer />
                        ) : (
                            <Images.DoubleLayer />
                        ),
                        currency: plan.period === "Custom" ? "" : "$",
                        active: false,
                        btnText: "Get Started",
                        onClick() {},
                    }))
                );
            },
        });
    }, [plansRes]);

    const intervalPlans = plans
        .filter((plan) => plan.period === props.interval || plan.period === PlanPeriod.Free)
        .sort((a, b) => {
            if (a.period === PlanPeriod.Free) return -1;
            if (b.period === PlanPeriod.Free) return 1;
            return a.price - b.price;
        })
        .map((plan) => {
            const isActive = currentPlan
                ? currentPlan?.plan.name === plan.name && currentPlan?.plan.period === plan.period
                : plan.period === PlanPeriod.Free;

            const is_enterprise = plan.name === "Enterprise";

            return {
                ...plan,
                disabled: currentPlan
                    ? props.interval === PlanPeriod.Monthly
                        ? !isActive
                        : isActive || plan.name !== "Enterprise"
                    : false,
                is_enterprise,
                active: isActive,
                btnText: isActive
                    ? "Current Plan"
                    : is_enterprise && !enableEnterprisePlan
                    ? "Contact Us"
                    : plan.btnText,
                onClick: isActive ? () => {} : plan.onClick,
            };
        });

    useEffect(() => {
        if (!tableRef.current || !intervalPlans.length) return;

        setSelectedDivHeight(tableRef.current?.clientHeight || 0);
    }, [tableRef.current, intervalPlans]);

    return (
        <>
            {plansRes.isLoading ? (
                <div className="relative h-52">
                    <CardLoader loading={true} />
                </div>
            ) : intervalPlans.length ? (
                <>
                    <CustomTable ref={tableRef}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%"></TableCell>
                                {intervalPlans.map((plan) => {
                                    const isCustom = plan.period === "Custom";

                                    return (
                                        <TableCell
                                            key={plan.name + plan.period}
                                            className={twMerge("relative", plan.disabled && "!opacity-50")}
                                            width="25%"
                                        >
                                            {plan.active && (
                                                <div
                                                    className="border-[2px] border-primary w-full absolute top-0 left-0 rounded-[16px] z-10"
                                                    style={{
                                                        height: selectedDivHeight,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            )}
                                            <CardHeader
                                                icon={plan.image}
                                                title={plan.name + " plan"}
                                                currency={plan.currency}
                                                price={isCustom ? "Custom" : plan.price.toString()}
                                                description={`per seat billed ${props.interval.toLocaleLowerCase()}`}
                                                buttonProps={{
                                                    children: plan.btnText,
                                                    disabled: plan.disabled || plan.active,
                                                    color: plan.active ? "inherit" : "primary",
                                                    variant: plan.active ? "outlined" : "contained",
                                                    onClick: () => {
                                                        if (plan.is_enterprise && !enableEnterprisePlan) {
                                                            setEnterpriseModalOpen(true);
                                                            return;
                                                        }
                                                        if (plan.active) return;
                                                        setSelectedPlan(plan);
                                                        setPurchaseModalOpen(true);
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="!text-primary">Limits</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="team_seat" />
                            <PlanBenefitCells
                                plans={intervalPlans}
                                indexKey="limits"
                                type="storage"
                                renderCell={(value: any) => {
                                    return (
                                        <>
                                            {value} GB{" "}
                                            {storageIncrease ? (
                                                <span className="text-primary">
                                                    x {storageIncrease} ={" "}
                                                    {parseFloat(value.replace(/\D/g, "")) * storageIncrease} GB
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                }}
                            />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="projects" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="devices_per_seat" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="scan_requests" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="video_renders" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="snapshots" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="no_credit_limits" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="limits" type="xspada_studio" />
                            <TableRow>
                                <TableCell className="!text-primary !pt-10">Customer Support</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <PlanBenefitCells plans={intervalPlans} indexKey="customer_support" type="discord" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="customer_support" type="chat" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="customer_support" type="phone" />
                            <PlanBenefitCells
                                plans={intervalPlans}
                                indexKey="customer_support"
                                type="dedicated_representative"
                            />
                            <PlanBenefitCells
                                plans={intervalPlans}
                                indexKey="customer_support"
                                type="private_onboarding"
                            />
                            <TableRow>
                                <TableCell className="!text-primary !pt-10">Roles</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <PlanBenefitCells plans={intervalPlans} indexKey="roles" type="admins" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="roles" type="members" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="roles" type="managers" />
                            <PlanBenefitCells plans={intervalPlans} indexKey="roles" type="guests" />
                            <TableRow>
                                <TableCell width="25%"></TableCell>
                                {intervalPlans.map((plan) => {
                                    return (
                                        <TableCell
                                            key={plan.name + plan.period}
                                            className={twMerge("relative", plan.disabled && "!opacity-50")}
                                            width="25%"
                                        >
                                            <LoaderButton
                                                disabled={plan.disabled || plan.active}
                                                color={plan.active ? "inherit" : "primary"}
                                                variant={plan.active ? "outlined" : "contained"}
                                                onClick={() => {
                                                    if (plan.active) return;
                                                    setSelectedPlan(plan);
                                                    setPurchaseModalOpen(true);
                                                }}
                                                fullWidth
                                            >
                                                {plan.btnText}
                                            </LoaderButton>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </CustomTable>
                    <PurchasePlanModal
                        open={Boolean(purchaseModalOpen)}
                        selectedPlan={selectedPlan}
                        onClose={() => setPurchaseModalOpen(false)}
                    />
                    <EnterprisePlanModal open={enterpriseModalOpen} onClose={() => setEnterpriseModalOpen(false)} />
                </>
            ) : (
                <div className="text-p text-[14px] leading-6 text-center">{"No Plan found"}</div>
            )}
        </>
    );
};

export default PlanCards;

const CustomTable = styled(Table)(() => ({
    overflow: "hidden",
    position: "relative",
    // header
    "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
            padding: "24px",
        },
    },

    "& .MuiTableCell-root": {
        textAlign: "center",
        border: "none",
        padding: "22px 16px",

        "&:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            textAlign: "left !important",
        },

        "&:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    },

    "& .MuiTableRow-root": {
        "&:nth-child(even)": {
            backgroundColor: "#F9FAFB",
        },
    },

    "& .MuiButton-root": {
        height: "48px !important",
    },

    // last row
    "& .MuiTableRow-root:last-child": {
        "& .MuiTableCell-root": {
            paddingInline: "24px",
            backgroundColor: "white",
        },
    },
}));

type BenefitLimitType = keyof PlanCardInteractions["benefits"]["limits"];
type BenefitRoleType = keyof PlanCardInteractions["benefits"]["roles"];
type BenefitCustomerSupportType = keyof PlanCardInteractions["benefits"]["customer_support"];
export type BenefitType = BenefitLimitType | BenefitRoleType | BenefitCustomerSupportType;

const PlanBenefitCells = (props: {
    plans: PlanCardInteractions[];
    indexKey: keyof Omit<PlanCardInteractions["benefits"], "__typename">;
    type: BenefitType;
    prefix?: string;
    suffix?: string;
    renderCell?: (value: any) => React.ReactNode;
}) => {
    const Images = useImages();

    if (!props.plans) return <></>;

    return (
        <TableRow>
            <TableCell>{BenefitItemName[props.type]}</TableCell>
            {props.plans.map((plan) => {
                const value = plan.benefits[props.indexKey][props.type];

                const isTeamSeat = props.type === "team_seat";

                if (value === undefined) return <TableCell />;

                let cellValue: string | number | React.ReactNode = value;

                if (value === "-") cellValue = <div className="bg-[#98A2B3] h-[2px] w-3 mx-auto" />;
                else if (typeof value === "boolean" || value === "true" || value === "false")
                    cellValue =
                        (typeof value === "boolean" && value) || value === "true" ? (
                            <Images.CircleCheck color="#079455" />
                        ) : (
                            <div className="bg-[#98A2B3] h-[2px] w-3 mx-auto" />
                        );

                return (
                    <TableCell className={twMerge(plan.disabled && "!opacity-50")} key={plan.name + plan.period}>
                        {props.renderCell?.(cellValue) ?? (
                            <>
                                {isTeamSeat ? (plan.name === "Professional" ? "" : "Minimum") : ""} {cellValue}{" "}
                                {isTeamSeat ? (plan.name === "Professional" ? "seat" : "seats") : ""}
                            </>
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
