import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ScanRequestStatusCard from "shared/components/ScanRequestStatusCard";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";

import SRTitle from "./components/SRTitle";
import useAddSubmissionScreen from "./hook";
import UploadSubmission from "./partials/UploadSubmission";

const AddSubmissionScreen = () => {
    const { isLoading, isFetching, request } = useSingleSR();
    const hook = useAddSubmissionScreen();
    const navigate = useNavigate();

    return (
        <ThemeFormProvider form={hook.form} onSubmit={hook.onSubmit}>
            <ThemeScreenCard
                headerComponent={request && !isLoading && <ScanRequestStatusCard status={request.status} />}
            >
                {(isLoading || isFetching) && (
                    <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                        <CircularProgress />
                    </div>
                )}
                {request && (
                    <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                        <div className="bg-white p-4 rounded-2xl h-full w-full flex flex-col relative">
                            <div className="overflow-auto overflow-x-hidden flex-1 flex flex-col">
                                <SRTitle />
                                <Field name="name" placeholder="Introduce your scan with a name" label="Scan Name" />
                                <div className="flex space-x-4">
                                    <Field
                                        type="select"
                                        name="method"
                                        options={[
                                            { label: "Unbounded", value: "nerfacto" },
                                            { label: "Focused", value: "gaussian-splatting" },
                                        ]}
                                        label="Model Type:"
                                        placeholder="Select model for scan processing"
                                    />
                                    <Field
                                        type="select"
                                        name="scanning_device_id"
                                        options={hook.scanningDevices}
                                        selectFieldProps={{
                                            noOptionsText:
                                                "No devices to select. Please add at least one device in profile settings.",
                                        }}
                                        label="Scanning Device:"
                                        placeholder="Select your scanning device"
                                    />
                                </div>
                                <div className="h-[400px]">
                                    <UploadSubmission />
                                </div>
                                <Field name="scan_video_id" render={() => <></>} />
                                <div className="mt-4 flex items-center justify-end space-x-4">
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate(-1)}
                                        disabled={hook.addRes.isLoading}
                                    >
                                        Cancel
                                    </Button>
                                    <LoaderButton variant="contained" type="submit" loading={hook.addRes.isLoading}>
                                        Submit Video
                                    </LoaderButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeScreenCard>
        </ThemeFormProvider>
    );
};

export default AddSubmissionScreen;
