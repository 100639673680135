import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import { useUpdateScanMutation } from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const useEditSRScan = () => {
    const { request } = useSingleSR();
    const { scanId } = useParams<{ id: string; scanId: string }>();
    const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const [scanningDevices, setScanningDevices] = useState<ISelectMenuList[]>([]);

    const [update, updateRes] = useUpdateScanMutation();

    const scan = request?.applications
        .find((req) => req.submissions.find((sub) => sub.scan_id === parseInt(scanId!)))
        ?.submissions.find((sub) => sub.scan_id === parseInt(scanId!));

    const form = useForm<FormValues>({
        defaultValues: {
            dataset_type: request?.dataset_type || "video",
            name: scan?.name,
            scanning_device_id: scan?.scanning_device?.scanning_device_id.toString(),
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Scan updated successfully",
            onSuccess() {
                navigate(`/requests/in-progress/detail/${request?.scan_request_id}/scans/${scanId}`);
                dispatch(
                    api.util.invalidateTags([
                        { type: "ScanRequests", id: "LIST" },
                        { type: "ScanRequests", id: request?.scan_request_id },
                    ])
                );
            },
        });
    }, [updateRes]);

    const onSubmit = (data: FormValues) => {
        update({
            id: parseInt(scanId!),
            data: {
                name: data.name,
                scanning_device_id: parseInt(data.scanning_device_id),
            },
        });
    };

    useEffect(() => {
        if (user?.scanning_devices && user.scanning_devices.length > 0) {
            const scanning_device: ISelectMenuList[] = [];

            user.scanning_devices.forEach((device) => {
                if (!scanning_device.find((i) => i.value === device.category)) {
                    scanning_device.push({
                        value: device.category,
                        label: device.category,
                        isGroup: true,
                    });
                }
                scanning_device.push({
                    value: device.scanning_device_id.toString(),
                    label: device.name,
                });
            });

            setScanningDevices(scanning_device);
        }
    }, [user]);

    return { form, scanningDevices, onSubmit, updateRes };
};

export default useEditSRScan;

type FormValues = yup.InferType<typeof schema>;

const schema = yup.object().shape({
    dataset_type: yup.string().required(),
    name: yup.string().required(),
    scanning_device_id: yup.string().required(),
});
