import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DataTableHeaderProps } from "shared/components/ui/DataTable/type";
import UserProfileCard from "shared/components/UserProfileCard";
import yup from "shared/utils/yup";

const useScanCreditSettings = () => {
    const schema = yup.object().shape({
        credit_division: yup.string().required(),
    });

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            credit_division: "Free for All",
        },
    });

    const { formState, watch } = form;

    const isModified = formState.isDirty;

    const onSubmit = () => {};

    const isAbsoluteDivision = watch("credit_division") == "Absolute Division";

    const ABSOLUTE_DIVISION_TABLE_HEADERS: DataTableHeaderProps[] = [
        {
            title: "Team Member",
            // width: "45%",
            align: "left",
            selector: () => {
                return <UserProfileCard name="Lana Steiner" email="lana@untitledui.com" />;
            },
        },
        {
            title: (
                <>
                    Scan Credit Allowed (<span className="text-[#7680FF]">every month</span>)
                </>
            ),
            width: "260px",
            align: "center",
            selector: () => {
                return "20";
            },
        },
    ];

    return { form, onSubmit, isModified, isAbsoluteDivision, ABSOLUTE_DIVISION_TABLE_HEADERS };
};

export default useScanCreditSettings;
