import { RouteObject } from "react-router-dom";

import SettingScreenLayout from "screens/Dashboard/Settings/layout";
import AccountScreens from "screens/Dashboard/Settings/screens/Accounts";
import AccountDetailScreen from "screens/Dashboard/Settings/screens/Accounts/Single";
import ImagineXSettingScreen from "screens/Dashboard/Settings/screens/ImagineXSettingScreen";
import ProfileSettingScreen from "screens/Dashboard/Settings/screens/ProfileSettingScreen";
import ScanCreditsSettingScreen from "screens/Dashboard/Settings/screens/ScanCreditsSettingScreen";
import SecuritySettingScreen from "screens/Dashboard/Settings/screens/SecuritySettingScreen";
import ShopScreen from "screens/Dashboard/Settings/screens/ShopScreen";
import ShopSuccessScreen from "screens/Dashboard/Settings/screens/ShopScreen/screen/ShopSuccessScreen";
import SubscriptionCanceledScreen from "screens/Dashboard/Settings/screens/ShopScreen/screen/SubscriptionCanceledScreen";
import SubscriptionSettingScreen from "screens/Dashboard/Settings/screens/SubscriptionSettingScreen";
import TeamScreens from "screens/Dashboard/Settings/screens/TeamScreens/TeamScreens";
import URL from "shared/constants/navigator";

const settingRoutes: RouteObject[] = [
    {
        path: URL.Settings,
        element: <SettingScreenLayout />,
        children: [
            {
                path: URL.Profile_Settings,
                element: <ProfileSettingScreen />,
            },
            {
                path: URL.Security_Settings,
                element: <SecuritySettingScreen />,
            },
            {
                path: URL.Shop,
                element: <ShopScreen />,
                children: [
                    {
                        path: URL.Shop_Payment_Success,
                        element: <ShopSuccessScreen />,
                    },
                    {
                        path: URL.Shop_Subscription_Cancel,
                        element: <SubscriptionCanceledScreen />,
                    },
                ],
            },
            {
                path: URL.Scan_Credits,
                element: <ScanCreditsSettingScreen />,
            },
            {
                path: URL.Subscription_Settings,
                element: <SubscriptionSettingScreen />,
            },
            {
                path: URL.Imagine_X_Settings,
                element: <ImagineXSettingScreen />,
            },
            {
                path: URL.team,
                element: <TeamScreens />,
            },
            {
                path: URL.Accounts,
                element: <AccountScreens />,
                children: [
                    {
                        path: ":id",
                        element: <AccountDetailScreen />,
                    },
                ],
            },
        ],
    },
];

export default settingRoutes;
