import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { RequestTeamMutation, useRequestTeamMutation } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";

const schema = yup.object().shape({
    code: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const JoinTeamModalContent = ({
    onClose,
    onSuccess,
}: {
    onClose: () => void;
    onSuccess?: (data: RequestTeamMutation["request_team"]) => void;
}) => {
    const dispatch = useAppDispatch();

    const [joinTeam, joinRes] = useRequestTeamMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            code: "",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        joinTeam(data);
    };

    useEffect(() => {
        dispatch(setCommonModalLoadingBtn(joinRes.isLoading ? "join-team-btn" : ""));
    }, [joinRes.isLoading]);

    useEffect(() => {
        rtkHandler(joinRes, {
            onSuccess({ request_team }: RequestTeamMutation) {
                if (onSuccess) {
                    return onSuccess(request_team);
                }
                onClose();
            },
        });
    }, [joinRes]);

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left">
            <Field name="code" label="Invite Code" placeholder="Enter the invite code of team" />
            <CommonModalActionButton
                buttons={[
                    {
                        id: "join-team-btn",
                        children: "Join",
                        type: "submit",
                        onClick: () => {},
                    },
                ]}
                cancelBtnProps={{
                    onClick: onClose,
                }}
            />
        </ThemeFormProvider>
    );
};

export default JoinTeamModalContent;
