import moment from "moment";
import { NavLink } from "react-router-dom";

import { GetScanRequestByIdQuery, GetScanRequestsQuery } from "shared/graphql";

import RenderImage from "../ui/RenderImage";

interface I {
    onClick?: () => void;
    url?: string;
    end?: boolean;
    request: GetScanRequestsQuery["team_scan_requests"][0];
    scan: GetScanRequestByIdQuery["scan_request"]["applications"][0]["submissions"][0];
}

const SRScanListItem = (props: I) => {
    return (
        <NavLink
            onClick={() => {
                props.onClick && props.onClick();
            }}
            to={props.url || "/"}
            className={({ isActive }) => {
                return `rounded-lg bg-grey-light py-2 px-4 flex items-center cursor-pointer border border-transparent hover:border-border2 ${
                    isActive ? "!bg-[#7680FF] !bg-opacity-20 !border-[#7680FF]" : ""
                }`;
            }}
            end={props.end || false}
        >
            {props.scan.input_file && (
                <RenderImage
                    containerClassName="!w-[60px] !min-w-[60px] !h-[60px]"
                    image={props.scan.input_file.thumbnail || ""}
                    imgClassName="rounded-lg"
                />
            )}
            <div className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis pl-4">
                <h6 className="text-p font-medium text-xs mb-[2px] overflow-hidden whitespace-nowrap text-ellipsis">
                    {props.request.location.name}
                </h6>
                <div>
                    <h4 className="text-h1 text-lg font-medium capitalize whitespace-nowrap overflow-hidden text-ellipsis leading-6">
                        {props.scan.name}
                    </h4>
                    <h4 className="text-p font-medium text-xs text-ellipsis overflow-hidden whitespace-nowrap">
                        {moment(props.scan.created_at).format("MMM DD, YYYY")}
                    </h4>
                </div>
            </div>
        </NavLink>
    );
};

export default SRScanListItem;
