import { Suspense, useEffect, useState } from "react";
import PageLoader from "../ui/PageLoader";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );

export default Loadable;

const LoadingScreen = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return !show ? <PageLoader className="fixed top-0 left-0" /> : <></>;
};
