import { CircularProgress } from "@mui/material";
import useImages from "assets/images";
import ITDCard from "shared/components/ui/ITDCard";
import useDeleteSRScan from "./hook";

const DeleteSRScan = () => {
    const { isLoading, onClickDeleteScan, Options, selected, onClickITD } = useDeleteSRScan();
    const { ArrowRightWhite } = useImages();

    return (
        <div className="card-dashed-border">
            <h4 className="text-p font-bold text-lg leading-6 mb-2">Delete Scan</h4>
            <p className="text-p opacity-50 text-md leading-4 font-normal">
                Do you want to delete the scan? Please select appropriate option.
            </p>
            <div className="grid grid-cols-4 gap-4 mt-6">
                {Options.map((option, index) => (
                    <ITDCard
                        iconWrapperClassName="flex-none"
                        key={index}
                        // selectable
                        onClick={() => onClickITD(option.value)}
                        selected={selected === option.value}
                        value={option.value}
                        icon={option.icon}
                        title={option.title}
                        desc={option.desc}
                    />
                ))}
                <div
                    onClick={onClickDeleteScan}
                    className={`flex flex-col justify-between h-full bg-[#5E5D5D] bg-opacity-20 shadow-card rounded-[10px] text-white p-4 text-lg leading-6 transition-all ${
                        !isLoading ? "cursor-pointer !bg-primary" : ""
                    }`}
                >
                    {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <ArrowRightWhite />
                            <p>Delete</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeleteSRScan;
