import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { twMerge } from "tailwind-merge";

interface Props {
    title?: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    children?: React.ReactNode;
    hasHeaderDivider?: boolean;
    containerClassName?: string;
    wrapperClassName?: string;
    collapseAble?: boolean;
    rightComponent?: React.ReactNode;
    open?: boolean;
}

const SettingPageCard = (props: Props) => {
    const {
        title,
        subTitle,
        rightComponent,
        children,
        wrapperClassName = "",
        hasHeaderDivider = true,
        containerClassName,
        collapseAble = false,
    } = props;
    const [open, setOpen] = useState(collapseAble ? false : true);

    useEffect(() => {
        setOpen(props.open ? props.open : collapseAble ? false : true);
    }, [props.open]);

    return (
        <div className={twMerge("shadow-card p-6 rounded-[16px] bg-white mb-6", wrapperClassName)}>
            <div
                className={twMerge(
                    "flex items-center space-x-8 justify-between",
                    containerClassName,
                    collapseAble ? "cursor-pointer" : ""
                )}
                onClick={() => collapseAble && setOpen(!open)}
            >
                {(title || subTitle) && (
                    <div className="flex flex-col space-y-1">
                        <h4 className="text-[#101828] text-[18px] font-medium leading-7">{title}</h4>
                        <p className="text-[#667085] text-md font-normal">{subTitle}</p>
                    </div>
                )}
                {collapseAble && (
                    <ThemeIconButton
                        icon={
                            open ? (
                                <ExpandLessIcon style={{ color: "#9E9E9E" }} />
                            ) : (
                                <ExpandMoreIcon style={{ color: "#9E9E9E" }} />
                            )
                        }
                    />
                )}
                {rightComponent && <div>{rightComponent}</div>}
            </div>
            {children && (
                <Collapse in={open}>
                    <div className={twMerge("pt-6", hasHeaderDivider ? "border-t border-[#EAECF0] mt-5" : "")}>
                        {children}
                    </div>
                </Collapse>
            )}
        </div>
    );
};

export default SettingPageCard;
