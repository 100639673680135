import useImages from "assets/images";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import URL from "shared/constants/navigator";

const AgreementLayout = () => {
    const { Logo } = useImages();

    const isTerms = window.location.pathname.includes("terms");
    const isEula = window.location.pathname.includes("end-user-license-agreement");
    const isMaster = window.location.pathname.includes("master");
    const isBeta = window.location.pathname.includes("beta");

    let title = "";
    if (isTerms) {
        title = "Terms and Conditions";
    } else if (isEula) {
        title = "End User License Agreement";
    } else if (isBeta) {
        title = "Beta Application License Agreement";
    } else if (isMaster) {
        title = "Master Service Agreement";
    }

    return (
        <div>
            <div className="grid grid-cols-[150px,1fr,150px] items-center">
                <div className="p-8 flex flex-col items-start">
                    <Link to={URL.Home}>
                        <Logo />
                    </Link>
                </div>
                <h1 className="text-center text-[24px] font-bold">{title}</h1>
            </div>
            <div className="px-8 pb-10">
                <Outlet />
            </div>
        </div>
    );
};

export default AgreementLayout;
