import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetProfilesQuery } from "shared/graphql";

interface Props {
    selectedAccount: GetProfilesQuery["profiles"][0] | null;
}

const initialState: Props = {
    selectedAccount: null,
};

const accounts = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        setSelectedAccount(state, action: PayloadAction<Props["selectedAccount"]>) {
            state.selectedAccount = action.payload;
        },
    },
});

export default accounts.reducer;

export const { setSelectedAccount } = accounts.actions;
