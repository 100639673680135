import { useState } from "react";
import { useFormContext } from "react-hook-form";
import ClientUploader from "../components/ClientUploader";
import FilePreview from "../components/FilePreview";
import InputFileItem from "../components/InputFileItem";
import ScanFilePicker from "../components/ScanFilePicker";

const UploadSubmission = () => {
    const context = useFormContext();
    const filePreview = context.watch("filePreview");
    const file = context.watch("input_videos");
    const type = context.watch("dataset_type");
    const [fileType, setFileType] = useState("file");

    if (filePreview && ["video", "video360"].includes(type)) {
        return <FilePreview />;
    }

    if (file && fileType === "file") {
        return <InputFileItem />;
    } else if (file && fileType !== "file") {
        return <ClientUploader type={fileType as any} />;
    }

    return (
        <ScanFilePicker
            onFileClick={() => setFileType("file")}
            onGoogleDriveClick={() => setFileType("google")}
            onDropboxClick={() => setFileType("dropbox")}
        />
    );
};

export default UploadSubmission;
