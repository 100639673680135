import { StudioLink } from "shared/constants/const";
import { Scan } from "shared/graphql";
import { RootState, store } from "store/index";
import { successToast } from "./toast";

export const routeWithParams = (root: string, params: any) => {
    let result = root;
    Object.keys(params).forEach((key) => {
        result = result.replace(`:${key}`, params[key]);
    });
    return result;
};

export const convertSizeToText = (size: number) => {
    if (size < 1024) {
        return `${size} B`;
    }
    if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)} KB`;
    }
    if (size < 1024 * 1024 * 1024) {
        return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
};

export const validCoordinates = (cords: any, altCords?: any) => {
    let latitude = parseFloat(cords["latitude"]);
    let longitude = parseFloat(cords["longitude"]);

    if (latitude === 0 && longitude === 0 && altCords) {
        latitude = parseFloat(altCords["latitude"]);
        longitude = parseFloat(altCords["longitude"]);
    }

    if (!(latitude >= -90 && latitude <= 90) || !(longitude >= -180 && longitude <= 180)) {
        return {
            latitude: 40.7018596,
            longitude: -74.0411671,
        };
    }

    return {
        latitude,
        longitude,
    };
};

export const updateMultipleScansCategory = (scans: any) => {
    return scans.map((item) => {
        return {
            ...item,
            category: ["3d Scan", "3D Scan"].includes(item.category) ? "NeRF" : item.category,
        };
    });
};

export const updateScanCategory = (scans: Scan) => {
    return {
        ...scans,
        category: ["3d Scan", "3D Scan"].includes(scans.category) ? "NeRF" : scans.category,
    };
};

export const extractLocationName = (location: string): string | undefined => {
    if (!location) return;

    const alphaNumLocation = location.match(/[a-zA-Z0-9!@#$%^&*()\-_]+/g)?.join(" ");

    return (alphaNumLocation ?? "").split(",").pop()?.trim();
};

export const calculateOverallPercentage = (decimalPercentages: number[]) => {
    const sum = decimalPercentages.reduce((accumulator, percentage) => accumulator + percentage, 0);
    const average = sum / decimalPercentages.length;
    return average * 100;
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export async function copyTextToClipboard(text: string, showToast: boolean = false) {
    if ("clipboard" in navigator) {
        showToast && successToast("Text copied to clipboard");
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand("copy", true, text);
    }
}

export function addQueryParamsToLink(link: string, queryParams: any): string {
    return `${link}&${new URLSearchParams(queryParams).toString()}`;
}

export const valueFromQueryParam = (key: string, input: string) => {
    const queryParams = input.split("?")[1].split("&");

    for (let i = 0; i < queryParams.length; i++) {
        const keyAndVal = queryParams[i].split("=");

        if (keyAndVal[0] === key) {
            return keyAndVal[1];
        }
    }

    return undefined;
};

export const getViewerLink = (scan_id: number, embed?: boolean) => {
    const { user, token } = store.getState() as RootState;

    if (!embed) {
        return `${StudioLink}?scan_id=${scan_id}&user_id=${user?.current?.user_id || ""}&user_email=${
            user?.current?.email || ""
        }&profile_id=${user.profile?.profile_id}`;
    }

    return `${StudioLink}/dashboard?scan_id=${scan_id}&user_id=${user?.current?.user_id || ""}&user_email=${
        user?.current?.email || ""
    }&embed=true&profile_id=${user.profile?.profile_id}&token=${token.current}`;
};

export const mimeTypeName = (mimeType: string) => {
    // pdf, zip, image,
    switch (mimeType) {
        case "application/pdf":
            return "PDF";
        case "application/zip":
            return "ZIP";
        case "image/png":
        case "image/jpeg":
            return "Image";
        default:
            return "File";
    }
};
