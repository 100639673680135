import { CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";
import { twMerge } from "tailwind-merge";

const CardLoader = ({
    loading,
    className,
    loaderProps,
}: {
    loading: boolean;
    className?: string;
    loaderProps?: CircularProgressProps;
}) => {
    if (!loading) return <></>;
    return (
        <div
            className={twMerge(
                "absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50 z-20",
                className
            )}
        >
            <CircularProgress {...loaderProps} />
        </div>
    );
};

export default CardLoader;
