import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import PageLoader from "shared/components/ui/PageLoader";
import URL from "shared/constants/navigator";
import { useVerifyTokenQuery } from "shared/graphql";
import useLogout from "shared/hooks/useLogout";
import { useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";

const Auth = () => {
    const { doLogout } = useLogout();
    const navigate = useNavigate();
    const [Component, setComponent] = useState(<PageLoader />);
    const user = useAppSelector((state) => state.user.current);
    const token = useAppSelector((state) => state.token.current);
    const tokenRes = useVerifyTokenQuery(undefined, {
        skip: Boolean(user) || !token,
    });

    useEffect(() => {
        rtkHandler(tokenRes, {
            onSuccess() {
                setComponent(<Outlet />);
            },
            onError() {
                doLogout();
                navigate(URL.Login, { replace: true });
            },
        });
    }, [tokenRes, doLogout, navigate]);

    useEffect(() => {
        if (user) {
            setComponent(<Outlet />);
        }
    }, [user]);

    return Component;
};

export default Auth;
