import useImages from "assets/images";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import URL from "shared/constants/navigator";
import { GetStorageQuery, useGetStorageQuery } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useHasSRAccess from "shared/hooks/useHasSRAccess";
import useProjects from "shared/hooks/useProjects";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setCommonModal } from "store/slices/commonModal";
import { setScanTutorialVideo } from "store/slices/tutorial";
import { toggleCustomerSupportModal } from "store/slices/utils";

const useStats = () => {
    const Images = useImages();
    const navigate = useNavigate();
    const sliderRef = useRef<Slider>(null);
    const { team, isTeamExpired } = useAuth();
    const { projects } = useProjects({});
    const hasScanRequestAccess = useHasSRAccess();
    const { scan_tutorial_totalSteps = 0, scan_tutorial_watchedStep = 0 } = useAppSelector((state) => state.tutorial);
    const dispatch = useAppDispatch();
    const [storage, setStorage] = useState(0);

    const storageRes = useGetStorageQuery();

    const [currentSlide, setCurrentSlide] = useState(0);

    const dayRemaining = team?.trial_period?.days_remaining ?? 0;
    const percentage = ((dayRemaining / 14) * 100) / 2;

    const creditPercentage =
        (((team?.credits?.addition_credit_limit ?? 0) - (team?.credits?.credits ?? 0)) /
            (team?.credits?.addition_credit_limit || 0)) *
            100 || 0;

    const captureScanGuidePercentage = ((scan_tutorial_watchedStep / scan_tutorial_totalSteps) * 100) / 2;

    useEffect(() => {
        const mobileStepper = document.querySelectorAll("#mobile-stepper .MuiMobileStepper-dot");
        mobileStepper.forEach((dot) => {
            dot.addEventListener("click", (e) => {
                const target = e.target as HTMLElement;
                const index = Array.from(mobileStepper).indexOf(target);
                setCurrentSlide(index);
                sliderRef.current?.slickGoTo(index);
            });
        });

        return () => {
            mobileStepper.forEach((dot) => {
                dot.removeEventListener("click", () => {});
            });
        };
    }, []);

    const handleViewTutorial = () => {
        dispatch(
            setScanTutorialVideo({
                open: true,
                showButtons: true,
            })
        );
    };

    const handleCustomerSupport = () => {
        if (isTeamExpired) {
            return navigate(`${URL.Settings}/${URL.Shop}`);
        }
        dispatch(toggleCustomerSupportModal());
    };

    useEffect(() => {
        rtkHandler(storageRes, {
            onSuccess: ({ storage: data }: GetStorageQuery) => {
                setStorage(data.consumed / 1024 / 1024 / 1024);
            },
        });
    }, [storageRes]);

    const handleNotEnoughScan = () => {
        dispatch(
            setCommonModal({
                icon: <Images.ExclamationLayer />,
                title: "Not Enough Scan Credits!",
                content: "Your scan credits are fully used, please upgrade your account to get more credits.",
                dialogueActionClassName: "flex-col",
                buttons: [
                    {
                        children: "Upgrade Account",
                        isCloseBtn: true,
                        onClick: () => {
                            navigate(`${URL.Settings}/${URL.Shop}`, {
                                replace: true,
                            });
                        },
                    },
                ],
            })
        );
    };

    return {
        projects,
        hasScanRequestAccess,
        currentSlide,
        percentage,
        creditPercentage,
        captureScanGuidePercentage,
        dayRemaining,
        sliderRef,
        storage,
        setCurrentSlide,
        handleViewTutorial,
        handleCustomerSupport,
        handleNotEnoughScan,
    };
};

export default useStats;
