import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Profile, UpdateTeamMutation, useUpdateTeamMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setProfile } from "store/slices/user";

const schema = yup.object().shape({
    name: yup.string().required(),
    bio: yup.string().required(),
    brand_color: yup.string().required(),
    logo: yup.mixed().required(),
});

type FormValues = yup.InferType<typeof schema>;

const useUpdateTeamDetails = () => {
    const { team, profile } = useAuth();
    const dispatch = useAppDispatch();
    const [updateTeam, updateRes] = useUpdateTeamMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            name: team?.name || "",
            bio: team?.bio || "",
            brand_color: team?.brand_color || "#D9D9D9",
            logo: team?.logo.url || "",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { logo, name, ...rest } = data;
        updateTeam({
            data: {
                ...rest,
                ...(logo &&
                    typeof logo !== "string" && {
                        logo_id: (logo as any).file_id,
                    }),
            },
        });
    };

    useEffect(() => {
        rtkHandler(updateRes, {
            onSuccess: ({ update_team }: UpdateTeamMutation) => {
                form.reset({
                    name: update_team.name,
                    bio: update_team.bio,
                    brand_color: update_team.brand_color,
                    logo: update_team.logo.url,
                });
                if (profile && update_team.logo) {
                    const updatedProfile: Profile = {
                        ...profile,
                        profile_image: {
                            ...profile.profile_image,
                            url: update_team.logo.url,
                        },
                    };
                    dispatch(setProfile(updatedProfile));
                }
            },
            setError: form.setError,
            successMessage: "Team details updated successfully",
        });
    }, [updateRes]);

    const loading = updateRes.isLoading;

    return { form, onSubmit, loading };
};

export default useUpdateTeamDetails;
