import ThemePagination from "../../ThemePagination";
import { useDataTableContext } from "../context";

const DataTableFooter = () => {
    const { pagination } = useDataTableContext();

    return <ThemePagination {...pagination} />;
};

export default DataTableFooter;
