import { Button, ButtonProps, DialogActions } from "@mui/material";
import LoaderButton from "shared/components/ui/LoaderButton";
import { useAppSelector } from "shared/hooks/useRedux";
import { CommonModalButtons } from "store/slices/commonModal";
import { twMerge } from "tailwind-merge";

const CommonModalActionButton = ({
    buttons = [],
    className,
    cancelBtnProps,
}: {
    buttons?: CommonModalButtons[];
    className?: string;
    cancelBtnProps?: ButtonProps;
}) => {
    const loadingBtn = useAppSelector((state) => state.commonModal.loadingBtn);

    if (className?.includes("flex-col")) {
        className = className.replace("flex-col", "flex-col-reverse");
    }

    return (
        <DialogActions className={`w-full gap-3 ${className}`}>
            {buttons && buttons?.length ? (
                <>
                    <Button
                        variant="outlined"
                        fullWidth
                        disabled={Boolean(loadingBtn)}
                        onClick={cancelBtnProps?.onClick}
                        color="inherit"
                        {...cancelBtnProps}
                        className={twMerge("h-11 !ml-0", cancelBtnProps?.className)}
                    >
                        {cancelBtnProps?.children ?? "Cancel"}
                    </Button>
                    {buttons.map(({ children, id, className, onClick, isCloseBtn, ...others }, index) => {
                        return (
                            <LoaderButton
                                id={id || `common-modal-btn-${index}`}
                                key={index}
                                className={twMerge("h-11 !ml-0", className)}
                                variant="contained"
                                fullWidth
                                loading={id === loadingBtn}
                                disabled={id === loadingBtn && Boolean(loadingBtn)}
                                onClick={(e) => {
                                    if (isCloseBtn) {
                                        cancelBtnProps?.onClick?.(e);
                                    }
                                    onClick?.(e);
                                }}
                                {...others}
                            >
                                {children}
                            </LoaderButton>
                        );
                    })}
                </>
            ) : (
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={cancelBtnProps?.onClick}
                    color="inherit"
                    {...cancelBtnProps}
                    className={twMerge("h-11 !ml-0", cancelBtnProps?.className)}
                >
                    {cancelBtnProps?.children ?? "Cancel"}
                </Button>
            )}
        </DialogActions>
    );
};

export default CommonModalActionButton;
