import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useUpdateUserRegisterationHistoryMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";

const useUpdateProfileCompletion = (props: { doNavigate?: boolean }) => {
    const [updateProfileCompletion, profileRes] = useUpdateUserRegisterationHistoryMutation();
    const navigate = useNavigate();
    const [screenName, setScreenName] = useState("");

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess() {
                if (!props.doNavigate) return;
                navigate(
                    routeWithParams(URL.CompleteProfile, {
                        token: screenName,
                    })
                );
            },
        });
    }, [profileRes]);

    const update = async (screen: "personal" | "identity" | "team" | "project", step: number) => {
        setScreenName(screen);
        await updateProfileCompletion({ step });
    };

    return { update, isLoading: profileRes.isLoading };
};

export default useUpdateProfileCompletion;
