import useImages from "assets/images";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import DataTable from "shared/components/ui/DataTable";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { IsStagingServer } from "shared/constants/const";
import useScanCreditSettings from "./hook";

const ScanCreditSettings = () => {
    const { form, onSubmit, isModified, isAbsoluteDivision, ABSOLUTE_DIVISION_TABLE_HEADERS } = useScanCreditSettings();
    const { SelectArrow } = useImages();

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit}>
            <SettingPageCard
                title="Scan Credits Settings"
                subTitle="Update your credits settings here"
                rightComponent={
                    <LoaderButton
                        // loading={isLoading || uploadLoading}
                        loadingText={"Updating..."}
                        type="submit"
                        variant="contained"
                        disabled={!isModified}
                    >
                        Update Settings
                    </LoaderButton>
                }
                hasHeaderDivider={false}
            >
                <Field
                    name="credit_division"
                    label="Credit Division Setting:"
                    type="select"
                    options={[
                        { label: "Free for All", value: "Free for All" },
                        ...(IsStagingServer
                            ? [
                                  { label: "Equal Division", value: "Equal Division" },
                                  { label: "Absolute Division", value: "Absolute Division" },
                              ]
                            : []),
                    ]}
                    selectFieldProps={{
                        inputProps: {
                            IconComponent: () => (
                                <span className="mr-4">
                                    <SelectArrow width={16} height={16} color="#D0D5DD" />
                                </span>
                            ),
                        },
                    }}
                />

                {isAbsoluteDivision && <DataTable headers={ABSOLUTE_DIVISION_TABLE_HEADERS} data={Array(5).fill([])} />}
            </SettingPageCard>
        </ThemeFormProvider>
    );
};

export default ScanCreditSettings;
