import useImages from "assets/images";
import { useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { GetScanRequestByIdQuery } from "shared/graphql";

interface Props {
    data: GetScanRequestByIdQuery["scan_request"]["applications"][0];
    isButton?: boolean;
}

const SRRevision = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const scan_request_id = parseInt(id!);
    const Images = useImages();
    const { requestRevision, revisionRes } = useSingleSR();

    return (
        <ConfirmButton
            icon={<Images.GreenTickLayerIcon color="#EB617A" />}
            title="Revision"
            content="Are you sure you want revision for this submission?"
            okBtnProps={{
                loading: revisionRes.isLoading,
                onClick: () =>
                    requestRevision({
                        scan_request_id,
                        application_id: props.data.application_id,
                    }),
            }}
        >
            <ThemeIconButton
                color="error"
                variant={props.isButton ? "filled" : "default"}
                icon={<Images.ReturnIcon color="#EB617A" height={22} width={22} />}
                className={props.isButton ? "!bg-error !bg-opacity-20" : ""}
            />
        </ConfirmButton>
    );
};

export default SRRevision;
