import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import StripePaymentContext, { StripePaymentContextType } from "./StripePayment.context";

export const PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(PUBLISHABLE_KEY ?? "");

const StripePaymentCard = (props: StripePaymentContextType) => {
    const { clientSecret } = props;

    if (!clientSecret || !PUBLISHABLE_KEY) {
        throw new Error("Stripe keys are not set");
    }

    return (
        <StripePaymentContext.Provider
            value={{
                ...props,
            }}
        >
            <Elements
                stripe={stripePromise}
                options={{
                    clientSecret,
                    appearance: {
                        theme: "stripe",
                        variables: { colorPrimaryText: "#262626" },
                    },
                }}
            >
                <CheckoutForm />
            </Elements>
        </StripePaymentContext.Provider>
    );
};

export default StripePaymentCard;
