import { Button } from "@mui/material";
import useImages from "assets/images";
import { Link, useNavigate } from "react-router-dom";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import URL from "shared/constants/navigator";

const SubscriptionEndScreen = () => {
    const { ArrowChevronRight } = useImages();
    const navigate = useNavigate();

    return (
        <ThemeScreenCard>
            <div className="card-dashed-border flex items-center justify-center text-center h-full">
                <div className="max-w-[500px] flex items-center justify-center flex-col">
                    <h4 className="text-[20px] font-bold mb-[10px] text-[#5E5D5D]">Your subscription has ended.</h4>
                    <p className="text-md font-normal mb-4 text-[#667085]">
                        Your team’s annual subscription for 7 members per month has ended. Renew your teams subscription
                        in settings to resume activity.
                    </p>
                    <Button
                        variant="text"
                        LinkComponent={Link}
                        onClick={() => navigate(`${URL.Settings}/${URL.Subscription_Settings}`)}
                    >
                        Renew subscription
                        <ArrowChevronRight color="#7680FF" />
                    </Button>
                </div>
            </div>
        </ThemeScreenCard>
    );
};

export default SubscriptionEndScreen;
