import useImages from "assets/images";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InviteTeamMemberModal from "shared/components/InviteMemberModal";
import NotRecordBox from "shared/components/NotRecordBox";
import UserProfileCard from "shared/components/UserProfileCard";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import DashboardCard from "../components/DashboardCard";

const TeamMembers = (props: { onView: (val: TeamMember) => void }) => {
    const Images = useImages();
    const { user, team } = useAuth();
    const navigate = useNavigate();
    const [openInviteModal, setOpenInviteModal] = useState(false);

    const members = team?.members.filter((member) => member.status === "Active");

    return (
        <>
            <DashboardCard>
                <div className="flex items-center justify-between border-b border-[#EAECF0] pb-4 mb-4">
                    <h4 className="text-[#475467] font-medium">Team Members </h4>
                    <ThemeIconButton
                        onClick={() => setOpenInviteModal(true)}
                        icon={<Images.PlusGrey height={18} />}
                        className="!rounded-md !shadow-none !bg-[#94949633]"
                    />
                </div>
                {members?.length ? (
                    members?.map((item) => {
                        const data = item as TeamMember;
                        return (
                            <div
                                key={item.member_id}
                                className="py-4 border-b border-[#EAECF0] last:border-0 last:pb-0 flex items-center justify-between pr-4"
                            >
                                <UserProfileCard
                                    image={data.user?.profile_image.url || ""}
                                    name={data.user?.name}
                                    email={data.user?.email}
                                />
                                <div className="flex-center space-x-4">
                                    {data.user?.user_id !== user?.user_id && (
                                        <ThemeIconButton
                                            icon={<Images.MessageSquareIcon color="#475467" height={18} width={18} />}
                                            className="!shadow-none"
                                            onClick={() =>
                                                navigate(URL.Messages, {
                                                    state: {
                                                        user: data,
                                                    },
                                                })
                                            }
                                        />
                                    )}
                                    <ThemeIconButton
                                        icon={<Images.Eye color="#475467" height={20} width={20} />}
                                        className="!shadow-none"
                                        onClick={() => props.onView(data)}
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="pt-10">
                        <NotRecordBox
                            icon={<Images.UserIcon color="#ffffff" />}
                            title="No Team Members"
                            description="Users invited by team admin will show here."
                        />
                    </div>
                )}
            </DashboardCard>
            <InviteTeamMemberModal open={openInviteModal} onClose={() => setOpenInviteModal(false)} />
        </>
    );
};

export default TeamMembers;
