import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button } from "@mui/material";
import useImages from "assets/images";
import CompanyOrientedTemplateImg from "assets/images/dummy/Company-Oriented-Template.png";
import ContentOrientedTemplateImg from "assets/images/dummy/Content-Oriented-Template.png";
import LandingPageTemplateImg from "assets/images/dummy/Landing-Page-Template.png";
import TemplateSidebarCard from "shared/components/TemplateSidebarCard/TemplateSidebarCard";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { closeTemplateSelection, setSelectedTemplate } from "store/slices/publishable_project";

import { v4 as uuidv4 } from "uuid";

const templates: { id: number; label: string; image: string }[] = [
    {
        id: 0,
        label: "Content Oriented",
        image: ContentOrientedTemplateImg,
    },
    {
        id: 1,
        label: "Company Oriented",
        image: CompanyOrientedTemplateImg,
    },
    {
        id: 2,
        label: "Landing Page",
        image: LandingPageTemplateImg,
    },
];

const TemplatesSidebar = () => {
    const dispatch = useAppDispatch();
    const { ArrowRightWhite } = useImages();

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);
    const editor = useAppSelector((state) => state.publishable_project.main_editor);

    useEffect(() => {}, [selectedTemplate]);

    return (
        <div className="w-[240px] h-full rounded-xl bg-[#FFFFFF] border border-[#EAECF0] p-5 flex flex-col">
            <div className="text-[#667085] font-semibold text-md mb-4">Templates</div>
            <div className="h-full flex-1">
                <PerfectScrollbar>
                    {templates.map((template) => (
                        <TemplateSidebarCard
                            key={template.id}
                            isSelected={selectedTemplate == template.id}
                            onClick={() => dispatch(setSelectedTemplate(template.id))}
                            {...template}
                        />
                    ))}
                </PerfectScrollbar>
            </div>
            <div>
                <div
                    className="text-center font-medium text-sm text-[#475467] mb-3 select-none cursor-pointer"
                    onClick={() => {
                        dispatch(setSelectedTemplate(-1));
                        dispatch(closeTemplateSelection());

                        setTimeout(() => {
                            editor?.commands.clearContent();
                            editor?.commands.setContent(`
                                <div id="publishedDate" class="published-date" template=0></div>
                                <div id="mainHeading" class="heading" custom_class="no-further-block full-width" alignment="center" content="UX review presentations"></div>
                                <div id="mainSubtitle" class="styled-text" custom_class="no-further-block full-width" alignment="center" content="How do you create compelling presentations that wow your colleagues and impress your managers? Find out with our in-depth guide on UX presentations."></div>
                                <div id="projectBanner" class="project-banner-component" template="0"></div>
                                <div id="projectBanner1" class="project-banner-component" template="0"></div>

                                <div id="divider" class="editor-divider"></div>
                                <div id="tabs" class="editor-tabs" template=0></div>
                                <div id="scans_or_snapshots" class="scans-snapshots-block"></div>
                                <div class="project-links-column"></div>

                                <div id="${uuidv4()}" class="editor-placeholder"></div>
                            `);
                        }, 0);
                    }}
                >
                    Start without Template
                </div>
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(closeTemplateSelection());
                    }}
                    color="primary"
                    className={"!h-9 !w-full !m-0 !flex !items-center gap-2 !text-md !font-semibold"}
                    disabled={selectedTemplate == -1}
                >
                    Use Template
                    <ArrowRightWhite width={20} />
                </Button>
            </div>
        </div>
    );
};

export default TemplatesSidebar;
