import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import useAuth from "./useAuth";

const useHasSRAccess = () => {
    const { isTeamMember } = useAuth();
    const UserHasRequesteeAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestee);
    const UserHasRequestorAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestor);

    let hasScanRequestAccess = false;
    if (UserHasRequesteeAccess || UserHasRequestorAccess) {
        hasScanRequestAccess = true;
    }
    if (isTeamMember && !UserHasRequesteeAccess) {
        hasScanRequestAccess = false;
    }

    return hasScanRequestAccess;
};

export default useHasSRAccess;
