import useImages from "assets/images";

import UnderMaintenanceImage from "assets/images/dummy/page-not-found-placeholder-image.png";

const NotFound = () => {
    const { LogoPNG } = useImages();

    return (
        <div className="w-full h-screen p-8 flex flex-col justify-between">
            <div>
                <img src={LogoPNG} className="w-[150px]" alt="logo" />
            </div>
            <div className="w-[395px] text-center mx-auto">
                <img src={UnderMaintenanceImage} className="w-[75%] mb-14 mx-auto" />
                <div className="text-[20px] text-[#5E5D5D] mb-3">Page not found</div>
                <div className="text-md text-[#667085]">
                    We can&apos;t find the page you are looking for. If you think this is some kind of error, send us an
                    email at{" "}
                    <a href="mailto:info@xspada.com" className="text-[#7680FF]">
                        info@xspada.com
                    </a>{" "}
                    for immediate support.
                </div>
            </div>
            <div className="text-md text-[#667085]">© XSpada 2023</div>
        </div>
    );
};

export default NotFound;
