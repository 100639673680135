import { Button } from "@mui/material";
import DummyCatImage from "assets/images/dummy/concepts-1.png";
import DummyImage from "assets/images/dummy/dummy-project-banner.png";
import BoardTabs from "./tabs";

const BoardScreen = () => {
    return (
        <>
            <div className="relative h-[313px] w-full rounded-md overflow-hidden text-white">
                <img
                    src={DummyImage}
                    alt="dummy"
                    className="w-full h-full object-cover object-center absolute top-0 left-0"
                />

                <div className="relative top-6 left-6">
                    <h4 className="text-[20px] leading-6 mb-1">Project Name</h4>
                    <h5 className="text-[14px] leading-5 text-white opacity-50">March 03, 2023</h5>
                </div>
            </div>

            <div className="px-8 -mt-7 relative z-10">
                <div className="flex items-end justify-between mb-4">
                    <div className="h-20 w-20 rounded-full overflow-hidden">
                        <img src={DummyCatImage} className="w-full h-full object-cover object-center" />
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button color="inherit" variant="outlined">
                            Contact Us
                        </Button>
                        <Button variant="contained">Follow</Button>
                    </div>
                </div>

                <h3 className="text-[28px] leading-[36px] text-[#3F3E3E] font-bold mb-4">Create World</h3>
                <h4 className="max-w-[410px] text-[#3F3E3E] leading-9 text-[28px] font-medium mb-8">
                    We show the world around you, creatively.
                </h4>

                {/* Tabs */}
                <BoardTabs />
            </div>
        </>
    );
};

export default BoardScreen;
