import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Image from "./Image";

export default Node.create({
    name: "imageComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            type: {
                default: "upload",
            },
            image: {
                default: null,
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.image-component", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                    type: dom.getAttribute("type"),
                    image: dom.getAttribute("image"),
                    width: parseInt(dom.getAttribute("width")),
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "image-component",
                id: node.attrs.id,
                type: node.attrs.type,
                image: node.attrs.image,
                width: node.attrs.width,
                justify: node.attrs.justify,
                caption: node.attrs.caption,
                caption_str: node.attrs.caption_str,
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Image);
    },
});
