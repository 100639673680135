import { Button } from "@mui/material";
import useImages from "assets/images";
import { useNavigate } from "react-router-dom";

const WaitingList = () => {
    const Images = useImages();
    const navigate = useNavigate();

    return (
        <div className="p-8">
            <div className="flex items-center justify-between">
                <div>
                    <Images.Logo />
                </div>
                <div className="flex items-center">
                    {/* <div className="flex items-center space-x-6 border-r border-[#7680FF] pr-6 mr-6">
                        <a href="">
                            <Images.SocialInstagramIcon />
                        </a>
                        <a href="">
                            <Images.SocialFacebookIcon />
                        </a>
                        <a href="">
                            <Images.SocialLinkedInIcon />
                        </a>
                    </div>
                    <div className="flex items-center space-x-6 border-r border-[#7680FF] pr-6 mr-6">
                        <a href="">
                            <Images.Skype width={30} height={30} color="#7680FF" />
                        </a>
                        <a href="">
                            <Images.SocialWhatsappIcon />
                        </a>
                        <a href="">
                            <Images.SocialEmailIcon />
                        </a>
                    </div>
                    <div className="flex items-center space-x-6 border-r border-[#7680FF] pr-6 mr-6">
                        <a href="">
                            <Images.Youtube color="#7680FF" />
                        </a>
                        <a href="">
                            <Images.Github color="#7680FF" />
                        </a>
                        <a href="">
                            <Images.X color="#7680FF" />
                        </a>
                    </div> */}
                    <Button variant="outlined" className="!text-[#9E9E9E] !h-8" onClick={() => navigate("/")}>
                        Back
                    </Button>
                </div>
            </div>
            <div className="text-center mt-[88px] mb-[100px]">
                <h4 className="text-[20px] leading-6 font-bold mb-[10px] text-p">
                    Your Signup Request is being processed
                </h4>
                <p className="max-w-[500px] text-[#667085] text-md leading-5 mx-auto">
                    We are currently experiencing a large number of new users onboarding & have added you to the waiting
                    list. You will receive a sign-up email shortly that will notify you that your account is ready to
                    use.
                </p>
            </div>
            <iframe
                src="https://my.spline.design/untitled-6067d2658905027f271cda7f9be32a57/"
                className="w-full h-[calc(100vh-385px)]"
            />
        </div>
    );
};

export default WaitingList;
