import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {},
});

export const {
    useGetPlansQuery,
    useGetSubscriptionsQuery,
    useCreateSubscriptionMutation,
    useCancelSubscriptionMutation,
} = api;
