import { Button } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { Navigate, useNavigate } from "react-router-dom";
import SRInviteMemberModal from "screens/Dashboard/ScanRequests/common/components/SRInviteMemberModal";
import RenderIf from "shared/components/RenderIf";
import ScanRequestStatusCard from "shared/components/ScanRequestStatusCard";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import useAuth from "shared/hooks/useAuth";
import useSRNewPostScreen, { SRNewSteps } from "./hook";
import ScanRequestBasicForm from "./partials/form/basic";
import ScanRequestDetailForm from "./partials/form/detail";
import ScanRequestLocation from "./partials/form/location";
import ScanRequestTitle from "./partials/title";

const SRNewPostScreen = () => {
    const hook = useSRNewPostScreen();
    const navigate = useNavigate();
    const step = hook.form.watch("step");
    const isBasicStep = step === SRNewSteps.Basic;
    const isDetailStep = step === SRNewSteps.Details;
    const isLocationStep = step === SRNewSteps.Location;
    const { isTeamMember } = useAuth();

    const steps = Object.values(SRNewSteps);

    if (isTeamMember) {
        return <Navigate to="/requests/new" />;
    }

    return (
        <ThemeScreenCard headerComponent={<ScanRequestStatusCard status={"Submit New"} />}>
            <ThemeFormProvider
                contextValue={{
                    options: hook.options,
                }}
                form={hook.form}
                onSubmit={hook.onSubmit}
                className="h-full"
            >
                <div className="rounded-[10px] card-dashed-border border-opacity-50 h-full flex items-center justify-center p-4 shadow-card">
                    <div className="bg-white p-4 rounded-2xl h-full w-full flex flex-col relative">
                        <div className="overflow-auto overflow-x-hidden flex-1 flex flex-col">
                            <ScanRequestTitle />
                            <div className="flex-1">
                                <RenderIf condition={isBasicStep}>
                                    <ScanRequestBasicForm />
                                </RenderIf>
                                <RenderIf condition={isDetailStep}>
                                    <ScanRequestDetailForm />
                                </RenderIf>
                                <RenderIf condition={isLocationStep}>
                                    <ScanRequestLocation />
                                </RenderIf>
                            </div>
                            <MobileStepper
                                variant="dots"
                                steps={steps.length}
                                position="static"
                                activeStep={steps.indexOf(step)}
                                classes={{
                                    root: "!grid !grid-cols-3 items-center gap-x-3 !p-0 mt-6 !h-[36px]",
                                    dots: "flex items-center justify-center",
                                    dotActive: "!h-[10px] !w-[10px]",
                                }}
                                nextButton={
                                    <div className="flex items-center justify-end">
                                        {isLocationStep ? (
                                            <LoaderButton
                                                loading={hook.createRes.isLoading}
                                                type="submit"
                                                variant="contained"
                                            >
                                                Submit Project Request
                                            </LoaderButton>
                                        ) : (
                                            <Button onClick={() => hook.onStepChange("next")} variant="contained">
                                                Next
                                            </Button>
                                        )}
                                    </div>
                                }
                                backButton={
                                    <div className="">
                                        {isBasicStep ? (
                                            <div></div>
                                        ) : (
                                            <Button onClick={() => hook.onStepChange("back")} variant="outlined">
                                                Previous
                                            </Button>
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </ThemeFormProvider>
            <SRInviteMemberModal
                id={hook.inviteModalId}
                onCloseModal={() => {
                    hook.setInviteModalId(null);
                    navigate("/requests/new");
                }}
            />
        </ThemeScreenCard>
    );
};

export default SRNewPostScreen;
