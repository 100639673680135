import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import Field from "shared/components/ui/Field";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import ThemeModal from "shared/components/ui/ThemeModal";
import ThemeRating from "shared/components/ui/ThemeRating";
import { GetScanRequestByIdQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";

interface Props {
    data: GetScanRequestByIdQuery["scan_request"]["applications"][0];
    isButton?: boolean;
}

const schema = yup.object().shape({
    rating: yup.number().notOneOf([0], "Please select a rating").required(),
    comment: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const SRFeedback = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const { request, feedbackRes, giveFeedback } = useSingleSR();
    const scan_request_id = parseInt(id!);
    const Images = useImages();
    const [ratingModal, setRatingModal] = useState(false);
    const form = useForm<FormValues>({
        defaultValues: {
            comment: "",
            rating: 0,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        giveFeedback({
            application_id: props.data.application_id,
            scan_request_id,
            rating: data.rating,
            comment: data.comment,
        });
    };

    const hasOtherPendingReviews = request?.applications.some(
        (item) => item.application_id !== props.data.application_id && item.status === "In Review"
    );

    useEffect(() => {
        rtkHandler(feedbackRes, {
            showErrorToast: false,
            onSuccess() {
                if (hasOtherPendingReviews) {
                    successToast(
                        "Review submitted for the application. Please add all reviews to move project request to DELIVERED."
                    );
                } else {
                    successToast(
                        "Reviews submitted for all the applications. You can visit this request anytime in DELIVERED."
                    );
                }
                setRatingModal(false);
            },
        });
    }, [feedbackRes, hasOtherPendingReviews]);

    return (
        <>
            {props.isButton ? (
                <Button
                    onClick={() => setRatingModal(true)}
                    className="!bg-[#7680FF33] !text-primary !bg-opacity-20 !h-8 !font-normal"
                    variant="contained"
                >
                    Submit Review <Images.ArrowUpRight />
                </Button>
            ) : (
                <ThemeIconButton
                    icon={<Images.StarIcon color="#7680FF" height={24} width={24} />}
                    onClick={() => setRatingModal(true)}
                />
            )}
            <ThemeModal
                open={ratingModal}
                icon={
                    <RenderImage
                        image={props.data.user.profile_image.url}
                        containerClassName="h-[56px] w-[56px] rounded-full"
                    />
                }
                onCloseModal={() => setRatingModal(false)}
                title="Mark Submission as Completed"
                content={
                    <>
                        Complete your request for team member{" "}
                        <span className="text-primary">{props.data.user.name}</span> and leave a review about their
                        submission.
                    </>
                }
                buttons={[
                    {
                        loading: feedbackRes.isLoading,
                        children: "Complete",
                        onClick: form.handleSubmit(onSubmit),
                    },
                ]}
            >
                <ThemeFormProvider form={form} onSubmit={() => {}}>
                    <Field
                        name="rating"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ThemeRating
                                    className="mb-2"
                                    initialRating={value}
                                    onChange={(value) => onChange(value)}
                                />
                            );
                        }}
                    />
                    <div className="text-left">
                        <Field
                            formGroup={false}
                            name="comment"
                            inputTextProps={{
                                multiline: true,
                                rows: 4,
                            }}
                            label="Team Member Review"
                            placeholder="Write your reviews about your experience with the team member"
                        />
                    </div>
                </ThemeFormProvider>
            </ThemeModal>
        </>
    );
};

export default SRFeedback;
