// CustomListNode.js
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ListBlock from "./ListBlock";

export default Node.create({
    name: "listComponent",
    content: "inline*",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            size: {
                default: 3, // Set a default size
            },
            type: {
                default: "numbered",
            },
            alignment: {
                default: "left",
            },
            content: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "ol.list-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    type: dom.getAttribute("type"),
                    size: parseInt(dom.getAttribute("size")) || 3,
                    alignment: dom.getAttribute("alignment"),
                    content: dom.getAttribute("content"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "ol",
            {
                class: "list-component",
                id: node.attrs.id,
                type: node.attrs.type,
                size: node.attrs.size,
                alignment: node.attrs.alignment,
                content: node.attrs.content,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ListBlock);
    },
});
