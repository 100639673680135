import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import {
    closeTemplateSelection,
    setDefaultContent,
    setEditor,
    setSelectedBlock,
    setSelectedTemplate,
    toggleSidebar,
} from "store/slices/publishable_project";

import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import CodeBlock from "@tiptap/extension-code-block";
import { Color } from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Youtube from "@tiptap/extension-youtube";
import { useEditor as useTipTapEditor } from "@tiptap/react";
import { Div } from "./partials/Block";

import CarouselExt from "./partials/Carousel/Carousel.Ext";
import CodeBlockExt from "./partials/CodeBlock/CodeBlock.Ext";
import DisableTyping from "./partials/DisableTyping";
import { Divider } from "./partials/Divider";
import EmbedExt from "./partials/Embed/Embed.Ext";
import FooterBlockExt from "./partials/FooterBlock/FooterBlock.Ext";
import ImageExt from "./partials/Image/Image.Ext";
import ListBlockExt from "./partials/ListBlock/ListBlock.Ext";
import { Box } from "./partials/NestedEditor/Box";
import PlaceholderExt from "./partials/Placeholder/Placeholder.Ext";
import ProjectBannerBlockExt from "./partials/ProjectBannerBlock/ProjectBannerBlock.Ext";
import ProjectLinksColumnExt from "./partials/ProjectLinksColumnBlock/ProjectLinksColumnBlock.Ext";
import PublishedDateBlockExt from "./partials/PublishedDateBlock/PublishedDateBlock.Ext";
import QuoteBlockExt from "./partials/QuoteBlock/QuoteBlock.Ext";
import ScanBlockExt from "./partials/ScanBlock/ScanBlock.Ext";
import ScansOrSnapshotsBlockExt from "./partials/ScansOrSnapshotsBlock/ScansOrSnapshotsBlock.Ext";
import SnapshotBlockExt from "./partials/SnapshotBlock/SnapshotBlock.Ext";
import StyledTextExt from "./partials/StyledText/StyledText.Ext";
import TabsBlock from "./partials/TabsBlock/TabsBlock.Ext";
import TitleExt from "./partials/Title/Title.Ext";
import ToCBlockExt from "./partials/ToCBlock/ToCBlock.Ext";
import VideoExt from "./partials/Video/Video.Ext";
import YoutubeExt from "./partials/Youtube/Youtube.Ext";

import { TextSelection } from "@tiptap/pm/state";
import { AddBlockExtension } from "./partials/AddBlock/AddBlock.Ext";
import { KeyMap } from "./partials/KeyMap";

import { v4 as uuidv4 } from "uuid";
import { dropCursor } from "./partials/CustomDropcursor";

const bubbleMenuExts = [Bold, Italic, Strike, Underline, Link, Highlight];
const tableExts = [Table, TableCell, TableHeader, TableRow];

export const editorExtensions = [
    Document,
    Div,
    // Placeholder,
    PlaceholderExt,
    Text,
    StyledTextExt,
    TitleExt,
    TextStyle,
    ...bubbleMenuExts,
    Blockquote,
    CodeBlock,
    Color,
    TextAlign,
    HardBreak,
    Image,
    Youtube,
    ...tableExts,
    CarouselExt,
    ImageExt,
    VideoExt,
    YoutubeExt,
    EmbedExt,
    ScanBlockExt,
    SnapshotBlockExt,
    Box,
    ProjectBannerBlockExt,
    FooterBlockExt,
    PublishedDateBlockExt,
    ProjectLinksColumnExt,
    QuoteBlockExt,
    ListBlockExt,
    ToCBlockExt,
    CodeBlockExt,
    Divider,
    TabsBlock,
    ScansOrSnapshotsBlockExt,
    AddBlockExtension,
    KeyMap,
];

const extraEditorExtensions = [
    // Dropcursor,
    dropCursor,
    DisableTyping,
    // BubbleMenu.configure({
    //     element: document.querySelector(".editor-bubble-menu") as any,
    // }),
];

export function editorDropHandler(dispatch, view, event) {
    event.preventDefault();
    event.stopPropagation();

    const dragData = (event?.dataTransfer as any).getData("application/x-tiptap-drag");

    if (dragData) {
        const parsedData = JSON.parse(dragData);
        const isNested = (view.dom.editor.view.props.attributes.id as string).includes("nested");
        const isMove = parsedData.type == "move";

        if (isNested && parsedData.type == "box") {
            return false;
        }

        const coords = view.posAtCoords({ left: event.clientX, top: event.clientY });

        if (coords) {
            const position = coords.pos;

            if (!isNested && position <= 8) {
                return false;
            }

            const resolvedPos = view.state.doc.resolve(position);

            let node;
            let nodePos = resolvedPos.pos;
            let uniqueId = uuidv4();

            if (isMove) {
                uniqueId = parsedData.from;
                let draggedNode;

                view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == uniqueId) {
                        draggedNode = node;
                        nodePos = position;
                        return false;
                    }
                });

                node = draggedNode;
            } else {
                if (parsedData.type === "styledTitle") {
                    node = view.state.schema.nodes.styledTitle.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "styledText") {
                    node = view.state.schema.nodes.styledText.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "carousel") {
                    node = view.state.schema.nodes.carousel.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "imageComponent") {
                    node = view.state.schema.nodes.imageComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "videoComponent") {
                    node = view.state.schema.nodes.videoComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "youtubeComponent") {
                    node = view.state.schema.nodes.youtubeComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "embedComponent") {
                    node = view.state.schema.nodes.embedComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "scanComponent") {
                    node = view.state.schema.nodes.scanComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "snapshotComponent") {
                    node = view.state.schema.nodes.snapshotComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "box") {
                    node = view.state.schema.nodes.box.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "quoteComponent") {
                    node = view.state.schema.nodes.quoteComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "listComponent") {
                    node = view.state.schema.nodes.listComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "tocComponent") {
                    node = view.state.schema.nodes.tocComponent.create({
                        id: uniqueId,
                    });
                } else if (parsedData.type === "codeBlockComponent") {
                    node = view.state.schema.nodes.codeBlockComponent.create({
                        id: uniqueId,
                    });
                }
            }

            // Calculate where to insert the node
            // Set the selection to the drop position
            view.dispatch(view.state.tr.setSelection(TextSelection.near(view.state.doc.resolve(position))));

            if (view.state.doc && (view.state.doc.content as any).content.length > 1) {
                if (isMove) {
                    const removeTransaction = view.state.tr.delete(nodePos, nodePos + node.nodeSize);

                    view.dispatch(removeTransaction);

                    const nodeType = view.state.schema.nodes.placeholder;
                    const placeholderId = uuidv4();
                    const transaction = view.state.tr.insert(
                        nodePos < resolvedPos.pos ? resolvedPos.pos - node.nodeSize : resolvedPos.pos,
                        (nodeType as any).createAndFill({
                            id: placeholderId,
                        })
                    );

                    view.dispatch(transaction);

                    setTimeout(() => {
                        const decoration_transaction = view.state.tr.setMeta("updateDecorations", true);
                        view.dispatch(decoration_transaction);

                        let placeholderNode: any = null;
                        let placeholderPos = -1;

                        view.state.doc.descendants((node, position) => {
                            if (node.attrs.id == placeholderId) {
                                placeholderNode = node;
                                placeholderPos = position;
                                return false;
                            }
                        });

                        const transaction = view.state.tr.replaceWith(
                            placeholderPos,
                            placeholderPos + placeholderNode.nodeSize,
                            node
                        );

                        view.dispatch(transaction);

                        dispatch(
                            setSelectedBlock({
                                type: node.type.name,
                                props: { id: uniqueId, attrs: node.attrs },
                            })
                        );
                    }, 0);
                } else {
                    let isAdded = false;
                    if (resolvedPos.nodeAfter) {
                        if (
                            ["mainHeading", "mainSubtitle", "projectBanner", "publishedDate"].includes(
                                resolvedPos.nodeAfter.attrs.id
                            )
                        ) {
                            return false;
                        } else if (
                            resolvedPos.nodeAfter.type.name === "placeholder" ||
                            resolvedPos.nodeAfter.type.name === "paragraphDiv"
                        ) {
                            const startOfPlaceholder = resolvedPos.pos; // Start position of the Placeholder
                            const endOfPlaceholder = startOfPlaceholder + resolvedPos.nodeAfter.nodeSize; // End position of the Placeholder

                            // Replace the Placeholder node
                            const transaction = view.state.tr.replaceWith(startOfPlaceholder, endOfPlaceholder, node);

                            view.dispatch(transaction);

                            isAdded = true;

                            dispatch(
                                setSelectedBlock({
                                    type: node.type.name,
                                    props: { id: uniqueId, attrs: node.attrs },
                                })
                            );
                        }
                    }

                    if (!isAdded && resolvedPos.nodeBefore) {
                        if (
                            ["mainHeading", "mainSubtitle", "projectBanner", "publishedDate"].includes(
                                resolvedPos.nodeBefore.attrs.id
                            )
                        ) {
                            return false;
                        } else if (
                            resolvedPos.nodeBefore.type.name === "placeholder" ||
                            resolvedPos.nodeBefore.type.name === "paragraphDiv"
                        ) {
                            const startOfPlaceholder = resolvedPos.pos - resolvedPos.nodeBefore.nodeSize; // Start position of the Placeholder
                            const endOfPlaceholder = resolvedPos.pos; // End position of the Placeholder

                            const transaction = view.state.tr.replaceWith(startOfPlaceholder, endOfPlaceholder, node);

                            view.dispatch(transaction);

                            dispatch(
                                setSelectedBlock({
                                    type: node.type.name,
                                    props: { id: uniqueId, attrs: node.attrs },
                                })
                            );
                        }
                    }

                    if (!isAdded) {
                        const nodeType = view.state.schema.nodes.placeholder;
                        const placeholderId = uuidv4();
                        const transaction = view.state.tr.insert(
                            position,
                            (nodeType as any).createAndFill({
                                id: placeholderId,
                            })
                        );

                        view.dispatch(transaction);

                        isAdded = true;
                        setTimeout(() => {
                            const decoration_transaction = view.state.tr.setMeta("updateDecorations", true);
                            view.dispatch(decoration_transaction);

                            let placeholderNode: any = null;

                            view.state.doc.descendants((node) => {
                                if (node.attrs.id == placeholderId) {
                                    placeholderNode = node;
                                    return false;
                                }
                            });

                            const transaction = view.state.tr.replaceWith(
                                resolvedPos.pos,
                                resolvedPos.pos + placeholderNode.nodeSize,
                                node
                            );

                            view.dispatch(transaction);

                            dispatch(
                                setSelectedBlock({
                                    type: node.type.name,
                                    props: { id: uniqueId, attrs: node.attrs },
                                })
                            );
                        }, 0);
                    }
                }
            } else {
                // Create a new transaction
                const transaction = view.state.tr.insert(0, node);

                view.dispatch(transaction);

                dispatch(
                    setSelectedBlock({
                        type: node.type.name,
                        props: { id: uniqueId, attrs: node.attrs },
                    })
                );
            }
        }

        return true;
    }

    return false;
}

const testContent = JSON.stringify({
    type: "doc",
    content: [
        {
            type: "styledTitle",
            attrs: {
                id: "38c0573e-a0ad-4c21-ad83-21dbf6931d9d",
                size: 1,
                color: null,
                alignment: "left",
                content: "Conclusion",
            },
        },
        {
            type: "styledText",
            attrs: {
                id: "22753b8a-388a-4af0-b2b0-dfd4f025f223",
                size: 1,
                color: null,
                alignment: "left",
                content:
                    "Morbi sed imperdiet in ipsum, adipiscing elit dui lectus. Tellus id scelerisque est ultricies ultricies. Duis est sit sed leo nisl, blandit elit sagittis. Quisque tristique consequat quam sed. Nisl at scelerisque amet nulla purus habitasse.\n\nNunc sed faucibus bibendum feugiat sed interdum. Ipsum egestas condimentum mi massa. In tincidunt pharetra consectetur sed duis facilisis metus. Etiam egestas in nec sed et. Quis lobortis at sit dictum eget nibh tortor commodo cursus.\n\nOdio felis sagittis, morbi feugiat tortor vitae feugiat fusce aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id morbi eget ipsum. Aliquam senectus neque ut id eget consectetur dictum. Donec posuere pharetra odio consequat scelerisque et, nunc tortor.\n",
            },
        },
    ],
});

export const templates: any[] = [
    {
        content: `
            <div id="publishedDate" class="published-date" template=0></div>
            <div id="mainHeading" class="heading" custom_class="no-further-block full-width" alignment="center" content="UX review presentations"></div>
            <div id="mainSubtitle" class="styled-text" custom_class="no-further-block full-width" alignment="center" content="How do you create compelling presentations that wow your colleagues and impress your managers? Find out with our in-depth guide on UX presentations."></div>
            <div id="projectBanner" class="project-banner-component" template=0></div>
            
            <div id="divider" class="editor-divider"></div>
            <div id="tabs" class="editor-tabs" template=0></div>
            <div id="scans_or_snapshots" class="scans-snapshots-block"></div>
            <div class="project-links-column"></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" content="Introduction"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.\n\nEget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/a92aa136-7afc-4fec-a846-d12725272c72.png" width=100 justify="left" caption=true caption_str="Image courtesy of Fauxels via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="quote-component" quote_str='"In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear."' alignment="left" caption=true caption_str="Olivia Rhye, Product Designer"></div>

            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Software and tools"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Other resources"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/49777a4e-fc47-4b40-8fe9-1f304a74cd6c.png" width=100 justify="left" caption=true caption_str="Image courtesy of Michael Burrows via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Lectus leo massa amet posuere. Malesuada mattis non convallis quisque. Libero sit et imperdiet bibendum quisque dictum vestibulum in non. Pretium ultricies tempor non est diam. Enim ut enim amet amet integer cursus. Sit ac commodo pretium sed etiam turpis suspendisse at."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Tristique odio senectus nam posuere ornare leo metus, ultricies. Blandit duis ultricies vulputate morbi feugiat cras placerat elit. Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit accumsan. Cursus viverra aenean magna risus elementum faucibus molestie pellentesque. Arcu ultricies sed mauris vestibulum."></div>            
            
            <div id="${uuidv4()}" class="editor-box" color="#F9FAFB" justify="left" width="100" editor_content='${testContent}'></div>
            
            <div id="${uuidv4()}" class="footer-component"></div>
            `,
    },
    {
        content: `
            <div id="publishedDate" class="published-date" template=1></div>
            <div id="mainHeading" class="heading" alignment="center" content="UX review presentations"></div>
            <div id="mainSubtitle" class="styled-text" alignment="center" content="How do you create compelling presentations that wow your colleagues and impress your managers? Find out with our in-depth guide on UX presentations."></div>
            <div id="projectBanner" class="project-banner-component" template=1></div>
            
            <div id="divider" class="editor-divider"></div>
            <div id="tabs" class="editor-tabs" template=1></div>
            <div id="scans_or_snapshots" class="scans-snapshots-block"></div>
            <div class="project-links-column"></div>

            <div id="${uuidv4()}" class="heading" alignment="left" content="Introduction"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.\n\nEget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/a92aa136-7afc-4fec-a846-d12725272c72.png" width=100 justify="left" caption=true caption_str="Image courtesy of Fauxels via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="quote-component" quote_str='"In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear."' alignment="left" caption=true caption_str="Olivia Rhye, Product Designer"></div>

            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.\n\nElit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.\n\nIpsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Software and tools"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Other resources"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/49777a4e-fc47-4b40-8fe9-1f304a74cd6c.png" width=100 justify="left" caption=true caption_str="Image courtesy of Michael Burrows via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Lectus leo massa amet posuere. Malesuada mattis non convallis quisque. Libero sit et imperdiet bibendum quisque dictum vestibulum in non. Pretium ultricies tempor non est diam. Enim ut enim amet amet integer cursus. Sit ac commodo pretium sed etiam turpis suspendisse at."></div>
            
            <div id="${uuidv4()}" class="editor-box" color="#F9FAFB" justify="left" width="100" editor_content='${testContent}'></div>
            
            `,
    },
    {
        content: `
            <div id="tabs" class="editor-tabs" template=2></div>

            <div id="publishedDate" class="published-date" template=2></div>
            <div id="mainHeading" class="heading" alignment="left" content="UX review presentations"></div>
            <div id="mainSubtitle" class="styled-text" alignment="left" content="How do you create compelling presentations that wow your colleagues and impress your managers? Find out with our in-depth guide on UX presentations."></div>
            <div id="projectBanner" class="project-banner-component" template=2></div>
            
            <div id="divider" class="editor-divider"></div>
            <div id="scans_or_snapshots" class="scans-snapshots-block"></div>
            <div class="project-links-column"></div>

            <div id="${uuidv4()}" class="heading" alignment="left" content="Introduction"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id.\n\nEget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/a92aa136-7afc-4fec-a846-d12725272c72.png" width=100 justify="left" caption=true caption_str="Image courtesy of Fauxels via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="quote-component" quote_str='"In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear."' alignment="left" caption=true caption_str="Olivia Rhye, Product Designer"></div>

            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.\n\nElit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus. Sed condimentum enim dignissim adipiscing faucibus consequat, urna. Viverra purus et erat auctor aliquam. Risus, volutpat vulputate posuere purus sit congue convallis aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget nunc lectus in tellus, pharetra, porttitor.\n\nIpsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Software and tools"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id."></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Eget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat."></div>
            
            <div id="${uuidv4()}" class="heading" alignment="left" size=4 content="Other resources"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus dis."></div>
            <div id="${uuidv4()}" class="image-component" type="upload" image="https://xspada-data.nyc3.digitaloceanspaces.com/images/49777a4e-fc47-4b40-8fe9-1f304a74cd6c.png" width=100 justify="left" caption=true caption_str="Image courtesy of Michael Burrows via Pexels"></div>
            <div id="${uuidv4()}" class="styled-text" alignment="left" size=2 content="Lectus leo massa amet posuere. Malesuada mattis non convallis quisque. Libero sit et imperdiet bibendum quisque dictum vestibulum in non. Pretium ultricies tempor non est diam. Enim ut enim amet amet integer cursus. Sit ac commodo pretium sed etiam turpis suspendisse at."></div>
            
            <div id="${uuidv4()}" class="editor-box" color="#F9FAFB" justify="left" width="100" editor_content='${testContent}'></div>
        `,
    },
];

const useEditor = () => {
    const dispatch = useAppDispatch();
    const {
        isSidebarOpen,
        selectedTemplate,
        isSelectingTemplate,
        isReselectingTemplate,
        isReselected,
        isPropertiesSidebarOpen,
        isPreviewOpen,
    } = useAppSelector((state) => ({
        isSidebarOpen: state.publishable_project.isSidebarOpen,
        selectedTemplate: state.publishable_project.selectedTemplate,
        isSelectingTemplate: state.publishable_project.isSelectingTemplate,
        isReselectingTemplate: state.publishable_project.isReselectingTemplate,
        isReselected: state.publishable_project.isReselected,
        isPropertiesSidebarOpen: state.publishable_project.isPropertiesSidebarOpen,
        isPreviewOpen: state.publishable_project.isPreviewOpen,
    }));

    const [isDownloading, setIsDownloading] = useState(true);

    const project = useAppSelector((state) => state.projects.selected);

    const editor = useTipTapEditor({
        extensions: [...editorExtensions, ...extraEditorExtensions],
        content: null,
        // editable: false,
        editorProps: {
            handleDOMEvents: {
                drop: (view, event) => editorDropHandler(dispatch, view, event),
            },
            attributes: {
                id: "root",
            },
        },
    });

    const isDownloadingContent = useMemo(() => {
        return project?.published_file?.url && isDownloading;
    }, [project, isDownloading]);

    const toggleComponentsSidebar = () => {
        dispatch(toggleSidebar());
    };

    const deselectBlock = () => {
        dispatch(setSelectedBlock(null));
    };

    const templateClass = useMemo(() => {
        return " template_" + selectedTemplate;
    }, [selectedTemplate]);

    useEffect(() => {
        if (editor && isSelectingTemplate) {
            if (selectedTemplate >= 0) {
                editor?.commands.setContent(templates[selectedTemplate]["content"]);
            } else {
                editor?.commands.clearContent();
            }
        }
    }, [selectedTemplate, editor, isSelectingTemplate]);

    useEffect(() => {
        if (editor) {
            dispatch(setEditor(editor));
        }
    }, [editor]);

    useEffect(() => {
        const downloadProjectContent = async (url: string) => {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            editor?.commands.setContent(data);

            dispatch(setDefaultContent(JSON.stringify(data)));
            setIsDownloading(false);
            dispatch(closeTemplateSelection());
        };
        if (editor) {
            if (editor && project?.published_file && !isReselectingTemplate && !isReselected) {
                downloadProjectContent(project!.published_file!.url);
            } else if (isSelectingTemplate) {
                dispatch(setSelectedTemplate(0));
            }
        }
    }, [editor, project, isSelectingTemplate, isReselectingTemplate, isReselected]);

    useEffect(() => {}, [isPreviewOpen]);

    return {
        editor,
        isSidebarOpen,
        toggleComponentsSidebar,
        isSelectingTemplate,
        isPropertiesSidebarOpen,
        isPreviewOpen,
        isDownloadingContent,
        templateClass,
        deselectBlock,
    };
};

export default useEditor;
