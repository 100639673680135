import useImages from "assets/images";
import Rating from "react-rating";
import { twMerge } from "tailwind-merge";

interface Props {
    initialRating?: number;
    className?: string;
    onChange?: (value: number) => void;
    readonly?: boolean;
    iconSize?: number;
}

const ThemeRating = (props: Props) => {
    const Images = useImages();

    return (
        // @ts-ignore
        <Rating
            initialRating={props.initialRating || 0}
            onChange={props.onChange}
            readonly={props.readonly || false}
            className={twMerge("flex items-center space-x-5", props.className)}
            emptySymbol={
                <Images.StarIcon
                    fill="#ffffff"
                    color="#7680FF"
                    height={props.iconSize || 43}
                    width={props.iconSize || 43}
                />
            }
            fullSymbol={
                <Images.StarFilledIcon color="#7680FF" height={props.iconSize || 43} width={props.iconSize || 43} />
            }
        />
    );
};

export default ThemeRating;
