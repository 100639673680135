import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { CommonModalState } from "store/slices/commonModal";
import CommonModalActionButton from "../../CommonModal/partials/actionButton";

export interface ThemeModalProps extends CommonModalState {
    open: boolean;
    onCloseModal?: () => void;
}

const ThemeModal = (props: ThemeModalProps) => {
    const {
        icon,
        title,
        subTitle,
        content,
        contentComponent,
        children,
        contentProps,
        dialogueActionClassName,
        buttons,
        maxWidth = "xs",
        cancelBtnProps = {},
        onCloseModal,
        open,
        ...rest
    } = props;

    return (
        <Dialog maxWidth={maxWidth} open={open} {...rest}>
            {(icon || title || subTitle) && <ThemeModalHeader icon={icon} title={title} subTitle={subTitle} />}
            <DialogContent {...contentProps}>
                <div className="text-[#667085] mt-1 text-md font-thin flex flex-col space-y-5">
                    {content && <div>{content}</div>}
                    {contentComponent && <>{contentComponent}</>}
                    {children && <div>{children}</div>}
                </div>
            </DialogContent>
            {buttons?.length ? (
                <CommonModalActionButton
                    buttons={buttons || []}
                    className={dialogueActionClassName}
                    cancelBtnProps={{
                        ...cancelBtnProps,
                        onClick: (e) => {
                            onCloseModal && onCloseModal();
                            cancelBtnProps.onClick?.(e);
                        },
                    }}
                />
            ) : (
                <></>
            )}
        </Dialog>
    );
};

export default ThemeModal;

export const ThemeModalHeader = (props: Pick<ThemeModalProps, "titleProps" | "title" | "subTitle" | "icon">) => {
    return (
        <DialogTitle className="!pb-0 !pt-6" {...props.titleProps}>
            {props.icon && <div className="mb-3 flex items-center justify-center">{props.icon}</div>}
            {props.title && <div className="font-semibold text-[#3F3E3E] text-xl">{props.title}</div>}
            {props.subTitle && <div className="font-normal text-[#667085] text-md mt-3 mb-2">{props.subTitle}</div>}
        </DialogTitle>
    );
};
