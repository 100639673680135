import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { File } from "shared/graphql";

type UploadStatus = "idle" | "uploading" | "success" | "error";

interface Props {
    uploads: {
        [key: string]: {
            status: UploadStatus;
            progress: number;
            error?: any;
            data?: File | null;
        };
    };
    loading: boolean;
    loader_id?: string;
}

const initialState: Props = {
    uploads: {},
    loading: false,
};

const fileUpload = createSlice({
    name: "fileUpload",
    initialState: initialState,
    reducers: {
        setAddUploadFile: (state, action: PayloadAction<string>) => {
            state.uploads[action.payload] = {
                status: "idle",
                progress: 0,
            };
        },
        setUploadFileData: (
            state,
            action: PayloadAction<{
                id: string;
                progress?: number;
                status?: UploadStatus;
                error?: any;
                data?: File | null;
            }>
        ) => {
            const { id, progress } = action.payload;
            state.uploads[id].progress = progress || state.uploads[id]?.progress || 0;
            state.uploads[id].status = action.payload.status || state.uploads[id].status;
            state.uploads[id].data = action.payload.data || state.uploads[id].data;
            state.uploads[id].error = action.payload.error || state.uploads[id].error;
        },
        setUploadFileLoading: (state, action: PayloadAction<{ loading: boolean; loader_id?: string }>) => {
            state.loading = action.payload.loading;
            state.loader_id = action.payload.loader_id;
        },
    },
});

export const { setAddUploadFile, setUploadFileData, setUploadFileLoading } = fileUpload.actions;

export default fileUpload.reducer;
