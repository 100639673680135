import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { VerifyForgotPasswordQuery, useResetUserPasswordMutation, useVerifyForgotPasswordQuery } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    password: yup.string().required(),
    cPassword: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Password doesn't match"),
    token: yup.string().required(),
    id: yup.number().required(),
});

type FormValues = yup.InferType<typeof schema>;

const useResetPasswordScreen = () => {
    const [doReset, resetRes] = useResetUserPasswordMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = parseInt(params.get("id") || "0");
    const token = params.get("token");

    const [email, setEmail] = useState("");

    const verifyRes = useVerifyForgotPasswordQuery({
        data: {
            id,
            token: token ?? "",
        },
    });

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            cPassword: "",
            password: "",
            id,
            token: token || "",
        },
    });

    const onSubmit = (data: FormValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { cPassword, ...rest } = data;
        doReset({
            data: rest,
        });
    };

    useEffect(() => {
        if (!id || !token) {
            errorToast("Invalid Path");
            navigate(URL.Login, {
                replace: true,
            });
        }
    }, [id, token]);

    useEffect(() => {
        rtkHandler(resetRes, {
            setError: form.setError,
            successMessage: "Password reset successfully",
            onSuccess() {
                navigate(URL.Login, {
                    replace: true,
                });
            },
        });
    }, [resetRes, navigate, dispatch]);

    useEffect(() => {
        rtkHandler(verifyRes, {
            onSuccess({ verify_forgot_password }: VerifyForgotPasswordQuery) {
                setEmail(verify_forgot_password.email);
            },
            onError() {
                navigate(URL.Login, {
                    replace: true,
                });
            },
        });
    }, [verifyRes]);

    return { email, isLoading: resetRes.isLoading, isVerifyingLink: verifyRes.isLoading, form, onSubmit };
};

export default useResetPasswordScreen;
