import { useSearchParams } from "react-router-dom";
import SocialMediaHandler from "./partials/SocialMediaHandler";
import TeamMembersSection from "./partials/TeamMembers";
import UpdateTeamDetails from "./partials/UpdateTeamDetails";
import { useEffect } from "react";

const TeamScreens = () => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    useEffect(() => {
        if (tab === "members") {
            setTimeout(() => {
                const element = document.getElementById("team-members-section");
                element?.scrollIntoView({ behavior: "smooth" });
            }, 500);
        }
    }, [tab]);

    return (
        <>
            <UpdateTeamDetails />
            <SocialMediaHandler />
            <TeamMembersSection />
        </>
    );
};

export default TeamScreens;
