import { yupResolver } from "@hookform/resolvers/yup";
import { Skeleton } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import StatisticCard from "shared/components/StatisticCard";
import Field from "shared/components/ui/Field/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { GetProfilesQuery, useTransferOwnershipMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useGetTeamMemberApi from "shared/hooks/useGetTeamMemberApi";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";
import yup from "shared/utils/yup";
import { setCloseCommonModal, setCommonModal, setCommonModalLoadingBtn } from "store/slices/commonModal";
import { twMerge } from "tailwind-merge";

const TransferOwnership = (props: { account: GetProfilesQuery["profiles"][0]; disabled: boolean }) => {
    const Images = useImages();
    const dispatch = useAppDispatch();

    const handleTransfer = () => {
        dispatch(
            setCommonModal({
                icon: <Images.CheckedUserLayerIcon />,
                title: "Transfer Ownership",
                content: "You will no longer be the admin of this profile after transferring ownership",
                contentComponent: <TransferOwnershipContent account={props.account} />,
            })
        );
    };

    return (
        <StatisticCard
            onClick={props.disabled ? undefined : handleTransfer}
            tooltipProps={{
                title: props.disabled ? "Please switch profile to perform this action." : "",
            }}
            classes={{
                wrapper: twMerge(
                    "p-4 bg-[#F2F3FD] border-[#C8CCFF] border rounded-md cursor-pointer",
                    props.disabled ? "opacity-50 cursor-not-allowed" : ""
                ),
            }}
            title="Team Ownership"
            value="You will no longer be the admin of this profile after transferring ownership."
            icon={<Images.UserLayerIcon height={32} width={32} />}
        />
    );
};

export default TransferOwnership;

const schema = yup.object().shape({
    team_member_id: yup.number().required(),
});

type FormValues = yup.InferType<typeof schema>;

const TransferOwnershipContent = (props: { account: GetProfilesQuery["profiles"][0] }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { members, isLoading } = useGetTeamMemberApi();
    const [transferOnwerShip, transferRes] = useTransferOwnershipMutation();

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(transferRes, {
            successMessage: "Ownership transferred successfully",
            setError: form.setError,
            onSuccess() {
                dispatch(setCloseCommonModal());
            },
        });
        dispatch(setCommonModalLoadingBtn(transferRes.isLoading ? "transfer-team-button" : ""));
    }, [transferRes]);

    const onSubmit = (data: FormValues) => {
        if (!props.account.member?.team_id) {
            errorToast("You are not the owner of this team.");
            return;
        }

        transferOnwerShip({
            member_id: data.team_member_id,
        });
    };

    return (
        <ThemeFormProvider form={form} className="text-left" onSubmit={onSubmit}>
            {isLoading ? (
                <Skeleton height={70} />
            ) : (
                <Field
                    label={"Team Member"}
                    type="select"
                    options={members
                        .filter((item) => (user ? item.user?.user_id !== user.user_id : false))
                        .map((item) => {
                            return {
                                label: item.user?.name || "",
                                value: item.member_id.toString(),
                            };
                        })}
                    selectFieldProps={{
                        noOptionsText: "No users found",
                    }}
                    name="team_member_id"
                    placeholder="Select Team Member"
                />
            )}
            <CommonModalActionButton
                cancelBtnProps={{
                    onClick: () => {
                        dispatch(setCloseCommonModal());
                    },
                }}
                buttons={[
                    {
                        children: "Transfer",
                        color: "primary",
                        id: "transfer-team-button",
                        type: "submit",
                    },
                ]}
            />
        </ThemeFormProvider>
    );
};
