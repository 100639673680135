import { pick } from "lodash";
import { useEffect, useState } from "react";
import { GetProfilesQuery, Profile, useGetProfilesQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const useGetProfileApi = ({ skip = false }: { skip?: boolean }) => {
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const profileRes = useGetProfilesQuery(undefined, {
        skip,
    });

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess(response: GetProfilesQuery) {
                setProfiles(response.profiles as Profile[]);
            },
        });
    }, [profileRes]);

    return { profiles, ...pick(profileRes, ["isLoading", "isFetching", "refetch"]) };
};

export default useGetProfileApi;
