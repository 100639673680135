import { Autocomplete, TextField } from "@mui/material";
import { IGeocoderProps } from "./type";
import useGeocoder from "./hook";
import useImages from "assets/images";

const Geocoder = (props: IGeocoderProps) => {
    const { value, onChange, suggestions } = useGeocoder(props);
    const Images = useImages();

    return (
        <div className="w-full absolute flex items-center px-3 py-2 !pr-14">
            <div className="w-full bg-white shadow-sm rounded-md">
                <Autocomplete
                    popupIcon={<Images.SearchIcon height={20} width={20} />}
                    sx={{
                        ".MuiSelect-select": {
                            color: value ? "currentColor" : "#dddddd",
                            fontWeight: "normal",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px !important",
                            borderRadius: "0px !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px !important",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                            backgroundColor: "white !important",
                            borderRadius: "100%",
                        },
                        "& .MuiAutocomplete-clearIndicator:hover": {
                            filter: "brightness(95%)",
                            opacity: 0.9,
                        },
                        "& .MuiAutocomplete-popupIndicator": {
                            transform: "rotate(360deg) !important",
                        },
                    }}
                    options={suggestions}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            props.onLocationSelect(newValue);
                        }
                    }}
                    getOptionLabel={(option: any) => {
                        return option.place_name;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search location here..."
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    )}
                    renderOption={(props, option: any) => <li {...props}>{option.place_name}</li>}
                    isOptionEqualToValue={(option, value) => {
                        return option.place_name == value;
                    }}
                />
            </div>
        </div>
    );
};

export default Geocoder;
