import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    ChangeRegisterEmailMutation,
    useChangeRegisterEmailMutation,
    useResendSignupEmailMutation,
    ResendSignupEmailMutation,
} from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setVerificationData } from "store/slices/verifyUser";

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

type FormValues = yup.InferType<typeof schema>;

const useRegisterSuccess = () => {
    const dispatch = useAppDispatch();
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const { email, email_verification_id } = useAppSelector((state) => state.verifyUser.verificationData);
    const [changeEmail, res] = useChangeRegisterEmailMutation();
    const [resendEmail, emailRes] = useResendSignupEmailMutation();
    const [doRefresh, setDoRefresh] = useState(false);

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    useEffect(() => {
        rtkHandler(res, {
            onSuccess(response: ChangeRegisterEmailMutation) {
                setOpenEmailModal(false);
                setDoRefresh(true);
                dispatch(
                    setVerificationData({
                        email: response.change_signup_email.data,
                        email_verification_id: response.change_signup_email.verification_id,
                    })
                );
            },
        });
    }, [res]);

    useEffect(() => {
        rtkHandler(emailRes, {
            onSuccess(response: ResendSignupEmailMutation) {
                setOpenEmailModal(false);
                dispatch(
                    setVerificationData({
                        email: response.resend_signup_email.data,
                        email_verification_id: response.resend_signup_email.verification_id,
                    })
                );
            },
        });
    }, [emailRes]);

    const onSubmit = (values: FormValues) => {
        if (email_verification_id)
            changeEmail({
                email,
                new_email: values.email,
                email_verification_id,
            });
    };

    const resendEmailOtp = async () => {
        return resendEmail({ email })
            .then(() => true)
            .catch(() => false);
    };

    return {
        email,
        email_verification_id,
        form,
        res,
        emailRes,
        openEmailModal,
        doRefresh,
        setOpenEmailModal,
        onSubmit,
        resendEmailOtp,
    };
};

export default useRegisterSuccess;
