import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import ThemeModal from "shared/components/ui/ThemeModal";
import { RequestTeamMutation } from "shared/graphql";
import { successToast } from "shared/utils/toast";
import AccountPostModalContent, { AccountTabSteps } from "./content";
import CreateTeamAccountModal from "./partials/CreateTeamModal";
import OpenStarterAccountModal from "./partials/OpenStarterAccountModal";
import JoinTeamModalContent from "./partials/JoinTeamModalContent";
import { useSearchParams } from "react-router-dom";

const AccountPostModal = () => {
    const Images = useImages();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState<AccountTabSteps | "">("");
    const [searchParams] = useSearchParams();
    const action = searchParams.get("action");

    useEffect(() => {
        if (action === "join") {
            setOpenModal("join");
        } else if (action === "new") {
            setOpenModal("new");
        }
    }, [action]);

    const onSuccessJoinTeam = (data: RequestTeamMutation["request_team"]) => {
        successToast(
            `Your request to join team ${data.name} has been submitted successfully. You will be notified once your request is accepted by the team admins.`,
            {
                duration: 3000,
            }
        );
        triggerModal();
    };

    const triggerModal = (modalKey?: AccountTabSteps | "") => {
        setOpenModal(modalKey ?? "");
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="contained">
                Add Account
            </Button>
            <ThemeModal
                icon={<Images.PlusLayerIcon />}
                classes={{
                    paper: "min-w-[480px]",
                }}
                title="Add Profile to your Account"
                content="Manage and add your different profiles from here."
                open={open}
                onClose={() => setOpen(false)}
                contentComponent={
                    <AccountPostModalContent
                        onClose={() => setOpen(false)}
                        onClickNext={(key: AccountTabSteps | "") => triggerModal(key)}
                    />
                }
            />
            <ThemeModal
                icon={<Images.UserLayerIcon height={50} width={50} />}
                open={openModal === "community"}
                onClose={() => triggerModal()}
                title="Community Profile"
                content="A community profile is independent of any team and helps create personalized content."
                contentComponent={<OpenStarterAccountModal onCloseModal={() => triggerModal()} />}
            />

            <ThemeModal
                icon={<Images.TeamLayerIcon height={50} width={50} />}
                open={openModal === "new"}
                onClose={() => triggerModal()}
                title="Create a New Team"
                content="With a new team, you can invite people and collaborate on your scans."
                contentComponent={<CreateTeamAccountModal onClose={() => triggerModal()} />}
            />

            <ThemeModal
                icon={<Images.JoinTeamLayerIcon height={50} width={50} />}
                open={openModal === "join"}
                onClose={() => triggerModal()}
                title="Join Another Team"
                content="You can join another team to collaborate on their scans using team invite codes."
                contentComponent={<JoinTeamModalContent onClose={() => triggerModal()} onSuccess={onSuccessJoinTeam} />}
            />
        </>
    );
};

export default AccountPostModal;
