import { useAppSelector } from "shared/hooks/useRedux";
import ComponentsSidebar from "./partials/ComponentsSidebar/ComponentsSidebar";
import Editor from "./partials/Editor/Editor";
import TemplatesSidebar from "./partials/TemplatesSidebar/TemplatesSidebar";

const PublishableProjectScreen = () => {
    const { isSelectingTemplate, isSidebarOpen } = useAppSelector((state) => ({
        isSelectingTemplate: state.publishable_project.isSelectingTemplate,
        isSidebarOpen: state.publishable_project.isSidebarOpen,
        isPropertiesSidebarOpen: state.publishable_project.isPropertiesSidebarOpen,
    }));

    return (
        <div className="h-full w-full flex items-center gap-x-4">
            {isSelectingTemplate ? <TemplatesSidebar /> : isSidebarOpen && <ComponentsSidebar />}
            <Editor />
        </div>
    );
};

export default PublishableProjectScreen;
