import { Outlet, useMatch, useParams } from "react-router-dom";

import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";

import SRLists from "../../common/components/SRLists";

const SRNewScreen = () => {
    const { type } = useParams<{ type: string }>();

    const isAlone = useMatch(`/requests/${type}`);

    return (
        <div className="grid lg:grid-cols-[440px,1fr] grid-cols-[300px,1fr]">
            <SRLists type="new" />
            {isAlone ? (
                <ThemeScreenCard>
                    <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                        <p className="text-p text-center text-md">
                            Select a project request from selector menu to see its details.
                        </p>
                    </div>
                </ThemeScreenCard>
            ) : (
                <Outlet />
            )}
        </div>
    );
};

export default SRNewScreen;
