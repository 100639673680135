import { createContext, useContext, useEffect, useState } from "react";

import { ThemeListCardProps } from ".";

const ThemeListCardContext = createContext({});

interface ThemeListCardContextProps<T> extends ThemeListCardProps<T> {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    isSearchEnabled: boolean;
    setIsSearchEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const useThemeListCardContext = <T,>() => {
    return useContext(ThemeListCardContext) as ThemeListCardContextProps<T>;
};

const ThemeListCardContextProvider = <T,>(props: { value: ThemeListCardProps<T>; children: React.ReactNode }) => {
    const [search, setSearch] = useState("");
    const [isSearchEnabled, setIsSearchEnabled] = useState(false);

    useEffect(() => {
        props.value.options?.onSearch && props.value.options.onSearch(search);
    }, [search]);

    return (
        <ThemeListCardContext.Provider
            value={{
                ...props.value,
                search,
                isSearchEnabled,
                setSearch,
                setIsSearchEnabled,
            }}
        >
            {props.children}
        </ThemeListCardContext.Provider>
    );
};

export { ThemeListCardContextProvider, useThemeListCardContext };
