import useImages from "assets/images";
import React, { useRef } from "react";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { UppyFileUploaderPickerProps } from "..";

const LocalPicker = (props: UppyFileUploaderPickerProps) => {
    const file_ref = useRef<HTMLInputElement>(null);
    const Images = useImages();

    return (
        <div>
            <ThemeIconButton
                tooltipTitle="Local"
                tooltipPosition="top"
                icon={<LayerIcon icon={Images.UploadIcon} iconColor="#475467" color="default" />}
                onClick={() => {
                    file_ref.current?.click();
                }}
            />
            <input
                type="file"
                ref={file_ref}
                accept={props.type === "zip" ? ".zip" : props.type === "video" ? "video/*" : "image/*"}
                className="hidden"
                value={""}
                onChange={(e) => {
                    if (e.target.files) {
                        const file = e.target.files[0];
                        props.onSelectFile &&
                            props.onSelectFile({
                                name: file.name,
                                size: file.size,
                                data: file,
                                pickerType: "local",
                            });
                    }
                }}
            />
        </div>
    );
};

export default LocalPicker;
