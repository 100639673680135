import LoginBannerLightMode from "./banners/Auth-Screen-Banner.png";
import RegisterBannerLightMode from "./banners/iPhone-13-Banner.png";
import LinePatternLightMode from "./banners/line-pattern.png";
import { ReactComponent as AddUserIcon } from "./icons/Add-User-Icon.svg";
import { ReactComponent as AddVideoCameraIcon } from "./icons/Add-Video-Camera-Icon.svg";
import { ReactComponent as AppleIconLightMode } from "./icons/Apple-Icon.svg";
import { ReactComponent as ArrowBlueRightIconLightMode } from "./icons/Arrow-Blue-Right.svg";
import { ReactComponent as ArrowChevronLeftLightMode } from "./icons/Arrow-Chevron-Left.svg";
import { ReactComponent as ArrowChevronRightLightMode } from "./icons/Arrow-Chevron-Right.svg";
import { ReactComponent as ArrowDownBlueCircleLightMode } from "./icons/Arrow-Down-Blue-Circle.svg";
// Icon
import { ReactComponent as ArrowDownBlueLightMode } from "./icons/Arrow-Down-Blue.svg";
import { ReactComponent as ArrowDownCircleLightMode } from "./icons/Arrow-Down-Circle.svg";
import { ReactComponent as ArrowDownSquareLightMode } from "./icons/Arrow-Down-Square.svg";
import { ReactComponent as ArrowDownLightMode } from "./icons/Arrow-Down.svg";
import { ReactComponent as ArrowLeftNavLightMode } from "./icons/Arrow-Left-Nav.svg";
import { ReactComponent as ArrowRightNavLightMode } from "./icons/Arrow-Right-Nav.svg";
import { ReactComponent as ArrowRightPrimaryLightMode } from "./icons/Arrow-Right-Primary.svg";
import { ReactComponent as ArrowRightWhiteLightMode } from "./icons/Arrow-Right.svg";
import { ReactComponent as ArrowUpRightLightMode } from "./icons/Arrow-Up-Right.svg";
import { ReactComponent as ArrowWhiteUpRightLightMode } from "./icons/Arrow-White-Up-Right.svg";
import { ReactComponent as ArticleLayerIconLightMode } from "./icons/Article-Layer-Icon.svg";
import { ReactComponent as BoltIconLightMode } from "./icons/Bolt-Icon.svg";
import { ReactComponent as BookmarkIcon } from "./icons/Bookmark-Icon.svg";
import { ReactComponent as BreadcrumbsSperatorLightMode } from "./icons/Breadcrumbs-Sperator.svg";
import { ReactComponent as BroadcastLayerIconLightMode } from "./icons/Broadcast-Layer-Icon.svg";
import { ReactComponent as CalenderIconLightMode } from "./icons/Calender-Icon.svg";
import { ReactComponent as CameraIcon } from "./icons/Camera-Icon.svg";
import { ReactComponent as CheckCircleIconLightMode } from "./icons/Check-Circle.svg";
import { ReactComponent as CheckSuccessFilledIcon } from "./icons/Check-Success-Filled-Icon.svg";
import { ReactComponent as CheckboxActiveLightMode } from "./icons/Checkbox-Active.svg";
import { ReactComponent as CheckCheckboxCircleIcon } from "./icons/Checkbox-Circle-Icon-Check.svg";
import { ReactComponent as UncheckCheckboxCircleIcon } from "./icons/Checkbox-Circle-Icon-Uncheck.svg";
import { ReactComponent as CheckboxInActiveLightMode } from "./icons/Checkbox-InActive.svg";
import { ReactComponent as CircleCheckEmpty } from "./icons/Circle-Check-Empty.svg";
import { ReactComponent as CircleCheckFilled } from "./icons/Circle-Check-Filled.svg";
import { ReactComponent as CloseIconLightMode } from "./icons/Close-Icon.svg";
import { ReactComponent as CompassIcon } from "./icons/Compass-Icon.svg";
import { ReactComponent as ContextMenuGrayIconLightMode } from "./icons/Context-Menu-Gray-Icon.svg";
import { ReactComponent as ContextMenuHorizontalGrayIconLightMode } from "./icons/Context-Menu-Horizontal-Gray-Icon.svg";
import { ReactComponent as ContextMenuIconLightMode } from "./icons/Context-Menu-Icon.svg";
import { ReactComponent as CopyGrayIconLightMode } from "./icons/Copy-Gray-Icon.svg";
import { ReactComponent as CopyIconLightMode } from "./icons/Copy-Icon.svg";
import { ReactComponent as CrossCircleIconLightMode } from "./icons/Cross-Circle-Icon.svg";
import { ReactComponent as CubeBlueIconLightMode } from "./icons/Cube-Blue-Icon.svg";
import { ReactComponent as DangerTriangleIconLightMode } from "./icons/Danger-Triangle-Icon.svg";
import { ReactComponent as DeleteBlueLightMode } from "./icons/Delete-Blue.svg";
import { ReactComponent as DeleteLayerIconLightMode } from "./icons/Delete-Layer-Icon.svg";
import { ReactComponent as DeleteRoundedIconLightMode } from "./icons/Delete-Rounded-Icon.svg";
import { ReactComponent as DeleteLightMode } from "./icons/Delete.svg";
import { ReactComponent as DocumentIconLightMode } from "./icons/Document-Icon.svg";
import { ReactComponent as DownloadIconV2LightMode } from "./icons/Download-Icon-V2.svg";
import { ReactComponent as DownloadLightMode } from "./icons/Download.svg";
import { ReactComponent as DroneLayerIcon } from "./icons/Drone-Layer-Icon.svg";
import { ReactComponent as DropboxIcon } from "./icons/Dropbox-Layer-Icon.svg";
import { ReactComponent as DropdownUpDownArrowActiveIconLightMode } from "./icons/Dropdown-Up-Down-Arrow-Active-Icon.svg";
import { ReactComponent as DropdownUpDownArrowInactiveIconLightMode } from "./icons/Dropdown-Up-Down-Arrow-Inactive-Icon.svg";
import { ReactComponent as EditSquareLightMode } from "./icons/Edit Square.svg";
import { ReactComponent as EllipsisIconLightMode } from "./icons/Ellipsis-Icon.svg";
import { ReactComponent as EmailIconLightMode } from "./icons/Email-Icon.svg";
import { ReactComponent as EmailLayerIconLightMode } from "./icons/Email-Layer-Icon.svg";
import { ReactComponent as EmbedIconLightMode } from "./icons/Embed-Icon.svg";
import { ReactComponent as EqualIcon } from "./icons/Equal-Icon.svg";
import { ReactComponent as EverythingLayerIconLightMode } from "./icons/Everything-Layer-Icon.svg";
import { ReactComponent as ExclamationLayerIconLightMode } from "./icons/Exclamation-Layer-Icon.svg";
import { ReactComponent as FacebookIconLightMode } from "./icons/Facebook-Icon.svg";
import { ReactComponent as FileUploadIconLightMode } from "./icons/File-Upload-Icon.svg";
import { ReactComponent as UploadIcon } from "./icons/Upload.svg";
import { ReactComponent as FilterIconLightMode } from "./icons/Filters.svg";
import { ReactComponent as FireLikeActiveIconLightMode } from "./icons/Fire-Like-Icon-Active.svg";
import { ReactComponent as FireLikeIconLightMode } from "./icons/Fire-Like-Icon.svg";
import { ReactComponent as FlagActiveIconLightMode } from "./icons/Flag-Icon-Active.svg";
import { ReactComponent as FlagIconLightMode } from "./icons/Flag-Icon.svg";
import { ReactComponent as FolderLayerIconLightMode } from "./icons/Folder-Layer-Icon.svg";
import { ReactComponent as FolderLightMode } from "./icons/Folder.svg";
import { ReactComponent as GoogleDriveIcon } from "./icons/Google-Drive-Layer-Icon.svg";
import { ReactComponent as GoogleIconLightMode } from "./icons/Google-Icon.svg";
import { ReactComponent as GreenTickLayerIconLightMode } from "./icons/Green-Tick-Layer-Icon.svg";
import { ReactComponent as HelpCircleIconLightMode } from "./icons/Help-Circle-Icon.svg";
import { ReactComponent as HomeInActiveLightMode } from "./icons/Home-InActive.svg";
import { ReactComponent as HomeLightMode } from "./icons/Home.svg";
import { ReactComponent as ImageDarkLightMode } from "./icons/Image-Dark.svg";
import { ReactComponent as ImageGrayLightMode } from "./icons/Image-Gray.svg";
import { ReactComponent as ImageLightMode } from "./icons/Image.svg";
import { ReactComponent as InboxIcon } from "./icons/Inbox-Icon.svg";
import { ReactComponent as InfoCircleIconLightMode } from "./icons/Info-Circle-Icon.svg";
import { ReactComponent as InfoCircleStatusIconLightMode } from "./icons/Info-Circle-Status-Icon.svg";
import { ReactComponent as JoinTeamLayerIcon } from "./icons/Join-Team-Layer-Icon.svg";
import { ReactComponent as KeyLayerIconLightMode } from "./icons/Key-Layered-Icon.svg";
import { ReactComponent as LocationIconLightMode } from "./icons/Location-Icon.svg";
import { ReactComponent as LocationLayerIcon } from "./icons/Location-Layer-Icon.svg";
import { ReactComponent as LockIcon } from "./icons/Lock-Icon.svg";
import { ReactComponent as LogoutIconLightMode } from "./icons/Logout-Icon.svg";
import { ReactComponent as LogoutLayerIconLightMode } from "./icons/Logout-Layer-Icon.svg";
import { ReactComponent as LongRightArrowLightMode } from "./icons/Long-Arrow-Right.svg";
import { ReactComponent as LongLeftArrowIconLightMode } from "./icons/Long-Left-Arrow.svg";
import { ReactComponent as MessageSquareIconLightMode } from "./icons/Message-Square-Icon.svg";
import { ReactComponent as MobileLayerIcon } from "./icons/Mobile-Layer-Icon.svg";
import { ReactComponent as ModelLayerIcon } from "./icons/Model-Layer-Icon.svg";
import { ReactComponent as NotificationLayerIcon } from "./icons/Notification-Layer-Icon.svg";
import { ReactComponent as NotificationLightMode } from "./icons/Notification.svg";
import { ReactComponent as NotifyAllIcon } from "./icons/Notify-All-Icon.svg";
import { ReactComponent as NotifyAllLayerIcon } from "./icons/Notify-All-Layer-Icon.svg";
import { ReactComponent as PauseIconLightMode } from "./icons/Pause-Icon.svg";
import { ReactComponent as PencilIconLightMode } from "./icons/Pencil-Icon.svg";
import { ReactComponent as PhoneIcon } from "./icons/Phone-Icon.svg";
import { ReactComponent as PhoneLayerIconLightMode } from "./icons/Phone-Layers-Icon.svg";
import { ReactComponent as PlayBlueCircleIconLightMode } from "./icons/Play-Blue-Circle-Icon.svg";
import { ReactComponent as PlayCircleIconLightMode } from "./icons/Play-Circle-Icon.svg";
import { ReactComponent as PlayButtonLightMode } from "./icons/PlayButton.svg";
import { ReactComponent as PlayListLightMode } from "./icons/PlayList.svg";
import { ReactComponent as PlusGreyLightMode } from "./icons/Plus-Grey.svg";
import { ReactComponent as PlusLayerIconLightMode } from "./icons/Plus-Layer-Icon.svg";
import { ReactComponent as PlusSquareLayerIconLightMode } from "./icons/Plus-Square-Layer-Icon.svg";
import { ReactComponent as PlusWhiteLightMode } from "./icons/Plus-White.svg";
import { ReactComponent as PrivateProfileLightMode } from "./icons/PrivateProfile.svg";
import { ReactComponent as ProfileAvatarIconLightMode } from "./icons/Profile-Avatar.svg";
import { ReactComponent as ProfileLightMode } from "./icons/Profile.svg";
import { ReactComponent as QuestionMarkCircleIconLightMode } from "./icons/Quesiton-Mark-Circle-Icon.svg";
import { ReactComponent as ResearchIconLightMode } from "./icons/Research-Icon.svg";
import { ReactComponent as ReturnLightMode } from "./icons/Return.svg";
import { ReactComponent as RunIconLightMode } from "./icons/Run-Icon.svg";
import { ReactComponent as ScanIcon } from "./icons/Scan.svg";
import { ReactComponent as SearchIconLightMode } from "./icons/Search-Icon.svg";
import { ReactComponent as SelectArrowAscendLightMode } from "./icons/Select-Arrow-Ascend.svg";
import { ReactComponent as SelectArrowDescendLightMode } from "./icons/Select-Arrow-Descend.svg";
import { ReactComponent as SelectArrowLightMode } from "./icons/Select-Arrow.svg";
import { ReactComponent as SendInviteLightMode } from "./icons/SendInvite.svg";
import { ReactComponent as SettingDarkIconLightMode } from "./icons/Setting-Dark-Icon.svg";
import { ReactComponent as ShareIconLightMode } from "./icons/Share-Icon.svg";
import { ReactComponent as Share2 } from "./icons/Share.svg";
import { ReactComponent as SharingLayerIcon } from "./icons/Sharing-Layer-Icon.svg";
import { ReactComponent as ShowLightMode } from "./icons/Show.svg";
import { ReactComponent as ShareLayerIcon } from "./icons/Share-Layer-Icon.svg";
// Sidebar Icons
import { ReactComponent as SmileyFaceIconLightMode } from "./icons/Smiley-Face.svg";
import { ReactComponent as FacebookOutlinedIcon } from "./icons/social/Facebook-Outlined-Icon.svg";
import { ReactComponent as InstagramOutlinedIcon } from "./icons/social/Instagram-Outlined-Icon.svg";
import { ReactComponent as LinkedinOutlinedIcon } from "./icons/social/Linkedin-Outlined-Icon.svg";
import { ReactComponent as SocialEmailIcon } from "./icons/social/social-email-icon.svg";
import { ReactComponent as Skype } from "./icons/Skype.svg";
import { ReactComponent as Github } from "./icons/Github.svg";
import { ReactComponent as Youtube } from "./icons/Youtube.svg";
import { ReactComponent as X } from "./icons/X.svg";
import { ReactComponent as Zap } from "./icons/Zap.svg";
import { ReactComponent as FolderOutlined } from "./icons/FolderOutlined.svg";
import { ReactComponent as DatabaseCoinOutlined } from "./icons/DatabaseCoinOutlined.svg";
import { ReactComponent as DatabaseOutlined } from "./icons/DatabaseOutlined.svg";
// Social Icons
import { ReactComponent as SocialFacebookIcon } from "./icons/social/social-facebook-icon.svg";
import { ReactComponent as SocialInstagramIcon } from "./icons/social/social-instagram-icon.svg";
import { ReactComponent as SocialLinkedInIcon } from "./icons/social/social-linkedin-icon.svg";
import { ReactComponent as SocialMessengerIcon } from "./icons/social/social-messenger-icon.svg";
import { ReactComponent as SocialPinterestIcon } from "./icons/social/social-pinterest-icon.svg";
import { ReactComponent as SocialRedditIcon } from "./icons/social/social-reddit-icon.svg";
import { ReactComponent as SocialTelegramIcon } from "./icons/social/social-telegram-icon.svg";
import { ReactComponent as SocialTikTokIcon } from "./icons/social/social-tiktok-icon.svg";
import { ReactComponent as SocialNewTwitterIcon } from "./icons/social/social-new-twitter-icon.svg";
import { ReactComponent as SocialTwitterIcon } from "./icons/social/social-twitter-icon.svg";
import { ReactComponent as SocialWhatsappIcon } from "./icons/social/social-whatsapp-icon.svg";
import { ReactComponent as TwitterOutlinedIcon } from "./icons/social/Twitter-Outlined-Icon.svg";
import { ReactComponent as SquareCheckLayerIcon } from "./icons/Square-Check-Layer-Icon.svg";
import { ReactComponent as SquarePlusIconFilled } from "./icons/Square-Plus-Icon.svg";
import { ReactComponent as SquarePlusIconLightMode } from "./icons/Square-Plus.svg";
import { ReactComponent as SquareScanIcon } from "./icons/Square-Scan-Icon.svg";
import { ReactComponent as StarIconRoundedActiveLightMode } from "./icons/Star-Icon-Active.svg";
import { ReactComponent as StarFilledIconLightMode } from "./icons/Star-Icon-Rounded.svg";
import { ReactComponent as StarIconLightMode } from "./icons/Star-Icon.svg";
import { ReactComponent as SuccessLayerIconLightMode } from "./icons/Success-Layer-Icon.svg";
import { ReactComponent as TabletLayerIcon } from "./icons/Tablet-Layer-Icon.svg";
import { ReactComponent as TagIconLightMode } from "./icons/Tag-Icon.svg";
import { ReactComponent as TagLayerIconLightMode } from "./icons/Tag-Layer-Icon.svg";
import { ReactComponent as TeamLayerIcon } from "./icons/Team-Layer-Icon.svg";
import { ReactComponent as TeamMembersLayerIconLightMode } from "./icons/Team-Members-Layer-Icon.svg";
import { ReactComponent as TeamPaymentIconLightMode } from "./icons/Team-Payment-Icon.svg";
import { ReactComponent as TickSquareIconLightMode } from "./icons/Tick-Square-Icon.svg";
import { ReactComponent as TimeCircleIconLightMode } from "./icons/Time-Circle-Icon.svg";
import { ReactComponent as TimePlusIcon } from "./icons/Time-Plus-Icon.svg";
import { ReactComponent as TimeTickIcon } from "./icons/Time-Tick-Icon.svg";
import { ReactComponent as TypicalCameraLayerIcon } from "./icons/Typical-Camera-Layer-Icon.svg";
import { ReactComponent as CheckedUserLayerIcon } from "./icons/User-Checked-Layer-Icon.svg";
import { ReactComponent as UserIconLightMode } from "./icons/User-Icon.svg";
import { ReactComponent as UserLayerIcon } from "./icons/User-Layer-Icon.svg";
import { ReactComponent as UserSquareIconLightMode } from "./icons/User-Square-Icon.svg";
import { ReactComponent as UsersIconLightMode } from "./icons/Users-Icon.svg";
import { ReactComponent as UsersPrimaryIconLightMode } from "./icons/Users-Primary-Icon.svg";
import { ReactComponent as UsersLayerIcon } from "./icons/Users-Layer-Icon.svg";
import { ReactComponent as VideoBlueIconLightMode } from "./icons/Video-Blue-Icon.svg";
import { ReactComponent as VideoCameraLayerIconLightMode } from "./icons/Video-Camera-Layer-Icon.svg";
import { ReactComponent as VideoDarkLightMode } from "./icons/Video-Dark.svg";
import { ReactComponent as VideoLayerIconLightMode } from "./icons/Video-Layer-Icon.svg";
import { ReactComponent as VideoLightMode } from "./icons/Video.svg";
import { ReactComponent as Image2Icon } from "./icons/Image-Icon.svg";
import { ReactComponent as ModelIcon } from "./icons/Model.svg";
import { ReactComponent as FocusIcon } from "./icons/Focus.svg";
import { ReactComponent as FullScreen } from "./icons/FullScreen.svg";
import { ReactComponent as MessageChat } from "./icons/MessageChat.svg";
import { ReactComponent as ZipFolder } from "./icons/ZipFolder.svg";
import { ReactComponent as Repeat } from "./icons/Repeat.svg";
import { ReactComponent as Info } from "./icons/Info.svg";
import { ReactComponent as Announcement } from "./icons/Announcement.svg";
import { ReactComponent as DotsVertical } from "./icons/DotsVertical.svg";
import { ReactComponent as Eye } from "./icons/Eye.svg";
import { ReactComponent as Edit02 } from "./icons/Edit02.svg";
import { ReactComponent as ChromeCast } from "./icons/ChromeCast.svg";
import { ReactComponent as UserCheck } from "./icons/UserCheck.svg";
import { ReactComponent as PieChart } from "./icons/PieChart.svg";
import { ReactComponent as NoRecordIllustration } from "./icons/NoRecordIllustration.svg";
import { ReactComponent as FourStar } from "./icons/4Star.svg";
import { ReactComponent as LogoMono } from "./Mono.svg";
import { ReactComponent as Passcode } from "./icons/Passcode.svg";
import LogoPNGLightMode from "./logo.png";
// Logo
import { ReactComponent as LogoLightMode } from "./logo.svg";
import { ReactComponent as LogoSmallLightMode } from "./logo-small.svg";
import { ReactComponent as Layer } from "./icons/Layer.svg";
import { ReactComponent as DoubleLayer } from "./icons/DoubleLayer.svg";
import { ReactComponent as TripleLayer } from "./icons/TripleLayer.svg";
import { ReactComponent as CircleCheck } from "./icons/CircleCheck.svg";
import { ReactComponent as CircleCross } from "./icons/CircleCross.svg";
import { ReactComponent as TwoUsers } from "./icons/TwoUsers.svg";
import { ReactComponent as Coins } from "./icons/Coins.svg";
import { ReactComponent as Phone } from "./icons/Phone.svg";
import { ReactComponent as CreditCard } from "./icons/CreditCard.svg";
import { ReactComponent as Globe } from "./icons/Globe.svg";

// Editor
import { ReactComponent as EditorAssetsScanIcon } from "./icons/editor/Editor-Assets-Scan-Icon.svg";
import { ReactComponent as EditorAssetsSnapshotIcon } from "./icons/editor/Editor-Assets-Snapshot-Icon.svg";

import { ReactComponent as EditorTextTitleIcon } from "./icons/editor/Editor-Text-Title-Icon.svg";
import { ReactComponent as EditorTextTextIcon } from "./icons/editor/Editor-Text-Text-Icon.svg";
import { ReactComponent as EditorTextBoxIcon } from "./icons/editor/Editor-Text-Box-Icon.svg";
import { ReactComponent as EditorTextListIcon } from "./icons/editor/Editor-Text-List-Icon.svg";
import { ReactComponent as EditorTextQuoteIcon } from "./icons/editor/Editor-Text-Quote-Icon.svg";
import { ReactComponent as EditorTextCodeblockIcon } from "./icons/editor/Editor-Text-Codeblock-Icon.svg";
import { ReactComponent as EditorTextToCIcon } from "./icons/editor/Editor-Text-ToC-Icon.svg";

import { ReactComponent as EditorMediaCarouselIcon } from "./icons/editor/Editor-Media-Carousel-Icon.svg";
import { ReactComponent as EditorMediaTableIcon } from "./icons/editor/Editor-Media-Table-Icon.svg";
import { ReactComponent as EditorMediaImageIcon } from "./icons/editor/Editor-Media-Image-Icon.svg";
import { ReactComponent as EditorMediaVideoIcon } from "./icons/editor/Editor-Media-Video-Icon.svg";
import { ReactComponent as EditorMediaEmbedIcon } from "./icons/editor/Editor-Media-Embed-Icon.svg";
import { ReactComponent as EditorMediaYTIcon } from "./icons/editor/Editor-Media-YT-Icon.svg";

import { ReactComponent as EditorCopyIcon } from "./icons/editor/Editor-Copy-Icon.svg";
import { ReactComponent as EditorDeleteIcon } from "./icons/editor/Editor-Delete-Icon.svg";

import { ReactComponent as EditorBubbleBoldIcon } from "./icons/editor/Editor-Bubble-Bold-Icon.svg";
import { ReactComponent as EditorBubbleItalicIcon } from "./icons/editor/Editor-Bubble-Italic-Icon.svg";
import { ReactComponent as EditorBubbleStrikeIcon } from "./icons/editor/Editor-Bubble-Strike-Icon.svg";
import { ReactComponent as EditorBubbleHighlightIcon } from "./icons/editor/Editor-Bubble-Highlight-Icon.svg";
import { ReactComponent as EditorBubbleUnderlineIcon } from "./icons/editor/Editor-Bubble-Underline-Icon.svg";
import { ReactComponent as EditorBubbleLinkIcon } from "./icons/editor/Editor-Bubble-Link-Icon.svg";

import { ReactComponent as EditorDividerAddIcon } from "./icons/editor/Editor-Divider-Add-Icon.svg";
import { ReactComponent as EditorCarouselArrowRightIcon } from "./icons/editor/Editor-Carousel-Arrow-Right.svg";
import { ReactComponent as EditorCarouselArrowLeftIcon } from "./icons/editor/Editor-Carousel-Arrow-Left.svg";
import { ReactComponent as EditorCarouselImageRemoveIcon } from "./icons/editor/Editor-Carousel-Image-Remove.svg";
import { ReactComponent as EditorCarouselImageDownloadIcon } from "./icons/editor/Editor-Carousel-Image-Download.svg";
import { ReactComponent as EditorAlignLeftIcon } from "./icons/editor/Editor-Align-Left.svg";
import { ReactComponent as EditorAlignCenterIcon } from "./icons/editor/Editor-Align-Center.svg";
import { ReactComponent as EditorAlignRightIcon } from "./icons/editor/Editor-Align-Right.svg";
import { ReactComponent as EditorBoardIcon } from "./icons/editor/Editor-Board-Icon.svg";

import { ReactComponent as EditorHeadingSizeIcon } from "./icons/editor/Editor-Heading-Size.svg";
import { ReactComponent as EditorTextSizeIcon } from "./icons/editor/Editor-Text-Size.svg";
import { ReactComponent as EditorTextAlignLeftIcon } from "./icons/editor/Editor-Text-Align-Left.svg";
import { ReactComponent as EditorTextAlignCenterIcon } from "./icons/editor/Editor-Text-Align-Center.svg";
import { ReactComponent as EditorTextAlignRightIcon } from "./icons/editor/Editor-Text-Align-Right.svg";
import { ReactComponent as EditorTextAlignJustifyIcon } from "./icons/editor/Editor-Text-Align-Justify.svg";
import { ReactComponent as EditorPlusPrimaryIcon } from "./icons/editor/Editor-Plus-Primary.svg";

import { ReactComponent as EditorNumberedListIcon } from "./icons/editor/Editor-Numbered-List-Type-Icon.svg";
import { ReactComponent as EditorBulletListIcon } from "./icons/editor/Editor-Bullet-List-Type-Icon.svg";

import { ReactComponent as ArrowChevronUpGrayIcon } from "./icons/Arrow-Chevron-Up-Gray.svg";
import { ReactComponent as DotsGridIcon } from "./icons/editor/Dots-Grid.svg";

import { ReactComponent as LinkGrayIcon } from "./icons/Link-Gray-Icon.svg";
import { ReactComponent as UpdateImageGrayIcon } from "./icons/Upload-Image-Gray-Icon.svg";
import { ReactComponent as PercentageIcon } from "./icons/Percentage-Icon.svg";
import { ReactComponent as PixelIcon } from "./icons/Pixel-Icon.svg";

const useImages = () => {
    return {
        Logo: LogoLightMode,
        LogoPNG: LogoPNGLightMode,
        LogoSmall: LogoSmallLightMode,

        // Banners
        LoginBanner: LoginBannerLightMode,
        LinePattern: LinePatternLightMode,
        RegisterBanner: RegisterBannerLightMode,

        // Sidebar Icon
        SidebarHomeActiveIcon: HomeLightMode,
        SidebarHomeInActiveIcon: HomeInActiveLightMode,

        // Icons
        DeleteIcon: DeleteLightMode,
        DeleteRoundedIcon: DeleteRoundedIconLightMode,
        DownloadIcon: DownloadLightMode,
        DownloadIconV2: DownloadIconV2LightMode,
        EditSquareIcon: EditSquareLightMode,
        FolderIcon: FolderLightMode,
        HomeIcon: HomeLightMode,
        HomeInActiveIcon: HomeInActiveLightMode,
        ImageDarkIcon: ImageDarkLightMode,
        ImageGrayIcon: ImageGrayLightMode,
        ImageIcon: ImageLightMode,
        NotificationIcon: NotificationLightMode,
        PlayButtonIcon: PlayButtonLightMode,
        PlayListIcon: PlayListLightMode,
        ProfileIcon: ProfileLightMode,
        ReturnIcon: ReturnLightMode,
        CubeBlueIcon: CubeBlueIconLightMode,
        ShowIcon: ShowLightMode,
        VideoDarkIcon: VideoDarkLightMode,
        VideoIcon: VideoLightMode,
        SelectArrow: SelectArrowLightMode,
        SelectArrowAscend: SelectArrowAscendLightMode,
        SelectArrowDescend: SelectArrowDescendLightMode,
        FilterIcon: FilterIconLightMode,
        ArrowDownCircle: ArrowDownCircleLightMode,
        ArrowDownBlueCircle: ArrowDownBlueCircleLightMode,
        ArrowDown: ArrowDownLightMode,
        PlusWhite: PlusWhiteLightMode,
        ArrowDownBlue: ArrowDownBlueLightMode,
        ArrowChevronLeft: ArrowChevronLeftLightMode,
        ArrowChevronRight: ArrowChevronRightLightMode,
        ArrowLeftNav: ArrowLeftNavLightMode,
        ArrowRightNav: ArrowRightNavLightMode,
        ArrowRightPrimary: ArrowRightPrimaryLightMode,
        SearchIcon: SearchIconLightMode,
        PlusGrey: PlusGreyLightMode,
        TickSquareGreen: TickSquareIconLightMode,
        ArrowUpRight: ArrowUpRightLightMode,
        ArrowWhiteUpRight: ArrowWhiteUpRightLightMode,
        BreadcrumbsSperator: BreadcrumbsSperatorLightMode,
        SettingDarkIcon: SettingDarkIconLightMode,
        CheckboxInActive: CheckboxInActiveLightMode,
        CheckboxActive: CheckboxActiveLightMode,
        ArrowRightWhite: ArrowRightWhiteLightMode,
        ResearchIcon: ResearchIconLightMode,
        RunIcon: RunIconLightMode,
        ArrowDownSquare: ArrowDownSquareLightMode,
        PrivateProfile: PrivateProfileLightMode,
        SendInvite: SendInviteLightMode,
        DeleteBlue: DeleteBlueLightMode,
        CloseIcon: CloseIconLightMode,
        VideoBlueIcon: VideoBlueIconLightMode,
        PlayBlueCircleIcon: PlayBlueCircleIconLightMode,
        GoogleIcon: GoogleIconLightMode,
        FacebookIcon: FacebookIconLightMode,
        AppleIcon: AppleIconLightMode,
        ProfileAvatarIcon: ProfileAvatarIconLightMode,
        ArrowBlueRightIcon: ArrowBlueRightIconLightMode,
        SmileyFaceIcon: SmileyFaceIconLightMode,
        CheckCircleIcon: CheckCircleIconLightMode,
        LocationIcon: LocationIconLightMode,
        SquarePlusIcon: SquarePlusIconLightMode,
        DangerTriangleIcon: DangerTriangleIconLightMode,
        TimeCircleIcon: TimeCircleIconLightMode,
        PauseIcon: PauseIconLightMode,
        LogoutIcon: LogoutIconLightMode,
        FileUploadIcon: FileUploadIconLightMode,
        EmailIcon: EmailIconLightMode,
        EmailLayerIcon: EmailLayerIconLightMode,
        PhoneLayerIcon: PhoneLayerIconLightMode,
        SuccessLayerIcon: SuccessLayerIconLightMode,
        DeleteLayerIcon: DeleteLayerIconLightMode,
        PencilIcon: PencilIconLightMode,
        InfoCircleIcon: InfoCircleIconLightMode,
        InfoCircleStatusIcon: InfoCircleStatusIconLightMode,
        HelpCircleIcon: HelpCircleIconLightMode,
        PlayCircleIcon: PlayCircleIconLightMode,
        StarIcon: StarIconLightMode,
        StarFilledIcon: StarFilledIconLightMode,
        StarRoundedActiveIcon: StarIconRoundedActiveLightMode,
        DropdownUpDownArrowActive: DropdownUpDownArrowActiveIconLightMode,
        DropdownUpDownArrowInactive: DropdownUpDownArrowInactiveIconLightMode,
        EllipsisIcon: EllipsisIconLightMode,
        ShareIcon: ShareIconLightMode,
        Share2,
        MessageSquareIcon: MessageSquareIconLightMode,
        FireLikeIcon: FireLikeIconLightMode,
        FireLikeActiveIcon: FireLikeActiveIconLightMode,
        FlagIcon: FlagIconLightMode,
        FlagActiveIcon: FlagActiveIconLightMode,
        ContextMenuIcon: ContextMenuIconLightMode,
        ContextMenuGrayIcon: ContextMenuGrayIconLightMode,
        ContextMenuHorizontalGrayIcon: ContextMenuHorizontalGrayIconLightMode,
        BoltIcon: BoltIconLightMode,
        ExclamationLayer: ExclamationLayerIconLightMode,
        CopyIcon: CopyIconLightMode,
        CopyGrayIcon: CopyGrayIconLightMode,
        TagIcon: TagIconLightMode,
        TagLayerIcon: TagLayerIconLightMode,
        GreenTickLayerIcon: GreenTickLayerIconLightMode,
        CheckSuccessFilledIcon,
        EqualIcon,
        FolderLayerIcon: FolderLayerIconLightMode,
        VideoLayerIcon: VideoLayerIconLightMode,
        EverythingLayerIcon: EverythingLayerIconLightMode,
        CircleCheckEmpty,
        CircleCheckFilled,
        LongRightArrowLightMode,
        LongLeftArrowIconLightMode,
        TeamMembersLayerIconLightMode,
        EmbedIcon: EmbedIconLightMode,
        CalenderIcon: CalenderIconLightMode,
        UserIcon: UserIconLightMode,
        UsersIcon: UsersIconLightMode,
        UsersPrimaryIcon: UsersPrimaryIconLightMode,
        UserSquareIcon: UserSquareIconLightMode,
        TeamPaymentIcon: TeamPaymentIconLightMode,
        ArticleLayerIcon: ArticleLayerIconLightMode,
        DocumentIcon: DocumentIconLightMode,
        LogoutLayerIcon: LogoutLayerIconLightMode,
        KeyLayerIcon: KeyLayerIconLightMode,
        CrossCircleIcon: CrossCircleIconLightMode,
        PlusLayerIcon: PlusLayerIconLightMode,
        QuestionMarkCircleIcon: QuestionMarkCircleIconLightMode,
        FacebookOutlinedIcon,
        LinkedinOutlinedIcon,
        InstagramOutlinedIcon,
        TwitterOutlinedIcon,
        UserLayerIcon,
        CheckedUserLayerIcon,
        TeamLayerIcon,
        JoinTeamLayerIcon,
        CheckCheckboxCircleIcon,
        UncheckCheckboxCircleIcon,
        VideoCameraLayerIcon: VideoCameraLayerIconLightMode,
        PlusSquareLayer: PlusSquareLayerIconLightMode,
        BroadcastLayer: BroadcastLayerIconLightMode,
        PhoneIcon: PhoneIcon,
        NotifyAllIcon,
        NotifyAllLayerIcon,
        NotificationLayerIcon,
        LockIcon,
        InboxIcon,
        DroneLayerIcon,
        MobileLayerIcon,
        TabletLayerIcon,
        TypicalCameraLayerIcon,
        LocationLayerIcon,
        GoogleDriveIcon,
        DropboxIcon,
        UsersLayerIcon,
        AddUserIcon,
        SharingLayerIcon,
        BookmarkIcon,
        SquareCheckLayerIcon,
        CompassIcon,
        CameraIcon,
        SquareScanIcon,
        AddVideoCameraIcon,
        TimePlusIcon,
        TimeTickIcon,
        SquarePlusIconFilled,
        ModelLayerIcon,
        ScanIcon,
        Image2Icon,
        UploadIcon,
        ModelIcon,
        FocusIcon,
        FullScreen,
        MessageChat,
        ZipFolder,
        Repeat,
        Info,
        Announcement,
        NoRecordIllustration,
        ShareLayerIcon,
        FourStar,
        LogoMono,
        Passcode,

        // Social Icons
        SocialFacebookIcon,
        SocialMessengerIcon,
        SocialLinkedInIcon,
        SocialNewTwitterIcon,
        SocialTwitterIcon,
        SocialTikTokIcon,
        SocialRedditIcon,
        SocialTelegramIcon,
        SocialPinterestIcon,
        SocialWhatsappIcon,
        SocialEmailIcon,
        SocialInstagramIcon,
        Skype,
        Github,
        Youtube,
        Zap,
        FolderOutlined,
        DatabaseCoinOutlined,
        DatabaseOutlined,
        DotsVertical,
        Eye,
        Edit02,
        X,
        ChromeCast,
        PieChart,
        UserCheck,
        Layer,
        DoubleLayer,
        TripleLayer,
        CircleCheck,
        CircleCross,
        TwoUsers,
        Coins,
        Phone,
        CreditCard,
        Globe,

        // Editor
        EditorAssetsScanIcon,
        EditorAssetsSnapshotIcon,
        EditorTextTitleIcon,
        EditorTextTextIcon,
        EditorTextBoxIcon,
        EditorTextListIcon,
        EditorTextQuoteIcon,
        EditorTextCodeblockIcon,
        EditorTextToCIcon,
        EditorMediaCarouselIcon,
        EditorMediaTableIcon,
        EditorMediaImageIcon,
        EditorMediaVideoIcon,
        EditorMediaEmbedIcon,
        EditorMediaYTIcon,
        EditorCopyIcon,
        EditorDeleteIcon,
        EditorBubbleBoldIcon,
        EditorBubbleItalicIcon,
        EditorBubbleStrikeIcon,
        EditorBubbleHighlightIcon,
        EditorBubbleUnderlineIcon,
        EditorBubbleLinkIcon,
        EditorDividerAddIcon,
        EditorCarouselArrowRightIcon,
        EditorCarouselArrowLeftIcon,
        EditorCarouselImageRemoveIcon,
        EditorCarouselImageDownloadIcon,
        EditorAlignLeftIcon,
        EditorAlignCenterIcon,
        EditorAlignRightIcon,
        EditorHeadingSizeIcon,
        EditorTextSizeIcon,
        EditorTextAlignLeftIcon,
        EditorTextAlignCenterIcon,
        EditorTextAlignRightIcon,
        EditorTextAlignJustifyIcon,
        EditorPlusPrimaryIcon,
        EditorNumberedListIcon,
        EditorBulletListIcon,
        EditorBoardIcon,
        LinkGrayIcon,
        UpdateImageGrayIcon,
        PercentageIcon,
        PixelIcon,
        ArrowChevronUpGrayIcon,
        DotsGridIcon,
    };
};

export default useImages;
