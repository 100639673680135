import Field from "shared/components/ui/Field/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import SettingPageCard from "../../partials/SettingPageCard";
import useSecuritySettingScreen from "./hook";

const SecuritySettingScreen = () => {
    const { form, isLoading, onSubmit } = useSecuritySettingScreen();

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit}>
            <SettingPageCard
                title="Password info"
                subTitle="Update your password here."
                rightComponent={
                    <LoaderButton
                        loading={isLoading}
                        loadingText="Changing password..."
                        type="submit"
                        variant="contained"
                    >
                        Change Password
                    </LoaderButton>
                }
            >
                <div className="border-b">
                    <Field
                        name="old_password"
                        label="Current Password"
                        placeholder="Enter your current password"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-1 lg:grid-cols-[280px,1fr] gap-3 lg:gap-9 !space-x-0"
                    />
                </div>
                <div className="border-b pt-5">
                    <Field
                        name="new_password"
                        label="New Password"
                        placeholder="Enter your new password"
                        type="password"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-1 lg:grid-cols-[280px,1fr] gap-3 lg:gap-9 !space-x-0"
                    />
                </div>
                <div className="pt-5">
                    <Field
                        name="cpassword"
                        label="Confirm Password"
                        placeholder="Enter your confirm password"
                        type="password"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-1 lg:grid-cols-[280px,1fr] gap-3 lg:gap-9 !space-x-0"
                    />
                </div>
            </SettingPageCard>
        </ThemeFormProvider>
    );
};

export default SecuritySettingScreen;
