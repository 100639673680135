import { RouteObject } from "react-router-dom";
import LoginLayout from "screens/Auth/layouts/LoginLayout";
import RegisterLayout from "screens/Auth/layouts/RegisterLayout";
import ForgotPasswordScreen from "screens/Auth/screens/ForgotPassword";
import LoginScreen from "screens/Auth/screens/Login";
import RegisterScreen from "screens/Auth/screens/Register";
import RegisterSuccessScreen from "screens/Auth/screens/RegisterSuccess";
import ResetPasswordScreen from "screens/Auth/screens/ResetPassword";
import VerifyEmailScreen from "screens/Auth/screens/VerifyEmail";
import WaitingList from "screens/Auth/screens/WaitingList";
import URL from "shared/constants/navigator";
import Guest from "shared/middleware/Guest";

const publicRoutes: RouteObject[] = [
    {
        path: "",
        element: <Guest />,
        children: [
            {
                path: URL.Home,
                element: <RegisterLayout />,
                children: [
                    {
                        path: URL.Home,
                        element: <RegisterScreen />,
                    },
                    {
                        path: URL.RegisterSuccess,
                        element: <RegisterSuccessScreen />,
                    },
                ],
            },
            {
                path: "",
                element: <LoginLayout />,
                children: [
                    {
                        path: URL.Login,
                        element: <LoginScreen />,
                    },
                    {
                        path: URL.ForgotPassword,
                        element: <ForgotPasswordScreen />,
                    },
                    {
                        path: URL.ResetPassword,
                        element: <ResetPasswordScreen />,
                    },
                ],
            },
            {
                path: URL.VerifyEmail,
                element: <VerifyEmailScreen />,
            },
            {
                path: URL.Waiting_List,
                element: <WaitingList />,
            },
            // {
            //     path: "/on-board",
            //     element: <OnBoardScreenLayout />,
            //     children: [
            //         {
            //             path: "profiles",
            //             element: <OnBoardProfiles />,
            //         },
            //         {
            //             path: "invitations",
            //             element: <OnBoardInvitations />,
            //         },
            //         {
            //             path: "team",
            //             element: <OnBoardTeam />,
            //         },
            //         {
            //             path: "join-team",
            //             element: <OnBoardJoinTeam />,
            //         },
            //     ],
            // },
        ],
    },
];

export default publicRoutes;
