import useImages from "assets/images";
import ThemeModal from "../ui/ThemeModal";
import InviteMemberForm from "./InviteMemberForm";

const InviteTeamMemberModal = (props: { open: boolean; onClose: () => void }) => {
    const Images = useImages();

    return (
        <ThemeModal
            open={props.open}
            icon={<Images.TeamMembersLayerIconLightMode />}
            title="Invite Team Members"
            content="Invite friends and colleagues to collaborate with your team."
            contentComponent={<InviteMemberForm isVisible={props.open} closeModal={props.onClose} />}
        />
    );
};

export default InviteTeamMemberModal;
