import { useMemo } from "react";
import { Permissions, UserPermission } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";

const useHasPermission = (
    permissionOpt: string | { permission: string; type: keyof Omit<Permissions, "__typename"> }
) => {
    const { team, isTeamOwner, isTeamManager, isTeamMember } = useAuth();
    const permissions = useAppSelector((state) => state.team.current?.setting.permissions);

    return useMemo(() => {
        if (permissions) {
            let userPermission: UserPermission;

            if (typeof permissionOpt !== "string" && permissionOpt.type) {
                userPermission = permissions[permissionOpt.type] || ({} as UserPermission);
            } else if (isTeamOwner) {
                userPermission = permissions.admin;
            } else if (isTeamManager) {
                userPermission = permissions.manager || ({} as UserPermission);
            } else if (isTeamMember) {
                userPermission = permissions.member || ({} as UserPermission);
            } else {
                return false;
            }

            if (!permissionOpt) {
                return false;
            }

            const permission = typeof permissionOpt === "string" ? permissionOpt : permissionOpt.permission;

            const pathArray = permission?.split(".") || [];

            for (const path of pathArray) {
                if (userPermission[path]) {
                    userPermission = userPermission[path];
                } else {
                    return false;
                }
            }

            return typeof userPermission === "boolean" ? userPermission : false;
        }

        return false;
    }, [permissionOpt, team]);
};

export default useHasPermission;
