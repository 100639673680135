import useImages from "assets/images";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SRInviteMemberModal from "screens/Dashboard/ScanRequests/common/components/SRInviteMemberModal";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import RenderIf from "shared/components/RenderIf";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import useAuth from "shared/hooks/useAuth";
import useSRActionButton from "./hook";

const SRDetailActionButtons = () => {
    const { isRequestor, request, requestReview, reviewRes } = useSingleSR();
    const hook = useSRActionButton();
    const Images = useImages();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [inviteModalId, setInviteModalId] = useState<number | null>(null);

    if (!request) return null;

    const userRequest = request.applications.find((item) => item.user.user_id === user?.user_id);
    const userHasSubmissions = userRequest?.submissions.length;

    const IsNewRequest = request?.status === "New";
    const IsInvited = userRequest?.status === "Invited";
    const AwaitReview = userRequest?.status === "Awaiting Submission";

    if (request.status === "Archived") return <></>;

    return (
        <>
            <RenderIf condition={request.status !== "Archived" && isRequestor && IsNewRequest}>
                <ThemeIconButton
                    icon={<Images.AddUserIcon />}
                    className="!bg-[#949496] !bg-opacity-25"
                    variant="filled"
                    onClick={() => setInviteModalId(request.scan_request_id)}
                />
                <ConfirmButton
                    icon={<Images.DeleteLayerIcon />}
                    title="Delete Project Request"
                    content="Are you sure you want to delete this project request? You'll no longer be able to access any scans submitted for this request."
                    okBtnText="Delete"
                    okBtnProps={{
                        loading: hook.deleteRes.isLoading,
                        color: "error",
                        onClick: () =>
                            hook.deleteReq({
                                request_id: request.scan_request_id,
                            }),
                    }}
                >
                    <ThemeIconButton
                        icon={<Images.DeleteIcon />}
                        className="!bg-error !bg-opacity-25"
                        color="error"
                        variant="filled"
                    />
                </ConfirmButton>
                <ConfirmButton
                    icon={<Images.DeleteLayerIcon />}
                    title="End Project Request"
                    content="Are you sure you want to end this project request?"
                    okBtnText="Confirm"
                    okBtnProps={{
                        loading: hook.endRes.isLoading,
                        color: "error",
                        onClick: () =>
                            hook.endReq({
                                request_id: request.scan_request_id,
                            }),
                    }}
                >
                    <ThemeIconButton
                        icon={
                            <span className="flex items-center">
                                End Request <Images.ArrowUpRight />
                            </span>
                        }
                        className="!bg-primary !bg-opacity-25 !w-auto !text-sm"
                        color="primary"
                        variant="filled"
                    />
                </ConfirmButton>
            </RenderIf>
            <RenderIf condition={!isRequestor}>
                <RenderIf condition={IsInvited || !userRequest}>
                    <ConfirmButton
                        icon={<Images.DeleteLayerIcon />}
                        title="Reject Request"
                        content="Are you sure you want to reject this project request?"
                        okBtnText="Reject"
                        okBtnProps={{
                            loading: hook.rejectRes.isLoading,
                            color: "error",
                            onClick: () =>
                                hook.rejectReq({
                                    request_id: request.scan_request_id,
                                }),
                        }}
                    >
                        <ThemeIconButton
                            icon={<Images.CloseIcon color="#EB617A" />}
                            className="!bg-error !bg-opacity-25"
                            color="error"
                            variant="filled"
                        />
                    </ConfirmButton>
                    <ConfirmButton
                        icon={<Images.SuccessLayerIcon />}
                        title="Accept Request"
                        content="Are you sure you want to accept this project request?"
                        okBtnText="Accept"
                        okBtnProps={{
                            loading: hook.acceptRes.isLoading,
                            onClick: () =>
                                hook.acceptReq({
                                    request_id: request.scan_request_id,
                                }),
                        }}
                    >
                        <ThemeIconButton
                            icon={
                                <span className="flex items-center">
                                    Accept Request <Images.ArrowUpRight />
                                </span>
                            }
                            className="!bg-primary !bg-opacity-25 !w-auto !text-sm"
                            color="primary"
                            variant="filled"
                        />
                    </ConfirmButton>
                </RenderIf>
                <RenderIf condition={AwaitReview && Boolean(userRequest)}>
                    <ThemeIconButton
                        icon={<Images.AddVideoCameraIcon color={"#3F3E3E"} />}
                        className="!bg-[#949496] !bg-opacity-25"
                        color="error"
                        variant="filled"
                        onClick={() => navigate(`add/submission`)}
                    />
                    <div className="border w-[0.5px] h-4" />
                    <ConfirmButton
                        icon={<Images.SuccessLayerIcon />}
                        title="Submit Results"
                        content="Are you sure you want to submit results for this project request?"
                        okBtnText="Confirm"
                        disabled={!userHasSubmissions}
                        okBtnProps={{
                            loading: reviewRes.isLoading,
                            onClick: () =>
                                requestReview({
                                    scan_request_id: request.scan_request_id!,
                                }),
                        }}
                    >
                        <ThemeIconButton
                            tooltipPosition="top"
                            tooltipTitle={userHasSubmissions ? "" : "Please add submissions to submit results"}
                            disabled={!userHasSubmissions}
                            icon={
                                <span className="flex items-center">
                                    Submit Results{" "}
                                    <Images.ArrowUpRight className={!userHasSubmissions ? "opacity-50" : ""} />
                                </span>
                            }
                            className="!bg-primary !bg-opacity-25 !w-auto !text-sm"
                            color="primary"
                            variant="filled"
                        />
                    </ConfirmButton>
                </RenderIf>
            </RenderIf>
            <SRInviteMemberModal
                id={inviteModalId}
                onCloseModal={() => {
                    setInviteModalId(null);
                }}
            />
        </>
    );
};

export default SRDetailActionButtons;
