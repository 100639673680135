import { TourProvider } from "@reactour/tour";
import { Badge, Close, Navigation } from "shared/components/Walkthrough/partials/WalkthroughElements";
import useWalkthroughLayout from "./hook";

const WalkthroughLayout = ({ children }: any) => {
    const { onTutorialClose } = useWalkthroughLayout();

    return (
        <TourProvider
            steps={[]}
            components={{ Close, Badge, Navigation }}
            onClickClose={onTutorialClose}
            onClickMask={onTutorialClose}
        >
            {children}
        </TourProvider>
    );
};

export default WalkthroughLayout;
