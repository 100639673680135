import { Outlet } from "react-router-dom";
import LoadingSnackbar from "shared/components/ui/LoadingSnackbar";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import SettingPageCard from "../../partials/SettingPageCard";
import AccountListLoader from "./partials/loader";
import AccountLists from "./partials/list";
import AccountPostModal from "./partials/PostModal";
import useAccountScreens from "./hook";

const AccountScreens = () => {
    const { accounts, isLoading, isFetching } = useAccountScreens();

    return (
        <>
            <SettingPageCard
                title="Accounts"
                subTitle="Update your Account Informations here"
                rightComponent={<AccountPostModal />}
                hasHeaderDivider={false}
                wrapperClassName="mb-0 pb-0"
            >
                <div className="grid grid-cols-[360px,1fr]">
                    <ThemeScreenCard
                        classes={{
                            header: "h-0 p-0",
                            body: "bg-white !h-[calc(100vh-254px)] relative",
                        }}
                        scrollbarProps={{
                            className: "p-0",
                        }}
                    >
                        <LoadingSnackbar loading={isFetching && !isLoading} />
                        {isLoading ? <AccountListLoader /> : <AccountLists data={accounts} />}
                    </ThemeScreenCard>
                    <ThemeScreenCard
                        classes={{
                            root: "!border-r-0",
                            header: "h-0 p-0",
                            body: "bg-white !h-[calc(100vh-254px)]",
                        }}
                        scrollbarProps={{
                            className: "p-0",
                        }}
                    >
                        <div className="px-10 py-6">
                            <Outlet />
                        </div>
                    </ThemeScreenCard>
                </div>
            </SettingPageCard>
        </>
    );
};

export default AccountScreens;
