import { yupResolver } from "@hookform/resolvers/yup";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CardLoader from "shared/components/ui/CardLoader";
import Field from "shared/components/ui/Field/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useChangeTeamUsernameMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { ProfileTypes } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { twMerge } from "tailwind-merge";
import DeleteTeam from "./partials/DeleteTeam";
import LeaveTeam from "./partials/LeaveTeam";
import TransferOwnership from "./partials/TransferOwnership";

const schema = yup.object().shape({
    username: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const AccountDetailScreen = () => {
    const Images = useImages();
    const [updateUsername, updateRes] = useChangeTeamUsernameMutation();
    const { isTeamOwner, isTeamMember, profile } = useAuth();
    const account = useAppSelector((state) => state.accounts.selectedAccount);

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            username: "",
        },
    });

    useEffect(() => {
        if (account) {
            form.setValue("username", account.username || "");
        }
    }, [account]);

    useEffect(() => {
        rtkHandler(updateRes, {
            setError: form.setError,
            successMessage: "Username updated successfully",
        });
    }, [updateRes]);

    const onSubmit = (data: FormValues) => {
        if (account) updateUsername({ data });
    };

    if (!account) return <CardLoader loading={true} />;

    const image = account.profile_image ? account?.profile_image?.url : "";

    return (
        <div className="flex-col flex h-full">
            <ThemeFormProvider form={form} className="flex-1" onSubmit={onSubmit}>
                <div className="mb-6">
                    {image ? (
                        <div className="w-fit border-[0.5px] border-[#D0D5DD] rounded-md">
                            <img src={image} className="w-[64px] h-[64px] rounded-md object-cover object-center" />
                        </div>
                    ) : (
                        <Images.TeamPaymentIcon width={64} height={64} />
                    )}
                </div>
                {account.name && (
                    <FieldDetail
                        label="Team Name"
                        classes={{
                            value: "text-opacity-50",
                        }}
                        value={account.name}
                    />
                )}
                <Field
                    label="Username"
                    name="username"
                    inputTextProps={{
                        readOnly: updateRes.isLoading,
                        disabled: profile?.profile_id !== account.profile_id,
                        sx: {
                            background: "#F9FAFB",
                            borderRadius: "8px",
                            "&::before": {
                                display: "none !important",
                            },
                            "&::after": {
                                display: "none !important",
                            },
                        },
                        endAdornment: (
                            <LoaderButton
                                loading={updateRes.isLoading}
                                disabled={form.formState.dirtyFields.username === undefined || updateRes.isLoading}
                                showCircularProgress={false}
                                loadingText="Updating..."
                                className="text-primary mr-2 text-md !font-normal"
                                type="submit"
                            >
                                Update
                            </LoaderButton>
                        ),
                    }}
                />
                <FieldDetail
                    label="Role"
                    classes={{
                        value: "text-opacity-50",
                    }}
                    value={account.type}
                />
            </ThemeFormProvider>
            <div className="flex flex-col space-y-4 mt-10">
                {account.type === ProfileTypes.Admin && (
                    <TransferOwnership
                        account={account}
                        disabled={!isTeamOwner || profile?.profile_id !== account.profile_id}
                    />
                )}
                {account.type === ProfileTypes.Admin && (
                    <DeleteTeam
                        account={account}
                        disabled={!isTeamOwner || profile?.profile_id !== account.profile_id}
                    />
                )}
                {account.type === ProfileTypes.TeamMember && (
                    <LeaveTeam
                        account={account}
                        disabled={!isTeamMember || profile?.profile_id !== account.profile_id}
                    />
                )}
            </div>
        </div>
    );
};

export default AccountDetailScreen;

const FieldDetail = (props: {
    label: string;
    value: string;
    rightComponent?: React.ReactNode;
    classes?: {
        wrapper?: string;
        label?: string;
        value?: string;
    };
}) => {
    const wrapperClasses = twMerge("mb-6", props.classes?.wrapper);
    const labelClasses = twMerge("text-sm font-medium uppercase text-p mb-2 block", props.classes?.label);
    const valueClasses = twMerge(
        "flex items-center px-[14px] py-[12px] h-12 bg-[#F9FAFB] rounded-md text-md font-normal text-p leading-5",
        props.classes?.value
    );

    return (
        <div className={wrapperClasses}>
            <label className={labelClasses}>{props.label}</label>
            <div className={valueClasses}>
                <div className="w-full capitalize">{props.value}</div>
                {props.rightComponent}
            </div>
        </div>
    );
};
