import { ReactComponent as EditorAssetsScanIcon } from "assets/images/icons/editor/Editor-Assets-Scan-Icon.svg";
import { ReactComponent as EditorAssetsSnapshotIcon } from "assets/images/icons/editor/Editor-Assets-Snapshot-Icon.svg";
import { ReactComponent as EditorBoardIcon } from "assets/images/icons/editor/Editor-Board-Icon.svg";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";

import { ReactComponent as EditorTextBoxIcon } from "assets/images/icons/editor/Editor-Text-Box-Icon.svg";
import { ReactComponent as EditorTextCodeblockIcon } from "assets/images/icons/editor/Editor-Text-Codeblock-Icon.svg";
import { ReactComponent as EditorTextListIcon } from "assets/images/icons/editor/Editor-Text-List-Icon.svg";
import { ReactComponent as EditorTextQuoteIcon } from "assets/images/icons/editor/Editor-Text-Quote-Icon.svg";
import { ReactComponent as EditorTextTextIcon } from "assets/images/icons/editor/Editor-Text-Text-Icon.svg";
import { ReactComponent as EditorTextTitleIcon } from "assets/images/icons/editor/Editor-Text-Title-Icon.svg";
import { ReactComponent as EditorTextToCIcon } from "assets/images/icons/editor/Editor-Text-ToC-Icon.svg";

import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { ReactComponent as EditorMediaCarouselIcon } from "assets/images/icons/editor/Editor-Media-Carousel-Icon.svg";
import { ReactComponent as EditorMediaEmbedIcon } from "assets/images/icons/editor/Editor-Media-Embed-Icon.svg";
import { ReactComponent as EditorMediaImageIcon } from "assets/images/icons/editor/Editor-Media-Image-Icon.svg";
import { ReactComponent as EditorMediaTableIcon } from "assets/images/icons/editor/Editor-Media-Table-Icon.svg";
import { ReactComponent as EditorMediaVideoIcon } from "assets/images/icons/editor/Editor-Media-Video-Icon.svg";
import { ReactComponent as EditorMediaYTIcon } from "assets/images/icons/editor/Editor-Media-YT-Icon.svg";
import { setSelectedBlock, showTemplateSelection } from "store/slices/publishable_project";
import CarouselProperties from "../Editor/partials/Carousel/Carousel.Props";
import EmbedProperties from "../Editor/partials/Embed/Embed.Props";
import ImageProperties from "../Editor/partials/Image/Image.Props";
import ListBlockProperties from "../Editor/partials/ListBlock/ListBlock.Props";
import NestedEditorProperties from "../Editor/partials/NestedEditor/NestedEditor.Props";
import QuoteBlockProperties from "../Editor/partials/QuoteBlock/QuoteBlock.Props";
import ScanBlockProperties from "../Editor/partials/ScanBlock/ScanBlock.Props";
import SnapshotBlockProperties from "../Editor/partials/SnapshotBlock/SnapshotBlock.Props";
import StyledTextProperties from "../Editor/partials/StyledText/StyledText.Props";
import StyledTitleProperties from "../Editor/partials/Title/Title.Props";
import VideoProperties from "../Editor/partials/Video/Video.Props";
import YoutubeProperties from "../Editor/partials/Youtube/Youtube.Props";
import BoardProperties from "./partials/Board.Props";

import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import ThemeModal from "shared/components/ui/ThemeModal";
import { v4 as uuidv4 } from "uuid";
import CodeBlockProperties from "../Editor/partials/CodeBlock/CodeBlock.Props";
import ToCBlockProperties from "../Editor/partials/ToCBlock/ToCBlock.Props";

export const Types: {
    [type: string]: {
        Icon: any;
        label: string;
    };
} = {
    board: { Icon: EditorBoardIcon, label: "Board" },
    scanComponent: { Icon: EditorAssetsScanIcon, label: "Scan" },
    snapshotComponent: { Icon: EditorAssetsSnapshotIcon, label: "Snapshot" },
    styledTitle: { Icon: EditorTextTitleIcon, label: "Title" },
    styledText: { Icon: EditorTextTextIcon, label: "Text" },
    box: { Icon: EditorTextBoxIcon, label: "Box" },
    listComponent: { Icon: EditorTextListIcon, label: "List" },
    quoteComponent: { Icon: EditorTextQuoteIcon, label: "Quote" },
    codeBlockComponent: { Icon: EditorTextCodeblockIcon, label: "Codeblock" },
    tocComponent: { Icon: EditorTextToCIcon, label: "Table of Content" },
    carousel: { Icon: EditorMediaCarouselIcon, label: "Carousel" },
    table: { Icon: EditorMediaTableIcon, label: "Table" },
    imageComponent: { Icon: EditorMediaImageIcon, label: "Image" },
    videoComponent: { Icon: EditorMediaVideoIcon, label: "Video" },
    embedComponent: { Icon: EditorMediaEmbedIcon, label: "Embed" },
    youtubeComponent: { Icon: EditorMediaYTIcon, label: "Youtube Link" },
    placeholder: { Icon: EditorTextBoxIcon, label: "Placeholder" },
};

interface IPropertiesSidebarProps {
    editor: Editor | null;
}

const PropertiesSidebar = (props: IPropertiesSidebarProps) => {
    const { editor } = props;

    const dispatch = useAppDispatch();

    const { EditorCopyIcon, EditorDeleteIcon, DeleteLayerIcon } = useImages();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);

    const [isDeleteBoardDialogVisible, setIsDeleteBoardDialogVisible] = useState(false);

    const parentEditor = useMemo<Editor | null>(() => {
        if (!editor || !selectedBlock) return null;

        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == selectedBlock?.props.id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, [editor, selectedBlock]);

    const TypeImage = useMemo(() => {
        if (!selectedBlock) return Types["board"].Icon;

        return Types[selectedBlock!.type].Icon;
    }, [selectedBlock]);

    const componentProperties = useMemo(() => {
        if (!selectedBlock || !parentEditor) return;

        let Node: any = React.Fragment;

        if (selectedBlock.type == "styledTitle") {
            Node = StyledTitleProperties;
        } else if (selectedBlock.type == "styledText") {
            Node = StyledTextProperties;
        } else if (selectedBlock.type == "carousel") {
            Node = CarouselProperties;
        } else if (selectedBlock.type == "imageComponent") {
            Node = ImageProperties;
        } else if (selectedBlock.type == "videoComponent") {
            Node = VideoProperties;
        } else if (selectedBlock.type == "youtubeComponent") {
            Node = YoutubeProperties;
        } else if (selectedBlock.type == "embedComponent") {
            Node = EmbedProperties;
        } else if (selectedBlock.type == "scanComponent") {
            Node = ScanBlockProperties;
        } else if (selectedBlock.type == "snapshotComponent") {
            Node = SnapshotBlockProperties;
        } else if (selectedBlock.type == "box") {
            Node = NestedEditorProperties;
        } else if (selectedBlock.type == "quoteComponent") {
            Node = QuoteBlockProperties;
        } else if (selectedBlock.type == "listComponent") {
            Node = ListBlockProperties;
        } else if (selectedBlock.type == "tocComponent") {
            Node = ToCBlockProperties;
        } else if (selectedBlock.type == "codeBlockComponent") {
            Node = CodeBlockProperties;
        }

        return (
            <Node
                key={selectedBlock.props.id}
                editor={parentEditor}
                props={{
                    id: selectedBlock.props.id,
                    ...selectedBlock.props.attrs,
                }}
            />
        );
    }, [selectedBlock, parentEditor]);

    const showButtons = useMemo(() => {
        return selectedBlock && selectedBlock.props.id != "mainHeading" && selectedBlock.props.id != "mainSubtitle";
    }, [selectedBlock]);

    const showDeleteBoardDialog = () => {
        setIsDeleteBoardDialogVisible(true);
    };

    const hideDeleteBoardDialog = () => {
        setIsDeleteBoardDialogVisible(false);
    };

    return (
        <>
            <div className="w-[240px] h-full flex flex-col rounded-xl bg-[#FFFFFF] border border-[#EAECF0]">
                <div className="text-[#667085] font-semibold text-md mb-4 pt-5 px-5">Design</div>
                <div className={"flex-1 w-full"}>
                    {!selectedBlock ? (
                        <div>
                            <div className="w-full pb-4 border-b border-[#D0D5DD] px-5 flex items-center select-none">
                                <TypeImage width={17} height={17} />
                                <span className="flex-1 pl-4 pr-2 font-medium text-[#667085] text-md">
                                    {Types["board"].label}
                                </span>
                                <span className="flex items-center gap-x-[14px]">
                                    <EditorDeleteIcon
                                        width={16}
                                        height={16}
                                        className="cursor-pointer"
                                        onClick={() => showDeleteBoardDialog()}
                                    />
                                </span>
                            </div>
                            <BoardProperties editor={editor} />
                        </div>
                    ) : (
                        <div>
                            <div className="w-full pb-4 border-b border-[#D0D5DD] px-5 flex items-center select-none">
                                <TypeImage width={17} height={17} />
                                <span className="flex-1 pl-4 pr-2 font-medium text-[#667085] text-md">
                                    {Types[selectedBlock!.type].label}
                                </span>
                                <span className="flex items-center gap-x-[14px]">
                                    {showButtons && selectedBlock.type != "placeholder" && (
                                        <EditorCopyIcon
                                            width={16}
                                            height={16}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                if (parentEditor) {
                                                    const duplicatedNode = (
                                                        parentEditor.view.state.schema.nodes[selectedBlock.type] as any
                                                    ).create({
                                                        ...selectedBlock.props.attrs,
                                                        id: uuidv4(),
                                                    });

                                                    let pos = 0;

                                                    parentEditor.view.state.doc.descendants((node, position) => {
                                                        if (node.attrs.id == selectedBlock.props.id) {
                                                            pos = position;
                                                            return false;
                                                        }
                                                    });

                                                    const transaction = parentEditor.view.state.tr.insert(
                                                        pos + 1,

                                                        duplicatedNode
                                                    );

                                                    parentEditor.view.dispatch(transaction);
                                                }
                                            }}
                                        />
                                    )}
                                    {showButtons && (
                                        <EditorDeleteIcon
                                            width={16}
                                            height={16}
                                            className="cursor-pointer"
                                            onClick={() => deleteBlockHandler(selectedBlock, parentEditor!, dispatch)}
                                        />
                                    )}
                                </span>
                            </div>
                            {componentProperties}
                        </div>
                    )}
                </div>
            </div>
            <ThemeModal
                icon={<DeleteLayerIcon height={50} width={50} />}
                open={isDeleteBoardDialogVisible}
                onClose={hideDeleteBoardDialog}
                title="Delete Board"
                content="This will delete the complete board and you will have to start from scratch."
                contentComponent={
                    <CommonModalActionButton
                        className="flex-col"
                        buttons={[
                            {
                                id: "delete-board-btn",
                                children: "Delete",
                                type: "button",
                                color: "error",
                                onClick: () => {
                                    if (editor) {
                                        hideDeleteBoardDialog();
                                        setTimeout(() => {
                                            editor.commands.clearContent();
                                            dispatch(showTemplateSelection());
                                        }, 0);
                                    }
                                },
                            },
                        ]}
                        cancelBtnProps={{
                            onClick: () => hideDeleteBoardDialog(),
                        }}
                    />
                }
            />
        </>
    );
};

export default PropertiesSidebar;

export const deleteBlockHandler = (selectedBlock: any, editor: Editor, dispatch: any) => {
    const selectedElement = document.getElementById(selectedBlock!.props!.id)?.parentElement;
    const nextSiblingId =
        selectedElement &&
        selectedElement.nextSibling &&
        selectedElement.nextSibling.nextSibling?.firstChild &&
        (selectedElement.nextSibling.nextSibling?.firstChild as any).getAttribute("id");

    let thisNodePos = -1;

    editor!.view.state.doc.descendants((node, position) => {
        if (node.attrs.id == selectedBlock!.props!.id) {
            thisNodePos = position;
            return false;
        }
    });

    if (thisNodePos != -1) {
        editor!.view.dispatch(editor!.state.tr.delete(thisNodePos, thisNodePos + 1));
    }

    document.getElementById(selectedBlock!.props!.id)?.parentElement?.remove();

    setTimeout(() => {
        dispatch(setSelectedBlock(null));

        if (nextSiblingId) {
            let nextNode: any = null;
            let nodeAttrs: any = null;

            editor!.view.state.doc.descendants((node) => {
                if (node.attrs.id == nextSiblingId) {
                    nextNode = node;
                    nodeAttrs = node.attrs;
                    return false;
                }
            });

            if (nextNode && Object.keys(Types).includes(nextNode.type.name)) {
                dispatch(
                    setSelectedBlock({
                        type: nextNode.type.name,
                        props: { id: nextSiblingId, attrs: nodeAttrs },
                    })
                );
            } else {
                dispatch(setSelectedBlock(null));
            }
        } else {
            let isDivider = false;
            const excluding = ["tabsBlock", "scansOrSnapshotsBlockComponent", "projectLinksColumnComponent"];
            let isOtherNodePresent = false;

            editor!.view.state.doc.descendants((node) => {
                if (node.type.name == "divider") {
                    isDivider = true;
                } else if (isDivider && !excluding.includes(node.type.name)) {
                    isOtherNodePresent = true;
                    return false;
                }
            });

            if (!isOtherNodePresent) {
                const nodeType = editor!.schema.nodes.placeholder;
                const transaction = editor!.state.tr.insert(
                    thisNodePos,
                    (nodeType as any).createAndFill({
                        id: uuidv4(),
                    })
                );

                editor!.view.dispatch(transaction);

                const decoration_transaction = editor!.state.tr.setMeta("updateDecorations", true);
                editor!.view.dispatch(decoration_transaction);
            }

            dispatch(setSelectedBlock(null));
        }
    }, 0);
};
