import { Node } from "@tiptap/core";

export const Divider = Node.create({
    name: "divider",

    group: "block",

    content: "",

    parseHTML() {
        return [
            {
                tag: "div.editor-divider",
            },
        ];
    },

    renderHTML() {
        return ["div", { class: "editor-divider" }];
    },
});
