import React, { useEffect, useMemo, useRef } from "react";
import { IToCBlockProps } from "./ToCBlock";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import EditorCustomDropDown from "shared/components/EditorCustomDropDown/EditorCustomDropDown";

const ToCBlockProperties = ({ editor, props }: { editor: Editor | null; props: IToCBlockProps }) => {
    const { id, type, size } = props as IToCBlockProps;
    const { ArrowDown, EditorTextSizeIcon, EditorNumberedListIcon, EditorBulletListIcon } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        type: string;
        size: 1 | 2 | 3 | 4 | 5;
    }>({
        defaultValues: {
            id,
            type,
            size,
        },
    });

    const type_val = watch("type");
    const size_val = watch("size");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            type: type_val,
            size: size_val,
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const node = parentEditor!.view.state.schema.nodes.tocComponent.create(data);

        const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [type_val, size_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2 flex items-center">Type of List</div>
                    <Field
                        formGroup={false}
                        name="type"
                        type="select"
                        options={[
                            { label: "Numbered", value: "numbered" },
                            { label: "Alphabetical", value: "alphabetical" },
                            { label: "Bullet", value: "bullet" },
                        ]}
                        selectFieldProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                            },
                            IconComponent: ArrowDown,
                            CustomMenuItem: EditorCustomDropDown,
                            dropdown_data: {
                                numbered: {
                                    Icon: <EditorNumberedListIcon width={17} height={17} />,
                                    label: "Numbered",
                                    value: "numbered",
                                },
                                alphabetical: {
                                    Icon: <EditorTextSizeIcon width={17} height={17} />,
                                    label: "Alphabetical",
                                    value: "alphabetical",
                                },
                                bullet: {
                                    Icon: <EditorBulletListIcon width={17} height={17} />,
                                    label: "Bullet",
                                    value: "bullet",
                                },
                            },
                        }}
                    />
                </div>
                <div className="w-full pt-4 px-5">
                    <div className="text-sm text-[#667085] mb-2">Size</div>
                    <ToggleButtonGroup
                        value={size_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("size", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={1} aria-label="1">
                            <EditorTextSizeIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="1">
                            <EditorTextSizeIcon width={12} height={12} />
                        </ToggleButton>
                        <ToggleButton value={3} aria-label="1">
                            <EditorTextSizeIcon width={10.75} height={10.75} />
                        </ToggleButton>
                        <ToggleButton value={4} aria-label="1">
                            <EditorTextSizeIcon width={9.5} height={9.5} />
                        </ToggleButton>
                        <ToggleButton value={5} aria-label="1">
                            <EditorTextSizeIcon width={8} height={8} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default ToCBlockProperties;
