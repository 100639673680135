import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import PublishedDateBlock from "./PublishedDateBlock";

export default Node.create({
    name: "publishedDateComponent",
    content: "",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            date_str: {
                default: null,
            },
            template: {
                default: 0,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.published-date", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    date_str: dom.getAttribute("date_str"),
                    template: dom.getAttribute("template"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "published-date",
                id: node.attrs.id,
                date_str: node.attrs.date_str,
                template: node.attrs.template,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(PublishedDateBlock);
    },
});
