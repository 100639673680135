import CircularProgress from "@mui/material/CircularProgress";

const SemiCircleCard = (props: Props) => {
    return (
        <>
            <div className="h-[120px] w-[220px] overflow-hidden relative">
                <CircularProgress
                    classes={{
                        root: "!rotate-180 absolute top-0 left-0 !z-10",
                    }}
                    sx={{
                        "& .MuiCircularProgress-circle": {
                            strokeLinecap: "round",
                            color: props.color || "#7680FF",
                        },
                    }}
                    thickness={3}
                    size={220}
                    variant="determinate"
                    value={props.percentage}
                />
                <CircularProgress
                    classes={{
                        root: "!rotate-180 absolute top-0 left-0",
                    }}
                    sx={{
                        "& .MuiCircularProgress-circle": {
                            strokeLinecap: "round",
                            color: "#EAECF0",
                        },
                    }}
                    thickness={3}
                    size={220}
                    variant="determinate"
                    value={50}
                />
                <h4 className="absolute bottom-1 text-[#101828] text-[24px] leading-8 font-semibold left-1/2 transform -translate-x-1/2">
                    {props.percentText}
                </h4>
            </div>
            <div className="mb-4 mt-8">
                <h5 className="text-[#101828] text-[16px] font-semibold leading-6 mb-1">{props.title}</h5>
                <p className="text-[#475467] text-md leading-5">{props.description}</p>
            </div>
        </>
    );
};

export default SemiCircleCard;

interface Props {
    title: string;
    description: string;
    percentage: number;
    percentText: string;
    color?: string;
}
