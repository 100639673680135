import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import { TeamMember, useAddTeamManagerMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const PromoteToManager = (props: { data: TeamMember }) => {
    const Images = useImages();
    const [addManager, addRes] = useAddTeamManagerMutation();

    useEffect(() => {
        rtkHandler(addRes, {
            successMessage: "Member promoted to manager successfully",
        });
    }, [addRes]);

    return (
        <ConfirmButton
            title="Promote To Manager"
            content="Are you sure you want to promote this member to manager?"
            icon={<Images.TeamMembersLayerIconLightMode />}
            okBtnText="Confirm"
            okBtnProps={{
                loading: addRes.isLoading,
                onClick: () =>
                    addManager({
                        member_id: props.data.member_id,
                    }),
            }}
        >
            <Button variant="text" color="primary">
                Promote To Manager
            </Button>
        </ConfirmButton>
    );
};

export default PromoteToManager;
