import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetProjects: {
            providesTags: (result) =>
                result
                    ? [
                          ...result.projects.map(({ project_id }) => ({ type: "Projects", id: project_id } as const)),
                          { type: "Projects", id: "LIST" },
                      ]
                    : [{ type: "Projects", id: "LIST" }],
        },
        GetProjectById: {
            providesTags: (result, error, { id }) => (error ? [] : [{ type: "Projects", id }]),
        },
        CreateProject: {
            invalidatesTags: (_, error) => (error ? [] : [{ type: "Projects", id: "LIST" }, "Notification"]),
        },
        EditProject: {
            invalidatesTags: (_, error, { id }) => (error ? [] : [{ type: "Projects", id }, "Notification"]),
        },
        DeleteProject: {
            invalidatesTags: (_, error, { data: { id } }) => {
                return error
                    ? []
                    : [
                          { type: "Projects", id: "LIST" },
                          { type: "Projects", id },
                          { type: "MyScans", id: "LIST" },
                          "Notification",
                      ];
            },
        },
    },
});

export const {
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useShareProjectMutation,
    useCreateProjectMutation,
    useEditProjectMutation,
    useDeleteProjectMutation,
} = api;
