import { CircularProgress } from "@mui/material";
import useImages from "assets/images";
import loadScript from "load-script";
import { useMemo, useState } from "react";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { UppyFileUploaderPickerProps } from "..";
import { errorToast } from "shared/utils/toast";

const DROPBOX_APP_KEY = "4lr9oks374yd4sc"; // App key
const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";
const DROPBOX_SCRIPT_ID = "dropboxjs";

const DropboxPicker = (props: UppyFileUploaderPickerProps) => {
    const Images = useImages();
    const [loading, setLoading] = useState(false);

    const options = useMemo(
        () => ({
            success: (
                files: Array<{
                    link: string;
                    bytes: number;
                    name: string;
                }>
            ) => {
                const file = files[0] as unknown as { name: string; bytes: number; link: string; type: string };

                if (process.env.NODE_ENV === "production" && file.bytes > 1610612736) {
                    errorToast("File too large to be submitted from Dropbox. Please manually upload.");
                    return;
                }

                props.onFileSelect &&
                    props.onFileSelect({
                        name: file.name,
                        size: file.bytes,
                        pickerType: "dropbox",
                        url: file.link,
                    });
            },
            linkType: "direct",
            multiselect: props.multiple || false,
            extensions:
                props.type === "zip"
                    ? [".zip"]
                    : props.type === "image"
                    ? [".jpg", ".png"]
                    : props.type === "video"
                    ? [".mp4"]
                    : [".jpg", ".png", ".mp4"],
            folderselect: false,
        }),
        []
    );

    const onClick = () => {
        if ((window as any).Dropbox) {
            (window as any).Dropbox.choose(options);
            return;
        }
        setLoading(true);
        loadScript(
            DROPBOX_SDK_URL,
            {
                attrs: {
                    id: DROPBOX_SCRIPT_ID,
                    "data-app-key": DROPBOX_APP_KEY,
                },
            },
            () => {
                setLoading(false);
                (window as any).Dropbox.choose(options);
            }
        );
    };

    return (
        <div className="relative">
            {loading && (
                <div className="absolute -top-1 -left-1 w-full h-full z-10 bg-white bg-opacity-70">
                    <CircularProgress size={40} />
                </div>
            )}
            <ThemeIconButton
                tooltipTitle="Dropbox"
                tooltipPosition="top"
                icon={<LayerIcon icon={Images.DropboxIcon} iconColor="#475467" color="default" />}
                onClick={onClick}
            />
        </div>
    );
};

export default DropboxPicker;
