import { TextField } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import useImages from "assets/images";

import { useThemeListCardContext } from "../context";

const SearchBox = () => {
    const Images = useImages();
    const { search, setSearch, isSearchEnabled, setIsSearchEnabled, props } = useThemeListCardContext();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isSearchEnabled) {
            inputRef.current?.focus();
        }
    }, [isSearchEnabled]);

    return (
        <TextField
            classes={{
                root: twMerge(
                    "!h-8 transition-width duration-300 ease-in-out",
                    isSearchEnabled ? "w-auto" : "!w-[32px]",
                    props?.searchProps?.classes?.root
                ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            sx={{
                "& .MuiInputBase-root": {
                    paddingInline: "8px",
                    width: isSearchEnabled ? "auto" : "32px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    transition: "width 300ms ease-in-out",

                    "& .MuiInputBase-input": {
                        height: "32px",
                        paddingInline: isSearchEnabled ? "8px" : "0",
                        opacity: isSearchEnabled ? 1 : 0,
                        visibility: isSearchEnabled ? "visible" : "hidden",
                        width: isSearchEnabled ? "100%" : "0px",
                        transition: "width 300ms ease-in-out, opacity 300ms ease-in-out, visibility 300ms ease-in-out",
                    },
                },
                ...props?.searchProps?.sx,
            }}
            placeholder="Search here..."
            inputProps={{
                ref: inputRef,
                onClick: () => setIsSearchEnabled(true),
                ...props?.searchProps?.inputProps,
            }}
            InputProps={{
                onClick: () => !isSearchEnabled && setIsSearchEnabled(true),
                startAdornment: <Images.SearchIcon />,
                endAdornment: isSearchEnabled ? (
                    <Images.CloseIcon
                        onClick={() => {
                            setIsSearchEnabled(false);
                            setSearch("");
                        }}
                        className="cursor-pointer"
                        height={16}
                        width={16}
                        color="#98A2B3"
                    />
                ) : null,
                ...props?.searchProps?.InputProps,
            }}
            {...omit(props?.searchProps, ["classes", "sx", "InputProps", "inputProps"])}
        />
    );
};

export default SearchBox;
