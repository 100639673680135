import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Embed from "./Embed";

export default Node.create({
    name: "embedComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            link: {
                default: null,
            },
            width: {
                default: 100,
            },
            height: {
                default: 400,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
            redirecting: {
                default: true,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.embed-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    link: dom.getAttribute("link"),
                    width: parseInt(dom.getAttribute("width")),
                    height: parseInt(dom.getAttribute("height")),
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                    redirecting: dom.getAttribute("redirecting") === "true",
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "embed-component",
                id: node.attrs.id,
                link: node.attrs.link,
                width: node.attrs.width.toString(),
                height: node.attrs.height.toString(),
                justify: node.attrs.justify,
                caption: node.attrs.caption.toString(),
                caption_str: node.attrs.caption_str,
                redirecting: node.attrs.redirecting.toString(),
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Embed);
    },
});
