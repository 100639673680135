import { Skeleton } from "@mui/material";

interface Props {
    tabsLength: number;
}

const NotificationSidebarLoader = ({ tabsLength }: Props) => {
    return (
        <>
            <div className="flex items-center border-b h-[50px] px-6 mb-5 py-2">
                <div className="flex items-center space-x-4">
                    {[...Array(tabsLength)].map((_, index) => (
                        <Skeleton variant="rounded" width={100} height={40} key={index} />
                    ))}
                </div>
            </div>
            <div className="px-6 space-y-4">
                <Skeleton variant="rounded" height={120} />
                <Skeleton variant="rounded" height={120} />
                <Skeleton variant="rounded" height={120} />
                <Skeleton variant="rounded" height={120} />
            </div>
        </>
    );
};

export default NotificationSidebarLoader;
