import { RouteObject } from "react-router-dom";
import JoinTeamRequestSubmittedScreen from "screens/Auth/screens/JoinTeamRequestSubmitted";
import NotFound from "screens/Errors/404";
import UnderMaintenance from "screens/Errors/503";
import URL from "shared/constants/navigator";

const publicRoutes: RouteObject[] = [
    {
        path: URL.Join_Team_Request_Submitted,
        element: <JoinTeamRequestSubmittedScreen />,
    },
    {
        path: URL.Under_Maintenance,
        element: <UnderMaintenance />,
    },
    { path: "*", element: <NotFound /> },
];

export default publicRoutes;
