import DummyUserImage from "assets/images/dummy/dummy-profile-pic.png";
import { twMerge } from "tailwind-merge";
interface IUserProfileCardProps {
    image?: string | React.ReactNode;
    name: string | React.ReactNode;
    email: string | React.ReactNode;
    classes?: {
        root?: string;
        image?: string;
        name?: string;
        email?: string;
        extra_details?: string;
    };
}

const UserProfileCard = (props: IUserProfileCardProps) => {
    const { image, name, email, classes } = props;

    const rootClasses = classes?.root ?? "";
    const imageClasses = classes?.image ?? "";
    const nameClasses = classes?.name ?? "";
    const emailClasses = classes?.email ?? "";
    const extraDetailsClasses = classes?.extra_details ?? "";

    return (
        <div className={twMerge("flex flex-row items-center space-x-3", rootClasses)}>
            {typeof image === "string" ? (
                <img
                    src={image || DummyUserImage}
                    className={twMerge("w-10 h-10 rounded-full object-cover", imageClasses)}
                />
            ) : (
                image
            )}
            <div className={twMerge("text-left whitespace-nowrap text-ellipsis overflow-hidden", extraDetailsClasses)}>
                <div
                    className={twMerge(
                        "text-md text-[#101828] leading-5 whitespace-nowrap text-ellipsis overflow-hidden",
                        nameClasses
                    )}
                    style={{
                        textTransform: "unset",
                    }}
                >
                    {name}
                </div>
                <div
                    className={twMerge(
                        "text-md text-[#667085] leading-5 whitespace-nowrap text-ellipsis overflow-hidden",
                        emailClasses
                    )}
                >
                    {email}
                </div>
            </div>
        </div>
    );
};

export default UserProfileCard;
