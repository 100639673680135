import useImages from "assets/images";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import { useDownloadModelQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const useDownloadSrModel = () => {
    const [selected, setSelected] = useState("");
    const { ArrowDownSquare, ResearchIcon, RunIcon } = useImages();
    const { request } = useSingleSR();
    const [isDownloading, setIsDownloading] = useState(false);
    const navigate = useNavigate();
    const { scanId } = useParams<{ scanId: string }>();
    const downloadQuery = useDownloadModelQuery(
        { scan_id: parseInt(scanId || "0") },
        {
            skip: !isDownloading,
        }
    );

    const scan = request?.applications
        .find((req) => req.submissions.find((sub) => sub.scan_id === parseInt(scanId!)))
        ?.submissions.find((sub) => sub.scan_id === parseInt(scanId!));

    const onClickITD = (val: any) => {
        setSelected((prev) => (prev === val ? "" : val));
    };

    const startDownload = () => {
        if (isDownloading) {
            downloadQuery.refetch();
        } else {
            setIsDownloading(true);
        }
    };

    const Options = useMemo(() => {
        return [
            {
                value: "research",
                icon: <ResearchIcon />,
                title: "Research",
                desc: "To research the nuances of the model files we built from your video.",
            },
            {
                value: "run-locally",
                icon: <RunIcon />,
                title: "Run Locally",
                desc: "To start, view, and research the model locally with downloaded files.",
            },
            {
                value: "import",
                icon: <ArrowDownSquare />,
                title: "Backup",
                desc: "To have a backup of model files to the local computer for future use cases.",
            },
        ];
    }, [ResearchIcon, RunIcon, ArrowDownSquare]);

    useEffect(() => {
        rtkHandler(downloadQuery, {
            successMessage: "Download link will be sent to your email shortly. Please check your inbox.",
            onSuccess() {
                navigate(`/requests/in-progress/detail/${request?.scan_request_id}/scans/${scanId}`);
            },
        });
    }, [downloadQuery]);

    return { Options, scan, selected, downloadQuery, onClickITD, startDownload };
};

export default useDownloadSrModel;
