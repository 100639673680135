import FileUploader from "shared/components/UppyUploader/FileUploader";
import useFileUploader from "shared/hooks/useFileUploader";

const PlaygroundScreen = () => {
    const { uploadFile } = useFileUploader();

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            uploadFile(file);
        };
        reader.readAsText(file);
    };

    return (
        <div className="h-full w-full flex items-center justify-center gap-y-10 flex-col">
            <input type="file" onChange={handleImageUpload} />
            <FileUploader plugins={["Dropbox", "Google-Drive", "Local"]} type="video" />
        </div>
    );
};

export default PlaygroundScreen;
