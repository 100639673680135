import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Video from "./Video";

export default Node.create({
    name: "videoComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            type: {
                default: "upload",
            },
            video: {
                default: null,
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.video-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    type: dom.getAttribute("type"),
                    video: dom.getAttribute("video"),
                    width: parseInt(dom.getAttribute("width")) || 100,
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        const customAttributes = {
            class: "video-component",
            id: node.attrs.id,
            type: node.attrs.type,
            video: node.attrs.video,
            style: `width: ${node.attrs.width}%; justify-content: ${node.attrs.justify};`,
            caption: node.attrs.caption.toString(),
            caption_str: node.attrs.caption_str,
        };

        return ["div", customAttributes, 0]; // This node doesn't have any direct content
    },

    addNodeView() {
        return ReactNodeViewRenderer(Video);
    },
});
