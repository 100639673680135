import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { routeWithParams } from "shared/utils/helpers";

import { EditProjectMutation, useEditProjectMutation } from "shared/graphql";
import useFileUploader from "shared/hooks/useFileUploader";
import { rtkHandler } from "shared/utils/handlers";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";
import { setProjectRefetchApi, setSelectedProject } from "store/slices/projects";
import { resetEditor, setDefaultContent, setSelectedBlock, togglePreview } from "store/slices/publishable_project";
import { v4 as uuidv4 } from "uuid";
import { Types, deleteBlockHandler } from "./PublishableProject/partials/PropertiesSidebar/PropertiesSidebar";

const usePublishableProjectLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const { uploadFile, loading: isUploading, loader_id: uploadId } = useFileUploader();
    const [updateProject, updateRes] = useEditProjectMutation();

    const { isSelectingTemplate, isPreviewOpen, defaultContent, selectedBlock } = useAppSelector((state) => ({
        isSelectingTemplate: state.publishable_project.isSelectingTemplate,
        isPreviewOpen: state.publishable_project.isPreviewOpen,
        defaultContent: state.publishable_project.defaultContent,
        selectedBlock: state.publishable_project.selectedBlock,
    }));

    const project = useAppSelector((state) => state.projects.selected);

    const editor = useAppSelector((state) => state.publishable_project.main_editor);

    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const [isSavedOnce, setIsSavedOnce] = useState(false);
    const [isShowPageLoader, setIsShowPageLoader] = useState(false);
    const [saveFrom, setSaveFrom] = useState("save");

    const isOnboardingFlow = Boolean(
        matchRoutes(
            [
                {
                    path: "/complete-profile/project/:id/editor",
                },
            ],
            location
        )
    );

    const onOpenProject = () => {
        editor?.destroy();
        dispatch(resetEditor());

        setTimeout(() => {
            navigate(
                routeWithParams(`${URL.Projects}/${URL.Projects_Detail}`, {
                    id,
                })
            );
        }, 0);
    };

    const onSkipOrNextStep = () => {
        navigate("/complete-profile/final");
    };

    const onSaveAndPreviewClickedHandler = (from?: "back" | "share" | "auto") => {
        if (from) {
            if (from == "back") {
                setIsShowPageLoader(true);
            } else if (from == "share") {
                dispatch(setCommonModalLoadingBtn("change-visibility-btn"));
            }
            setSaveFrom(from);
        }

        const contentJSON = { ...editor?.getJSON() };
        const finalJSON = {
            type: contentJSON["type"],
            content: [] as any[],
        };

        for (let i = 0; i < (contentJSON as any)["content"]?.length; i++) {
            if (
                ((contentJSON as any)["content"][i] as any)["attrs"] &&
                "editor" in ((contentJSON as any)["content"][i] as any)["attrs"]
            ) {
                const tempAttrs = { ...((contentJSON as any)["content"][i] as any)["attrs"] };

                if ("editor" in tempAttrs) {
                    delete tempAttrs["editor"];
                }

                finalJSON.content.push({
                    type: ((contentJSON as any)["content"][i] as any)["type"],
                    attrs: tempAttrs,
                });
            } else if (
                ((contentJSON as any)["content"][i] as any)["type"] != "placeholder" &&
                ((contentJSON as any)["content"][i] as any)["type"] != "paragraphDiv"
            ) {
                finalJSON.content.push({ ...((contentJSON as any)["content"][i] as any) });
            }
        }

        const contentString = JSON.stringify(finalJSON);

        if (contentString != defaultContent || from == "share") {
            const blob = new Blob([contentString], { type: "application/json" });

            // Create a File from the Blob
            const fileName = `${project?.name}_${uuidv4()}.json`; // Name your file
            const file = new File([blob], fileName, { type: "application/json" });

            if (file) {
                uploadFile(file, "project-content-upload")
                    .then((res: any) => {
                        dispatch(setDefaultContent(contentString));

                        updateProject({
                            id: project!.project_id,
                            data: {
                                published_file_id: res.file_id,
                                ...(isOnboardingFlow ? { visible: true } : {}),
                                ...(from == "share" ? { visible: !project!.visible } : {}),
                            },
                        });
                    })
                    .catch(() => {
                        setIsShowPageLoader(false);
                        dispatch(setCommonModalLoadingBtn(""));
                    });
            }
        } else {
            setIsShowPageLoader(false);
            dispatch(setCommonModalLoadingBtn(""));

            if (from == "back") {
                onOpenProject();
            }
        }

        if (!from) {
            dispatch(togglePreview());
        }
    };

    const onClosePreviewClickedHandler = () => {
        dispatch(togglePreview());
    };

    const showShareDialog = () => {
        setIsShareDialogOpen(true);
    };

    const hideShareDialog = () => {
        setIsShareDialogOpen(false);
    };

    const isSaving = updateRes.isLoading || (isUploading && uploadId == "project-content-upload");

    useEffect(() => {
        if (!project) {
            // if (isOnboardingFlow) {
            //     return navigate(URL.Dashboard, { replace: true });
            // }
            onOpenProject();
        }
    }, [project]);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: saveFrom == "auto" || saveFrom == "share" ? undefined : "Saved successfully.",
            onSuccess({ edit_project }: EditProjectMutation) {
                setIsShowPageLoader(false);

                dispatch(setProjectRefetchApi(true));

                setIsSavedOnce(true);

                if (saveFrom == "back") {
                    onOpenProject();
                } else if (saveFrom == "share") {
                    dispatch(setCommonModalLoadingBtn(""));
                    dispatch(setSelectedProject({ ...project, visible: edit_project.visible }));
                }
            },
            onError() {
                setIsShowPageLoader(false);
                dispatch(setCommonModalLoadingBtn(""));
            },
        });
    }, [updateRes, navigate, saveFrom]);

    useEffect(() => {
        let periodicSaveTimer: any = null;

        const timerFunc = () => {
            onSaveAndPreviewClickedHandler("auto");

            periodicSaveTimer = setTimeout(timerFunc, 30 * 1000);
        };

        if (!isSelectingTemplate) {
            periodicSaveTimer = setTimeout(timerFunc, 30 * 1000);
        }

        return () => {
            if (!isSelectingTemplate) {
                clearTimeout(periodicSaveTimer);
            }
        };
    }, [isSelectingTemplate, project, defaultContent]);

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key == "Escape") {
                dispatch(setSelectedBlock(null));
            } else if (event.key == "Delete") {
                if (selectedBlock && editor) {
                    deleteBlockHandler(selectedBlock, editor, dispatch);
                }
            } else if (event.key == "Tab" && selectedBlock && editor) {
                const selectedElement = document.getElementById(selectedBlock!.props!.id)?.parentElement;
                const nextSiblingId =
                    selectedElement &&
                    selectedElement.nextSibling &&
                    selectedElement.nextSibling.nextSibling?.firstChild &&
                    (selectedElement.nextSibling.nextSibling?.firstChild as any).getAttribute("id");

                if (nextSiblingId) {
                    let nextNode: any = null;
                    let nodeAttrs: any = null;

                    editor!.view.state.doc.descendants((node) => {
                        if (node.attrs.id == nextSiblingId) {
                            nextNode = node;
                            nodeAttrs = node.attrs;
                            return false;
                        }
                    });

                    if (nextNode && Object.keys(Types).includes(nextNode.type.name)) {
                        dispatch(
                            setSelectedBlock({
                                type: nextNode.type.name,
                                props: { id: nextSiblingId, attrs: nodeAttrs },
                            })
                        );
                    }
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [editor, selectedBlock]);

    return {
        isSelectingTemplate,
        project,
        onOpenProject,
        onSaveAndPreviewClickedHandler,
        onClosePreviewClickedHandler,
        isShareDialogOpen,
        showShareDialog,
        hideShareDialog,
        isPreviewOpen,
        isSaving,
        isOnboardingFlow,
        updateRes,
        onSkipOrNextStep,
        isSavedOnce,
        isShowPageLoader,
    };
};

export default usePublishableProjectLayout;
