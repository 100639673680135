import { setProfile, setUser } from "store/slices/user";
import { Profile, User, api as generatedApi } from "../../generated";
import { setToken } from "store/slices/token";
import { setTeam } from "store/slices/teams";
import { setNotificationCount } from "store/slices/notifications";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        Login: {
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const {
                        data: { login },
                    } = await queryFulfilled;
                    const { token, profile, ...others } = login;
                    dispatch(generatedApi.util.resetApiState());
                    dispatch(setUser(others as User));
                    dispatch(setProfile(profile as Profile));
                    dispatch(setToken(token));
                } catch (err) {
                    return;
                }
            },
        },

        VerifyOtp: {
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const {
                        verify_otp: { token, user },
                    } = data;
                    if (token) {
                        dispatch(generatedApi.util.resetApiState());
                        dispatch(setUser(user as User));
                        dispatch(setToken(token));
                    }
                } catch (err) {
                    return;
                }
            },
        },
        VerifyToken: {
            providesTags: ["VerifyToken"],
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const {
                        data: { verify_token },
                    } = await queryFulfilled;
                    dispatch(generatedApi.util.resetApiState());
                    dispatch(setUser(verify_token as User));
                } catch (err) {
                    return;
                }
            },
        },
        GetUserDetails: {
            providesTags: ["UserDetails"],
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const {
                        data: { team, profile, notifications },
                    } = await queryFulfilled;
                    dispatch(setProfile(profile as Profile));
                    dispatch(setTeam(team));
                    const unreadNotifs = notifications.filter((notif) => !notif.read);
                    dispatch(setNotificationCount(unreadNotifs.length));
                } catch (err) {
                    return;
                }
            },
        },
    },
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useVerifyTokenQuery,
    useVerifyOtpMutation,
    useResendPhoneOtpMutation,
    useResendEmailOtpMutation,
    useForgotPasswordMutation,
    useVerifyForgotPasswordQuery,
    useResetUserPasswordMutation,
    useGetUserDetailsQuery,
    useChangeRegisterEmailMutation,
    useUpdateUserRegisterationHistoryMutation,
    useResendSignupEmailMutation,
    useUpdateUserPreferencesMutation,
    useGiveRatingToOnboardingMutation,
} = api;
