import { pick } from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    GetScanRequestByIdQuery,
    useAcceptSubmissionMutation,
    useGetScanRequestByIdQuery,
    useGiveFeedbackToSubmissionMutation,
    useRequestReviewMutation,
    useRequestRevisionMutation,
    useSendSubmissionReminderMutation,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setSelectScanRequest } from "store/slices/scan_requests";

const useSingleSR = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const request = useAppSelector((state) => state.scan_request.selected);
    const dispatch = useAppDispatch();

    const [sendRemider, reminderRes] = useSendSubmissionReminderMutation();
    const [acceptSubmission, acceptRes] = useAcceptSubmissionMutation();
    const [requestRevision, revisionRes] = useRequestRevisionMutation();
    const [giveFeedback, feedbackRes] = useGiveFeedbackToSubmissionMutation();
    const [requestReview, reviewRes] = useRequestReviewMutation();

    const { user } = useAuth();

    const isRequestor = request?.requestor.user_id === user?.user_id;

    const scan_request_id = parseInt(id || "0");

    const res = useGetScanRequestByIdQuery(
        {
            scan_request_id,
        },
        {
            skip: !scan_request_id,
        }
    );

    useEffect(() => {
        rtkHandler(res, {
            onSuccess(response: GetScanRequestByIdQuery) {
                dispatch(setSelectScanRequest(response.scan_request));
            },
            onError: () => {
                navigate("/requests/new", {
                    replace: true,
                });
            },
        });
    }, [res]);

    useEffect(() => {
        rtkHandler(reminderRes, {
            successMessage: "Reminder sent successfully",
        });
    }, [reminderRes]);

    useEffect(() => {
        rtkHandler(acceptRes, {
            successMessage: "Submission accepted successfully",
        });
    }, [acceptRes]);

    useEffect(() => {
        rtkHandler(revisionRes, {
            successMessage: "Revision requested successfully",
        });
    }, [revisionRes]);

    useEffect(() => {
        rtkHandler(reviewRes, {
            successMessage: "Your application has been submitted and will be reviewed by the requestor",
            toastOptions: {
                duration: 5000,
            },
        });
    }, [reviewRes]);

    return {
        request,
        sendRemider,
        acceptSubmission,
        requestRevision,
        giveFeedback,
        requestReview,
        isRequestor,
        reviewRes,
        reminderRes,
        acceptRes,
        revisionRes,
        feedbackRes,
        ...pick(res, "isLoading", "isFetching"),
    };
};

export default useSingleSR;
