const Roles = {
    "Film/Media": [
        "Location Manager",
        "Production Designer",
        "Scout Coordinator",
        "Set Decorator",
        "Art Director",
        "Production Manager",
        "Producer",
        "Director",
        "Transportation Coordinator",
        "Director of Photography",
    ],
    "Real Estate": [
        "Appraiser",
        "Property Manager",
        "Broker",
        "Site Inspector",
        "Investor",
        "Development Director",
        "Agent",
        "Land Surveyor",
        "Adjuster",
        "Property Assessor",
    ],
    Construction: [
        "Site Manager",
        "Surveyor",
        "Engineer",
        "Project Manager",
        "Safety Officer",
        "Architect",
        "Environmental Consultant",
        "QA Inspector",
        "Equipment Coordinator",
        "Team Lead",
    ],
    Journalism: [
        "Investigative Reporter",
        "Photojournalist",
        "Field Producer",
        "News Correspondent",
        "Documentary Filmmaker",
        "Assignment Editor",
        "Bureau Chief",
        "Specialist Correspondent",
        "Travel Journalist",
        "Environmental Reporter",
    ],
    "Government/City Services": [
        "Urban Planner",
        "City Inspector",
        "Police Department",
        "Transportation Planner",
        "Environmental Health Specialist",
        "Building Commissioner",
        "Land Use Planner",
        "Code Enforcement Officer",
        "Economic Development Director",
        "Emergency Management Coordinator",
    ],
    "E-commerce": [
        "Solo Reseller",
        "Digital Marketing Specialist",
        "Supply Chain Manager",
        "Warehouse Site Selector",
        "Logistics Coordinator",
        "Distribution Center Manager",
        "Retail Site Analyst",
        "Operations Manager",
        "Delivery Route Planner",
        "Facility Manager",
    ],
};

export default Roles;
