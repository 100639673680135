import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import StyledText from "./StyledText";

export default Node.create({
    name: "styledText",
    content: "inline*",
    group: "block",
    defining: true,
    draggable: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            size: {
                default: 1, // Set a default size
            },
            color: {
                default: null, // Default color (none)
            },
            alignment: {
                default: "left", // Set default alignment
            },
            content: {
                default: null,
            },
            custom_class: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.styled-text", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    size: parseInt(dom.getAttribute("size")) || 1,
                    color: dom.getAttribute("color"),
                    alignment: dom.getAttribute("alignment"),
                    content: dom.getAttribute("content") || null,
                    custom_class: dom.getAttribute("custom_class") ?? "",
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "styled-text",
                id: node.attrs.id,
                size: node.attrs.size,
                color: node.attrs.color,
                alignment: node.attrs.alignment,
                content: node.attrs.content,
                custom_class: node.attrs.custom_class,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(StyledText);
    },
});
