import React, { PropsWithChildren, createContext, useEffect, useRef, useState } from "react";
import useAuth from "shared/hooks/useAuth";
import Talk from "talkjs";

const TalkJSProvider = (props: PropsWithChildren) => {
    const [talkLoaded, markTalkLoaded] = useState(false);
    const [session, setSession] = useState<Talk.Session | null>(null);
    const TalkJSRef = useRef<Talk.Session | null>(null);
    const { user, team } = useAuth();

    useEffect(() => {
        Talk.ready.then(() => markTalkLoaded(true));

        if (talkLoaded && user && team) {
            const me = team.members.find((m) => m.user?.user_id === user.user_id);
            if (!me || !me.user) return;

            const currentUser = new Talk.User({
                id: me.member_id,
                name: me.user.name ?? "",
                email: me.user.email,
                photoUrl: me.user.profile_image.url,
                role: "default",
            });

            const TalkJSSession = new Talk.Session({
                appId: process.env.REACT_APP_TALKJS_APP_ID,
                me: currentUser,
            });

            TalkJSRef.current = TalkJSSession;

            setSession(TalkJSSession);

            return () => {
                TalkJSRef.current?.destroy();
            };
        }
    }, [talkLoaded, user, team]);

    return (
        <TalkJSContextProvider.Provider
            value={{
                talkjs: session,
            }}
        >
            {props.children}
        </TalkJSContextProvider.Provider>
    );
};

export default TalkJSProvider;

const TalkJSContextProvider = createContext<{
    talkjs: Talk.Session | null;
}>({
    talkjs: null,
});

export const useTalkJS = () => {
    const context = React.useContext(TalkJSContextProvider);
    if (context === undefined) {
        throw new Error("useTalkJS must be used within a TalkJSContextProvider");
    }
    return context;
};
