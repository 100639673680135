import useImages from "assets/images";
import { Link, Outlet } from "react-router-dom";
import URL from "shared/constants/navigator";

const BoardsLayout = () => {
    const { Logo } = useImages();

    return (
        <div>
            <div className="p-8">
                <Link to={URL.Home}>
                    <Logo />
                </Link>
            </div>
            <div className="px-16 pb-10">
                <Outlet />
            </div>
        </div>
    );
};

export default BoardsLayout;
