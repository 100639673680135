import useImages from "assets/images";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactPlayer from "react-player";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { convertSizeToText } from "shared/utils/helpers";

const FilePreview = () => {
    const Images = useImages();
    const [play, setPlay] = useState(false);
    const context = useFormContext();
    const { name, size, url } = context.watch("filePreview");

    const onRemove = () => {
        context.setValue("filePreview", null);
        context.setValue("input_videos", null);
    };

    return (
        <div className="h-[400px] rounded-md relative overflow-hidden border border-[#D0D5DD]">
            <ThemeIconButton
                className="top-4 right-4 !bg-[#667085] !absolute !rounded-full z-30"
                icon={<Images.DeleteIcon color="#ffffff" />}
                onClick={onRemove}
            />
            <ReactPlayer url={url} width="100%" height="400px" playing={play} loop muted controls={false} />
            <div className="absolute top-1/2 -translate-y-1/2 left-0 flex items-center justify-center space-x-10 w-full">
                <div
                    className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                    onClick={() => setPlay(!play)}
                >
                    {play ? <Images.PauseIcon /> : <Images.PlayButtonIcon />}
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full pt-6 p-4 bg-gradient-to-t from-[#000000A9] to-transparent">
                <h4 className="text-[#ffffff] leading-5 font-medium text-lg">{name}</h4>
                <p className="text-[#ffffff] leading-5 font-normal text-md">{convertSizeToText(size)}</p>
            </div>
        </div>
    );
};

export default FilePreview;
