import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ButtonGroup } from "@mui/material";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";
import ChipBox from "shared/components/ChipBox";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import {
    GetUserPromoCodeQuery,
    PlanPeriod,
    useGetSubscriptionsQuery,
    useGetUserPromoCodeQuery,
    useRemovePromoCodeMutation,
    useUsePromoCodeMutation,
} from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { twMerge } from "tailwind-merge";
import SettingPageCard from "../../partials/SettingPageCard";
import PlanCards from "./partials/PlanCards";
import SubscriptionDetails from "./partials/SubscriptionDetails";
const schema = yup.object().shape({
    promo_code: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const ShopScreen = () => {
    const userPromoCode = useGetUserPromoCodeQuery();
    let currentPlan: any = useGetSubscriptionsQuery().data?.subscriptions[0] || null;
    currentPlan = currentPlan ? currentPlan.status === "Active" : null;
    const Images = useImages();
    const [removePromoCode, removePromoRes] = useRemovePromoCodeMutation();
    const [submitPromoCode, promoRes] = useUsePromoCodeMutation();
    const [promoCodeModal, setPromoCodeModal] = useState(false);
    const [removePromoCodeModal, setRemovePromoCodeModal] = useState(false);
    const [interval, setInterval] = useState<PlanPeriod>(PlanPeriod.Monthly);
    const [promoCode, setPromoCode] = useState("");

    const form = useForm<FormValues>({
        defaultValues: {
            promo_code: "",
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(userPromoCode, {
            showErrorToast: false,
            onSuccess(response: GetUserPromoCodeQuery) {
                setPromoCode(response.user_promo_code ? response.user_promo_code.code : "");
            },
            onError() {
                setPromoCode("");
            },
        });
    }, [userPromoCode]);

    useEffect(() => {
        rtkHandler(removePromoRes, {
            successMessage: "Promo code removed successfully",
            onSuccess() {
                setRemovePromoCodeModal(false);
            },
        });
    }, [removePromoRes]);

    useEffect(() => {
        rtkHandler(promoRes, {
            successMessage: "Promo code applied successfully",
            onSuccess() {
                form.reset();
                setPromoCodeModal(false);
            },
        });
    }, [promoRes]);

    const onSubmit = (data: FormValues) => {
        submitPromoCode(data);
    };

    return (
        <>
            <SubscriptionDetails />

            <SettingPageCard
                title="Plans"
                subTitle="Get Access to more features and upgrade your account."
                rightComponent={
                    <div className="flex items-center space-x-4">
                        {!currentPlan && (
                            <LoaderButton
                                loading={promoRes.isLoading}
                                variant="outlined"
                                color="inherit"
                                loadingText="Fetching..."
                                onClick={() => (promoCode ? setRemovePromoCodeModal(true) : setPromoCodeModal(true))}
                                endIcon={promoCode ? <Images.CloseIcon /> : null}
                            >
                                {promoCode ? promoCode : "Add Promo Code"}
                            </LoaderButton>
                        )}
                        <ButtonGroup color="inherit">
                            <Button
                                className={twMerge(interval === PlanPeriod.Monthly && "!bg-[#F9FAFB]")}
                                onClick={() => setInterval(PlanPeriod.Monthly)}
                            >
                                Monthly
                            </Button>
                            <Button
                                className={twMerge(interval === PlanPeriod.Annually && "!bg-[#F9FAFB]")}
                                onClick={() => setInterval(PlanPeriod.Annually)}
                            >
                                Annual
                                <ChipBox
                                    color="primary"
                                    variant="filled"
                                    label="33% off"
                                    className="ml-2 !text-white !bg-[#9199FF] !border-none leading-[18px] !text-sm !font-normal"
                                />
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            >
                <PlanCards interval={interval} />
            </SettingPageCard>

            <Outlet />

            <ThemeModal
                open={promoCodeModal}
                title="Add Promo Code"
                icon={<Images.KeyLayerIcon />}
                content={"Add your promo code here to activate benefits in your shop page."}
                onCloseModal={() => setPromoCodeModal(false)}
                buttons={[
                    {
                        loading: promoRes.isLoading,
                        children: "Submit",
                        onClick: form.handleSubmit(onSubmit),
                    },
                ]}
            >
                <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left">
                    <Field formGroup={false} name="promo_code" label="Promo Code" placeholder="Enter your promo code" />
                </ThemeFormProvider>
            </ThemeModal>

            <ThemeModal
                open={removePromoCodeModal}
                title="Remove Promo Code"
                icon={<Images.DeleteLayerIcon />}
                content={"Are you sure you want to remove your promo code?"}
                onCloseModal={() => setRemovePromoCodeModal(false)}
                buttons={[
                    {
                        loading: removePromoRes.isLoading,
                        color: "error",
                        children: "Remove",
                        onClick: () => removePromoCode(),
                    },
                ]}
            />
        </>
    );
};

export default ShopScreen;
