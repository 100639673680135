import { Button } from "@mui/material";
import Field from "shared/components/ui/Field/Field";
import SettingPageCard from "../../partials/SettingPageCard";
import useImagineXSettingScreen from "./hook";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";

const ImagineXSettingScreen = () => {
    const { form, onSubmit } = useImagineXSettingScreen();

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit}>
            <SettingPageCard
                title="Imagine-X Account Info"
                subTitle="XSpada is proud to have Imagine-X Account syncing! After syncing your Imagine-X account, you will be able to view all of you scan files from both XSpada & Imagine-X, all in one model viewer. To Sync your accounts, please enter you Imagine-X account information and press ‘Sync Accounts’"
                rightComponent={
                    <div className="flex items-center space-x-2">
                        <Button type="submit" variant="outlined" color="inherit">
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" className="whitespace-nowrap">
                            Sync Account
                        </Button>
                    </div>
                }
            >
                <div className="border-b">
                    <Field
                        name="user_id"
                        label="User ID"
                        placeholder="Enter your user id"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-[280px,1fr] gap-9"
                    />
                </div>
                <div className="border-b pt-5">
                    <Field
                        name="username"
                        label="Imagine X Username"
                        placeholder="Enter your username"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-[280px,1fr] gap-9"
                    />
                </div>
                <div className="pt-5">
                    <Field
                        name="password"
                        label="Imagine X Password"
                        placeholder="Enter your password"
                        inlineFlex={true}
                        containerClassName="grid grid-cols-[280px,1fr] gap-9"
                    />
                </div>
            </SettingPageCard>
        </ThemeFormProvider>
    );
};

export default ImagineXSettingScreen;
