import { Skeleton } from "@mui/material";

const AccountListLoader = () => {
    return (
        <>
            {[...Array(5)].map((_, index) => {
                return (
                    <div
                        className="flex items-start space-x-4 w-full px-2 py-4 border-b border-[#D0D5DD80]"
                        key={index}
                    >
                        <Skeleton width={35} height={50} />
                        <div className="w-full">
                            <Skeleton variant="text" />
                            <Skeleton variant="text" className="w-1/3" />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default AccountListLoader;
