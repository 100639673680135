import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useState } from "react";
import { useAppSelector } from "shared/hooks/useRedux";

export interface ITabsBlockProps {
    id: string;
    template: number;
}

const TabsBlock = forwardRef((props: any, ref) => {
    const { id } = props.node.attrs as ITabsBlockProps;

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);

    const [selectedTab, setSelectedTab] = useState("project");

    useEffect(() => {
        const nodes: any[] = [];
        document
            .querySelectorAll<any>(
                ".react-renderer:not(.node-publishedDateComponent,.node-projectBannerComponent,.node-tabsBlock,.node-projectLinksColumnComponent,.node-scansOrSnapshotsBlockComponent)"
            )
            .forEach((el) => {
                if (!el.querySelector("#mainHeading,#mainSubtitle")) {
                    nodes.push(el);
                }
            });

        document.querySelector(".editor-preview")?.classList.remove("selected-tab-project");
        document.querySelector(".editor-preview")?.classList.remove("selected-tab-scans");
        document.querySelector(".editor-preview")?.classList.remove("selected-tab-snapshots");

        document.querySelector(".editor-preview")?.classList.add("selected-tab-" + selectedTab);

        if (selectedTab == "project") {
            nodes.forEach((el) => el.classList.remove("tab-hidden"));
        } else {
            nodes.forEach((el) => el.classList.add("tab-hidden"));
        }
    }, [selectedTab]);

    useEffect(() => {
        setSelectedTab("project");
    }, [selectedTemplate]);

    return (
        <>
            <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
                <div className={`editor-tabs w-fit mx-auto flex items-center gap-x-6 z-10`}>
                    <div
                        className={
                            "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-medium text-[#3F3E3E] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                            (selectedTab == "project" ? "!border-[#7680FF] !text-[#7680FF]" : "")
                        }
                        onClick={() => {
                            setSelectedTab("project");
                        }}
                    >
                        Projects
                    </div>
                    <div
                        className={
                            "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-medium text-[#3F3E3E] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                            (selectedTab == "scans" ? "!border-[#7680FF] !text-[#7680FF]" : "")
                        }
                        onClick={() => {
                            setSelectedTab("scans");
                        }}
                    >
                        Scans
                    </div>
                    <div
                        className={
                            "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-medium text-[#3F3E3E] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                            (selectedTab == "snapshots" ? "!border-[#7680FF] !text-[#7680FF]" : "")
                        }
                        onClick={() => {
                            setSelectedTab("snapshots");
                        }}
                    >
                        Snapshots
                    </div>
                </div>
            </NodeViewWrapper>
        </>
    );
});

export default TabsBlock;
