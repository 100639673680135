import { RouteObject } from "react-router-dom";
import BoardScreen from "screens/Boards/Board";
import BoardsLayout from "screens/Boards/layout";
import URL from "shared/constants/navigator";
import Auth from "shared/middleware/Auth";

const boardRoutes: RouteObject[] = [
    {
        element: <Auth />,
        children: [
            {
                path: "",
                element: <BoardsLayout />,
                children: [
                    {
                        path: URL.Board,
                        element: <BoardScreen />,
                    },
                ],
            },
        ],
    },
];

export default boardRoutes;
