import axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { File } from "shared/graphql";
import { errorToast } from "shared/utils/toast";
import FileItemContainer from "./FileItemContainer";

interface Props {
    type: "google" | "dropbox";
}

const END_POINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT || "";

let progressInterval: any = null;

const ClientUploader = (props: Props) => {
    const context = useFormContext();
    const type = context.watch("dataset_type");
    const file = context.watch("input_videos");
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        progressInterval = setInterval(() => {
            setProgress((prev) => prev + 1);
        }, 100);

        return () => {
            clearInterval(progressInterval);
        };
    }, []);

    useEffect(() => {
        if (progress > 90 && loading) {
            clearInterval(progressInterval);
            progressInterval = setInterval(() => {
                setProgress((prev) => prev + 1);
            }, 1000);
        } else if (progress > 98 && loading) {
            clearInterval(progressInterval);
        } else {
            clearInterval(progressInterval);
        }
    }, [loading, progress]);

    useEffect(() => {
        setLoading(true);
        let promise: any = null;
        if (file && props.type === "dropbox") {
            promise = axios.post(`${END_POINT}/dropbox`, {
                name: file.name,
                url: file.link,
                type: type === "images" ? "zip" : "video",
            });
        } else {
            promise = axios.post(`${END_POINT}/drive`, {
                name: file.name,
                video_id: file.id,
                type: type === "images" ? "zip" : "video",
                access_token: file.authResponse.access_token,
            });
        }
        promise
            .then((res) => {
                const data = res.data as File;
                context.setValue("filePreview", {
                    name: data.name,
                    size: data.size,
                    url: data.url,
                });
                context.setValue("scan_video_id", data.file_id);
            })
            .catch(() => {
                errorToast("Error uploading file");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [file]);

    let fileDetails = {
        name: "",
        size: 0,
    };

    if (props.type === "google") {
        fileDetails = {
            name: file.name,
            size: file.sizeBytes,
        };
    } else {
        fileDetails = {
            name: file.name,
            size: file.bytes,
        };
    }

    return (
        <FileItemContainer
            name={fileDetails.name}
            size={fileDetails.size}
            progress={progress}
            showProgress
            onRemove={() => {
                context.setValue("input_videos", null);
            }}
        />
    );
};

export default ClientUploader;
