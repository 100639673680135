import { ProfileTypes } from "shared/utils/enums";
import { useAppSelector } from "./useRedux";
import { teamSelector, tokenSelector, userSelector } from "store/selectors";

const useAuth = () => {
    const { current: user, profile } = useAppSelector(userSelector);
    const { current: team } = useAppSelector(teamSelector);
    const { current: token } = useAppSelector(tokenSelector);

    if (!user) {
        return {};
    }

    const dayRemaining = team?.trial_period ?? 0;
    const isTeamExpired = dayRemaining && dayRemaining.days_remaining <= 0;

    const type = profile?.type;

    const isTeamOwner = type === ProfileTypes.Admin;

    const isTeamMember = type === ProfileTypes.TeamMember;

    const isTeamManager = type === ProfileTypes.TeamManager;

    const isAdmin = type === ProfileTypes.Admin;

    const isSuperAdmin = type === ProfileTypes.SuperAdmin;

    return {
        user,
        profile,
        team,
        token,
        isTeamOwner,
        isTeamMember,
        isTeamManager,
        isAdmin,
        isSuperAdmin,
        isTeamExpired,
    };
};

export default useAuth;
