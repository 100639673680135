import { Extension } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";
import { keymap } from "prosemirror-keymap";

export const KeyMap = Extension.create({
    addProseMirrorPlugins() {
        return [
            keymap({
                Enter: (state, dispatch) => {
                    const { $from } = state.selection;
                    const { pos } = $from;
                    // Check if the current node is of the type you want to modify
                    if ($from.node().type.name === "styledText") {
                        if (dispatch) {
                            const br = state.schema.nodes.hardBreak.create();

                            // Create a transaction that inserts the line break
                            const tr = state.tr.insert(pos, br);

                            // Move the selection to just after the newly inserted line break
                            tr.setSelection(TextSelection.near(tr.doc.resolve(pos + 1)));

                            // Dispatch the transaction
                            dispatch(tr);
                        }
                        return true;
                    }
                    // Return false to let other commands (or the default behavior) handle the Enter key
                    return false;
                },
                Backspace: (state, dispatch) => {
                    if (state.selection instanceof TextSelection && state.selection.$cursor) {
                        const { $cursor } = state.selection;
                        if ($cursor && $cursor.pos > 1 && dispatch) {
                            // // Create a transaction that deletes the character before the cursor
                            // const tr = state.tr.delete($cursor.pos - 1, $cursor.pos);
                            // dispatch(tr);
                            // return true; // Indicate that this command handled the backspace key
                        }
                    }
                    return false; // Let other commands or the default behavior handle it
                },
            }),
        ];
    },
});
