import { Fade, ListSubheader, MenuItem, Select } from "@mui/material";
import useImages from "assets/images";
import { useMemo } from "react";
import { IThemeSelectProps } from "shared/types/utils/fields.type";

const ThemeSelect = (props: IThemeSelectProps) => {
    const {
        value,
        MenuProps,
        sx,
        options,
        placeholder,
        noOptionsText = "No options",
        selectFieldProps,
        ...restProps
    } = props;
    const { SelectArrow } = useImages();

    const DropDownItem = useMemo(() => {
        return selectFieldProps && selectFieldProps.CustomMenuItem ? selectFieldProps.CustomMenuItem : MenuItem;
    }, [selectFieldProps]);

    return (
        <Select
            IconComponent={SelectArrow}
            fullWidth
            defaultValue={"Standard"}
            displayEmpty
            value={value}
            MenuProps={{
                TransitionComponent: Fade,
                transitionDuration: 0,
                ...MenuProps,
            }}
            sx={{
                ".MuiPaper-root": {
                    transition: "none !important",
                    boxShadow:
                        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                },
                ".MuiSelect-select": {
                    color: value ? "currentColor" : "#dddddd",
                    fontWeight: "normal",

                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                },
                ...sx,
            }}
            {...selectFieldProps}
            {...restProps}
        >
            {placeholder && (
                <DropDownItem disabled value="" className="!hidden" {...selectFieldProps}>
                    {placeholder}
                </DropDownItem>
            )}
            {options && options.length ? (
                options.map((option, index) => {
                    if (option.isGroup) {
                        return (
                            <ListSubheader
                                key={index}
                                classes={{
                                    root: "!text-p !font-normal !italic !py-[2px] !leading-4 !mt-3",
                                }}
                            >
                                {option.label}
                            </ListSubheader>
                        );
                    }
                    return (
                        <DropDownItem key={index} value={option.value} {...selectFieldProps}>
                            {option.label}
                        </DropDownItem>
                    );
                })
            ) : (
                <DropDownItem disabled {...selectFieldProps}>
                    <em>{noOptionsText}</em>
                </DropDownItem>
            )}
        </Select>
    );
};

export default ThemeSelect;
