import { HandlersInterface } from "shared/types/handlers.type";
import { errorToast, successToast } from "./toast";

export const rtkHandler = (response, options?: HandlersInterface) => {
    if (!response || response.status === "pending") return;

    const { data, error } = response;
    const { showErrorToast = true } = options || {};

    if (error) {
        if (showErrorToast && error["message"] && typeof error["message"] === "string") {
            errorToast(error["message"], options?.toastOptions);
        } else if (options && options.setError && error["message"] && typeof error["message"] === "object") {
            const errArr = error["message"];
            Object.keys(errArr).forEach((key) => {
                options.setError &&
                    options.setError(key, {
                        type: "manual",
                        message: errArr[key],
                    });
            });
        }
        options?.onError && options.onError(error as typeof error);
    } else if (data) {
        options?.successMessage && successToast(options.successMessage, options?.toastOptions);
        options?.onSuccess && options.onSuccess(data as typeof data);
    }
};
