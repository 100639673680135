import { CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import EditorCustomDropDown from "shared/components/EditorCustomDropDown/EditorCustomDropDown";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import useFileUploader from "shared/hooks/useFileUploader";
import { useAppSelector } from "shared/hooks/useRedux";
import { IVideoProps } from "./Video";

const VideoProperties = ({ editor, props }: { editor: Editor | null; props: IVideoProps }) => {
    const { id, type, video, width, justify, caption, caption_str } = props as IVideoProps;
    const {
        UpdateImageGrayIcon,
        EditorAlignLeftIcon,
        EditorAlignCenterIcon,
        EditorAlignRightIcon,
        EditorCarouselImageRemoveIcon,
        ArrowDown,
    } = useImages();

    const { uploadFile, loading, loader_id } = useFileUploader();

    const project = useAppSelector((state) => state.projects.selected);

    const isInit = useRef(false);
    const uploadRef = useRef(null);

    const { control, watch, setValue } = useForm<{
        id: string;
        type: "upload" | "asset";
        video: string | null;
        width: number;
        justify: "left" | "center" | "right";
        caption: "yes" | "no";
        caption_str: string | null;
    }>({
        defaultValues: {
            id,
            type,
            video,
            width,
            justify,
            caption: caption ? "yes" : "no",
            caption_str,
        },
    });

    const type_val = watch("type");
    const video_val = watch("video");
    const width_val = watch("width");
    const justify_val = watch("justify");
    const caption_val = watch("caption");
    const caption_str_val = watch("caption_str");

    const assetOptions = useMemo(() => {
        if (!project) return [];

        let assets: any[] = [];

        project.folders.forEach((folder) => {
            assets = [...assets, ...folder.asset.filter((asset) => asset.file.type == "Video")];
        });

        return assets.map((asset) => ({
            label: asset.name,
            value: asset.file.url,
        }));
    }, [project]);

    const assetsData = useMemo(() => {
        if (!project) return [];

        let assets: any[] = [];

        project.folders.forEach((folder) => {
            assets = [...assets, ...folder.asset.filter((asset) => asset.file.type == "Video")];
        });

        const assetsMap = {};

        assets.forEach((asset) => {
            assetsMap[asset.file.url] = {
                image: asset.file.thumbnail,
                label: asset.name,
                value: asset.file.url,
            };
        });

        return assetsMap;
    }, [project]);

    const handleVideoUpload = (e: any) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            uploadFile(file, "editor-video-upload").then((res: any) => {
                setValue("video", res.url);
            });
        };
        reader.readAsText(file);
    };

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            type: type_val,
            video: video_val,
            width: width_val,
            justify: justify_val,
            caption: caption_val == "yes",
            caption_str: caption_str_val,
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const node = parentEditor!.view.state.schema.nodes.videoComponent.create(data);

        const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [type_val, video_val, width_val, justify_val, caption_val, caption_str_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Type</div>
                    <ToggleButtonGroup
                        value={type_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("video", null);
                                setValue("type", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"upload"} aria-label="Upload">
                            Upload
                        </ToggleButton>
                        <ToggleButton value={"asset"} aria-label="Asset">
                            Asset
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <div className="text-sm text-[#667085] mt-4 mb-2">
                        {type_val == "upload" ? "Video" : "Video Asset"}
                    </div>
                    {!video_val &&
                        (type_val == "upload" ? (
                            <>
                                <div
                                    className={
                                        "w-full h-[34px] border border-[#D0D5DD] rounded-lg bg-[#FFFFFF] shadow-[0px_0.7px_1.5px_0px_#1018280D] flex items-center justify-center gap-x-1 cursor-pointer hover:brightness-90 " +
                                        (loading && loader_id == "editor-video-upload" && "pointer-events-none")
                                    }
                                    onClick={() => {
                                        (uploadRef.current as any).click();
                                    }}
                                >
                                    {loading && loader_id == "editor-video-upload" ? (
                                        <CircularProgress
                                            variant="indeterminate"
                                            size="20px"
                                            thickness={2}
                                            color="primary"
                                        />
                                    ) : (
                                        <>
                                            <UpdateImageGrayIcon width={14} height={14} />
                                            <div className="text-sm font-medium text-[#667085]">Upload Video</div>
                                        </>
                                    )}
                                </div>
                                <input
                                    ref={uploadRef}
                                    type="file"
                                    accept="video/mp4,video/x-m4v,video/*"
                                    className="hidden"
                                    onChange={handleVideoUpload}
                                />
                            </>
                        ) : (
                            <Field
                                formGroup={false}
                                name="video"
                                type="select"
                                options={assetOptions}
                                selectFieldProps={{
                                    className: "!h-[34px]",
                                    sx: {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "8px !important",
                                        },
                                    },
                                    IconComponent: ArrowDown,
                                    CustomMenuItem: EditorCustomDropDown,
                                    dropdown_data: assetsData,
                                    empty_placeholder: "No Assets Found",
                                }}
                            />
                        ))}
                    {video_val && (
                        <div className="h-[34px] w-full bg-white rounded-lg border border-[#D0D5DD] flex items-center">
                            <div className="h-full flex-1 p-2 text-sm text-[#667085]">
                                {/* <img src={video_val} className="w-full h-full object-cover rounded-[4px]" /> */}
                                Video
                            </div>
                            {/* <div className="h-full cursor-pointer px-3 border-l border-[border-[#D0D5DD] flex items-center justify-center">
                                <EditorCarouselImageDownloadIcon width={13} height={13} />
                            </div> */}
                            <div
                                className="h-full cursor-pointer px-3 border-l border-[border-[#D0D5DD] flex items-center justify-center"
                                onClick={() => {
                                    setValue("video", null);
                                }}
                            >
                                <EditorCarouselImageRemoveIcon width={13} height={13} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Width</div>
                    <Field
                        name="width"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                        }}
                    />

                    <div className="text-sm text-[#667085] mt-4 mb-2">Justify</div>
                    <ToggleButtonGroup
                        value={justify_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Caption</div>
                    <ToggleButtonGroup
                        value={caption_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("caption", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default VideoProperties;
