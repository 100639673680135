import { RouteObject } from "react-router-dom";

import AddSubmissionScreen from "screens/Dashboard/ScanRequests/common/screens/AddSubmission";
import SRDetailScreen from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen";
import SRSingleScan from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen/screens/ScansTab/Single";
import SRSingleScanLayout from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen/screens/ScansTab/Single/layout";
import DeleteSRScan from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen/screens/ScansTab/Single/screens/Delete";
import DownloadSrModel from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen/screens/ScansTab/Single/screens/DownloadScanModel";
import EditSRScan from "screens/Dashboard/ScanRequests/common/screens/SRDetailScreen/screens/ScansTab/Single/screens/EditScan";
import SRNewPostScreen from "screens/Dashboard/ScanRequests/screens/New/Post";
import SREntryPoint from "shared/middleware/SREntryPoint";

const scanRequestDetailRoutes: RouteObject[] = [
    {
        path: "detail/:id",
        children: [
            {
                path: "",
                element: <SRDetailScreen />,
            },
            {
                path: "scans",
                children: [
                    {
                        path: ":scanId",
                        element: <SRSingleScanLayout />,
                        children: [
                            {
                                path: "",
                                element: <SRSingleScan />,
                            },
                            {
                                path: "delete",
                                element: <DeleteSRScan />,
                            },
                            {
                                path: "edit",
                                element: <EditSRScan />,
                            },
                            {
                                path: "download",
                                element: <DownloadSrModel />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "add/submission",
                element: <AddSubmissionScreen />,
            },
        ],
    },
];

const scanRequestRoutes: RouteObject[] = [
    {
        path: "/requests",
        children: [
            {
                path: ":type",
                element: <SREntryPoint />,
                children: [
                    {
                        path: "post",
                        element: <SRNewPostScreen />,
                    },
                    ...scanRequestDetailRoutes,
                ],
            },
        ],
    },
];

export default scanRequestRoutes;
