import { Skeleton, TextFieldProps } from "@mui/material";
import { omit } from "lodash";
import React from "react";
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar";
import { twMerge } from "tailwind-merge";

import { ISortingType } from "shared/types/utils/utils.type";

import LoadingSnackbar from "../LoadingSnackbar";
import { SortBtnProps } from "../SortIconButton";
import { IThemeIconButtonProps } from "../ThemeIconButton/type";
import { ThemeListCardContextProvider } from "./context";
import HeaderComponent from "./partials/HeaderComponent";

export interface ThemeListCardProps<T> extends React.PropsWithChildren {
    loading?: boolean;
    fetching?: boolean;
    loadingCount?: number;

    headerComponent?: React.ReactNode;
    headerRightComponent?: React.ReactNode;

    emptyText?: string;
    emptyComponent?: React.ReactNode;
    data?: T[];
    listHeader?: React.ReactNode;
    renderList?: (data: T) => React.ReactNode;
    listItems?: (data: T) => React.ReactNode;

    scrollbarProps?: ScrollBarProps;
    props?: {
        sortBtnProps?: SortBtnProps;
        searchProps?: TextFieldProps;
        tutorialProps?: Omit<IThemeIconButtonProps, "icon">;
        addProps?: Omit<IThemeIconButtonProps, "icon">;
    };
    options?: {
        onSearch?: (value: string) => void;
        onSort?: (value: ISortingType) => void;
        onTutorial?: () => void;
        onAddClick?: React.MouseEventHandler<HTMLButtonElement>;
    };
    classes?: {
        root?: HTMLDivElement["className"];
        header?: HTMLDivElement["className"];
        body?: HTMLDivElement["className"];
    };
}

const ThemeListCard = <T,>(props: ThemeListCardProps<T>) => {
    return (
        <ThemeListCardContextProvider value={props}>
            <div
                className={twMerge("border-r border-[#D0D5DD] border-opacity-25 w-full relative", props.classes?.root)}
            >
                <LoadingSnackbar loading={!props.loading && props.fetching ? true : false} />
                <div
                    className={twMerge(
                        "p-6 flex items-center border-b bg-white border-[#D0D5DD] border-opacity-25 h-[80px]",
                        props.classes?.header
                    )}
                >
                    {props.headerComponent}
                    <HeaderComponent />
                </div>
                <div className={twMerge(`bg-[#F9F9F9] h-[calc(100vh-80px)]`, props.classes?.body)}>
                    <PerfectScrollbar
                        className={twMerge("p-6", props.scrollbarProps?.className)}
                        options={{
                            suppressScrollX: true,
                            ...props.scrollbarProps?.options,
                        }}
                        {...omit(props.scrollbarProps, ["options", "className"])}
                    >
                        {props.listHeader && (
                            <div className="flex items-center justify-between mb-5 pb-0">{props.listHeader}</div>
                        )}
                        {props.renderList ? (
                            props.renderList(props.data as T)
                        ) : (
                            <div className="flex flex-col space-y-4">
                                {props.loading ? (
                                    [...Array(props.loadingCount || 5)].map((item, index) => {
                                        return (
                                            <Skeleton
                                                variant="rounded"
                                                width="100%"
                                                height="60px"
                                                key={`skeleton-${index}`}
                                            />
                                        );
                                    })
                                ) : props.data?.length ? (
                                    props.data.map((item) => {
                                        return props.listItems ? props.listItems(item) : <></>;
                                    })
                                ) : props.emptyComponent ? (
                                    props.emptyComponent
                                ) : (
                                    <div className="text-p text-[14px] leading-6 text-center">
                                        {props.emptyText || "No data found"}
                                    </div>
                                )}
                            </div>
                        )}
                    </PerfectScrollbar>
                </div>
            </div>
        </ThemeListCardContextProvider>
    );
};

export default ThemeListCard;
