import { Button } from "@mui/material";

import SettingPageCard from "../../partials/SettingPageCard";
import useProfileSettingScreen from "./hook";
import ScanningDevices from "./partials/ScanningDevices";
import UpdateAccountDetail from "./partials/UpdateAccountDetail";

const ProfileSettingScreen = () => {
    const { onClickDeactivateAccount } = useProfileSettingScreen();

    return (
        <>
            <UpdateAccountDetail />
            <ScanningDevices />
            <SettingPageCard
                title="Delete account"
                subTitle="Once you delete your account, there is no going back. Please be certain."
                rightComponent={
                    <Button type="button" variant="contained" color="error" onClick={onClickDeactivateAccount}>
                        Delete Account
                    </Button>
                }
            />
        </>
    );
};

export default ProfileSettingScreen;
