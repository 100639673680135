const BetaApplicationContent = () => {
    return (
        <>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "12.5pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IMPORTANT - READ CAREFULLY:
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IMPROPER USE OR DISCLOSURE OF CONTENT PROVIDED IN THIS SOFTWARE, INCLUDING USER MATERIALS, IS A
                    VIOLATION OF THE TERMS OF AGREEMENT AND INTELLECTUAL PROPERTY LAWS. LICENSOR WILL PURSUE ALL LEGAL
                    REMEDIES TO THE GREATEST EXTENT OF THE LAW FOR ANY MISUSE OF THIS SOFTWARE AND/OR THE AUDIO, VIDEO,
                    AND OTHER DATA AND CONTENT CONTAINED HEREIN. ANY DISCLOSURE OF SUCH INFORMATION WITHOUT THE EXPRESS
                    PRIOR WRITTEN CONSENT OF LICENSOR IS DEEMED TO BE A VIOLATION OF THIS AGREEMENT. NOTHING IN THIS
                    AGREEMENT SHALL PROVIDE LICENSEE WITH THE RIGHT TO USE, DISPLAY, OR BROADCAST USER MATERIALS.
                    LICENSEE IS SOLELY RESPONSIBLE FOR PROCURING THE RIGHTS AND APPROVALS TO USE USER MATERIALS FROM THE
                    RIGHTSHOLDER.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    THE BETA SOFTWARE ON THIS DEVICE IS PROVIDED ONLY UNDER THE FOLLOWING LICENSE WITH SOAR3D, Inc and
                    XSPADA, INC (COLLECTIVELY AND HEREINAFTER "LICENSOR"). PLEASE READ THE FOLLOWING LICENSE CAREFULLY.
                    LICENSOR WILL PROVIDE YOU WITH ACCESS TO THE BETA SOFTWARE AND RELATED ONLINE OR ELECTRONIC
                    DOCUMENTATION (HEREINAFTER REFERRED TO AS "BETA SOFTWARE") ONLY IF YOU ACCEPT THIS BETA SOFTWARE
                    LICENSE AGREEMENT (HEREINAFTER THIS "AGREEMENT"). THIS IS AN AGREEMENT BETWEEN YOU ON BEHALF OF
                    YOURSELF, YOUR EMPLOYER, AND/OR YOUR PRINCIPALS (HEREINAFTER AND COLLECTIVELY THE "LICENSEE") AND
                    LICENSOR.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The BETA SOFTWARE also includes any patches, updates, and supplements to this original BETA
                    SOFTWARE, to be provided to the LICENSEE by and at the LICENSOR’s sole discretion. By installing,
                    copying, downloading, accessing, or otherwise using the BETA SOFTWARE or any content associated with
                    the BETA SOFTWARE, LICENSEE agrees to be bound by the terms of this AGREEMENT. If LICENSEE does not
                    agree to this AGREEMENT’s terms, LICENSEE shall not access, install, download, or otherwise use the
                    BETA SOFTWARE.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    &nbsp;
                </span>
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1. License Terms
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1.1. License Grant
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Subject to this AGREEMENT’s terms, LICENSOR grants LICENSEE a non-exclusive, revocable,
                    non-transferable, non-sublicensable license (the "LICENSE") for the sole individual who accepts the
                    LICENSE to use the BETA SOFTWARE during the LICENSE PERIOD, for the sole purpose of evaluating the
                    BETA SOFTWARE and for no other purpose whatsoever. The BETA SOFTWARE contains images, sounds, video,
                    and other elements from users (the “USER MATERIALS”) that are owned by the user who captured by
                    users for the use of demonstrating the BETA SOFTWARE. Any other use of the USER MATERIALS outside of
                    the BETA SOFTWARE without the consent of the NFL is prohibited.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE may access and use the BETA SOFTWARE only on the device on which this LICENSE is accepted.
                    LICENSEE must acquire and dedicate a LICENSE for each separate computer or device on or from which
                    the BETA SOFTWARE is installed, used, accessed, displayed, or run. A LICENSE for the BETA SOFTWARE
                    may not be shared or used concurrently on different computers or by different users.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Should LICENSEE create, upload, or capture images, sounds, video, and other elements using, into, or
                    through the BETA SOFTWARE (the “LICENSEE MATERIALS”), LICENSEE shall maintain ownership of such
                    LICENSEE MATERIALS. LICENSEE hereby grants LICENSOR a non-exclusive, irrevocable, perpetual,
                    transferrable, sublicensable, license to use for any purpose, process, create derivative works,
                    display, and distribute the LICENSEE MATERIALS in all known and unknown media throughout the
                    universe in perpetuity.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1.2. Beta-Specific Obligations
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE agrees to provide timely feedback, which may include comments, suggestions, ideas, bug
                    reports, conferences with LICENSOR’s representatives, and/or written evaluations, to LICENSOR about
                    the BETA SOFTWARE and about ways that the content can be used (the “
                </span>
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Feedback
                </span>
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    ”), and LICENSOR will have no confidentiality obligations, as described in this Section 1.2,
                    concerning the Feedback. LICENSEE hereby assigns all rights including any associated goodwill to
                    LICENSOR and agrees that LICENSOR shall be the sole owner of the Feedback and have all rights to
                    license, sell, modify, create derivative works of, publicly display, publicly perform, disclose,
                    distribute, incorporate and otherwise use or exploit the Feedback for any purpose.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1.3. Further Restrictions
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE shall not use the BETA SOFTWARE for any reason not expressly permitted in this
                    AGREEMENT.&nbsp; In addition, LICENSEE shall not disclose the existence or any elements of the BETA
                    SOFTWARE to third parties and shall not permit any third party to: (a) grant any sublicense for all
                    or part of the BETA SOFTWARE; (b) reverse engineer, decompile, or disassemble the BETA SOFTWARE, in
                    whole or in part; (c) interfere with, bypass or disable any features or functionality that are
                    embedded in or included with the BETA SOFTWARE; (d) use the BETA SOFTWARE for any benchmarking or
                    competitive purpose; (e) access or use the BETA SOFTWARE in any unlawful manner or for any unlawful
                    purpose; (f) use any data scraping, data harvesting, web crawlers, or other data extraction methods
                    to extract data from the BETA SOFTWARE; (g) use the BETA SOFTWARE in conjunction with any other
                    software, data, or equipment in such a manner as would cause the resulting product to infringe upon
                    any Intellectual Property Rights of third parties; (h) copy the BETA SOFTWARE, except for backup or
                    archival purposes and provided that each such copy of the BETA SOFTWARE is subject to the terms of
                    this AGREEMENT; (i) transfer, assign, rent, lease, or otherwise dispose of the BETA SOFTWARE on a
                    temporary or permanent basis; (j) make the BETA SOFTWARE available to remote users; (k) distribute
                    the content, including pictures, sounds, and videos generated using the BETA SOFTWARE; or (l)
                    include any malware, disablng code, malicious code, or “copyleft” open source code..
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    1.4. Additional Obligations
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE agrees: (a) not to remove from the BETA SOFTWARE any notices embedded thereon or therein
                    that acknowledge that LICENSOR has a copyright, trademark, certain pending patent rights, and other
                    intellectual property interests in the BETA SOFTWARE, as the case may be; (b) not to remove any
                    references in or on the BETA SOFTWARE to LICENSOR'S name; (c) to immediately notify LICENSOR if
                    LICENSEE becomes aware of any actual or potential claims by a third party arising in respect
                    LICENSEE'S use of the BETA SOFTWARE; (d) not to, and not to attempt to, reverse engineer, decipher,
                    decompile, disassemble, or duplicate the BETA SOFTWARE; and (e) not to use information about the
                    BETA SOFTWARE to create a product that competes with LICENSOR in any way.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2. Additional Rights And Limitations
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.1. System Requirements
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The BETA SOFTWARE may only be used on computer systems that meet the system requirements specified
                    by LICENSOR. LICENSOR is not required to supply any required hardware and/or software necessary for
                    the proper operation of the BETA SOFTWARE.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    &nbsp;
                </span>
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.2. No Distribution&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The BETA SOFTWARE may provide LICENSEE access to USER MATERIALS. LICENSEE is solely responsible for
                    procuring the rights and approvals to use USER MATERIALS from the rights holder and ensuring that
                    the LICENSEE complies with all terms and/or conditions expressly agreed upon between the user and
                    the applicable rights holder. The display, performance, dissemination, transfer, and/or distribution
                    of such the BETA SOFTWARES or any USER MATERIALS is an improper use of USER MATERIALS and is
                    expressly prohibited. Any such display, performance, dissemination, transfer, and/or distribution is
                    an agreement by the LICENSEE to pay LICENSOR a license fee of $1,000,000 per picture or second of
                    video or sound in addition to any costs, fees, and/or penalties due to the owner of such images,
                    videos, and sounds.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    3. Modifications To The BETA SOFTWARE
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    3.1. Reservations
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSOR reserves the right at any time not to release or to discontinue release of any BETA
                    SOFTWARE and to alter prices, features, specifications, capabilities, functions, licensing terms,
                    release dates, general availability or other characteristics of the BETA SOFTWARE.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    3.2. Upgrades
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Any upgrade or modification to the BETA SOFTWARE provided by LICENSOR is subject to the terms of
                    this AGREEMENT.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4. Ownership — Confidential Information — Intellectual Property Rights
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4.1. Ownership and Rights
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    All right, title, and ownership, including all intellectual property and other proprietary rights,
                    in and to the BETA SOFTWARE shall remain LICENSOR’s sole and exclusive property. All right, title,
                    and ownership, including all intellectual property and other proprietary rights, in and to the USER
                    MATERIALS shall remain the USER’s sole and exclusive property. This AGREEMENT does not grant
                    LICENSEE any rights connected with any copyright, patent, trademark, trade secret, or other
                    intellectual property or other proprietary right of LICENSOR or any other user, except for what is
                    expressly provided herein with this AGREEMENT and LICENSOR and the NFL retain all rights not
                    specifically and expressly granted to LICENSEE in this Agreement.&nbsp;&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE may be given access to certain non-public information, software or specifications relating
                    to the BETA SOFTWARE (“Confidential Information”), which is confidential and proprietary to
                    LICENSOR.&nbsp; LICENSEE may use Confidential Information only as necessary in exercising its rights
                    under this AGREEMENT.&nbsp; LICENSEE may not disclose any Confidential Information to any third
                    party without LICENSOR’S prior written consent.&nbsp; LICENSEE will use best efforts to protect
                    Confidential Information from unauthorized use, access or disclosure, and in the same manner and to
                    at least the same extent that LICENSEE would use to protect LICENSEE’S own confidential and
                    proprietary information of similar importance.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If LICENSEE breaches any provision of this AGREEMENT, LICENSEE acknowledges that such breach may
                    substantially diminish the value of LICENSOR’S intellectual property and other proprietary rights in
                    the BETA SOFTWARE and may irrevocably harm LICENSOR, and in such event LICENSOR (without limiting
                    its other rights or remedies) shall be entitled to equitable relief (including but not limited to
                    injunctive relief) to protect LICENSOR'S interests.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    5. Termination
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    5.1. License Duration
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The license period shall be in effect from the time LICENSEE accesses the BETA SOFTWARE, thereby
                    accepting the terms and conditions of this AGREEMENT, or otherwise expressly accepts the terms and
                    conditions of this AGREEMENT, and shall remain in effect until LICENSOR revokes access to the BETA
                    SOFTWARE, whichever is earlier (hereinafter the "LICENSE PERIOD").&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    This AGREEMENT and the LICENSE granted herein shall terminate upon the expiration of the LICENSE
                    PERIOD or such earlier date as LICENSOR may provide LICENSEE. LICENSOR reserves the right to
                    terminate this AGREEMENT at any time upon written notice to LICENSEE.&nbsp; LICENSEE may terminate
                    this AGREEMENT by ceasing use of the BETA SOFTWARE and any derivative works in addition to deleting
                    any CONTENT is LICENSEE’s possession.&nbsp; This AGREEMENT will otherwise terminate upon the
                    conditions outlined in this AGREEMENT or if LICENSEE fails to comply with any term or condition of
                    this AGREEMENT, including failure to pay any applicable license fee.&nbsp; LICENSOR will not be
                    liable for any costs, expenses, or damages incurred by LICENSEE as a result of LICENSOR’S
                    termination of this AGREEMENT.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    5.2. Survival
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The following provisions shall survive any expiration or termination of this AGREEMENT: Sections
                    1.3, 1.4, 2, 4, 5.2, 5.3, 6, 7, and 8.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    5.3. Post Termination
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    At the conclusion of the LICENSE PERIOD, LICENSEE shall uninstall and delete any copies of the BETA
                    SOFTWARE and destroy or delete any copies of any documentation provided in connection with the BETA
                    SOFTWARE.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    In the event of expiration or termination of this AGREEMENT, LICENSEE shall immediately cease use of
                    the BETA SOFTWARE. LICENSEE agrees upon the expiration or termination of this AGREEMENT for any
                    reason whatsoever to immediately cease use and destroy all copies of the BETA SOFTWARE, USER
                    MATERIALS, and any pictures, videos, and sounds from the BETA SOFTWARE in its possession and/or
                    under its control, including the USER MATERIALS and any pictures, videos, and sounds downloaded from
                    the BETA SOFTWARE. Nothing in this AGREEMENT shall absolve LICENSEE from liability for damages
                    resulting from any breach of this AGREEMENT by LICENSEE, notwithstanding that LICENSOR may have
                    other remedies available under this AGREEMENT (including the right to terminate the
                    LICENSE).&nbsp;&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    6. Disclaimer, Indemnification, And Limitations On Liability
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    6.1. Disclaimer
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    THE BETA SOFTWARE IS PROVIDED "AS IS" WITHOUT WARRANTY OR SUPPORT OF ANY KIND. LICENSOR MAKES NO
                    REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, CONCERNING THE BETA SOFTWARE, INCLUDING WITHOUT
                    LIMITATION WARRANTIES OF FITNESS FOR A PARTICULAR USE OR PURPOSE, MERCHANTABILITY, NONINFRINGEMENT,
                    OR THAT THE BETA SOFTWARE WILL OPERATE WITHOUT INTERRUPTION OR BE ERROR-FREE, AND LICENSOR HEREBY
                    DISCLAIMS ALL SUCH REPRESENTATIONS AND WARRANTIES.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    6.2. Indemnification
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "6.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE will defend, indemnify, and hold LICENSOR harmless from and against any liabilities,
                    losses, damages, judgments, fines, penalties, costs and expenses (including reasonable attorneys’
                    fees and court costs), as incurred, arising out of or in any way connected to: (i) LICENSEE&apos;S
                    use of the BETA SOFTWARE; or (ii) any breach or violation by LICENSEE of this AGREEMENT or any
                    applicable law, rule, or regulation.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "6.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    6.3. Limitation on Liability
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IN NO EVENT WILL LICENSOR BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR
                    SPECIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS, OR REVENUE) OR ANY COST PROCURING SUBSTITUTE
                    PRODUCTS OR SERVICES ARISING OUT OF OR IN CONNECTION TO THIS AGREEMENT OR THE USE OF THE BETA
                    SOFTWARE, UNDER ANY THEORY OF LIABILITY (INCLUDING TORT CONTRACT, OR ANY OTHER THEORY) WHETHER
                    SUFFERED BY LICENSEE OR ANY OTHER USER OF THE BETA SOFTWARE OR ANY THIRD PARTY, EVEN IF LICENSOR WAS
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; IN ADDITION, LICENSOR’S TOTAL LIABILITY TO
                    LICENSEE FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE LIMITED TO AND WILL
                    NOT EXCEED AN AGGREGATE TOTAL OF FIFTY DOLLARS ($50).&nbsp; THE FOREGOING LIMITATIONS WILL SURVIVE
                    AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS
                    ESSENTIAL PURPOSE.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7. General
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.1. Invalid Term, Condition, or Provisions
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If any term, condition, or provision in this AGREEMENT is found to be invalid, unlawful or
                    unenforceable to any extent, the remaining terms, conditions, and provisions will continue to be
                    valid and enforceable to the fullest extent permitted by law. This AGREEMENT constitutes the entire
                    and exclusive understanding and agreement between LICENSOR and LICENSEE regarding the BETA SOFTWARE,
                    and this AGREEMENT supersedes any prior understandings and agreements between LICENSOR and LICENSEE
                    concerning its subject matter. There are no representations, warranties, terms, conditions,
                    undertakings or collateral agreements, express, implied or statutory, between LICENSEE and LICENSOR
                    other than as expressly made in this AGREEMENT.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.2. Modifications
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSOR may modify the terms of this AGREEMENT at any time and in its sole discretion, by notifying
                    LICENSEE.&nbsp; LICENSEE may terminate this AGREEMENT as permitted herein and cease use of the BETA
                    SOFTWARE if such modifications are not acceptable to LICENSEE.&nbsp; LICENSEE’S continued use of the
                    BETA SOFTWARE following notice of such modifications constitutes LICENSEE’S acceptance of such
                    modifications.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.3. Governing Law
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Except as expressly provided herein, this AGREEMENT shall be governed by the laws of the State of
                    California and the United States of America’s federal laws, exclusive of any conflicts of laws
                    principles that would require the application of the laws of another jurisdiction. LICENSEE agrees
                    that any dispute regarding this AGREEMENT or the rights and obligations herein will be heard in the
                    state or federal courts having jurisdiction for Los Angeles County, California, and LICENSEE agrees
                    to be subject to the personal jurisdiction of such courts.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.3. Assignment
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSEE may not assign or transfer this AGREEMENT, by operation of law or otherwise, without
                    LICENSOR’S prior written consent.&nbsp; Any attempt by LICENSEE to assign or transfer this AGREEMENT
                    without such consent will be null and void.&nbsp; LICENSOR may freely assign or transfer this
                    AGREEMENT without restriction.&nbsp; Subject to the foregoing, this AGREEMENT will bind and inure to
                    the benefit of the parties, their successors, and permitted assigns.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.4. Notice
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Any notices or other communications provided by LICENSOR under this AGREEMENT, including those
                    regarding modifications to this AGREEMENT, will be given via email and the date of receipt will be
                    deemed the date on which such notice is transmitted.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    7.5. Waiver; Remedies
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    LICENSOR’S failure to enforce any right or provision of this AGREEMENT will not be considered a
                    waiver of such right or provision. The waiver of any such right or provision will be effective only
                    if in writing and signed by a duly authorized representative of LICENSOR.&nbsp; Except as expressly
                    set forth in this AGREEMENT, the exercise by either party of any of its remedies under this
                    AGREEMENT will be without prejudice to its other remedies under this AGREEMENT or otherwise.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "-6.5pt 0pt 3.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "13pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    8. Acceptance Of Terms And Provisions Herein
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "3.75pt 0pt 0.75pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "10.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    8.1. Acceptance
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    backgroundColor: "#ffffff",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "13.25pt 0pt 14pt 0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "8.5pt",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    If LICENSEE downloads the BETA SOFTWARE and/or installs the BETA SOFTWARE and/or uses the BETA
                    SOFTWARE on a computer and/or accepts the LICENSE by clicking the “I Accept” box, LICENSEE shall be
                    deemed to have accepted the terms of this AGREEMENT and to be legally bound thereby. Such acceptance
                    cannot be canceled or rescinded. LICENSEE remains bound by all terms and provisions of this
                    AGREEMENT and continues to be bound to the terms and conditions that survive this AGREEMENT
                    enumerated herein and above.
                </span>
            </p>
            <p>
                <span id="docs-internal-guid-c535e462-7fff-ffab-9534-236c85674354">
                    <br />
                </span>
            </p>
        </>
    );
};

export default BetaApplicationContent;
