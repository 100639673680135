import { twMerge } from "tailwind-merge";

import { useThemeListCardContext } from "../context";
import AddBox from "./AddBox";
import SearchBox from "./SearchBox";
import SortBox from "./SortBox";
import TutorialBox from "./TutorialBox";

const HeaderComponent = () => {
    const { options, isSearchEnabled, headerRightComponent } = useThemeListCardContext();

    return (
        <div className="flex items-center justify-between space-x-2 flex-1">
            <div className="flex items-center space-x-2 flex-1">
                {options?.onSearch && <SearchBox />}
                <div
                    className={twMerge(
                        "flex items-center space-x-2 transition-300 transition-[display] delay-200 duration-300",
                        !isSearchEnabled ? "opacity-100 visible" : "opacity-0 invisible hidden"
                    )}
                >
                    {options?.onSort && <SortBox />}
                    {options?.onTutorial && <TutorialBox />}
                </div>
            </div>
            <div
                className={twMerge(
                    "transition-300 transition-[display] delay-200 duration-300 flex items-center space-x-2",
                    !isSearchEnabled ? "opacity-100 visible" : "opacity-0 invisible hidden"
                )}
            >
                {headerRightComponent}
                {options?.onAddClick && <AddBox />}
            </div>
        </div>
    );
};

export default HeaderComponent;
