import { yupResolver } from "@hookform/resolvers/yup";
import omit from "lodash/omit";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useChangePasswordMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    old_password: yup.string().required(),
    new_password: yup.string().required(),
    cpassword: yup
        .string()
        .required()
        .oneOf([yup.ref("new_password")], "Passwords must match"),
});

type IUpdatePassword = yup.InferType<typeof schema>;

const useSecuritySettingScreen = () => {
    const [changePassword, changeRes] = useChangePasswordMutation();

    const form = useForm<IUpdatePassword>({
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: "",
            new_password: "",
            cpassword: "",
        },
    });

    const onSubmit = (data: IUpdatePassword) => {
        changePassword({ data: omit(data, ["cpassword"]) });
    };

    useEffect(() => {
        rtkHandler(changeRes, {
            setError: form.setError,
            successMessage: "Password changed successfully",
            onSuccess() {
                form.reset({
                    old_password: "",
                    new_password: "",
                    cpassword: "",
                });
            },
        });
    }, [changeRes]);

    return { form, isLoading: changeRes.isLoading, onSubmit };
};

export default useSecuritySettingScreen;
