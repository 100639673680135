import useImages from "assets/images";

import ThemeIconButton from "../../ThemeIconButton";
import { useThemeListCardContext } from "../context";

const TutorialBox = () => {
    const { options, props } = useThemeListCardContext();
    const { QuestionMarkCircleIcon } = useImages();

    if (!options?.onTutorial) return null;

    return (
        <ThemeIconButton
            onClick={options.onTutorial}
            icon={<QuestionMarkCircleIcon color="#AEADAD" height={19} width={19} />}
            color="primary"
            variant="outlined"
            {...props?.tutorialProps}
        />
    );
};

export default TutorialBox;
