const COLOR_PALLETE = {
    grey: {
        DEFAULT: "#5E5D5D",
        light: "#F3F3F3",
    },
    primary: {
        DEFAULT: "#7680FF",
    },
    error: {
        DEFAULT: "#EB617A",
    },
    danger: {
        DEFAULT: "#EB617A",
    },
    success: {
        DEFAULT: "#15AA2C",
    },
    border: "#C9C9C9",
    border2: "#D0D5DD",
    bg: "#F9F9F9",
    p: "#5E5D5D",
    h1: "#3F3E3E",
};

export default COLOR_PALLETE;
