import { CircularProgress } from "@mui/material";
import useImages from "assets/images";
import loadScript from "load-script";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";

const DROPBOX_APP_KEY = "4lr9oks374yd4sc"; // App key
const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";
const DROPBOX_SCRIPT_ID = "dropboxjs";

interface Props {
    onClick: () => void;
}

const DropboxUpload = (props: Props) => {
    const Images = useImages();
    const context = useFormContext();
    const [loading, setLoading] = useState(false);
    const type = context.watch("dataset_type");

    const options = useMemo(
        () => ({
            success: (
                files: Array<{
                    link: string;
                    bytes: number;
                    name: string;
                }>
            ) => {
                const file = files[0] as unknown as { name: string; link: string; type: string };
                context.setValue("input_videos", file);
            },
            cancel: () => {},
            linkType: "direct",
            multiselect: false,
            extensions: type === "images" ? [".zip"] : [".mp4", ".mov"],
            folderselect: false,
        }),
        []
    );

    const onClick = () => {
        if ((window as any).Dropbox) {
            (window as any).Dropbox.choose(options);
            props.onClick();
            return;
        }
        setLoading(true);
        loadScript(
            DROPBOX_SDK_URL,
            {
                attrs: {
                    id: DROPBOX_SCRIPT_ID,
                    "data-app-key": DROPBOX_APP_KEY,
                },
            },
            () => {
                setLoading(false);
                props.onClick();
                (window as any).Dropbox.choose(options);
            }
        );
    };

    return (
        <div className="relative !w-10 !h-10">
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full z-10 bg-white bg-opacity-70">
                    <CircularProgress size={40} />
                </div>
            )}
            <ThemeIconButton className="!w-10 !h-10" icon={<Images.DropboxIcon />} onClick={onClick} />
        </div>
    );
};

export default DropboxUpload;
