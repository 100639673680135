import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { File as FileType } from "shared/graphql";
import { errorToast } from "shared/utils/toast";
import { UppyFileUploaderPickerProps } from ".";
import useHasStorage from "shared/hooks/useHasStorage";

const CHUNK_SIZE = 1024 * 1024 * 10;
const uppy = new Uppy({ debug: true, autoProceed: true });
const END_POINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT;

const useMultiUploader = (props: UppyFileUploaderPickerProps) => {
    const [progress, setProgress] = useState(0);
    const [serverFile, setServerFile] = useState<FileType | null>(null);
    const [selectedFile, setSelectedFile] = useState<PickerFile | null>();
    const checkStorage = useHasStorage();

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
        setProgress(percentCompleted);
    };

    useEffect(() => {
        let promise: Promise<AxiosResponse<FileType> | null> = Promise.resolve(null);
        if (selectedFile && ["dropbox", "google-drive"].includes(selectedFile.pickerType)) {
            if (selectedFile.pickerType === "dropbox") {
                checkStorage(selectedFile.size).then(() => {
                    promise = axios.post(
                        `${END_POINT}/dropbox`,
                        {
                            name: selectedFile.name,
                            url: selectedFile.url,
                            type: props.type === "images" ? "zip" : "video",
                        },
                        { onUploadProgress }
                    );
                });
            } else {
                checkStorage(selectedFile.size).then(() => {
                    promise = axios.post(
                        `${END_POINT}/drive`,
                        {
                            name: selectedFile.name,
                            video_id: selectedFile.video_id,
                            type: props.type === "images" ? "zip" : "video",
                            access_token: selectedFile.access_token,
                        },
                        { onUploadProgress }
                    );
                });
            }
            promise
                .then((res) => {
                    if (res) {
                        setServerFile(res.data);
                    }
                })
                .catch(() => {
                    errorToast("Error uploading file");
                });
        }
    }, [selectedFile]);

    useEffect(() => {
        props.onSelectFile?.(selectedFile ?? null);
    }, [selectedFile]);

    useEffect(() => {
        props.onFileUpload?.(serverFile);
    }, [serverFile]);

    const successHandler = async (result: any, file) => {
        if (result.successful && result.successful.length) {
            let uuid = result.successful[0].uploadURL.split("/").pop();
            uuid = uuid?.split("?").shift() ?? "";
            if (uuid && file) {
                const res = await axios.get<FileType>(`${END_POINT}/metadata/${uuid}`);
                if (res.status === 200) {
                    const data = res.data;
                    setServerFile(data);
                }
            }
            return;
        }

        if (result.failed && result.failed.length) {
            errorToast("Error uploading file");
        }
    };

    useEffect(() => {
        if (selectedFile && selectedFile.pickerType === "local") {
            const file = selectedFile.data;

            if (!file) return;

            checkStorage(file.size).then((res) => {
                if (res) {
                    uppy.addFile({
                        name: file?.name,
                        type: file?.type,
                        data: file,
                    });

                    uppy.use(Tus, {
                        endpoint: END_POINT,
                        chunkSize: CHUNK_SIZE,
                        removeFingerprintOnSuccess: true,
                        headers: {
                            "file-name": file?.name,
                            "file-type": file?.type,
                        },
                        onShouldRetry: () => {
                            return false;
                        },
                    });

                    uppy.on("complete", (result) => {
                        successHandler(result, file);
                    });

                    uppy.on("progress", (e: any) => {
                        setProgress(e);
                    });
                }
            });

            return () => {
                uppy.getFiles()[0] && uppy.removeFile(uppy.getFiles()[0].id);
                uppy.close();
                uppy.off("complete", (result) => {
                    successHandler(result, file);
                });
            };
        }

        return () => {
            uppy.close();
            uppy.off("complete", (result) => {
                successHandler(result, selectedFile?.data);
            });
        };
    }, [selectedFile]);

    const onRemoveFile = () => {
        setServerFile(null);
        setSelectedFile(null);
        setProgress(0);
    };

    return { selectedFile, onRemoveFile, setServerFile, serverFile, progress, setSelectedFile };
};

export default useMultiUploader;

export interface PickerFile {
    name: string;
    size: number;
    pickerType: "google-drive" | "dropbox" | "local";
    video_id?: string;
    url?: string;
    access_token?: string;
    data?: File;
}
