import { setProfile, setUser } from "store/slices/user";
import { Profile, User, api as generatedApi } from "../../generated";
import { setToken } from "store/slices/token";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        SwitchProfile: {
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const {
                        switch_profile: { token, profile, ...others },
                    } = data;
                    dispatch(generatedApi.util.resetApiState());
                    dispatch(setUser(others as User));
                    dispatch(setProfile(profile as Profile));
                    dispatch(setToken(token));
                } catch (err) {
                    return;
                }
            },
        },
        GetProfiles: {
            providesTags: ["Profiles"],
        },
        GetInvitations: {
            providesTags: ["Invitations"],
        },
    },
});

export const { useGetProfilesQuery, useSwitchProfileMutation, useGetInvitationsQuery } = api;
