import { Checkbox, CheckboxProps } from "@mui/material";
import useImages from "assets/images";
import { twMerge } from "tailwind-merge";

interface ThemeCheckboxProps extends CheckboxProps {
    variant?: "circle" | "square";
}

const ThemeCheckbox = (props: ThemeCheckboxProps) => {
    const Images = useImages();

    const variant = props.variant ?? "square";
    const wrapperClass = twMerge("h-5 w-5 inline-flex items-center justify-center mr-2");

    let activeIcon = <Images.CheckboxActive />;
    let inActiveIcon = <Images.CheckboxInActive />;

    if (variant === "circle") {
        activeIcon = <Images.CheckCheckboxCircleIcon color="#7680FF" />;
        inActiveIcon = <Images.UncheckCheckboxCircleIcon color="#7680FF" />;
    }

    return (
        <div className={wrapperClass}>
            <Checkbox icon={inActiveIcon} checkedIcon={activeIcon} {...props} />
        </div>
    );
};

export default ThemeCheckbox;
