import useImages from "assets/images";
import { useEffect, useRef, useState } from "react";
import { File as FileType } from "shared/graphql";
import { twMerge } from "tailwind-merge";

import { CircularProgress } from "@mui/material";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import axios from "axios";
import { errorToast } from "shared/utils/toast";

import "./Dropify/dropify-react.css";

interface Props {
    value: File | null;
    onChange: (file: FileType | null) => void;
    classes?: {
        container?: string;
    };
    uploadingState?: (state: "uploading" | "done" | "error") => void;
}

const CHUNK_SIZE = 1024 * 1024 * 10;
const ENDPOINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT || "";

const uppy = new Uppy({ debug: true, autoProceed: true }).use(Tus, {
    endpoint: ENDPOINT,
    chunkSize: CHUNK_SIZE,
});

const FDropifyUploadPhoto = ({ value, onChange, classes, uploadingState }: Props) => {
    const Images = useImages();
    const [preview, setPreview] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [progress, setProgress] = useState(0);
    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!file) return;
        if (typeof file === "object") {
            const url = URL.createObjectURL(file);
            setPreview(url);
            setProgress(1);

            const uppyFile = uppy.addFile({
                name: file?.name,
                type: file?.type,
                data: file,
            });

            uppy.setFileState(uppyFile, {
                tus: {
                    headers: {
                        "file-name": file?.name,
                        "file-type": file?.type,
                    },
                },
            });

            uploadingState && uploadingState("uploading");

            uppy.on("complete", async (result: any) => {
                if (result.successful && result.successful.length) {
                    uploadingState && uploadingState("done");
                    let uuid = result.successful[0].uploadURL.split("/").pop();
                    uuid = uuid?.split("?").shift() ?? "";
                    if (uuid && file) {
                        const res = await axios.get<FileType>(`${ENDPOINT}/metadata/${uuid}`);
                        if (res.status === 200) {
                            setPreview(res.data.url);
                            setProgress(100);
                            onChange(res.data);
                        } else {
                            errorToast("Error uploading file");
                        }
                    }
                } else {
                    uploadingState && uploadingState("error");
                }
            });

            uppy.on("progress", (e: any) => {
                if (e === 100) {
                    setProgress(97);
                } else {
                    setProgress(e > 0 ? e : 1);
                }
            });

            return () => {
                uppy.close();
            };
        }
    }, [file]);

    useEffect(() => {
        if (typeof value === "string") {
            setPreview(value);
        }
    }, [value]);

    const containerClasses = twMerge(
        "flex items-center justify-center h-[140px] w-full rounded-md border border-[#D0D5DD] relative hover:border-primary transition-300 overflow-hidden",
        classes?.container
    );

    return (
        <div className={containerClasses}>
            {progress > 0 && progress < 100 && (
                <div className="absolute w-full h-full top-0 left-0 bg-white bg-opacity-80 flex items-center justify-center">
                    <CircularProgress variant="determinate" value={progress} size={70} />
                    <span className="absolute text-[#6B7280] text-md font-semibold top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        {progress}%
                    </span>
                </div>
            )}
            <input
                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                type="file"
                ref={fileRef}
                accept="image/*"
                onChange={(e) => setFile(e.target.files?.[0] || null)}
            />
            {preview ? (
                <img className="h-full w-full object-contain object-center" alt="team-image" src={preview} />
            ) : (
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <Images.FileUploadIcon width={40} height={40} />
                    <div className="text-md mt-2">
                        <span className="font-semibold text-[#7680FF]">Click to upload</span> or drag and drop
                    </div>
                    <div className="text-md mt-1">SVG, PNG, JPG or GIF</div>
                </div>
            )}
        </div>
    );
};

export default FDropifyUploadPhoto;
