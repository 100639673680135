// CustomListNode.js
import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ToCBlock from "./ToCBlock";

export default Node.create({
    name: "tocComponent",
    content: "inline*",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            type: {
                default: "numbered",
            },
            size: {
                default: 3, // Set a default size
            },
            content: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.toc-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    type: dom.getAttribute("type"),
                    size: parseInt(dom.getAttribute("size")) || 3,
                    content: dom.getAttribute("content"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "toc-component",
                id: node.attrs.id,
                type: node.attrs.type,
                size: node.attrs.size,
                content: node.attrs.content,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ToCBlock);
    },
});
