import { setUser } from "store/slices/user";
import { User, api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        EditAccount: {
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const { edit_account } = data;
                    dispatch(setUser(edit_account as User));
                } catch (err) {
                    return;
                }
            },
        },
    },
});

export const {
    useChangePasswordMutation,
    useEditAccountMutation,
    useChangeProfileImageMutation,
    useDeleteAccountMutation,
    useUpdatePhoneNumberMutation,
    useUpdateEmailMutation,
    useVerifyEmailUpdateMutation,
    useVerifyPhoneUpdateMutation,
} = api;
