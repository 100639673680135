import useImages from "assets/images";
import { useEffect } from "react";
import StatisticCard from "shared/components/StatisticCard";
import { GetProfilesQuery, useLeaveTeamMutation } from "shared/graphql";
import useLogout from "shared/hooks/useLogout";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setCloseCommonModal, setCommonModal, setCommonModalLoadingBtn } from "store/slices/commonModal";
import { twMerge } from "tailwind-merge";

const LeaveTeam = (props: { account: GetProfilesQuery["profiles"][0]; disabled: boolean }) => {
    const Images = useImages();
    const [leaveTeam, leaveRes] = useLeaveTeamMutation();
    const dispatch = useAppDispatch();
    const { doLogout } = useLogout();

    useEffect(() => {
        rtkHandler(leaveRes, {
            successMessage: "You have left the team successfully",
            onSuccess() {
                dispatch(setCloseCommonModal());
                doLogout();
            },
        });
        dispatch(setCommonModalLoadingBtn(leaveRes.isLoading ? "delete-team-button" : ""));
    }, [leaveRes]);

    const handleLeaveTeam = () => {
        dispatch(
            setCommonModal({
                icon: <Images.LogoutLayerIcon />,
                title: "Leave Team",
                content:
                    "You will lose access to your teams account and your scans will be assigned to the team admin.",
                dialogueActionClassName: "flex-col",
                buttons: [
                    {
                        children: "Leave Team",
                        color: "error",
                        id: "delete-team-button",
                        loading: leaveRes.isLoading,
                        onClick: () => {
                            leaveTeam();
                        },
                    },
                ],
            })
        );
    };

    return (
        <StatisticCard
            onClick={props.disabled ? undefined : handleLeaveTeam}
            tooltipProps={{
                title: props.disabled ? "Please switch profile to perform this action." : "",
            }}
            classes={{
                wrapper: twMerge(
                    "p-4 bg-[#FEF3F2] border-[#EB617A] border rounded-md cursor-pointer",
                    props.disabled ? "opacity-50 cursor-not-allowed" : ""
                ),
            }}
            title="Leave Team"
            value="You will lose access to your teams account and your scans will be assigned to the team admin."
            icon={<Images.LogoutLayerIcon height={32} width={32} />}
        />
    );
};

export default LeaveTeam;
