import { IconButton, Skeleton } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { twMerge } from "tailwind-merge";

import useImages from "assets/images";
// @ts-ignore
import Video from "assets/videos/scan-tutorial-video.mp4";
import ThemeModal from "shared/components/ui/ThemeModal";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setScanTutorialVideo, setScanTutorialWatchedStep } from "store/slices/tutorial";

import TutorialData from "./steps";

const MyScanTutorial = () => {
    const { scan_tutorial_video } = useAppSelector((state) => state.tutorial);
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(
            setScanTutorialVideo({
                open: false,
            })
        );
    };

    return (
        <ThemeModal
            open={scan_tutorial_video.open || false}
            onClose={onClose}
            contentComponent={<MyScanTutorialModalContent />}
        />
    );
};

export default MyScanTutorial;

const MyScanTutorialModalContent = () => {
    const watchedStep = useAppSelector((state) => state.tutorial.scan_tutorial_watchedStep) || 1;
    const [step, setStep] = useState(1);
    const Images = useImages();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (step > watchedStep) {
            dispatch(setScanTutorialWatchedStep(step));
        }
    }, [step]);

    return (
        <div>
            <div className={twMerge("h-[192px] w-full mb-5 overflow-hidden", !loading ? "rounded-[15px]" : "")}>
                {loading && (
                    <Skeleton
                        height={"100%"}
                        classes={{
                            root: "!transform-none !rounded-md",
                        }}
                        variant="text"
                        width={"100%"}
                    />
                )}
                <ReactPlayer
                    onReady={() => setLoading(false)}
                    url={Video}
                    width="100%"
                    height="100%"
                    playing={true}
                    loop
                    muted
                />
            </div>
            {TutorialData.map((item, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: index + 1 === step ? "block" : "none",
                        }}
                    >
                        <div className="text-center">
                            <h4 className="mb-2 text-[18px] leading-6 text-[#101828] font-bold">{item.title}</h4>
                            <p className="text-[#7E7D7D] text-md leading-5 mb-5">{item.description}</p>
                        </div>
                    </div>
                );
            })}
            <div className="flex items-center justify-center space-x-4 mb-5">
                <MobileStepper
                    variant="dots"
                    steps={TutorialData.length}
                    position="static"
                    activeStep={step - 1}
                    classes={{
                        root: "flex items-center space-x-3",
                        dots: "flex items-center",
                        dotActive: "!h-[10px] !w-[10px]",
                    }}
                    nextButton={
                        <IconButton
                            className={twMerge(
                                "w-9 h-9 rounded-full items-center justify-center flex !bg-[#F2F4F7]",
                                step === TutorialData.length ? "opacity-50" : "opacity-100"
                            )}
                            disabled={step === TutorialData.length}
                            onClick={() => setStep(step + 1)}
                        >
                            <Images.ArrowChevronRight color="#7680FF" />
                        </IconButton>
                    }
                    backButton={
                        <IconButton
                            className={twMerge(
                                "w-9 h-9 rounded-full items-center justify-center flex !bg-[#F2F4F7]",
                                step === 1 ? "opacity-50" : "opacity-100"
                            )}
                            disabled={step === 1}
                            onClick={() => setStep(step - 1)}
                        >
                            <Images.ArrowChevronLeft />
                        </IconButton>
                    }
                />
            </div>
        </div>
    );
};
