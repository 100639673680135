import { Button, MobileStepper } from "@mui/material";
import useImages from "assets/images";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import { twMerge } from "tailwind-merge";
import DashboardCard, { StatsCard } from "../../components/DashboardCard";
import SemiCircleCard from "../../components/SemiCircleCard";
import useStats from "./hook";

const Stats = () => {
    const navigate = useNavigate();
    const hook = useStats();
    const Images = useImages();
    const { team, isTeamExpired } = useAuth();

    const members = team?.members.filter((member) => member.status === "Active");

    const storagePercentage = (hook.storage / (team?.setting.limits.storage_limit_in_gbs ?? 0)) * 100 ?? 0;

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="grid grid-cols-2 gap-4">
                <div className="h-full">
                    <DashboardCard className="flex flex-col !p-0">
                        <div className="text-[#101828] text-[18px] font-semibold leading-7 px-6 py-2">
                            {hook.currentSlide === 0 && team?.trial_period ? "Trial Period" : "Classroom"}
                        </div>
                        <div className="p-6 border-t border-b border-[#EAECF0] flex-1">
                            <Slider
                                ref={hook.sliderRef}
                                afterChange={(current) => hook.setCurrentSlide(current)}
                                autoplay
                                swipeToSlide
                                slidesToScroll={1}
                                arrows={false}
                                infinite={true}
                                autoplaySpeed={5000}
                                slidesToShow={1}
                            >
                                {team?.trial_period && (
                                    <SemiCircleCard
                                        title="You’ve almost reached your limit"
                                        description="Your team will lose access to the team after trial period. Please upgrade your plan before you lose access to your data."
                                        percentage={50 - hook.percentage}
                                        percentText={`${hook.dayRemaining < 0 ? 0 : hook.dayRemaining} Days`}
                                        color={isTeamExpired ? "#EB617A" : "#7680FF"}
                                    />
                                )}
                                <SemiCircleCard
                                    title="Guide to Capturing Optimal Focused Scenes"
                                    description="See how you can get the most out of project requests with your team. From different modes to different statuses, this tutorial has got it covered."
                                    percentage={hook.captureScanGuidePercentage}
                                    percentText={`${Math.round(hook.captureScanGuidePercentage * 2)}%`}
                                />
                            </Slider>
                            <MobileStepper
                                sx={{
                                    "& .MuiMobileStepper-dot": {
                                        backgroundColor: "#EAECF0",
                                    },
                                    "& .MuiMobileStepper-dotActive": {
                                        backgroundColor: "#7680FF",
                                    },
                                }}
                                classes={{
                                    root: "!p-0",
                                    dot: "!w-10 !rounded-md",
                                }}
                                id="mobile-stepper"
                                nextButton={<></>}
                                backButton={<></>}
                                variant="dots"
                                steps={team?.trial_period ? 2 : 1}
                                position="static"
                                activeStep={hook.currentSlide}
                            />
                        </div>
                        <div className="px-6 py-4 flex items-start justify-end">
                            {hook.currentSlide === 0 ? (
                                <>
                                    {team?.trial_period ? (
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={
                                                isTeamExpired ? (
                                                    <Images.Zap color="#7680FF" height={20} />
                                                ) : (
                                                    <Images.Announcement color="#7680FF" height={20} />
                                                )
                                            }
                                            onClick={hook.handleCustomerSupport}
                                        >
                                            {isTeamExpired ? "Upgrade Plan" : "Customer Support"}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            startIcon={<Images.ChromeCast color="#7680FF" />}
                                            onClick={hook.handleViewTutorial}
                                        >
                                            View Tutorial
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    startIcon={<Images.ChromeCast color="#7680FF" />}
                                    onClick={hook.handleViewTutorial}
                                >
                                    View Tutorial
                                </Button>
                            )}
                        </div>
                    </DashboardCard>
                </div>
                <div className="flex flex-col gap-4">
                    <StatsCard
                        title="Total Projects"
                        value={hook.projects.length.toString()}
                        outOf={team?.setting.limits.projects_limit.toString() ?? "0"}
                        icon={<Images.FolderOutlined color={"#ffffff"} height={20} width={20} />}
                        onClick={() => navigate(URL.Projects)}
                    />
                    <StatsCard
                        title="Total Storage"
                        value={numeral(hook.storage).format("0.00a")}
                        outOf={team?.setting.limits.storage_limit_in_gbs.toString() ?? "0"}
                        outOfUnit="GB"
                        percentage={storagePercentage > 100 ? 100 : storagePercentage}
                        icon={<Images.DatabaseOutlined color={"#ffffff"} height={20} width={20} />}
                    />
                    <StatsCard
                        title="Total Team Members"
                        value={members?.length.toString() ?? "0"}
                        outOf={team?.setting.limits.maximum_seats_limit.toString() ?? "0"}
                        percentage={hook.creditPercentage}
                        icon={<Images.TwoUsers color={"#ffffff"} height={20} width={20} />}
                    />
                </div>
            </div>
            <div className="flex space-x-4">
                {/* <DashboardCard
                    className={twMerge("flex items-center space-x-4 cursor-pointer", isTeamExpired && "opacity-50")}
                    onClick={() => {
                        if (isTeamExpired) return;
                        if (creditsCount > 0) {
                            navigate(
                                routeWithParams(`${URL.My_Scans}/${URL.My_Scans_Post}`, {
                                    type: "video",
                                })
                            );
                        } else {
                            hook.handleNotEnoughScan();
                        }
                    }}
                >
                    <div className="rounded-[10px] h-12 w-12 border border-[#EAECF0] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
                        <Images.AddUserIcon height={24} width={24} color="#344054" />
                    </div>
                    <div className="items-stretch flex flex-col self-start">
                        <h4 className="text-slate-700 text-base font-semibold leading-6">Submit a new scan</h4>
                        <h5 className="overflow-hidden text-slate-600 text-ellipsis text-sm leading-5">
                            Show your team members what you’re up to
                        </h5>
                    </div>
                </DashboardCard> */}
                <DashboardCard
                    className={twMerge("flex items-center space-x-4 cursor-pointer")}
                    onClick={() => {
                        navigate(`${URL.Projects}/${URL.Projects_Post}`);
                    }}
                >
                    <div className="rounded-[10px] h-12 w-12 border border-[#EAECF0] flex items-center justify-center shadow-[0px_1px_2px_0px_#1018280D]">
                        <Images.FolderIcon height={24} width={24} color="#344054" />
                    </div>
                    <div className="items-stretch flex flex-col self-start">
                        <h4 className="text-slate-700 text-base font-semibold leading-6">Create a new project</h4>
                        <h5 className="overflow-hidden text-slate-600 text-ellipsis text-sm leading-5">
                            Dive into the editor and start requesting
                        </h5>
                    </div>
                </DashboardCard>
            </div>
        </div>
    );
};

export default Stats;
