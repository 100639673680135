import useImages from "assets/images";
import { useState } from "react";
import ReactPlayer from "react-player";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { File } from "shared/graphql";
import { convertSizeToText } from "shared/utils/helpers";

const FilePreview = (props: { file: File | null; onRemove?: () => void }) => {
    const Images = useImages();
    const [play, setPlay] = useState(false);

    if (!props.file) return null;

    return (
        <div className="h-full flex flex-col rounded-md relative">
            <div className="h-full">
                {props.onRemove && (
                    <ThemeIconButton
                        className="top-4 right-4 !bg-[#667085] !absolute !rounded-full z-30"
                        icon={<Images.DeleteIcon color="#ffffff" />}
                        onClick={props.onRemove}
                    />
                )}
                <ReactPlayer
                    url={props.file.url}
                    width="100%"
                    height="100%"
                    playing={play}
                    loop
                    muted
                    controls={false}
                />
                <div className="absolute top-1/2 -translate-y-1/2 left-0 flex items-center justify-center space-x-10 w-full">
                    <div
                        className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                        onClick={() => setPlay(!play)}
                    >
                        {play ? <Images.PauseIcon /> : <Images.PlayButtonIcon />}
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full pt-6 p-4 bg-gradient-to-t from-[#000000A9] to-transparent">
                <h4 className="text-[#ffffff] leading-5 font-medium text-lg">{props.file.name}</h4>
                <p className="text-[#ffffff] leading-5 font-normal text-md">{convertSizeToText(props.file.size)}</p>
            </div>
        </div>
    );
};

export default FilePreview;
