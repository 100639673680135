import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetScanRequestByIdQuery } from "shared/graphql";

interface Props {
    selected: GetScanRequestByIdQuery["scan_request"] | null;
}

const initialState: Props = {
    selected: null,
};

const scan_requests = createSlice({
    name: "scan_requests",
    initialState,
    reducers: {
        setSelectScanRequest(state, action: PayloadAction<Props["selected"]>) {
            state.selected = action.payload;
        },
    },
});

export default scan_requests.reducer;

export const { setSelectScanRequest } = scan_requests.actions;
