import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface IStyledTextProps {
    id: string;
    size: 1 | 2 | 3 | 4 | 5;
    color: string;
    alignment: "left" | "center" | "right" | "justify";
    content: string | null;
    custom_class: string;
}

const StyledText = forwardRef((props: any, ref) => {
    const { id, size, color, alignment, content, custom_class } = props.node.attrs as IStyledTextProps;
    const dispatch = useAppDispatch();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const contentRef = useRef(null);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const styling = useMemo(() => {
        return `text-size-${size} text-${alignment}`;
    }, [size, color, alignment]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (contentRef.current) {
            (contentRef.current as any).innerText = content ?? "Add text here";

            (contentRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    useEffect(() => {}, [content]);

    return (
        <NodeViewWrapper
            ref={ref}
            id={id}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "styledText",
                        props: { id: props.node.attrs.id, attrs: props.node.attrs },
                    })
                );

                const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
                parentEditor!.view.dispatch(transaction);
            }}
            contentEditable={false}
            className="relative"
        >
            <div
                ref={contentRef}
                contentEditable={!isPreviewOpen}
                className={`editor-block relative z-[11] styled-text ${custom_class} ${styling} ${
                    isSelected && "editor-block-selected"
                }`}
                style={{
                    color: color,
                }}
                onInput={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();

                    let pos = 0;
                    let nodeAttrs: any = null;

                    parentEditor!.view.state.doc.descendants((node, position) => {
                        if (node.attrs.id == id) {
                            pos = position;
                            nodeAttrs = node.attrs;
                            return false;
                        }
                    });

                    const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
                        ...nodeAttrs,
                        content: (e.target as any).innerText,
                    });

                    parentEditor!.view.dispatch(transaction);
                }}
            ></div>
            {parentEditor && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
});

export default StyledText;
