import useImages from "assets/images";
import { PropsWithChildren } from "react";
import { useIsProjectEditor } from "../screens/CompleteProfileScreen/layout";
import { twMerge } from "tailwind-merge";

const AuthHeader = (props: PropsWithChildren) => {
    const Images = useImages();
    const { isProjectEditor } = useIsProjectEditor();

    return (
        <div
            className={twMerge(
                "flex items-center justify-between h-20 px-8 transition-300",
                isProjectEditor && "!pl-11"
            )}
        >
            {isProjectEditor ? <Images.LogoMono /> : <Images.Logo />}
            <div>{props.children}</div>
        </div>
    );
};

export default AuthHeader;
