import { Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { DataTableHeaderProps } from "shared/components/ui/DataTable/type";
import UserProfileCard from "shared/components/UserProfileCard";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { ProfileTypes } from "shared/utils/enums";
import moment from "shared/utils/moment";

import AcceptInvitation from "./partials/AcceptInvitation";
import PromoteToManager from "./partials/PromoteToManager";
import RemoveMember from "./partials/RemoveMember";
import ResendInviteMember from "./partials/ResendInviteMember";

const useTeamMembersSection = () => {
    const { isTeamMember } = useAuth();

    const members = useAppSelector((state) => state.team.current?.members) || [];

    const [inviteModal, setInviteModal] = useState(false);

    const [filterOpt, setFilterOpt] = useState("Active");

    const toggleInviteModal = () => {
        setInviteModal(!inviteModal);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const teamTab = urlParams.get("teamTab");
        if (teamTab && TeamMemberFilters.map((item) => item.value).includes(teamTab)) {
            setFilterOpt(teamTab);
        }
    }, []);

    const HEADERS: DataTableHeaderProps[] = useMemo(() => {
        if (filterOpt !== "Active") {
            return [
                {
                    title: "User Emails",
                    selector: (item) => {
                        return item.user_id?.email || item.email || "";
                    },
                },
                {
                    title: "Invite Date",
                    selector: (item) => (item.invited_at ? moment(item.invited_at).format("MMM DD, YYYY") : ""),
                    width: "220px",
                    align: "center",
                },

                ...((!isTeamMember
                    ? [
                          {
                              title: "Actions",
                              selector: (item: TeamMember) => {
                                  return (
                                      <div className="flex flex-row items-center justify-center gap-x-4">
                                          {filterOpt === "Invited" && <ResendInviteMember data={item} />}
                                          {filterOpt === "Requested" && <AcceptInvitation data={item} />}
                                          <RemoveMember data={item} />
                                      </div>
                                  );
                              },
                              width: "235px",
                              align: "center",
                          },
                      ]
                    : []) as DataTableHeaderProps[]),
            ];
        }

        return [
            {
                title: "Team Member",
                selector(item: TeamMember) {
                    return (
                        <UserProfileCard
                            image={item.user?.profile_image?.url}
                            name={item.user?.name}
                            email={item.email}
                        />
                    );
                },
            },
            {
                title: "Username",
                selector: "profile.username",
                align: "center",
            },
            {
                title: "Joined Since",
                selector: (item) => (item.accepted_at ? moment(item.accepted_at).format("MMM DD, YYYY") : ""),
                align: "center",
            },
            {
                title: "Role",
                selector(item: TeamMember) {
                    return (
                        <div className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px] inline">
                            {item.role}
                        </div>
                    );
                },
                align: "center",
            },
            {
                title: "Scanning Devices",
                selector(item: TeamMember) {
                    const maxDevices = 2;
                    const first4Devices = item.user?.scanning_devices?.slice(0, maxDevices);
                    const isLengthExceed =
                        first4Devices && (item.user?.scanning_devices?.length ?? 0) > first4Devices.length;

                    return (
                        <Tooltip
                            title={
                                isLengthExceed
                                    ? item.user?.scanning_devices?.map((device) => {
                                          return (
                                              <div
                                                  className="text-[#344054] text-sm font-medium leading-[18px]"
                                                  key={device.scanning_device_id}
                                              >
                                                  {device.name}
                                              </div>
                                          );
                                      })
                                    : ""
                            }
                            placement="top"
                            classes={{
                                tooltip: "!bg-white !py-2 !px-3 text-center shadow-[0px_12px_16px_-4px_#10182814]",
                                arrow: "!text-white",
                            }}
                        >
                            <div className="flex flex-wrap justify-center gap-1">
                                {first4Devices && first4Devices.length ? (
                                    first4Devices?.slice(0, maxDevices).map((device) => {
                                        return (
                                            <div
                                                className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px] inline"
                                                key={device.scanning_device_id}
                                            >
                                                {device.name}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <>-</>
                                )}
                                {isLengthExceed && (
                                    <div className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px] inline">
                                        +{(item.user?.scanning_devices?.length ?? 0) - first4Devices.length}
                                    </div>
                                )}
                            </div>
                        </Tooltip>
                    );
                },
                align: "center",
            },
            ...((!isTeamMember
                ? [
                      {
                          title: "Actions",
                          width: "20%",
                          selector(item: any) {
                              if (item.role === ProfileTypes.Admin) return <></>;
                              return (
                                  <div className="flex flex-row items-center justify-center gap-x-4">
                                      <RemoveMember data={item} />
                                      {filterOpt === "Active" && item.role !== ProfileTypes.TeamManager && (
                                          <PromoteToManager data={item} />
                                      )}
                                  </div>
                              );
                          },
                          align: "center",
                      },
                  ]
                : []) as DataTableHeaderProps[]),
        ];
    }, [filterOpt, members, isTeamMember]);

    const onClickFilter = (value: string) => {
        setFilterOpt(value);
    };

    const TeamMemberFilters = [
        {
            label: "Active",
            value: "Active",
        },
        {
            label: "Invites",
            value: "Invited",
        },
        {
            label: "Requests",
            value: "Requested",
        },
    ];

    const membersByStatus = useMemo(() => {
        const finalMembers: any = {};

        TeamMemberFilters.forEach((filter) => {
            (finalMembers as any)[filter.value] = [];
        });

        if (members.length > 0) {
            members.forEach((member) => {
                (finalMembers as any)[member.status].push(member);
            });

            return finalMembers;
        }

        return finalMembers;
    }, [members]);

    return {
        membersByStatus,
        HEADERS,
        TeamMemberFilters,
        filterOpt,
        inviteModal,
        onClickFilter,
        toggleInviteModal,
    };
};

export default useTeamMembersSection;
