import { TabProps, Tabs, styled } from "@mui/material";
import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
    value: string;
    onChange: (val: string) => void;
    children: React.ReactNode;
    classes?: TabProps["classes"];
    className?: string;
    customTabStyles?: TabProps["sx"];
}

const ButtonTabs = (props: Props) => {
    const classes = twMerge("flex items-center overflow-auto w-full", props.className);

    return (
        <CustomTabs
            scrollButtons="auto"
            value={props.value}
            onChange={(_, val) => {
                props.onChange(val);
            }}
            className={classes}
            sx={props.customTabStyles}
            classes={props.classes || {}}
        >
            {props.children}
        </CustomTabs>
    );
};

export default ButtonTabs;

const CustomTabs = styled(Tabs)({
    // indicator
    "& .MuiTabs-indicator": {
        height: "36px",
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    "& .MuiTab-root": {
        textAlign: "center",
        width: "50%",
        marginLeft: "0 !important",
        color: "#5E5D5D !important",
        fontWeight: 500,
        padding: "9px 0",

        "& span": {
            position: "relative",
            zIndex: 1,
        },
    },
});
