import { useContext, useEffect, useRef, useState } from "react";
import { UseControllerReturn } from "react-hook-form";
import FieldContext from "../../Field.context";
import { IFieldProps } from "../../Field.type";
import "./dropify-react.css";
import useImages from "assets/images";

const Dropify = () => {
    const { FileUploadIcon } = useImages();
    const { methods, props }: any = useContext(FieldContext);

    const { inputFileProps }: IFieldProps = props;
    const {
        previewMessage = (
            <>
                <span className="text-primary">Click to upload</span> or drag and drop
            </>
        ),
    } = inputFileProps || {};

    const {
        field: { name, value, onChange, ref },
    }: UseControllerReturn = methods;

    const preview_img_ref: any = useRef();
    const [fileName, setFileName] = useState("");
    const [hasImage, setHasImage] = useState(false);

    const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setFileName(file.name);
            setHasImage(true);
            onChange(file);
            const url = URL.createObjectURL(file);
            preview_img_ref.current.src = url;
        }
    };

    // onRemoveImage
    const onRemoveImage = () => {
        preview_img_ref.current.src = "";
        setFileName("");
        setHasImage(false);
        onChange(null);
    };

    useEffect(() => {
        if (typeof value === "string" && value) {
            preview_img_ref.current.src = value;
            setHasImage(true);
        } else if (!value) {
            preview_img_ref.current.src = "";
            setFileName("");
            setHasImage(false);
        }
    }, [value]);

    return (
        <>
            <div className={`dropify-wrapper ${hasImage && "has-preview"}`}>
                <div className="dropify-message text-center">
                    <span className="file-icon">
                        <FileUploadIcon />
                    </span>
                    <p>{previewMessage}</p>
                    <p>SVG, PNG, JPG or JPEG (max. 800x250px)</p>
                </div>
                <input
                    type="file"
                    ref={ref}
                    name={name}
                    data-preview-image=""
                    onChange={onChangeImage}
                    id="input-file-now"
                    className="dropify"
                    accept="image/*"
                />
                <button type="button" onClick={onRemoveImage} className="dropify-clear">
                    Remove
                </button>
                <div className="dropify-preview">
                    <span className="dropify-render">
                        <img src="" ref={preview_img_ref} alt="" />
                    </span>
                    <div className="dropify-infos">
                        <div className="dropify-infos-inner">
                            <p className="dropify-filename">
                                <span className="file-icon"></span>{" "}
                                <span className="dropify-filename-inner">{fileName}</span>
                            </p>
                            <p className="dropify-infos-message">Drag and drop or click to replace</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dropify;
