import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        RemoveMember: {
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
        InviteMembers: {
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
        ApproveRequest: {
            invalidatesTags: (_, error) => (error ? [] : ["Profiles", "UserDetails", "Invitations"]),
        },
        AddTeamManager: {
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
        TransferOwnership: {
            invalidatesTags: (_, error) => (error ? [] : ["Profiles", "UserDetails"]),
        },
    },
});

export const {
    useInviteMembersMutation,
    useResendInvitationMutation,
    useRequestTeamMutation,
    useAcceptInvitationMutation,
    useApproveRequestMutation,
    useRemoveMemberMutation,
    useAddTeamManagerMutation,
} = api;
