import { useContext } from "react";
import { UseControllerReturn } from "react-hook-form";
import ThemeSelect from "shared/components/Fields/ThemeSelect";
import FieldContext from "../Field.context";
import { IFieldProps } from "../Field.type";
import { omit } from "lodash";

const FSelect = () => {
    const { methods, props }: any = useContext(FieldContext);

    const {
        field: { name, value, onChange },
    }: UseControllerReturn = methods;
    const { placeholder, options = [], inputVariant = "outlined", selectFieldProps }: IFieldProps = props;

    return (
        <ThemeSelect
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={(e) => {
                selectFieldProps?.onChange && selectFieldProps.onChange(e);
                onChange(e);
            }}
            variant={inputVariant}
            options={options}
            {...omit([selectFieldProps, "onChange"])}
            selectFieldProps={selectFieldProps}
            classes={{
                icon: "!mr-[10px]",
                ...selectFieldProps?.classes,
            }}
        />
    );
};

export default FSelect;
