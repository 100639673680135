import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import yup from "shared/utils/yup";

interface SubscriptionSettingScreenForm {
    user_id: string;
    username: string;
    password: string;
}

const useSubscriptionSettingScreen = () => {
    const schema = yup.object().shape({
        user_id: yup.string().required(),
        username: yup.string().email().required(),
        password: yup.string().required(),
    });

    const form = useForm<SubscriptionSettingScreenForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            user_id: "",
            username: "",
            password: "",
        },
    });

    const onSubmit = () => {};

    return { form, onSubmit };
};

export default useSubscriptionSettingScreen;
