import { RouteObject } from "react-router-dom";
import Dashboard from "screens/Dashboard/Dashboard";

import SubscriptionEndScreen from "screens/Dashboard/SubscriptionEnd";
import TrailPeriodEndedScreen from "screens/Dashboard/TrailPeriodEnded";
import URL from "shared/constants/navigator";

const dashboardRoutes: RouteObject[] = [
    {
        path: URL.Dashboard,
        element: <Dashboard />,
    },
    {
        path: URL.Trail_Period_End,
        element: <TrailPeriodEndedScreen />,
    },
    {
        path: URL.Subscription_End,
        element: <SubscriptionEndScreen />,
    },
];

export default dashboardRoutes;
