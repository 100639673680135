import React from "react";

interface Props {
    loading: boolean;
    text?: string;
    className?: string;
}

const LoadingSnackbar: React.FC<Props> = ({ loading, text = "Loading...", className = "" }) => {
    if (!loading) return null;

    return (
        <div
            style={{
                zIndex: 9999,
            }}
            className={`absolute rounded-[21px] bottom-4 left-1/2 -translate-x-1/2 px-3 py-[6px] text-md leading-5 font-normal text-white bg-primary inline ${className}`}
        >
            {text}
        </div>
    );
};

export default LoadingSnackbar;
