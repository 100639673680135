import { useEffect, useState } from "react";
import { GetTeamQuery, MemberDetailsFragment, useGetTeamQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const useGetTeamMemberApi = () => {
    const [members, setMembers] = useState<MemberDetailsFragment[]>([]);
    const teamRes = useGetTeamQuery();

    useEffect(() => {
        rtkHandler(teamRes, {
            showErrorToast: false,
            onSuccess({ team }: GetTeamQuery) {
                setMembers(team.members.filter((m) => m.status === "Active"));
            },
        });
    }, [teamRes]);

    return { members, isLoading: teamRes.isLoading };
};

export default useGetTeamMemberApi;
