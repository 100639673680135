import { useEffect, useState } from "react";
import {
    AddScanningDevicesMutation,
    AddUserScanningDevicesMutation,
    GetScanningDeviceQuery,
    User,
    useAddScanningDevicesMutation,
    useAddUserScanningDevicesMutation,
    useGetScanningDeviceQuery,
} from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { ScanningDeviceCategory } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";
import { setUser } from "store/slices/user";

const useScanDevicePostModalContent = (props: { onClose: () => void; type: ScanningDeviceCategory }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const [devices, setDevices] = useState<GetScanningDeviceQuery["scanning_devices"]>([]);
    const getResponse = useGetScanningDeviceQuery(undefined);
    const [selectedDevices, setSelectedDevices] = useState<ISelectMenuList[]>([]);
    const [addScanning, addResponse] = useAddScanningDevicesMutation();
    const [updateUser, updateUserRes] = useAddUserScanningDevicesMutation();

    useEffect(() => {
        rtkHandler(getResponse, {
            onSuccess({ scanning_devices }: GetScanningDeviceQuery) {
                setDevices(
                    scanning_devices.filter(
                        (item) =>
                            item.category === props.type &&
                            user?.scanning_devices
                                .map((d) => d.scanning_device_id)
                                .includes(item.scanning_device_id) === false
                    )
                );
            },
        });
    }, [getResponse]);

    useEffect(() => {
        dispatch(
            setCommonModalLoadingBtn(addResponse.isLoading || updateUserRes.isLoading ? "add-scanning-device" : "")
        );
    }, [addResponse.isLoading, updateUserRes.isLoading]);

    useEffect(() => {
        rtkHandler(addResponse, {
            onSuccess(res: AddScanningDevicesMutation) {
                const devices = res.add_scanning_devices.filter((item) =>
                    selectedDevices.map((a) => a.label).includes(item.name)
                );

                updateUser({
                    data: {
                        devices: devices.map((item) => item.scanning_device_id),
                    },
                });
            },
        });
    }, [addResponse]);

    useEffect(() => {
        rtkHandler(updateUserRes, {
            successMessage: "Scanning device added successfully.",
            onSuccess(res: AddUserScanningDevicesMutation) {
                dispatch(
                    setUser({
                        ...user,
                        scanning_devices: res?.add_user_scanning_devices,
                    } as User)
                );
                props.onClose();
                dispatch(api.util.invalidateTags(["Teams"]));
            },
        });
    }, [updateUserRes]);

    const submitScanningDevices = () => {
        if (!selectedDevices.length) {
            errorToast("Please select at least one device.");
            return;
        }

        const newDevices = selectedDevices.filter((item: any) => item.new);

        if (newDevices.length) {
            addScanning({
                data: {
                    devices: newDevices.map((item) => item.value),
                    category: props.type,
                },
            });
            return;
        }

        const devices = selectedDevices.map((item) => parseInt(item.value));

        updateUser({
            data: { devices },
        });
    };

    return { selectedDevices, devices, setSelectedDevices, submitScanningDevices };
};

export default useScanDevicePostModalContent;
