import { Button } from "@mui/material";

import useImages from "assets/images";
import { useNavigate } from "react-router-dom";
import SRAccept from "screens/Dashboard/ScanRequests/common/components/SRAccept";
import SRFeedback from "screens/Dashboard/ScanRequests/common/components/SRFeedback";
import SRReminder from "screens/Dashboard/ScanRequests/common/components/SRReminder";
import SRRevision from "screens/Dashboard/ScanRequests/common/components/SRRevision";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import RenderIf from "shared/components/RenderIf";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeRating from "shared/components/ui/ThemeRating";

interface Props {
    submissionId: string;
    onBack: () => void;
}

const SRSingleSubmission = (props: Props) => {
    const Images = useImages();
    const navigate = useNavigate();
    const { request, isRequestor } = useSingleSR();

    const { submissionId } = props;

    const application = request?.applications.find(
        (application) => application.application_id === parseInt(submissionId!)
    );

    if (!application) return <></>;

    const awaiting = application.status === "Awaiting Submission";
    const accepted = application.status === "Accepted";
    const InReview = application.status === "In Review";

    const review = application?.review;

    return (
        <>
            <div className="relative overflow-hidden">
                <div className="h-[240px] border-b relative mb-6">
                    <Button
                        onClick={props.onBack}
                        className="absolute top-2 left-2 !border-none !text-[#98A2B3] !font-normal space-x-1"
                        color="inherit"
                        variant="text"
                    >
                        <Images.ArrowLeftNav color="#98A2B3" />
                        <span>Back</span>
                    </Button>
                    <div className="absolute h-[288px] w-[288px] border border-[#EAECF0] border-opacity-30 rounded-full p-6 -top-[90px] left-1/2 -translate-x-1/2">
                        <div className="w-full h-full border border-[#EAECF0] border-opacity-50 rounded-full p-6">
                            <div className="w-full h-full border border-[#EAECF0] border-opacity-70 rounded-full p-6">
                                <div className="w-full h-full border border-[#EAECF0] border-opacity-90 rounded-full p-5">
                                    <div className="w-full h-full border border-[#EAECF0] rounded-full p-5 flex items-center justify-center">
                                        <RenderImage
                                            image={application?.user.profile_image.url}
                                            containerClassName="h-[56px] w-[56px] rounded-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bottom-0 text-center w-full">
                        <h4 className="text-[#101828] text-xl font-medium mb-2">{application?.user.name}</h4>
                        {review ? (
                            <>
                                <h4 className="mb-5 text-md text-[#667085]">
                                    Reviews From requestor for the submission of requestee
                                </h4>
                                <ThemeRating className="mb-2" readonly initialRating={review.rating} />
                            </>
                        ) : (
                            <h5 className="text-[#667085] text-md font-normal mb-5">No Reviews Yet</h5>
                        )}
                        {isRequestor && request?.status !== "Archived" && (
                            <div className="!mb-5 flex items-center space-x-3 justify-center">
                                <RenderIf condition={InReview}>
                                    <SRRevision isButton data={application} />
                                    <SRAccept isButton data={application} />
                                </RenderIf>
                                <RenderIf condition={awaiting}>
                                    <SRReminder isButton data={application} />
                                </RenderIf>
                                <RenderIf condition={accepted}>
                                    <SRFeedback isButton data={application} />
                                </RenderIf>
                            </div>
                        )}
                    </div>
                </div>
                {application?.submissions.length ? (
                    <div className="grid grid-cols-3 gap-4">
                        {application?.submissions.map((sub, i) => {
                            return (
                                <div
                                    className="flex flex-col space-y-2 cursor-pointer"
                                    key={i}
                                    onClick={() => navigate(`scans/${sub.scan_id}`)}
                                >
                                    <RenderImage
                                        image={sub.input_file.thumbnail || ""}
                                        containerClassName="h-[200px] rounded-md"
                                    />
                                    <span className="text-sm leading-4 font-medium text-[#3F3E3E]">{sub.name}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <h4 className="text-p text-[14px] leading-6 text-center">No submissions yet</h4>
                )}
            </div>
        </>
    );
};

export default SRSingleSubmission;
