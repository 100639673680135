import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPost } from "shared/types/feed/post.type";

interface Props {
    selected: IPost | null | undefined;
    selectedCategories: string[];
    sharePostProps: IPost | null;
}

const initialState: Props = {
    selected: undefined,
    selectedCategories: [],
    sharePostProps: null,
};

const posts = createSlice({
    name: "posts",
    initialState,
    reducers: {
        setSelectedPost(state, action: PayloadAction<IPost | null>) {
            state.selected = action.payload;
        },
        showShareModal(state, action) {
            state.sharePostProps = action.payload;
        },
        hideShareModal(state) {
            state.sharePostProps = null;
        },
        toggleCategory(state, action: PayloadAction<string>) {
            const categoryToToggle = action.payload;

            if (state.selectedCategories.includes(categoryToToggle)) {
                state.selectedCategories = state.selectedCategories.filter((category) => category !== categoryToToggle);
            } else {
                state.selectedCategories = [...state.selectedCategories, categoryToToggle];
            }
        },
    },
});

export default posts.reducer;

export const { setSelectedPost, showShareModal, hideShareModal, toggleCategory } = posts.actions;
