import { createSlice } from "@reduxjs/toolkit";
import { Editor } from "@tiptap/core";

interface Props {
    selectedTemplate: number;
    isSelectingTemplate: boolean;
    isReselectingTemplate: boolean;
    isReselected: boolean;
    isSidebarOpen: boolean;
    isPropertiesSidebarOpen: boolean;
    isPreviewOpen: boolean;
    selectedBlock?: {
        type: string;
        props: {
            id: string;
            attrs: any;
        };
    };
    main_editor: Editor | null;
    defaultContent: string | null;
}

const initialState: Props = {
    selectedTemplate: -1,
    isSelectingTemplate: true,
    isReselectingTemplate: false,
    isReselected: false,
    isSidebarOpen: true,
    isPropertiesSidebarOpen: false,
    isPreviewOpen: false,
    selectedBlock: undefined,
    main_editor: null,
    defaultContent: null,
};

const publishableProject = createSlice({
    name: "publishable_project",
    initialState,
    reducers: {
        setSelectedTemplate(state, action) {
            state.selectedTemplate = action.payload;
        },
        showTemplateSelection(state) {
            state.isSelectingTemplate = true;
            state.isReselectingTemplate = true;
            state.isReselected = true;
            state.isPropertiesSidebarOpen = false;
            state.selectedTemplate = -1;
            state.defaultContent = null;
        },
        closeTemplateSelection(state) {
            state.isSelectingTemplate = false;
            state.isReselectingTemplate = false;
            state.isPropertiesSidebarOpen = true;
        },
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        togglePropertiesSidebar(state) {
            state.isPropertiesSidebarOpen = !state.isPropertiesSidebarOpen;
        },
        togglePreview(state) {
            state.isPropertiesSidebarOpen = state.isPreviewOpen;
            state.isSidebarOpen = state.isPreviewOpen;
            state.isPreviewOpen = !state.isPreviewOpen;
        },
        enablePreview(state) {
            state.isPropertiesSidebarOpen = false;
            state.isSelectingTemplate = false;
            state.isSidebarOpen = false;
            state.isPreviewOpen = true;
        },
        setSelectedBlock(state, action) {
            state.selectedBlock = action.payload;
        },
        setEditor(state, action) {
            state.main_editor = action.payload;
        },
        resetEditor(state) {
            state.selectedTemplate = -1;
            state.isSelectingTemplate = true;
            state.isSidebarOpen = true;
            state.isPropertiesSidebarOpen = false;
            state.isPreviewOpen = false;
            state.selectedBlock = undefined;
            state.main_editor = null;
            state.defaultContent = null;
        },
        setDefaultContent(state, action) {
            state.defaultContent = action.payload;
        },
    },
});

export default publishableProject.reducer;

export const {
    setSelectedTemplate,
    showTemplateSelection,
    closeTemplateSelection,
    toggleSidebar,
    togglePropertiesSidebar,
    togglePreview,
    enablePreview,
    setSelectedBlock,
    setEditor,
    resetEditor,
    setDefaultContent,
} = publishableProject.actions;
