import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import DataTable from "shared/components/ui/DataTable";
import { DataTableHeaderProps } from "shared/components/ui/DataTable/type";
import UserProfileCard from "shared/components/UserProfileCard";
import { TeamMember } from "shared/graphql";
import useGetTeamMemberApi from "shared/hooks/useGetTeamMemberApi";

const ScanCreditsUsage = () => {
    const { members, isLoading } = useGetTeamMemberApi();
    const HEADERS: DataTableHeaderProps[] = [
        {
            title: "Team Member",
            width: "45%",
            align: "left",
            selector: (item: TeamMember) => {
                return (
                    <UserProfileCard
                        image={item.user?.profile_image.url}
                        name={item.user?.name}
                        email={item.user?.email}
                    />
                );
            },
        },
        {
            title: (
                <>
                    Scan Credit Allowed (<span className="text-[#7680FF]">this month</span>)
                </>
            ),
            width: "22%",
            align: "left",
            selector: "scan_credits_allocated",
        },
        {
            title: (
                <>
                    Scan Credit Used (<span className="text-[#7680FF]">this month</span>)
                </>
            ),
            width: "20%",
            align: "left",
            selector: "scan_credits_used",
        },
        {
            title: "Role",
            selector(item: TeamMember) {
                return (
                    <div className="text-[#344054] bg-[#F2F4F7] rounded-full text-sm font-medium leading-[18px] px-2 py-[2px]">
                        {item.role}
                    </div>
                );
            },
            width: "13%",
            align: "center",
        },
    ];

    return (
        <SettingPageCard
            title="Scan Credits Usage"
            subTitle="See your team members scan credits division and usage here"
            hasHeaderDivider={false}
        >
            <DataTable loading={isLoading} headers={HEADERS} data={members} />
        </SettingPageCard>
    );
};

export default ScanCreditsUsage;
