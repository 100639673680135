import { CircularProgress, Tooltip } from "@mui/material";
import { capitalize } from "lodash";
import ReactPlayer from "react-player";

import useImages from "assets/images";
import DummyProfileImage from "assets/images/dummy/dummy-profile-pic.png";
import Map from "shared/components/Map";
import ITDCard from "shared/components/ui/ITDCard";
import RenderImage from "shared/components/ui/RenderImage";
import { validCoordinates } from "shared/utils/helpers";

import useSRSingleScan from "./hook";

const SRSingleScan = () => {
    const hook = useSRSingleScan();
    const scan = hook.scan;
    const request = hook.request;
    const application = hook.application;

    const Images = useImages();

    if (!scan || !request) return <></>;

    return (
        <div className="grid grid-cols-[55%,1fr] gap-4 mb-4">
            <div className="card-dashed-border flex flex-col gap-4">
                <div className="grid grid-cols-1 gap-4">
                    <div className="grid grid-cols-3 gap-4">
                        <ITDCard
                            img={application?.user?.profile_image?.url ?? DummyProfileImage}
                            iconWrapperClassName="flex-initial h-10 w-10"
                            iconClassName="h-10 w-10 rounded-full border border-[#E9E9E9]"
                            title={application?.user?.name || ""}
                            desc="Scanner"
                        />
                        <ITDCard
                            iconWrapperClassName="flex-initial"
                            icon={<Images.ImageDarkIcon />}
                            title={request.scan_focus}
                            desc="Object"
                        />
                        <ITDCard
                            iconWrapperClassName="flex-initial"
                            icon={<Images.VideoDarkIcon />}
                            title={capitalize(request.dataset_type)}
                            desc="Dataset Type"
                        />
                    </div>
                </div>
                <div className="flex-1 w-full bg-white p-4 rounded-2xl">
                    <div className="w-full h-[200px]">
                        <Map center={validCoordinates(request.location)} isInteract={false} showLocationMarker={true} />
                    </div>
                    <div className="mt-3 font-medium text-[#5E5D5D]">
                        <Tooltip placement="top" title={hook.locationName}>
                            <div className="text-lg whitespace-nowrap overflow-hidden text-ellipsis">
                                {hook.locationName ?? "N/A"}
                            </div>
                        </Tooltip>
                        <div className="text-p opacity-50 text-md leading-4 font-normal">Location</div>
                    </div>
                </div>
            </div>
            <div className="bg-primary bg-opacity-10 p-4 rounded-[16px] overflow-hidden h-full">
                <div className={`relative h-full group ${hook.play ? "play-video" : ""}`}>
                    {!hook.play && (
                        <RenderImage
                            image={scan.input_file?.thumbnail || ""}
                            imgClassName="rounded-[16px] max-h-[450px]"
                            containerClassName="h-full w-full absolute top-0 left-0 group-[.play-video]:opacity-0 group-[.play-video]:invisible group-[.play-video]:h-0 z-[1] rounded-[15px] bg-white"
                        />
                    )}
                    {scan.input_file?.url && hook.play && (
                        <div className="video-player animate-fade-in h-full w-full max-h-full group-[.play-video]:block absolute top-0 left-0 z-">
                            <ReactPlayer
                                url={scan.input_file.url}
                                width="100%"
                                height="100%"
                                playing={hook.play}
                                loop
                                muted
                                controls
                                onReady={hook.readyToPlay}
                            />
                        </div>
                    )}
                    {!hook.play && (
                        <>
                            <div className="absolute top-4 right-4 flex items-center justify-center space-x-10">
                                <Tooltip title="Download Video" arrow placement="top">
                                    <div
                                        className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                        onClick={hook.downloadVideo}
                                    >
                                        {hook.isDownloading ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <Images.DownloadIconV2 height={25} width={25} color="#3F3E3E" />
                                        )}
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    )}
                    {hook.play ? (
                        <div
                            className="absolute top-[22px] right-4 bg-white bg-opacity-70 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer z-[4]"
                            onClick={hook.togglePlay}
                        >
                            <Images.CloseIcon />
                        </div>
                    ) : (
                        scan.input_file?.url && (
                            <div className="absolute bottom-[34px] flex items-center justify-center space-x-10 w-full">
                                <div
                                    className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                    onClick={hook.togglePlay}
                                >
                                    <Images.PlayButtonIcon />
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default SRSingleScan;
