import { Badge, LinearProgress } from "@mui/material";
import { matchRoutes, useLocation } from "react-router-dom";

import useImages from "assets/images";
import IsStagingComponent from "shared/components/IsStagingComponent";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { compressSidebar, expandSidebar, toggleNotificationSidebar } from "store/slices/utils";

import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import SidebarItem from "./item";
import LoggedUserDetail from "./utils/LoggedUserDetail";
import SidebarMenus from "./utils/SidebarMenus";
import Bell01 from "@untitled-ui/icons-react/build/esm/Bell01";

const Sidebar = () => {
    const ThemeImage = useImages();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { team } = useAuth();
    const notifications = useAppSelector((state) => state.notifications.count);
    const isSidebarCollapsed = useAppSelector((state) => state.utils.isSidebarCollapsed);

    const days = team?.trial_period?.days_remaining || 0;
    const dayRemaining = days < 0 ? 0 : days;
    const percentage = (dayRemaining / 14) * 100;

    const isEditorPage = Boolean(
        matchRoutes(
            [
                {
                    path: URL.Publishable_Project,
                },
            ],
            location
        )
    );

    useEffect(() => {
        if (isEditorPage) {
            dispatch(compressSidebar());
        } else {
            dispatch(expandSidebar());
        }
    }, [isEditorPage]);

    return (
        <div className="flex flex-col h-full">
            <div className="flex-center mb-6">
                <div className={"" + (isSidebarCollapsed && "hidden group-hover:block")}>
                    <ThemeImage.Logo />
                </div>
                <div className={"hidden " + (isSidebarCollapsed && "!block group-hover:!hidden")}>
                    <ThemeImage.LogoSmall height={33} />
                </div>
            </div>

            <div className="flex-1 flex flex-col">
                <div className="flex-1 mb-10">
                    <SidebarMenus />
                    <SidebarItem
                        label={
                            <Badge
                                variant="standard"
                                color="primary"
                                classes={{
                                    badge: "!top-[11px] !-right-[20px]",
                                }}
                                badgeContent={notifications}
                            >
                                Notifications
                            </Badge>
                        }
                        url={location.pathname}
                        isStatic
                        onClick={() => {
                            dispatch(toggleNotificationSidebar());
                        }}
                        icon={<Bell01 color="#9E9E9E" height={22} width={22} />}
                    />
                </div>

                <div>
                    <IsStagingComponent>
                        <SidebarItem
                            label="Playground"
                            url={URL.Playground}
                            icon={<ThemeImage.SettingDarkIcon className="grey-icon" height={22} width={22} />}
                        />
                    </IsStagingComponent>
                    {team?.trial_period && (
                        <div
                            className={
                                "bg-[#F9FAFB] px-3 py-4 rounded-lg mt-5 " +
                                (isSidebarCollapsed && "hidden group-hover:block")
                            }
                        >
                            <h5 className="mb-1 text-md text-p font-normal">Trial Period</h5>
                            <h6 className="text-p text-sm">
                                Your team will lose access to the team after trial period.
                            </h6>
                            <LinearProgress
                                variant="determinate"
                                value={100 - percentage}
                                className="my-3 rounded-lg"
                                classes={{
                                    bar: twMerge("!bg-[#9199FF] !h-2", dayRemaining ? "" : "!bg-danger"),
                                    root: "!bg-[#E4E6FF] !h-2",
                                }}
                            />
                            {dayRemaining ? (
                                <span className="text-md font-semibold leading-5 text-primary">
                                    {dayRemaining} Day{dayRemaining > 1 ? "s" : ""} Remaining
                                </span>
                            ) : (
                                <span className="text-md font-semibold leading-5 text-danger">Expired</span>
                            )}
                        </div>
                    )}
                    <LoggedUserDetail />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
