import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

import { EditorContent, useEditor as useTipTapEditor } from "@tiptap/react";

import { editorDropHandler, editorExtensions } from "../../useEditor";

import { v4 as uuidv4 } from "uuid";

export interface INestedEditorProps {
    id: string;
    color: string;
    width: number;
    justify: "left" | "center" | "right";
    editor: Editor | null;
    editor_content: any;
}

const NestedEditor = (props: any) => {
    const { id, color, width, justify, editor, editor_content } = props.node.attrs as INestedEditorProps;
    const dispatch = useAppDispatch();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);

    const oldEditorContentRef = useRef<any>(null);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const nestedEditor = useTipTapEditor({
        extensions: editorExtensions,
        content: null,
        editable: false,
        editorProps: {
            handleDOMEvents: {
                drop: (view, event) => editorDropHandler(dispatch, view, event),
            },
            attributes: {
                id: "nested_" + uuidv4(),
            },
        },
    });

    const alignmentStyles = useMemo(() => {
        let style = "flex ";

        if (justify == "left") {
            style += "justify-start";
        } else if (justify == "right") {
            style += "justify-end";
        } else {
            style += "justify-center";
        }

        return style;
    }, [justify]);

    useEffect(() => {
        if (isSelected) {
            const transaction = props.editor!.state.tr.setMeta("updateDecorations", true);
            props.editor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (!editor && props.editor) {
            let pos = 0;
            let thisNode = null;

            props.editor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    thisNode = node;
                    return false;
                }
            });

            if (thisNode) {
                setTimeout(() => {
                    const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                        ...(thisNode as any).attrs,
                        editor: nestedEditor,
                    });
                    props.editor.view.dispatch(transaction);

                    if (nestedEditor && editor_content) {
                        oldEditorContentRef.current = editor_content;
                        nestedEditor!.commands.setContent(
                            typeof editor_content === "string" ? JSON.parse(editor_content) : editor_content
                        );
                    }
                }, 0);
            }
        } else if (editor && props.editor && nestedEditor && !_.isEqual(oldEditorContentRef.current, editor_content)) {
            oldEditorContentRef.current = editor_content;
            nestedEditor!.commands.setContent(
                typeof editor_content === "string" ? JSON.parse(editor_content) : editor_content
            );
        }
    });

    useEffect(() => {
        if (nestedEditor && props.editor) {
            // Update the node attribute when the content changes
            nestedEditor.on("update", () => {
                const contentJSON = nestedEditor.getJSON();

                let pos = 0;
                let thisNode = null;

                props.editor!.view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == id) {
                        pos = position;
                        thisNode = node;
                        return false;
                    }
                });

                if (thisNode) {
                    const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                        ...(thisNode as any).attrs,
                        editor_content: contentJSON,
                    });

                    props.editor.view.dispatch(transaction);
                }
            });
        }
    }, [nestedEditor, props.editor]);

    useEffect(() => {
        if (nestedEditor) {
            const transaction = nestedEditor.state.tr.setMeta("updateDecorations", true);
            nestedEditor.view.dispatch(transaction);
        }
    }, [nestedEditor, selectedBlock]);

    useEffect(() => {});

    return (
        <NodeViewWrapper
            id={id}
            className={`editor-block box-component relative ${isSelected && "editor-block-selected"}`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "box",
                        props: { id: props.node.attrs.id, attrs: props.node.attrs },
                    })
                );
            }}
        >
            <div className={"w-full flex " + alignmentStyles}>
                <div
                    className="p-8 rounded-lg"
                    style={{ width: width < 25 ? "25%" : width > 100 ? "100%" : width + "%", backgroundColor: color }}
                >
                    <EditorContent editor={nestedEditor} />
                </div>
            </div>
            {props.editor && <RepositioningHandler node_id={id} editor={props.editor} />}
        </NodeViewWrapper>
    );
};

export default NestedEditor;
