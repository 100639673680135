import { pick } from "lodash";
import { useEffect, useState } from "react";
import { GetProjectsInput, GetProjectsQuery, useGetProjectsQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const useProjects = (props: { filters?: GetProjectsInput }) => {
    const [projects, setProjects] = useState<GetProjectsQuery["projects"]>([]);

    const projectRes = useGetProjectsQuery({
        filters: props.filters || useProjects.defaultProps.filters,
    });

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess({ projects }: GetProjectsQuery) {
                setProjects(projects);
            },
        });
    }, [projectRes]);

    return { projects, ...pick(projectRes, ["isLoading", "isFetching"]) };
};

export default useProjects;

useProjects.defaultProps = {
    filters: {
        sort: "ascending",
        search_key: null,
    },
};
