import { Avatar, AvatarGroup, Tooltip, styled } from "@mui/material";
import useImages from "assets/images";
import DummyGirl from "assets/images/dummy/social-dummy-person-1.png";

const BoardAbout = () => {
    const Images = useImages();

    return (
        <div className="px-16 grid grid-cols-[1fr,400px] items-center space-x-3">
            <div className="flex flex-col space-y-5">
                <div>
                    <h4 className="text-[#5E5D5D] leading-6 text-[20px] mb-3">Bio</h4>
                    <h5 className="text-lg leading-6 text-p">
                        Capturing the awe-inspiring heights of architectural marvels! 🏙️🌟 Behold my 3D scan of the
                        iconic Burj Khalifa.
                    </h5>
                </div>

                <div>
                    <h4 className="text-[#5E5D5D] leading-6 text-[20px] mb-3">Members</h4>
                    <AvatarGroup
                        max={5}
                        className="justify-end"
                        componentsProps={{
                            additionalAvatar: {
                                classes: {
                                    root: "!bg-[#E4E6FF] !text-p !text-opacity-50 cursor-pointer !text-lg !w-8 !h-8",
                                },
                            },
                        }}
                    >
                        {[...Array(5)].map((_, i) => (
                            <Tooltip title={"hello world"} arrow key={i}>
                                <CustomAvatar alt={"hello world"} src={DummyGirl} />
                            </Tooltip>
                        ))}
                    </AvatarGroup>
                </div>
            </div>
            <div className="py-5 px-4 bg-[#F9F9F9] rounded-md">
                <div className="mb-6 flex-col space-y-[18px]">
                    <div className="flex items-center space-x-3">
                        <Images.UserIcon color="#9E9E9E" />
                        <h5 className="text-lg leading-6 text-p">
                            <b>Team Admin:</b> Patrick Kidwell
                        </h5>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Images.CalenderIcon color="#9E9E9E" />
                        <h5 className="text-lg leading-6 text-p">
                            <b>Team Since:</b> March, 2020
                        </h5>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Images.UsersIcon color="#9E9E9E" />
                        <h5 className="text-lg leading-6 text-p">
                            <b>Followers:</b> 35k
                        </h5>
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <a href="">
                        <Images.SocialFacebookIcon height={24} width={24} />
                    </a>
                    <a href="">
                        <Images.SocialLinkedInIcon height={24} width={24} />
                    </a>
                    <a href="">
                        <Images.SocialInstagramIcon height={24} width={24} />
                    </a>
                    <a href="">
                        <Images.SocialTwitterIcon height={24} width={24} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BoardAbout;

const CustomAvatar = styled(Avatar)(() => ({
    width: "32px",
    height: "32px",
}));
