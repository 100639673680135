import React from "react";

interface Props {
    elseComponent?: React.ReactNode;
    children: React.ReactNode;
}

const IsStagingComponent: React.FC<Props> = ({ children, elseComponent }) => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
        return <>{elseComponent ? elseComponent : <></>}</>;
    }

    return <>{children}</>;
};

export default IsStagingComponent;
