import React from "react";

interface IFieldLabelProps {
    className?: string;
    children: React.ReactNode;
}

const FieldLabel = ({ className, children }: IFieldLabelProps) => {
    return (
        <label className={`text-[#344054] text-md font-medium block mb-1 whitespace-nowrap ${className}`}>
            {children}
        </label>
    );
};

export default FieldLabel;
