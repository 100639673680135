import { useEffect } from "react";

import useImages from "assets/images";
import { useDeleteAccountMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useLogout from "shared/hooks/useLogout";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setCloseCommonModal, setCommonModal, setCommonModalLoadingBtn } from "store/slices/commonModal";

const useProfileSettingScreen = () => {
    const { user } = useAuth();
    const { DeleteLayerIcon } = useImages();
    const [deleteAccount, deleteRes] = useDeleteAccountMutation();
    const dispatch = useAppDispatch();
    const { doLogout } = useLogout();

    useEffect(() => {
        rtkHandler(deleteRes, {
            successMessage: "Account deleted successfully",
            onSuccess() {
                doLogout();
                dispatch(setCloseCommonModal());
            },
        });
        dispatch(setCommonModalLoadingBtn(deleteRes.isLoading ? "delete-account-btn" : ""));
    }, [deleteRes]);

    const onClickDeactivateAccount = () => {
        dispatch(
            setCommonModal({
                icon: <DeleteLayerIcon />,
                title: "Are you sure you want to delete your account?",
                children: (
                    <p>
                        Please read the <span className="text-primary">terms and condition</span> before your delete
                        your account. All the scans you own in all your teams, will get deleted.
                    </p>
                ),
                buttons: [
                    {
                        children: "Delete Account",
                        color: "error",
                        loadingText: "Deleting User",
                        id: "delete-account-btn",
                        onClick: () => deleteAccount(),
                    },
                ],
                dialogueActionClassName: "flex-col",
            })
        );
    };

    return {
        user,
        onClickDeactivateAccount,
    };
};

export default useProfileSettingScreen;
