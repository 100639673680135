import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { resetCommonModal, setCloseCommonModal } from "store/slices/commonModal";

const useCommonModal = () => {
    const dispatch = useAppDispatch();
    const modalOpen = useAppSelector((state) => state.commonModal.open);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(modalOpen);
        if (!modalOpen) {
            dispatch(resetCommonModal());
        }
    }, [modalOpen]);

    const closeModal = () => {
        dispatch(setCloseCommonModal());
    };

    return { open, closeModal };
};

export default useCommonModal;
