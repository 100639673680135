import React from "react";

import useImages from "assets/images";
import { useAppSelector } from "shared/hooks/useRedux";

interface Props {
    title: string | React.ReactNode;
    description: string[];
    is_read: boolean;
    children?: React.ReactNode;
    has_content?: boolean;
}

const NotificationCard = (props: Props) => {
    const { title, description, is_read, children, has_content = true } = props;

    const { ProfileIcon } = useImages();

    const user = useAppSelector((state) => state.user.current);

    return (
        <div
            className={`p-6 border-b border-[#E2E2E2] last:border-transparent ${
                is_read && "opacity-50"
            } transition-opacity hover:opacity-100`}
        >
            <div className={`flex items-start space-x-2 ${has_content && "mb-4"}`}>
                {(user?.profile_image as any)?.url ? (
                    <img
                        className="h-[32px] w-[32px] rounded-md object-cover object-center border-[0.5px] border-[#D2D2D2]"
                        src={(user?.profile_image as any)?.url}
                        alt="Profile Picture"
                    />
                ) : (
                    <ProfileIcon className="h-[32px] w-[32px]" />
                )}
                <div>
                    <h5 className="text-h1 text-md font-normal leading-[18px]">{title}</h5>
                    <div className="flex items-center">
                        {description.map((desc, index) => (
                            <React.Fragment key={index}>
                                {index !== 0 && <span className="mx-[5px] block w-[2px] h-[2px] rounded-full bg-p" />}
                                <span className="text-p text-[10px] font-normal" key={index}>
                                    {desc}
                                </span>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            {has_content && <div className="w-full pl-[calc(32px+0.5rem)]">{children}</div>}
        </div>
    );
};

export default NotificationCard;
