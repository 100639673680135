import { useState } from "react";
import MyScanTutorial from "shared/components/MyScanTutorial";
import PageHeader from "shared/components/ui/PageHeader";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import RecentProjects from "./partials/RecentProjects";
import RecentScans from "./partials/RecentScans";
import Stats from "./partials/Stats";
import TeamMemberDetailDrawer from "./partials/TeamMemberDetailDrawer";
import TeamMembers from "./partials/TeamMembers";

const Dashboard = () => {
    const [selectedMember, setSelectedMember] = useState<TeamMember>();
    const { team } = useAuth();

    const onViewUser = (val: TeamMember) => {
        setSelectedMember(val);
        const dashboardLayout = document.getElementById("dashboard-layout");
        const dashboardLayoutWidth = dashboardLayout?.scrollWidth;
        dashboardLayout?.scrollBy({
            left: dashboardLayoutWidth,
            behavior: "smooth",
        });
    };

    return (
        <>
            <PageHeader title={`${team?.name || ""}'s Dashboard`} />
            <div className="flex overflow-hidden w-full space-x-4 bg-[#F9F9F9] p-4" id="dashboard-layout">
                <div className="flex min-w-[calc(100%-392px-16px)] w-[calc(100%-392px)] flex-col space-y-4">
                    <Stats />
                    <RecentScans />
                    <RecentProjects />
                </div>
                <div className="min-w-[392px] w-[392px]">
                    <TeamMembers onView={onViewUser} />
                </div>
                <div className="min-w-[392px] w-[392px]">
                    <TeamMemberDetailDrawer data={selectedMember} />
                </div>
            </div>
            <MyScanTutorial />
        </>
    );
};

export default Dashboard;
