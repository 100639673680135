import { RouteObject, useRoutes } from "react-router-dom";
import UnderMaintenance from "screens/Errors/503";
import agreementRoutes from "./partials/agreementRoutes";
import authRoutes from "./partials/authRoutes";
import guestRoutes from "./partials/guestRoutes";
import publicRoutes from "./partials/publicRoutes";
import boardRoutes from "./partials/boards";

const under_maintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";

const routes: RouteObject[] = under_maintenance
    ? [{ path: "*", element: <UnderMaintenance /> }]
    : [...guestRoutes, ...authRoutes, ...agreementRoutes, ...publicRoutes, ...boardRoutes];

const AppRoutes = () => {
    return useRoutes(routes);
};

export default AppRoutes;
