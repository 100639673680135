import { useEffect, useState } from "react";
import { RemoveUserScanningDevicesMutation, User, useRemoveUserScanningDevicesMutation } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setUser } from "store/slices/user";

const useScanningDevices = () => {
    const [removeDevice, removeRes] = useRemoveUserScanningDevicesMutation();
    const [openDeleteModal, setOpenDeleteModal] = useState("");
    const user = useAppSelector((state) => state.user.current);
    const devices = user?.scanning_devices;
    const dispatch = useAppDispatch();

    useEffect(() => {
        rtkHandler(removeRes, {
            successMessage: "Device removed successfully.",
            onSuccess(res: RemoveUserScanningDevicesMutation) {
                setOpenDeleteModal("");
                const scanning_devices = res?.remove_user_scanning_devices;
                dispatch(setUser({ ...user, scanning_devices } as User));
            },
        });
    }, [removeRes]);

    const onDeleteScanningDevice = (id: string) => {
        removeDevice({
            data: {
                devices: [parseInt(id)],
            },
        });
    };

    return { devices, openDeleteModal, removeRes, setOpenDeleteModal, onDeleteScanningDevice };
};

export default useScanningDevices;
