import { Badge, Button, ButtonGroup } from "@mui/material";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import InviteTeamMemberModal from "shared/components/InviteMemberModal";
import DataTable from "shared/components/ui/DataTable";
import useAuth from "shared/hooks/useAuth";
import { ProfileTypes } from "shared/utils/enums";
import useTeamMembersSection from "./hook";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const TeamMembersSection = () => {
    const { HEADERS, filterOpt, TeamMemberFilters, inviteModal, membersByStatus, onClickFilter, toggleInviteModal } =
        useTeamMembersSection();
    const { isTeamMember } = useAuth();
    const [searchParams] = useSearchParams();
    const options = searchParams.get("options");

    useEffect(() => {
        if (options === "requests") {
            onClickFilter("Requested");
        }
    }, [options]);

    const data = (membersByStatus as any)[filterOpt];

    return (
        <div id="team-members-section">
            <SettingPageCard
                title="Team Members"
                subTitle="Manage your team members and invites here."
                containerClassName="items-center"
                hasHeaderDivider={false}
                rightComponent={
                    !isTeamMember ? (
                        <Button variant="contained" className="whitespace-nowrap" onClick={toggleInviteModal}>
                            Invite Member
                        </Button>
                    ) : (
                        <></>
                    )
                }
            >
                {!isTeamMember && (
                    <ButtonGroup variant="outlined" aria-label="text button group" className="mb-6">
                        {TeamMemberFilters.map((filter) => (
                            <Button
                                key={filter.value}
                                color={filterOpt === filter.value ? "primary" : "inherit"}
                                onClick={() => onClickFilter(filter.value)}
                                className="flex items-center space-x-2"
                            >
                                <span>{filter.label}</span>
                                {filter.label === "Requests" && membersByStatus["Requested"].length ? (
                                    <Badge
                                        badgeContent={membersByStatus["Requested"].length}
                                        color="primary"
                                        classes={{
                                            root: "!w-6 !h-5",
                                            badge: "!w-6 !h-5 !top-0 !transform-none !bg-[#9199FF]",
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Button>
                        ))}
                    </ButtonGroup>
                )}
                <DataTable
                    headers={HEADERS}
                    data={[...data].sort((a) => (a?.profile?.type === ProfileTypes.Admin ? -1 : 1))}
                />
            </SettingPageCard>
            <InviteTeamMemberModal open={inviteModal} onClose={toggleInviteModal} />
        </div>
    );
};

export default TeamMembersSection;
