import React from "react";
import { FormProvider as HookFormProvider, UseFormReturn } from "react-hook-form";

interface Props {
    contextValue?: any;
    form: UseFormReturn<any>;
    onSubmit: ((onValid: any, onInvalid?: any) => void) | null;
    children: React.ReactNode;
    className?: string;
}

const ThemeFormProvider = (props: Props) => {
    return (
        <HookFormProvider {...props.form} {...props.contextValue}>
            <form
                onSubmit={
                    props.onSubmit
                        ? props.form.handleSubmit(props.onSubmit)
                        : (e) => {
                              e.preventDefault();
                          }
                }
                autoComplete="off"
                className={props.className}
            >
                {props.children}
            </form>
        </HookFormProvider>
    );
};

export default ThemeFormProvider;
