import { createContext } from "react";

export interface StripePaymentContextType {
    price: number;
    currency: string;
    onClose: () => void;
    clientSecret: string;
}

const StripePaymentContext = createContext<StripePaymentContextType>({
    price: 0,
    currency: "",
    onClose: () => {},
    clientSecret: "",
});

export default StripePaymentContext;
