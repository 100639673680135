import { Button } from "@mui/material";
import usePagination, { UsePaginationProps } from "@mui/material/usePagination/usePagination";
import useImages from "assets/images";
import { twMerge } from "tailwind-merge";

export interface ThemePaginationProps extends UsePaginationProps {
    classes?: {
        root?: string;
    };
}

const ThemePagination = (props: ThemePaginationProps) => {
    const { ArrowRightNav, ArrowLeftNav } = useImages();

    const { items } = usePagination(props);

    const nextButton = items[items.length - 1];
    const prevButton = items[0];

    const pages = items.slice(1, items.length - 1);

    const rootClassName = twMerge(
        "w-full flex items-center justify-between px-6 py-5 rounded-b-lg",
        props.classes?.root ?? ""
    );

    return (
        <div className={rootClassName}>
            <Button
                onClick={prevButton.onClick}
                disabled={prevButton.disabled}
                variant="outlined"
                fullWidth
                className={twMerge(
                    "!text-md !font-medium !h-9 !w-fit !text-[#344054] !border-[#D0D5DD]",
                    prevButton.disabled
                        ? "!cursor-not-allowed !text-opacity-50 !border-opacity-50"
                        : "!hover:!bg-[#F7F8FA]"
                )}
                color="inherit"
                startIcon={
                    <ArrowLeftNav color="#667085" width={16} height={16} opacity={prevButton.disabled ? 0.5 : 1} />
                }
            >
                Previous
            </Button>
            <div className="flex space-x-1">
                {pages.map(({ onClick, page, type, selected }, index) => {
                    let children: any = null;

                    if (type === "start-ellipsis" || type === "end-ellipsis") {
                        children = "…";
                    } else if (type === "page") {
                        children = page;
                    } else {
                        children = type;
                    }

                    return (
                        <Button
                            key={index}
                            onClick={onClick}
                            variant="text"
                            color={selected ? "primary" : "inherit"}
                            className={twMerge(
                                "!py-[10px] !px-4 !border-0 !min-w-0 !h-10 !w-10",
                                selected ? "!bg-[#F2F3FD]" : ""
                            )}
                        >
                            {children}
                        </Button>
                    );
                })}
            </div>
            <Button
                onClick={nextButton.onClick}
                disabled={nextButton.disabled}
                variant="outlined"
                fullWidth
                className={twMerge(
                    "!text-md !font-medium !h-9 !w-fit !text-[#344054] !border-[#D0D5DD]",
                    nextButton.disabled ? "!cursor-not-allowed !text-opacity-50 !border-opacity-50" : "!cursor-pointer"
                )}
                color="inherit"
                endIcon={
                    <ArrowRightNav color="#667085" width={16} height={16} opacity={nextButton.disabled ? 0.5 : 1} />
                }
            >
                Next
            </Button>
        </div>
    );
};

export default ThemePagination;
