import useImages from "assets/images";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useAppDispatch } from "shared/hooks/useRedux";
import { setCloseCommonModal, setCommonModal } from "store/slices/commonModal";

const ShopSuccessScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { GreenTickLayerIcon } = useImages();

    useEffect(() => {
        dispatch(
            setCommonModal({
                icon: <GreenTickLayerIcon />,
                title: "Payment Confirmed",
                content:
                    "Your payment has been received and scan credits in your account will be updated respective to the plan you purchased.",
                classes: {
                    paper: "pb-4",
                },
                onClose: () => {
                    dispatch(setCloseCommonModal());
                    navigate(`${URL.Settings}/${URL.Shop}`, { replace: true });
                },
            })
        );

        const timer = setTimeout(() => {
            dispatch(setCloseCommonModal());
            navigate(`${URL.Settings}/${URL.Shop}`, { replace: true });
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return <></>;
};

export default ShopSuccessScreen;
