import { Fade, MobileStepper } from "@mui/material";
import { useEffect, useState } from "react";

const AuthSlider = ({ steps }: { steps: any[] }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    let interval: any = null;

    useEffect(() => {
        const mobileStepper = document.querySelectorAll("#mobile-stepper .MuiMobileStepper-dot");
        mobileStepper.forEach((dot) => {
            dot.addEventListener("click", (e) => {
                const target = e.target as HTMLElement;
                const index = Array.from(mobileStepper).indexOf(target);
                setCurrentSlide(index);
                clearInterval(interval);
            });
        });

        // start carousel
        interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % 3);
        }, 3000);

        return () => {
            mobileStepper.forEach((dot) => {
                dot.removeEventListener("click", () => {});
            });
            clearInterval(interval);
        };
    }, []);

    return (
        <div>
            {steps.map((step, index) => (
                <Fade
                    in={currentSlide === index}
                    key={index}
                    style={{ display: currentSlide === index ? "block" : "none" }}
                >
                    {step}
                </Fade>
            ))}
            <MobileStepper
                sx={{
                    "& .MuiMobileStepper-dot": {
                        backgroundColor: "#EAECF0",
                    },
                    "& .MuiMobileStepper-dotActive": {
                        backgroundColor: "#7680FF",
                    },
                }}
                classes={{
                    root: "!p-0 !bg-transparent !my-6",
                    dot: "!w-10 !rounded-md",
                }}
                id="mobile-stepper"
                nextButton={<></>}
                backButton={<></>}
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={currentSlide}
            />
        </div>
    );
};

export default AuthSlider;
