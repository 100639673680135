import { Chip, ChipProps, styled } from "@mui/material";
import useImages from "assets/images";

const ChipBox = (props: ChipProps) => {
    const Images = useImages();

    return (
        <CustomChip deleteIcon={<Images.CloseIcon height={12} width={12} style={{ color: "#9199FF" }} />} {...props} />
    );
};

export default ChipBox;

const CustomChip = styled(Chip)(({ color = "primary" }) => ({
    fontSize: 14,
    height: 24,
    padding: "2px 0",
    color:
        color === "primary" ? "#7680FF" : color === "default" ? "#667085" : color === "success" ? "#067647" : "#667085",
    background:
        color === "primary" ? "#F2F3FD" : color === "default" ? "#F2F4F7" : color === "success" ? "#ABEFC6" : "#F2F4F7",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor:
        color === "primary" ? "#C8CCFF" : color === "default" ? "#EAECF0" : color === "success" ? "#ABEFC6" : "#EAECF0",

    "& .MuiChip-label": {
        paddingInline: 12,
    },
}));
