import { yupResolver } from "@hookform/resolvers/yup";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import FAvatarUploadPhoto from "shared/components/ui/Field/partials/FAvatarUploadPhoto";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { CreateTeamMutation, useCreateTeamMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useSwitchProfileApi from "shared/hooks/useSwitchProfileApi";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import CPContent from "./components/headers";
import useUpdateProfileCompletion from "./hook/useUpdateProfileCompletion";

const schema = yup.object().shape({
    step: yup.number().required(),
    name: yup.string().when("step", {
        is: 1,
        then: (schema) => schema.required(),
    }),
    bio: yup.string().when("step", {
        is: 1,
        then: (schema) => schema.required(),
    }),
    username: yup.string().when("step", {
        is: 1,
        then: (schema) => schema.required(),
    }),
    logo: yup
        .number()
        .typeError("This field is required")
        .when("step", {
            is: 1,
            then: (schema) => schema.required(),
        }),
});

type FormValues = yup.InferType<typeof schema>;

const TeamAccountScreen = () => {
    const Images = useImages();
    const { user } = useAuth();
    const [createTeam, createRes] = useCreateTeamMutation();
    const { update: updateProfileCompletion, isLoading } = useUpdateProfileCompletion({});
    const { doSwitchProfile, isLoading: switchLoading } = useSwitchProfileApi({
        doNavigate: false,
    });

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            step: 1,
            name: `${user?.name}'s Team`,
            username: (user?.name ?? "").replace(/\s/g, "_").toLowerCase(),
            logo: undefined,
        },
    });

    useEffect(() => {
        rtkHandler(createRes, {
            setError: form.setError,
            onSuccess: async (res: CreateTeamMutation) => {
                res.create_team.members[0].profile?.profile_id &&
                    doSwitchProfile(res.create_team.members[0].profile?.profile_id)?.then(() => {
                        updateProfileCompletion("project", 3);
                    });
            },
        });
    }, [createRes]);

    const onSubmit = async (data: FormValues) => {
        if (data.name && data.logo && data.bio)
            createTeam({
                data: {
                    name: data.name,
                    username: data.username,
                    bio: data.bio,
                    brand_color: "#7680FF",
                    logo: data.logo,
                },
            });
    };

    return (
        <CPContent icon={Images.UsersIcon} title={"Create Your Team"} description={"Let's create your first team"}>
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="w-[360px] mx-auto text-left">
                <Field
                    name="logo"
                    render={({ field }) => {
                        return (
                            <FAvatarUploadPhoto
                                classes={{
                                    container: "!w-full !bg-white h-[120px] !rounded-md",
                                    loader: "w-full top-0 left-0 !h-full !rounded-md",
                                    image: "!w-full !h-full !rounded-md !object-contain",
                                }}
                                placeholder={"your team profile picture"}
                                value={field.value}
                                onChange={(file) => field.onChange(file?.file_id || null)}
                            />
                        );
                    }}
                />
                <Field placeholder="Enter your team name" name="name" inputTextProps={{ className: "!bg-white" }} />
                <Field placeholder="Enter your team bio" name="bio" inputTextProps={{ className: "!bg-white" }} />
                <Field
                    placeholder="Enter your username for the team"
                    name="username"
                    inputTextProps={{ className: "!bg-white" }}
                />
                <LoaderButton
                    className="h-[44px]"
                    fullWidth
                    variant="contained"
                    onClick={form.handleSubmit(onSubmit)}
                    loading={createRes.isLoading || isLoading || switchLoading}
                >
                    Continue
                </LoaderButton>
            </ThemeFormProvider>
        </CPContent>
    );
};

export default TeamAccountScreen;
