import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface ICodeBlockProps {
    id: string;
    index: boolean;
    size: 1 | 2 | 3 | 4 | 5;
    content: string | null;
}

const CodeBlock = forwardRef((props: any, ref) => {
    const { id, index, size, content } = props.node.attrs as ICodeBlockProps;
    const dispatch = useAppDispatch();

    const listRef = useRef(null);

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const listStyling = useMemo(() => {
        if (index) {
            return {
                listStyleType: "decimal",
            };
        } else {
            return {
                listStyleType: "none",
            };
        }
    }, [index]);

    const sizingStyles = useMemo(() => {
        return `text-size-${size} `;
    }, [size]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (listRef.current) {
                const selection = window.getSelection();
                if (selection) {
                    const currentNode = selection.anchorNode;

                    // Find the <li> element
                    let li = currentNode;
                    while (li && li.nodeName !== "LI") {
                        li = li.parentNode;
                    }

                    if (li && li.nodeName === "LI") {
                        if (event.key === "Enter") {
                            event.preventDefault();

                            // Create a new <li> element
                            const newLi = document.createElement("li");
                            newLi.contentEditable = "true";
                            newLi.innerText = ""; // Start with an empty item

                            // Insert the new <li> after the current <li>
                            li.parentNode!.insertBefore(newLi, li.nextSibling);

                            // Move the cursor to the new <li>
                            const range = document.createRange();
                            const sel = window.getSelection();
                            range.setStart(newLi, 0);
                            range.collapse(true);
                            sel?.removeAllRanges();
                            sel?.addRange(range);
                            newLi.focus();
                        } else if (event.key === "Backspace" && selection.isCollapsed && li.textContent === "") {
                            event.preventDefault();

                            // If the <li> is empty, remove it and move the cursor to the previous <li>
                            const prevLi = (li as any).previousElementSibling;
                            li.parentNode!.removeChild(li);

                            if (prevLi) {
                                // Move the cursor to the end of the previous <li>
                                const range = document.createRange();
                                const sel = window.getSelection();
                                range.setStart(prevLi, prevLi.childNodes.length);
                                range.collapse(true);
                                sel?.removeAllRanges();
                                sel?.addRange(range);
                                prevLi.focus();
                            }
                        }
                    }
                }
            }
        };

        if (parentEditor) {
            (listRef.current as any)?.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (parentEditor) (listRef.current as any)?.removeEventListener("keydown", handleKeyDown);
        };
    }, [parentEditor]);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (listRef.current) {
            (listRef.current as any).innerHTML = content ?? "<li>// Add code here</li>";

            (listRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    return (
        <NodeViewWrapper
            ref={ref}
            id={id}
            className={`editor-block code-block-component ${isSelected && "editor-block-selected"} ${
                index && "code-block-show-index"
            } border rounded-xl border-[#EAECF0] relative`}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                    setSelectedBlock({
                        type: "codeBlockComponent",
                        props: { id: props.node.attrs.id, attrs: props.node.attrs },
                    })
                );
            }}
            contentEditable={false}
        >
            {index && (
                <div className="w-[57px] h-full bg-[#F9FAFB] rounded-l-xl border-r border-[#EAECF0] z-[1] absolute top-0 left-0"></div>
            )}
            <ol
                ref={listRef}
                contentEditable={!isPreviewOpen}
                style={listStyling}
                className={`text-md font-medium text-[#475467] ${sizingStyles} ${
                    index ? "px-[22px]" : "pl-2 pr-[22px]"
                } relative z-[2]`}
                onInput={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();

                    let pos = 0;
                    let nodeAttrs: any = null;

                    parentEditor!.view.state.doc.descendants((node, position) => {
                        if (node.attrs.id == id) {
                            pos = position;
                            nodeAttrs = node.attrs;
                            return false;
                        }
                    });

                    const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
                        ...nodeAttrs,
                        content: (e.target as any).innerHTML,
                    });

                    parentEditor!.view.dispatch(transaction);
                }}
            >
                <li>Add text here</li>
            </ol>
            {parentEditor && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
});

export default CodeBlock;
