import BoardItem from "../item";

const BoardScans = () => {
    return (
        <div className="grid grid-cols-3 gap-x-10 gap-y-8">
            {[...Array(12)].map((item, index) => {
                return <BoardItem key={index} />;
            })}
        </div>
    );
};

export default BoardScans;
