import useImages from "assets/images";
import ThemeTabs from "shared/components/ui/ThemeTabs";
import BoardScans from "./tabs/scans";
import BoardArticles from "./tabs/articles";
import BoardSnapshots from "./tabs/snapshots";
import BoardNotes from "./tabs/notes";
import BoardAbout from "./tabs/about";
import BoardPages from "./tabs/pages";

const BoardTabs = () => {
    const { FilterIcon, SelectArrow } = useImages();

    return (
        <>
            <ThemeTabs
                className="flex items-center justify-between border-b"
                tabContentClassName="!px-0 !py-6"
                tabs={[
                    {
                        label: "Pages",
                        children: <BoardPages />,
                    },
                    {
                        label: "Scans",
                        children: <BoardScans />,
                    },
                    { label: "Articles", children: <BoardArticles /> },
                    { label: "Snapshots", children: <BoardSnapshots /> },
                    { label: "Notes", children: <BoardNotes /> },
                    { label: "About", children: <BoardAbout /> },
                ]}
                iconButtons={[
                    {
                        icon: <FilterIcon width={20} height={20} color="#5E5D5D80" />,
                        label: "Filter",
                        onClick: () => {},
                    },
                    {
                        icon: <SelectArrow width={12} height={12} color="#9C9C9C" />,
                        label: "Sort",
                        onClick: () => {},
                    },
                ]}
            />
        </>
    );
};

export default BoardTabs;
