import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import ProjectBannerBlock from "./ProjectBannerBlock";

export default Node.create({
    name: "projectBannerComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            image: {
                default: null,
            },
            template: {
                default: 0,
            },
            user_name: {
                default: null,
            },
            user_img: {
                default: null,
            },
            team_name: {
                default: null,
            },
            team_bio: {
                default: null,
            },
            team_img: {
                default: null,
            },
            facebook: {
                default: null,
            },
            instagram: {
                default: null,
            },
            linkedin: {
                default: null,
            },
            twitter: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.project-banner-component", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                    image: dom.getAttribute("image"),
                    template: dom.getAttribute("template"),
                    user_name: dom.getAttribute("user_name"),
                    user_img: dom.getAttribute("user_img"),
                    team_name: parseInt(dom.getAttribute("team_name")),
                    team_bio: dom.getAttribute("team_bio"),
                    team_img: dom.getAttribute("team_img"),
                    facebook: dom.getAttribute("facebook"),
                    instagram: dom.getAttribute("instagram"),
                    linkedin: dom.getAttribute("linkedin"),
                    twitter: dom.getAttribute("twitter"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "project-banner-component",
                id: node.attrs.id,
                image: node.attrs.image,
                user_name: node.attrs.user_name,
                user_img: node.attrs.user_img,
                team_name: node.attrs.team_name,
                team_bio: node.attrs.team_bio,
                team_img: node.attrs.team_img,
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ProjectBannerBlock);
    },
});
