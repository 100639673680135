import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-hot-toast";

const CopyToClipboard = (text: string) => {
    const onClick = () => {
        toast.success("Text copied to clipboard");
        navigator.clipboard.writeText(text);
    };

    return (
        <IconButton onClick={onClick}>
            <ContentCopyIcon
                style={{
                    height: 20,
                }}
            />
        </IconButton>
    );
};

export default CopyToClipboard;
