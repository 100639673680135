import React from "react";

interface IScanOrSnapshotCardProps {
    type: string;
    data: any;
}

const ScanOrSnapshotCard = (props: IScanOrSnapshotCardProps) => {
    const { type, data } = props;

    return (
        <a href={`${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${data.scan_id}`} target="_blank" rel="noreferrer">
            <div className="w-full">
                <div className="w-full h-0 pb-[76%] relative bg-[#F2F3FD] rounded-[7px]">
                    {(type == "scans" && data.input_file.thumbnail) || (type == "snapshots" && data.image.url) ? (
                        <img
                            src={type == "scans" ? data.input_file.thumbnail : data.image.url}
                            className="w-full h-full object-cover rounded-[7px] absolute top-0 left-0"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="text-md font-medium text-[#3F3E3E] mt-2 break-words">{data.name}</div>
            </div>
        </a>
    );
};

export default ScanOrSnapshotCard;
