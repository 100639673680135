import { Button } from "@mui/material";
import useImages from "assets/images";
import Banner from "assets/images/banners/Auth-Banner.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Outlet, useNavigate } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import AuthHeader from "../components/AuthHeader";
import AuthSlider from "../components/AuthSlider";

const RegisterLayout = () => {
    const Images = useImages();
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-[640px,1fr] h-screen overflow-hidden">
            <div className="flex flex-col h-screen">
                <AuthHeader>
                    <Button
                        className="!h-8 !rounded-[30px] text-[#5E5D5D] !text-[14px] !leading-5 !px-[14px] !min-w-0"
                        endIcon={<Images.ArrowRightPrimary />}
                        color="primary"
                        variant="outlined"
                        onClick={() => navigate(URL.Login)}
                    >
                        Log In
                    </Button>
                </AuthHeader>
                <PerfectScrollbar className="flex-1 py-4">
                    <div className="flex justify-center items-center h-full flex-wrap">
                        <Outlet />
                    </div>
                </PerfectScrollbar>
                <div className="py-7 px-8 flex items-center justify-between text-[#667085] text-md leading-5">
                    <p>© XSpada 2023</p>
                    <p>
                        <a href="mailto:support@xspada.com" className="flex items-center space-x-1">
                            <Images.EmailIcon height={13} color="#667085" />
                            <span>support@xspada.com</span>
                        </a>
                    </p>
                </div>
            </div>
            <div className="bg-[#F2F4F7] pt-[96px] pl-[80px] h-screen flex flex-col">
                <div className="pr-[80px]">
                    <AuthSlider
                        steps={[
                            <div key={0}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    Grouping deliverables together by scans allows for easier project tracking and
                                    transparency to clients.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-semibold">
                                            — Michael Rubloff{" "}
                                            <span className="font-normal text-[#475467]">
                                                (Owner of Neural Radiance Fields Online)
                                            </span>
                                        </h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Testimonial</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                            <div key={1}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    XSpada opens a window to bridge some of the existing gaps and barriers in drone
                                    operations.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-semibold">
                                            — Isabelle Nyroth
                                            <span className="font-normal text-[#475467]">(Founder of Yvarbrims)</span>
                                        </h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Testimonial</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                            <div key={2}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    I gave XSpada a try recently and was really pleased with the finish. Extremely easy
                                    to process with great detail.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-semibold">
                                            — Fred Buenrostro{" "}
                                            <span className="font-normal text-[#475467]">
                                                (Surveyor Project Manager/ Drone Mapping)
                                            </span>
                                        </h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Testimonial</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                        ]}
                    />
                </div>
                <RenderImage
                    image={Banner}
                    containerClassName="flex-1 border-[#667085] border-t-[2px] border-l-[2px] rounded-tl-[20px]"
                    imgClassName="!object-left-top"
                />
            </div>
        </div>
    );
};

export default RegisterLayout;
