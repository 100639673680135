import { useContext } from "react";
import { UseControllerReturn } from "react-hook-form";
import ThemeAutoComplete from "shared/components/Fields/ThemeAutoComplete";
import FieldContext from "../Field.context";
import { IFieldProps } from "../Field.type";

const FAutoComplete = () => {
    const { methods, props }: any = useContext(FieldContext);

    const {
        field: { value, name, onChange },
    }: UseControllerReturn = methods;

    const {
        placeholder,
        options = [],
        autoCompleteProps,
        inputVariant = "outlined",
        inputTextProps,
    }: IFieldProps = props;

    return (
        <ThemeAutoComplete
            name={name}
            options={options}
            placeholder={placeholder}
            inputVariant={inputVariant}
            inputTextProps={inputTextProps}
            onChange={(_, newValue) => {
                if (autoCompleteProps?.onChange) {
                    onChange(autoCompleteProps?.onChange(_, newValue));
                }
                onChange(newValue);
            }}
            autoCompleteProps={autoCompleteProps}
            value={value || null}
        />
    );
};

export default FAutoComplete;
