import { useState } from "react";

import SortIconButton from "../../SortIconButton";
import { useThemeListCardContext } from "../context";

const SortBox = () => {
    const { options, props } = useThemeListCardContext();

    const [sort, setSort] = useState("ascending");

    if (!options?.onSort) return null;

    return (
        <SortIconButton
            variant="outlined"
            sort={sort}
            onSortChange={(val) => {
                setSort(val);
                options.onSort && options.onSort(val);
            }}
            {...props?.sortBtnProps}
        />
    );
};

export default SortBox;
