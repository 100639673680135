import useImages from "assets/images";
import { useEffect, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import Field from "shared/components/ui/Field";
import FieldLabel from "shared/components/ui/FieldLabel";

interface IProfilePhotoUploadFieldProps {
    name: string;
    defaultValue?: File;
}

const ProfilePhotoUploadField = (props: IProfilePhotoUploadFieldProps) => {
    const { name, defaultValue = undefined } = props;
    const form = useFormContext();
    const { ProfileIcon, FileUploadIcon } = useImages();
    const profilePicRef = useRef(null);

    const methods = useController({
        control: form.control,
        name,
        defaultValue,
    });

    const {
        field: { value },
    } = methods;

    const profileValue = form.watch("profile_image");

    useEffect(() => {
        if (profileValue != undefined && profilePicRef.current) {
            if (typeof profileValue !== "string") {
                if ("original_name" in profileValue) {
                    if ("url" in profileValue) {
                        (profilePicRef.current! as any).src = profileValue["url"] as string;
                    }
                } else {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        (profilePicRef.current! as any).src = reader.result as string;
                    };
                    reader.readAsDataURL(profileValue);
                }
            } else {
                (profilePicRef.current! as any).src = profileValue as string;
            }
        }
    }, [profileValue]);

    return (
        <div className="grid grid-cols-[280px,1fr] gap-9">
            <div>
                <FieldLabel>Your photo</FieldLabel>
                <div className="text-md text-[#5E5D5D]">This will be displayed on your profile.</div>
            </div>
            <div className="flex gap-x-5">
                <div
                    className={
                        "w-[64px] h-[64px] rounded-full overflow-clip " +
                        (value == undefined ? "border border-[#EAECF0]" : "")
                    }
                >
                    {value != undefined ? (
                        <img ref={profilePicRef} className="w-full h-full object-cover" />
                    ) : (
                        <div className="w-full h-full flex items-center justify-center">
                            <ProfileIcon className="w-1/2 h-1/2" />
                        </div>
                    )}
                </div>
                <Field
                    name={name}
                    formGroup={false}
                    type="image-picker"
                    inlineFlex={true}
                    containerClassName="flex-1 mb-0"
                    dropifyProps={{
                        wrapperStyle: " !h-[126px] ",
                        showPreview: true,
                        defaultView: (
                            <div className="w-full h-full flex flex-col items-center justify-center">
                                <FileUploadIcon width={40} height={40} />
                                <div className="text-md mt-3">
                                    <span className="font-semibold text-[#7680FF]">Click to upload</span> or drag and
                                    drop
                                </div>
                                <div className="text-sm mt-1">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                            </div>
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default ProfilePhotoUploadField;
