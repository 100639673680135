import { Tab, Tooltip } from "@mui/material";
import useImages from "assets/images";
import { twMerge } from "tailwind-merge";
import Permissions from "../HasPermission/Permissions";
import useHasPermission from "../HasPermission/hook";
import ButtonTabs from "../ui/ButtonTabs";

export type RequestorTypes = "requestee" | "requestor";

const RequestorSwitch = (props: { value: RequestorTypes; onChange: (val: RequestorTypes) => void }) => {
    const Images = useImages();

    const UserHasRequesteeAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestee);
    const UserHasRequestorAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestor);

    return UserHasRequesteeAccess || UserHasRequestorAccess ? (
        <div
            className={twMerge(
                "relative bg-white rounded-[18px] h-[24px]",
                UserHasRequesteeAccess && UserHasRequestorAccess ? "w-[60px]" : "w-[30px]"
            )}
        >
            <ButtonTabs
                value={props.value}
                customTabStyles={{
                    height: "24px",
                    "& .MuiTab-root": {
                        height: "24px",
                        borderRadius: "14px",
                        ...(UserHasRequesteeAccess && UserHasRequestorAccess
                            ? {}
                            : {
                                  width: "100%",
                              }),
                    },
                    "& .MuiTabs-indicator": {
                        borderRadius: "14px",
                        height: "24px",
                        backgroundColor: "#7680FF",
                    },
                    "& .Mui-selected svg": {
                        color: "#fff",
                        transition: "all 0.3s ease",
                    },
                }}
                onChange={(val) => {
                    props.onChange(val as RequestorTypes);
                }}
            >
                {UserHasRequestorAccess && (
                    <Tab
                        sx={{
                            padding: "0px 0px !important",
                            zIndex: 10,
                        }}
                        label={
                            <Tooltip title="Requestor">
                                <Images.SendInvite height={13} width={13} color="#98A2B3" />
                            </Tooltip>
                        }
                        value={"requestor"}
                        {...a11yProps(0)}
                    />
                )}

                {UserHasRequesteeAccess && (
                    <Tab
                        sx={{
                            padding: "0px 0px !important",
                            zIndex: 10,
                        }}
                        label={
                            <Tooltip title="Requestee">
                                <Images.InboxIcon height={15} width={15} color="#98A2B3" />
                            </Tooltip>
                        }
                        value="requestee"
                        {...a11yProps(1)}
                    />
                )}
            </ButtonTabs>
        </div>
    ) : (
        <></>
    );
};

export default RequestorSwitch;

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};
