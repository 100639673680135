import useImages from "assets/images";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import DropboxUpload from "./DropboxUpload";
import GoogleDriveUpload from "./GoogleDriveUpload";
import { IsStagingServer } from "shared/constants/const";

interface Props {
    onFileClick: () => void;
    onGoogleDriveClick: () => void;
    onDropboxClick: () => void;
}

const ScanFilePicker = (props: Props) => {
    const Images = useImages();
    const context = useFormContext();
    const type = context.watch("dataset_type");
    const file_ref = useRef<HTMLInputElement>(null);

    return (
        <div className="h-full w-full flex items-center justify-center border border-[#D0D5DD] rounded-md py-10">
            <div>
                <div className="flex items-center justify-center space-x-6">
                    <ThemeIconButton
                        className="!w-10 !h-10"
                        icon={<Images.FileUploadIcon />}
                        onClick={() => {
                            props.onFileClick();
                            if (file_ref.current) {
                                file_ref.current.click();
                            }
                        }}
                    />
                    <input
                        type="file"
                        ref={file_ref}
                        accept={type === "images" ? ".zip" : "video/*"}
                        className="hidden"
                        value={""}
                        onChange={(e) => {
                            if (e.target.files) {
                                context.setValue("input_videos", e.target.files[0]);
                            }
                        }}
                    />
                    {type !== "images" && IsStagingServer && (
                        <>
                            <GoogleDriveUpload onClick={props.onGoogleDriveClick} />
                            <DropboxUpload onClick={props.onDropboxClick} />
                        </>
                    )}
                </div>
                <p className="text-p text-md font-normal leading-5 mt-4">
                    <span className="text-primary">Click any option</span> to upload your file
                </p>
            </div>
        </div>
    );
};

export default ScanFilePicker;
