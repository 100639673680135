import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Props {
    current: string | null;
}

const initialState: Props = {
    current: null,
};

const token = createSlice({
    name: "token",
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<Props["current"]>) {
            state.current = action.payload || null;
        },
    },
});

export default token.reducer;

export const { setToken } = token.actions;
