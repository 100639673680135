import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {},
});

export const {
    useGoogleLoginMutation,
    useGoogleRegisterMutation,

    useAppleLoginMutation,
    useAppleRegisterMutation,
} = api;
