import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { useState } from "react";
import ThemeCheckbox from "../ThemeCheckbox";

interface I {
    name: string;
    value?: boolean;
    onChange: (e: any) => void;
    label: string | React.ReactNode;
    sxStyle?: FormControlLabelProps["sx"];
    controlledField?: boolean;
}

const CheckboxWithLabel = ({ name, value, controlledField = true, onChange, label, sxStyle = {} }: I) => {
    const [checked, setChecked] = useState(value ? value : false);
    const isControlledChecked = controlledField ? checked : value;

    return (
        <FormControlLabel
            onChange={(e: any) => {
                if (controlledField) setChecked(e.target.checked);
                onChange(e);
            }}
            sx={sxStyle}
            className={`inline-checkbox p-4 ${isControlledChecked ? "checked" : ""}`}
            control={<ThemeCheckbox checked={isControlledChecked} className="pl-0" />}
            label={label}
            name={name}
        />
    );
};

export default CheckboxWithLabel;
