import React from "react";
import { twMerge } from "tailwind-merge";

const RenderIf = ({
    condition,
    children,
    className,
    useStyle = false,
}: {
    condition: boolean | undefined | string;
    children: React.ReactNode;
    className?: string;
    useStyle?: boolean;
}) => {
    if (!condition && !useStyle) {
        return <></>;
    }

    if (condition && (useStyle || className)) {
        return <div className={twMerge(className, useStyle ? "block" : "hidden")}>{children}</div>;
    }

    return <>{children}</>;
};

export default RenderIf;
