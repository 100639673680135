import { yupResolver } from "@hookform/resolvers/yup";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneNumberPicker from "shared/components/PhoneNumberPicker";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useEditAccountMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import CPContent from "./components/headers";
import useUpdateProfileCompletion from "./hook/useUpdateProfileCompletion";

const schema = yup.object().shape({
    name: yup.string().required(),
    phone: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const PersonalDetailScreen = () => {
    const Images = useImages();
    const [loading, setLoading] = useState(false);
    const { update: updateProfileCompletion, isLoading } = useUpdateProfileCompletion({ doNavigate: true });
    const [updateUser, updateRes] = useEditAccountMutation();
    const { user } = useAuth();

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormValues) => {
        setLoading(true);
        await updateUser({
            data: {
                name: data.name,
                phone: data.phone,
            },
        });
    };

    useEffect(() => {
        rtkHandler(updateRes, {
            onSuccess: async () => {
                await updateProfileCompletion("identity", 1);
                setLoading(false);
            },
        });
    }, [updateRes]);

    useEffect(() => {
        rtkHandler(updateRes, {
            onSuccess() {
                updateProfileCompletion("identity", 1);
                setLoading(false);
            },
        });
    }, [updateRes]);

    return (
        <CPContent
            icon={Images.UserIcon}
            title="Personalize Account"
            description={
                <>
                    Setup your account for <span className="text-primary">{user?.email}</span>
                </>
            }
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="w-[360px] mx-auto text-left">
                <Field
                    label="Full name"
                    name="name"
                    placeholder="Enter your full name"
                    inputTextProps={{ className: "!bg-white" }}
                />
                <Field
                    label="Phone number"
                    name="phone"
                    inputTextProps={{ className: "!bg-white" }}
                    render={({ field: { value, onChange } }) => (
                        <PhoneNumberPicker value={value} onChange={(val) => onChange(val)} />
                    )}
                />
                <LoaderButton
                    className="h-[44px]"
                    fullWidth
                    variant="contained"
                    type="submit"
                    loading={loading || isLoading}
                >
                    Continue
                </LoaderButton>
            </ThemeFormProvider>
        </CPContent>
    );
};

export default PersonalDetailScreen;
