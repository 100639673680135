import { yupResolver } from "@hookform/resolvers/yup";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import { ContactUsEnquiryType, useContactUsMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

interface Props {
    open: boolean;
    onClose: () => void;
}

const schema = yup.object().shape({
    requested_guests: yup.number().required(),
    requested_seats: yup.number().required().min(10, "Minimum 10 seats required"),
    comment: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const EnterprisePlanModal = (props: Props) => {
    const Images = useImages();
    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            requested_guests: 0,
            requested_seats: 10,
            comment: "",
        },
    });

    const [submitContactUs, contactRes] = useContactUsMutation();

    useEffect(() => {
        rtkHandler(contactRes, {
            successMessage: "Your enquiry has been submitted successfully.",
            onSuccess() {
                form.reset();
                props.onClose();
            },
        });
    }, [contactRes]);

    const onSubmit = (data: FormValues) => {
        submitContactUs({
            form: {
                data: JSON.stringify(data),
                type: ContactUsEnquiryType.EnterpriseContactUs,
            },
        });
    };

    return (
        <ThemeModal
            open={props.open}
            onClose={props.onClose}
            icon={<LayerIcon icon={Images.TripleLayer} iconClassName="h-6 w-6" className="h-12 w-12" />}
            title="Contact Us"
            subTitle="Select your options for enterprise plan and our team will reach out to you."
            contentProps={{ className: "text-left" }}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit}>
                <hr className="border-[#EAECF0] mt-1 mb-4" />
                <div className="flex space-x-5">
                    <Field
                        label="Seats"
                        name="requested_seats"
                        placeholder="10"
                        inputTextProps={{
                            type: "number",
                        }}
                    />
                    <Field
                        label="Guests"
                        name="requested_guests"
                        placeholder="0"
                        inputTextProps={{
                            type: "number",
                        }}
                    />
                </div>
                <Field
                    label="Comments"
                    name="comment"
                    placeholder="Any special requirements for your plan"
                    inputTextProps={{
                        multiline: true,
                        rows: 3,
                    }}
                />
                <CommonModalActionButton
                    cancelBtnProps={{
                        onClick: props.onClose,
                    }}
                    buttons={[
                        {
                            children: "Purchase Plan",
                            onClick: form.handleSubmit(onSubmit),
                            loading: contactRes.isLoading,
                        },
                    ]}
                />
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default EnterprisePlanModal;
