import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import QuoteBlock from "./QuoteBlock";

export default Node.create({
    name: "quoteComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            quote_str: {
                default: null,
            },
            size: {
                default: 1, // Set a default size
            },
            alignment: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.quote-component", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                    quote_str: dom.getAttribute("quote_str"),
                    size: parseInt(dom.getAttribute("size")) || 1,
                    alignment: dom.getAttribute("alignment"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "quote-component",
                id: node.attrs.id,
                quote_str: node.attrs.quote_str,
                size: node.attrs.size,
                alignment: node.attrs.alignment,
                caption: node.attrs.caption,
                caption_str: node.attrs.caption_str,
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(QuoteBlock);
    },
});
