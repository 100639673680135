import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "shared/components/ui/PageLoader";
import URL from "shared/constants/navigator";
import { RequestTeamMutation, useRequestTeamMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const AcceptJoinTeamRequestScreen = () => {
    const [joinWithCode, joinRes] = useRequestTeamMutation();
    const { inviteCode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (inviteCode) {
            joinWithCode({
                code: inviteCode!,
            });
        } else {
            navigate(URL.Dashboard, {
                replace: true,
            });
        }
    }, [inviteCode]);

    useEffect(() => {
        rtkHandler(joinRes, {
            onSuccess({ request_team }: RequestTeamMutation) {
                const teamName = request_team.name;
                if (teamName) {
                    navigate(`${URL.Join_Team_Request_Submitted}?team_name=${teamName}`, {
                        replace: true,
                    });
                }
            },
            onError() {
                navigate(URL.Dashboard, {
                    replace: true,
                });
            },
        });
    }, [joinRes]);

    return (
        <div>
            <PageLoader />
        </div>
    );
};

export default AcceptJoinTeamRequestScreen;
