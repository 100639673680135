import useImages from "assets/images";
import Field from "shared/components/ui/Field";
import FieldLabel from "shared/components/ui/FieldLabel";
import Countries from "shared/utils/countries";

const AddressField = () => {
    const { ArrowDown, LocationIcon } = useImages();

    return (
        <div className="grid grid-cols-[280px,1fr] gap-9">
            <FieldLabel>Address</FieldLabel>
            <div className="grid grid-cols-1">
                <Field
                    name="address.street"
                    placeholder="Street Address"
                    inlineFlex={true}
                    containerClassName="mb-0"
                    icon={<LocationIcon width={20} height={20} color="#667085" />}
                />
                <div className="grid grid-cols-3 gap-4">
                    <Field name="address.city" placeholder="City" inlineFlex={true} />
                    <Field
                        name="address.country"
                        placeholder="Country"
                        type="auto-complete"
                        options={Object.values(Countries).map((value) => {
                            return {
                                label: value,
                                value,
                            };
                        })}
                        autoCompleteProps={{
                            popupIcon: <ArrowDown />,
                            isOptionEqualToValue(option: any, value: any) {
                                return option.value === value.value;
                            },
                        }}
                    />
                    <Field name="address.zip" placeholder="ZIP Code" inlineFlex={true} />
                </div>
            </div>
        </div>
    );
};

export default AddressField;
