import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        CreateFolder: {
            invalidatesTags: (_, error, { project_id }) =>
                error ? [] : [{ type: "Projects", id: project_id }, "Notification"],
        },
        EditFolder: {
            invalidatesTags: (_, error, { project_id }) =>
                error ? [] : [{ type: "Projects", id: project_id }, "Notification"],
        },
        DeleteFolder: {
            invalidatesTags: (_, error, { project_id }) =>
                error ? [] : [{ type: "Projects", id: project_id }, "Notification"],
        },
    },
});

export const { useCreateFolderMutation, useEditFolderMutation, useDeleteFolderMutation } = api;
