import { Popover } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { twMerge } from "tailwind-merge";

type FColorPickerProps = React.InputHTMLAttributes<HTMLInputElement> & {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
};

const FColorPicker = (props: FColorPickerProps) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-color-popover" : undefined;

    const handleClickColor = (e: any) => {
        setAnchorEl(e);
    };

    const handleCloseColor = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className={twMerge(
                    "flex items-center space-x-2 text-md font-normal mt-2 border w-full py-[10px] px-[14px] rounded-md border-[#D0D5DD] shadow-input",
                    props.className,
                    props.disabled && "opacity-50",
                    props.readOnly && "opacity-50"
                )}
            >
                <div
                    className={twMerge(
                        "h-6 w-6 rounded-md cursor-pointer",
                        props.disabled && "opacity-50",
                        props.readOnly && "opacity-50"
                    )}
                    onClick={(e) => (props.disabled || props.readOnly ? null : handleClickColor(e.currentTarget))}
                    style={{
                        background: props.value,
                    }}
                />
                <span className="uppercase">{props.value}</span>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseColor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <SketchPicker color={props.value} onChange={(color) => props.onChange(color.hex)} />
            </Popover>
        </>
    );
};

export default FColorPicker;
