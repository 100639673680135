import { createContext, useContext } from "react";
import { DataTableProps, DataTableProviderProps } from "./type";

const DataTableContext = createContext<DataTableProps>({
    data: [],
    headers: [],
});

export const DataTableProvider = ({ value, children }: DataTableProviderProps) => {
    return (
        <DataTableContext.Provider
            value={{
                ...value,
            }}
        >
            {children}
        </DataTableContext.Provider>
    );
};

export const useDataTableContext = () => {
    return useContext(DataTableContext);
};
