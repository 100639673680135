import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import EditorCustomDropDown from "shared/components/EditorCustomDropDown/EditorCustomDropDown";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useAppSelector } from "shared/hooks/useRedux";
import { valueFromQueryParam } from "shared/utils/helpers";
import { ISnapshotBlockProps } from "./SnapshotBlock";

const SnapshotBlockProperties = ({ editor, props }: { editor: Editor | null; props: ISnapshotBlockProps }) => {
    const { id, scan, snapshot_link, width, justify, caption, caption_str, redirecting } = props as ISnapshotBlockProps;
    const { EditorAlignLeftIcon, EditorAlignCenterIcon, EditorAlignRightIcon, ArrowDown, PercentageIcon } = useImages();

    const isInit = useRef(false);

    const project = useAppSelector((state) => state.projects.selected);

    const { control, watch, setValue } = useForm<{
        id: string;
        scan: string | null;
        snapshot: string | null;
        width: number;
        justify: "left" | "center" | "right";
        caption: "yes" | "no";
        caption_str: string | null;
        redirecting: "yes" | "no";
    }>({
        defaultValues: {
            id,
            scan,
            snapshot: (snapshot_link && valueFromQueryParam("snapshot_id", snapshot_link)) ?? null,
            width,
            justify,
            caption: caption ? "yes" : "no",
            caption_str,
            redirecting: redirecting ? "yes" : "no",
        },
    });

    const scan_val = watch("scan");
    const snapshot_val = watch("snapshot");
    const width_val = watch("width");
    const justify_val = watch("justify");
    const caption_val = watch("caption");
    const caption_str_val = watch("caption_str");
    const redirecting_val = watch("redirecting");

    const scanOptions = useMemo(() => {
        if (!project) return [];

        let scans: any[] = [];

        project.folders.forEach((folder) => {
            scans = [...scans, ...folder.scans.filter((scan) => scan.method == "gaussian-splatting")];
        });

        return scans.map((scan) => ({
            label: scan.name,
            value: "" + scan.scan_id,
        }));
    }, [project]);

    const scanData = useMemo(() => {
        if (!project) return [];

        let scans: any[] = [];

        project.folders.forEach((folder) => {
            scans = [...scans, ...folder.scans.filter((scan) => scan.method == "gaussian-splatting")];
        });

        const scansMap = {};

        scans.forEach((scan) => {
            scansMap["" + scan.scan_id] = {
                image: scan.input_file.thumbnail,
                label: scan.name,
                is_public: scan.visibility,
                value: `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan.scan_id}&embed=true`,
            };
        });

        return scansMap;
    }, [project]);

    const snapshotOptions = useMemo(() => {
        if (!project || !scan_val || scan_val == "") return [];

        let selectedScan: any = null;

        project.folders.forEach((folder) => {
            folder.scans.forEach((scan) => {
                if (scan.scan_id == parseInt(scan_val)) {
                    selectedScan = scan;
                }
            });
        });

        if (!selectedScan) return [];

        return selectedScan.snapshots.map((snapshot) => ({
            label: snapshot.name,
            value: "" + snapshot.snapshot_id,
        }));
    }, [project, scan_val]);

    const snapshotsData = useMemo(() => {
        if (!project || !scan_val || scan_val == "") return [];

        let selectedScan: any = null;

        project.folders.forEach((folder) => {
            folder.scans.forEach((scan) => {
                if (scan.scan_id == parseInt(scan_val)) {
                    selectedScan = scan;
                }
            });
        });

        if (!selectedScan) return [];

        const snapshotsMap = {};

        selectedScan.snapshots.forEach((snapshot) => {
            snapshotsMap["" + snapshot.snapshot_id] = {
                image: snapshot.image.url,
                label: snapshot.name,
                value: `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan_val}&snapshot_id=${snapshot.snapshot_id}&embed=true`,
            };
        });

        return snapshotsMap;
    }, [project, scan_val]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            scan: scan_val,
            snapshot: snapshot_val ? snapshotsData[snapshot_val].image : null,
            snapshot_link: snapshot_val
                ? `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan_val}&snapshot_id=${snapshot_val}&embed=true`
                : null,
            width: width_val,
            justify: justify_val,
            caption: caption_val == "yes",
            caption_str: caption_str_val,
            redirecting: redirecting_val == "yes",
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const node = parentEditor!.view.state.schema.nodes.snapshotComponent.create(data);

        const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [scan_val, snapshot_val, width_val, justify_val, caption_val, caption_str_val, redirecting_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Scan</div>
                    <Field
                        formGroup={false}
                        name="scan"
                        type="select"
                        options={scanOptions}
                        selectFieldProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                            },
                            IconComponent: ArrowDown,
                            CustomMenuItem: EditorCustomDropDown,
                            dropdown_data: scanData,
                            empty_placeholder: "No Scans Found",
                        }}
                    />

                    {scan_val && (
                        <>
                            <div className="text-sm text-[#667085] mt-4 mb-2">Snapshot</div>
                            <Field
                                formGroup={false}
                                name="snapshot"
                                type="select"
                                options={snapshotOptions}
                                selectFieldProps={{
                                    className: "!h-[34px]",
                                    sx: {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "8px !important",
                                        },
                                    },
                                    IconComponent: ArrowDown,
                                    CustomMenuItem: EditorCustomDropDown,
                                    dropdown_data: snapshotsData,
                                    empty_placeholder: "No Snapshots Found",
                                }}
                            />
                        </>
                    )}
                </div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Width</div>
                    <Field
                        name="width"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                            endAdornment: <PercentageIcon width={10} height={10} />,
                        }}
                    />

                    <div className="text-sm text-[#667085] mt-4 mb-2">Justify</div>
                    <ToggleButtonGroup
                        value={justify_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Caption</div>
                    <ToggleButtonGroup
                        value={caption_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("caption", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <div className="text-sm text-[#667085] mt-4 mb-2">Redirecting</div>
                    <ToggleButtonGroup
                        value={redirecting_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("redirecting", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default SnapshotBlockProperties;
