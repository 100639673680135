import { TableBody, TableCell, TableRow, styled } from "@mui/material";
import { twMerge } from "tailwind-merge";
import { useDataTableContext } from "../context";
import { DataTableHeaderProps } from "../type";

const DataTableBody = () => {
    const { headers, data, classes, hasActionColumn, onRowClick, emptyMessage } = useDataTableContext();

    const renderCell = (content: DataTableHeaderProps, data: any) => {
        const selector = content.selector;

        if (typeof selector === "string") {
            if (selector.includes(".")) {
                const [first, ...rest] = selector.split(".");
                const newData = data[first];

                return rest.reduce((acc, cur) => {
                    return acc && acc[cur];
                }, newData);
            }

            return data[selector];
        }

        if (typeof selector === "function") {
            return selector(data);
        }

        return null;
    };

    return (
        <TableBody className={classes?.body}>
            {data.length === 0 && (
                <CustomTableRow className={classes?.bodyRow}>
                    <TableCell
                        colSpan={headers.length}
                        align="center"
                        className={twMerge(classes?.cell, classes?.bodyCell)}
                    >
                        {emptyMessage ?? "No data found"}
                    </TableCell>
                </CustomTableRow>
            )}
            {data.map((item, index) => (
                <CustomTableRow key={index} className={classes?.bodyRow}>
                    {headers.map((content, cellIndex) => (
                        <TableCell
                            onClick={() => {
                                if (headers.length - 1 === cellIndex) {
                                    if (!hasActionColumn) {
                                        onRowClick && onRowClick(item);
                                    }
                                } else {
                                    onRowClick && onRowClick(item);
                                }
                            }}
                            key={cellIndex}
                            align={content.align}
                            width={content.width}
                            className={twMerge("!text-[#667085]", classes?.cell, classes?.bodyCell)}
                        >
                            {renderCell(content, item) || "-"}
                        </TableCell>
                    ))}
                </CustomTableRow>
            ))}
        </TableBody>
    );
};

export default DataTableBody;

const CustomTableRow = styled(TableRow)(() => ({
    "&:last-child td": {
        borderBottom: "none",
    },
}));
