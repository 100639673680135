import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useForgotPasswordMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

type ForgotPasswordPayload = yup.InferType<typeof schema>;

const useForgotPassword = () => {
    const [forgotPassword, response] = useForgotPasswordMutation();
    const navigate = useNavigate();
    const form = useForm<ForgotPasswordPayload>({
        resolver: yupResolver(schema),
        defaultValues:
            process.env.NODE_ENV === "development"
                ? {
                      email: "khakiu.uk@gmail.com",
                  }
                : {
                      email: "",
                  },
    });

    useEffect(() => {
        rtkHandler(response, {
            successMessage: "Password reset link has been sent to your email.",
            setError: form.setError,
            onSuccess() {
                navigate(URL.Login);
            },
        });
    }, [response, navigate]);

    const onSubmit = (data: ForgotPasswordPayload) => {
        forgotPassword({
            data,
        });
    };

    return { form, response, onSubmit };
};

export default useForgotPassword;
