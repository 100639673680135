import { Autocomplete, TextField } from "@mui/material";
import useImages from "assets/images";
import { IFieldAutocomplete } from "shared/types/utils/fields.type";

const ThemeAutoComplete = (props: IFieldAutocomplete) => {
    const { options, onChange, value, placeholder, autoCompleteProps, inputVariant, inputTextProps, classes } = props;
    const { SelectArrow } = useImages();

    return (
        <Autocomplete
            disablePortal
            options={options || []}
            popupIcon={<SelectArrow color="#9C9C9C" />}
            classes={{
                noOptions: "text-md",
                listbox: "text-md",
                loading: "text-md",
                endAdornment: "!mr-[10px]",
                ...classes,
            }}
            sx={{
                ".MuiSelect-select": {
                    color: value ? "currentColor" : "#dddddd",
                    fontWeight: "normal",
                },
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant={inputVariant}
                    InputProps={{
                        ...params.InputProps,
                        ...inputTextProps,
                    }}
                />
            )}
            onChange={onChange}
            disableCloseOnSelect={autoCompleteProps?.multiple || false}
            value={value || null}
            {...autoCompleteProps}
        />
    );
};

export default ThemeAutoComplete;
