import { useFormContext } from "react-hook-form";

import Field from "shared/components/ui/Field";

const ScanRequestDetailForm = () => {
    const { options }: any = useFormContext();
    const OpenForAll = "Open For All";

    return (
        <>
            <div className="grid grid-cols-2 gap-6 mb-6">
                <Field
                    formGroup={false}
                    name="scanner_count"
                    placeholder="Scanners you are looking for"
                    label="Scanners Count:"
                    type="select"
                    options={[OpenForAll, ...Array(10)].map((_, i) => ({
                        label: i === 0 ? OpenForAll : i.toString(),
                        value: i === 0 ? OpenForAll : i.toString(),
                    }))}
                />
                <Field
                    formGroup={false}
                    name="estimated_time"
                    placeholder="Time it will take for your request"
                    label="Estimated Job Time:"
                    type="select"
                    options={options.estimated_time}
                />
                <Field
                    formGroup={false}
                    name="scan_focus"
                    placeholder="Specify the main focus of the scan"
                    label="Scan Focus:"
                    type="select"
                    options={options.scan_focus}
                />
                <Field
                    formGroup={false}
                    name="dataset_type"
                    placeholder="Specify the type of the scan"
                    label="Scan Type:"
                    type="select"
                    options={options.dataset_type}
                />
            </div>
            <Field
                name="camera_type"
                placeholder="Specify the main focus of the scan"
                label="Camera Type:"
                type="select"
                options={options.camera_type}
            />
            <Field
                name="extra_notes"
                placeholder="Any special notes for your request "
                label="Extra Note:"
                inputTextProps={{
                    multiline: true,
                    rows: 4,
                }}
            />
        </>
    );
};

export default ScanRequestDetailForm;
