import { groupBy } from "lodash";
import { useNavigate } from "react-router-dom";

import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import CollapseableList from "shared/components/CollapseableList";
import RenderImage from "shared/components/ui/RenderImage";

const SRScanTab = () => {
    const { request } = useSingleSR();
    const navigate = useNavigate();

    const groupedApplications = groupBy(request?.applications, "user.name");

    return (
        <div>
            {Object.keys(groupedApplications).map((name, i) => {
                const applications = groupedApplications[name];

                if (applications[0].submissions.length === 0) return <></>;

                return (
                    <CollapseableList title={name} key={i}>
                        <div className="grid grid-cols-3 gap-4">
                            {applications[0].submissions.map((sub, uii) => {
                                return (
                                    <div
                                        className="flex flex-col space-y-2 cursor-pointer"
                                        key={uii}
                                        onClick={() => navigate(`scans/${sub.scan_id}`)}
                                    >
                                        <RenderImage
                                            containerClassName="h-[200px]"
                                            image={sub.input_file.thumbnail || ""}
                                            imgClassName="rounded-md"
                                        />
                                        <span className="text-sm leading-4 font-medium text-[#3F3E3E]">{sub.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </CollapseableList>
                );
            })}
        </div>
    );
};

export default SRScanTab;
