import { useTour } from "@reactour/tour";

import useImages from "assets/images";

export const Badge = () => {
    return <></>;
};

export const Close = () => {
    const { CloseIcon } = useImages();
    const { setIsOpen, setCurrentStep } = useTour();

    return (
        <button
            className="h-5 w-5 items-center justify-center flex rounded-full bg-[#7680FF1A] absolute top-3 right-3"
            onClick={() => {
                setIsOpen(false);
                setCurrentStep(0);
            }}
        >
            <CloseIcon color="#EB617A" width={12} height={12} />
        </button>
    );
};

export const Navigation = (props: any) => {
    const { ArrowLeftNav, ArrowRightNav } = useImages();
    const { currentStep, steps, setCurrentStep } = props;

    const formattedDigits = (digits: number) => {
        return digits.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });
    };

    const onPrevClickHandler = () => {
        setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);
    };

    const onNextClickHandler = () => {
        setCurrentStep(currentStep < steps.length - 1 ? currentStep + 1 : steps.length - 1);
    };

    return (
        <div className="grid grid-cols-3 justify-between border-t border-[#EAECF0] mt-[10px] pt-1">
            <div
                className={
                    "text-sm text-[#667085] flex items-center gap-2 w-fit " +
                    (currentStep == 0 ? "cursor-not-allowed" : "hover:cursor-pointer")
                }
                onClick={onPrevClickHandler}
            >
                <ArrowLeftNav color="#667085" /> Previous
            </div>
            <div className="text-sm text-[#667085] text-center">
                {formattedDigits(currentStep + 1)}/{formattedDigits(steps.length)}
            </div>
            <div
                className={
                    "text-sm text-[#667085] ml-auto mr-0 flex items-center gap-2 w-fit " +
                    (currentStep == steps.length - 1 ? "cursor-not-allowed" : "hover:cursor-pointer")
                }
                onClick={onNextClickHandler}
            >
                Next
                <ArrowRightNav color="#667085" />
            </div>
        </div>
    );
};

interface IContentProps {
    title: string;
    body: string;
}

export const Content = ({ title, body }: IContentProps) => {
    return (
        <div>
            <div className="font-semibold text-md text-[#344054] mb-1">{title}</div>
            <div className="text-md text-[#667085]">{body}</div>
        </div>
    );
};

export default {};
