import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";

const DisableTyping = Extension.create({
    name: "disableTyping",

    addProseMirrorPlugins(): Plugin<any>[] {
        return [
            new Plugin<any>({
                props: {
                    handleDOMEvents: {
                        keydown: (view, event) => {
                            // You can put more specific conditions here if necessary
                            // For instance, allowing certain keys like arrow keys
                            event.preventDefault();
                            return true; // This tells ProseMirror that the event has been handled
                        },
                    },
                },
            }),
        ];
    },

    addGlobalAttributes() {
        return [
            {
                types: ["textStyle"],
                attributes: {
                    style: {
                        default: null,
                        parseHTML: (element) => element.style.caretColor,
                        renderHTML: (attributes) => {
                            if (!attributes.style) {
                                return {};
                            }
                            return {
                                style: `caret-color: ${attributes.style}`,
                            };
                        },
                    },
                },
            },
        ];
    },
});

export default DisableTyping;
