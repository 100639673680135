import "mapbox-gl/dist/mapbox-gl.css";

import { useRef } from "react";
import { MapRef, Marker, NavigationControl, Map as ReactMapBox } from "react-map-gl";

import useMap from "./hook";
import Geocoder from "./partials/Geocoder";
import { IMapProps } from "./type";

// added the following 6 lines.
import { useFormContext } from "react-hook-form";

const Map = (props: IMapProps) => {
    const form = useFormContext();
    const { viewport, onMove, onClick, onLocationSelect } = useMap({ ...props, ...form });
    const { locationName } = props;
    const mapRef = useRef<MapRef>(null);

    return (
        <ReactMapBox
            ref={mapRef}
            {...viewport}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            onMove={onMove}
            onClick={props.isInteract ? onClick : () => {}}
            mapStyle="mapbox://styles/mapbox/light-v11"
            style={{
                borderRadius: "16px",
            }}
            interactive={props.isInteract}
            dragPan={true}
        >
            {props.showLocationMarker ? (
                <Marker latitude={viewport.latitude} longitude={viewport.longitude} color="#554AF0" />
            ) : (
                <></>
            )}

            {props.isInteract ? <Geocoder value={locationName} onLocationSelect={onLocationSelect} /> : <></>}

            {props.isInteract ? <NavigationControl /> : <></>}
        </ReactMapBox>
    );
};

export default Map;
