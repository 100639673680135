import { ButtonProps, DialogContentProps, DialogProps, DialogTitleProps } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILoaderButtonProps } from "shared/components/ui/LoaderButton";

export interface CommonModalState extends DialogProps {
    icon?: React.ReactNode | null;
    title?: string;
    subTitle?: string;
    content?: any;
    contentComponent?: React.ReactNode;
    titleProps?: DialogTitleProps;
    contentProps?: DialogContentProps;
    dialogueActionClassName?: string;
    buttons?: CommonModalButtons[];
    loadingBtn?: string;
    cancelBtnProps?: ButtonProps;
}

export interface CommonModalButtons extends ILoaderButtonProps {
    isCloseBtn?: boolean;
}

const initialState: CommonModalState = {
    children: null as any,
    icon: null,
    open: false,
    title: "",
    content: "",
    dialogueActionClassName: "",
    buttons: [],
    maxWidth: "xs",
    loadingBtn: "",
};

const commonModal = createSlice({
    name: "commonModal",
    initialState,
    reducers: {
        setCloseCommonModal(state) {
            state.open = false;
        },
        resetCommonModal() {
            return { ...initialState };
        },
        setCommonModal: (state, { payload }: PayloadAction<Omit<CommonModalState, "open">>) => {
            return { ...state, ...payload, open: true } as any;
        },
        setCommonModalLoadingBtn(state, { payload }: PayloadAction<string>) {
            state.loadingBtn = payload;
        },
    },
});

export const { setCloseCommonModal, resetCommonModal, setCommonModalLoadingBtn, setCommonModal } = commonModal.actions;

export default commonModal.reducer;
