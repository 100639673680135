import useImages from "assets/images";

interface Props {
    icon: React.ReactNode;
    title: string;
    description: string;
}

const NotRecordBox = (props: Props) => {
    const Images = useImages();

    return (
        <div className="flex items-center justify-center flex-col text-[#5E5D5D]">
            <div className="relative mb-4">
                <Images.NoRecordIllustration />
                <div className="absolute top-[50px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    {props.icon}
                </div>
            </div>
            <h3 className="text-lg font-medium leading-6">{props.title}</h3>
            {props.description && <p className="text-md leading-5 mt-1">{props.description}</p>}
        </div>
    );
};

export default NotRecordBox;
