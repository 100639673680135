import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

import useImages from "assets/images";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { GetScanRequestByIdQuery } from "shared/graphql";

interface Props {
    data: GetScanRequestByIdQuery["scan_request"]["applications"][0];
    isButton?: boolean;
}

const SRReminder = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const scan_request_id = parseInt(id!);
    const Images = useImages();
    const { reminderRes, sendRemider } = useSingleSR();

    return (
        <ConfirmButton
            icon={<Images.GreenTickLayerIcon color="#EB617A" />}
            title="Send Reminder"
            content="Are you to send reminder for this submission?"
            okBtnProps={{
                loading: reminderRes.isLoading,
                onClick: () =>
                    sendRemider({
                        scan_request_id,
                        application_id: props.data.application_id,
                    }),
            }}
        >
            {props.isButton ? (
                <Button className="!bg-[#7680FF33] !text-primary !bg-opacity-20 !h-8 !font-normal" variant="contained">
                    Send reminder <Images.ArrowUpRight />
                </Button>
            ) : (
                <ThemeIconButton icon={<Images.NotificationIcon color="#7680FF" height={20} width={20} />} />
            )}
        </ConfirmButton>
    );
};

export default SRReminder;
