import { ButtonBase, TextField } from "@mui/material";
import useImages from "assets/images";
import { capitalize, groupBy } from "lodash";
import { useState } from "react";
import UserProfileCard from "shared/components/UserProfileCard";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeModal from "shared/components/ui/ThemeModal";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import COLOR_PALLETE from "shared/utils/colorPallete";

interface Props {
    open: boolean;
    onClose: (member?: TeamMember) => void;
}

const SendNewMessageModal = (props: Props) => {
    const Images = useImages();
    const { user, team } = useAuth();
    const [search, setSearch] = useState("");

    const members = team?.members.filter((member) =>
        member.user?.user_id !== user?.user_id && member.status === "Active" && member.user?.name
            ? member.user?.name.toLowerCase().includes(search.toLowerCase())
            : false
    );
    const groupedTeam = groupBy(members, (member) => capitalize(member.user?.name ? member.user?.name[0] : ""));

    return (
        <ThemeModal
            open={props.open}
            onClose={() => props.onClose()}
            onCloseModal={() => props.onClose()}
            title="New Chat"
            subTitle={"Search for a user to start a new chat with."}
            icon={
                <LayerIcon
                    icon={Images.UserIcon}
                    iconColor={COLOR_PALLETE.primary.DEFAULT}
                    color="primary"
                    iconClassName="h-[25px] w-[25px] !text-primary"
                    className="h-[60px] w-[60px]"
                />
            }
        >
            <div className="h-[10px]" />
            {team?.members.length !== 0 && (
                <TextField fullWidth placeholder="Search for a user" onChange={(e) => setSearch(e.target.value)} />
            )}
            <div className="flex flex-col space-y-4 mt-4">
                {groupedTeam && Object.keys(groupedTeam).length ? (
                    Object.keys(groupedTeam)
                        .sort()
                        .map((key) => (
                            <div className="flex flex-col space-y-4" key={key}>
                                <h4 className="text-p bg-gray-100 text-left text-sm font-semibold leading-6 px-3">
                                    {key}
                                </h4>
                                {groupedTeam[key].map((member, index) => (
                                    <ButtonBase
                                        key={index}
                                        className="!p-2 !rounded-md"
                                        onClick={() => props.onClose(member as TeamMember)}
                                    >
                                        <UserProfileCard
                                            classes={{
                                                root: "w-full",
                                            }}
                                            image={member.user?.profile_image.url}
                                            name={member.user?.name}
                                            email={member.user?.email}
                                        />
                                    </ButtonBase>
                                ))}
                            </div>
                        ))
                ) : (
                    <h4 className="text-p bg-gray-100 text-sm font-semibold leading-6 px-3 text-center">
                        No users found
                    </h4>
                )}
            </div>
        </ThemeModal>
    );
};

export default SendNewMessageModal;
