import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import useImages from "assets/images";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { convertSizeToText } from "shared/utils/helpers";

interface Props {
    name: string;
    size: number;
    onRemove: () => void;
    progress?: number;
    showProgress?: boolean;
}

const FileItemContainer = (props: Props) => {
    const Images = useImages();

    return (
        <div className="h-full w-full border border-[#D0D5DD] rounded-md p-6 min-h-[300px]">
            <div className="flex space-x-5">
                <ThemeIconButton icon={<Images.DeleteLayerIcon height={40} width={40} />} onClick={props.onRemove} />
                <div className="w-full">
                    <h4 className="text-[#344054] leading-5 font-medium text-md">{props.name}</h4>
                    <p className="text-[#667085] leading-5 font-normal text-md mb-[10px]">
                        {convertSizeToText(props.size)}
                    </p>
                    {props.showProgress && <LinearProgressWithLabel value={props.progress || 0} />}
                </div>
            </div>
        </div>
    );
};

export default FileItemContainer;

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <div className="flex items-center space-x-3">
            <div className="w-full">
                <LinearProgress
                    classes={{
                        root: "!h-2 rounded-md !bg-[#F2F4F7]",
                        bar: "!rounded-md",
                    }}
                    variant="determinate"
                    {...props}
                />
            </div>
            <p className="text-[#344054] text-md font-medium">{`${Math.round(props.value)}%`}</p>
        </div>
    );
}
