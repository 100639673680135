import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import ScanBlock from "./ScanBlock";

export default Node.create({
    name: "scanComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            scan: {
                default: null,
            },
            scan_img: {
                default: null,
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
            redirecting: {
                default: true,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.scan-component",
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                    scan: dom.getAttribute("scan"),
                    width: parseInt(dom.getAttribute("width")) || 100, // Providing a default value in case of parsing failure
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                    redirecting: dom.getAttribute("redirecting") === "true",
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        return [
            "div",
            {
                class: "scan-component",
                id: node.attrs.id,
                scan: node.attrs.scan,
                style: `width: ${node.attrs.width}%; justify-content: ${node.attrs.justify};`,
                caption: node.attrs.caption.toString(),
                caption_str: node.attrs.caption_str,
                redirecting: node.attrs.redirecting.toString(),
            },
            0, // This node doesn't have any direct content
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ScanBlock);
    },
});
