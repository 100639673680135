import { LinearProgress } from "@mui/material";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props extends PropsWithChildren {
    className?: string;
    onClick?: () => void;
}

const DashboardCard = (props: Props) => {
    return (
        <div
            className={twMerge(
                "bg-[#FFFFFF] border-[#EAECF0] border shadow-[0px_1px_2px_0px_#1018280D] p-5 rounded-lg h-full w-full",
                props.className,
                props.onClick && "cursor-pointer"
            )}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};

export default DashboardCard;

interface StatsProps extends PropsWithChildren {
    icon: React.ReactNode;
    title: string;
    value?: string;
    outOf?: string;
    outOfUnit?: string;
    percentage?: number;
    onClick?: () => void;
}

export const StatsCard = (props: StatsProps) => {
    const percentage =
        props.percentage || (props.value && props.outOf ? (parseInt(props.value) / parseInt(props.outOf)) * 100 : 0);

    return (
        <DashboardCard className={twMerge("relative", props.onClick && "cursor-pointer")} onClick={props.onClick}>
            {/* <div className="absolute top-4 right-4">
                <ThemeIconButton icon={<Images.DotsVertical />} className="!rounded-full !shadow-none" />
            </div> */}
            <div className="flex space-x-6">
                <div className="h-10 w-10 rounded-lg flex items-center justify-center bg-primary">{props.icon}</div>
                <div className="flex flex-1 flex-col leading-5">
                    <h4 className="text-[#475467] font-medium mb-2">{props.title}</h4>
                    <h5 className="text-[14px] leading-5 text-[#9E9E9E] mb-4">
                        <span className="text-[#101828] text-[24px] font-medium leading-8">{props.value}</span>/
                        {props.outOf} {props.outOfUnit}
                    </h5>
                    <LinearProgress
                        variant="determinate"
                        value={percentage}
                        className="rounded-lg"
                        classes={{
                            bar: "!h-2 !bg-[#9199FF]",
                            root: "!bg-[#E4E6FF] !h-2",
                        }}
                    />
                </div>
            </div>
        </DashboardCard>
    );
};
