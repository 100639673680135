import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import SnapshotBlock from "./SnapshotBlock";

export default Node.create({
    name: "snapshotComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            scan: {
                default: null,
            },
            snapshot: {
                default: null,
            },
            snapshot_link: {
                default: null,
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
            redirecting: {
                default: true,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.snapshot-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    scan: dom.getAttribute("scan"),
                    snapshot: dom.getAttribute("snapshot"),
                    snapshot_link: dom.getAttribute("snapshot_link"),
                    width: parseInt(dom.getAttribute("width")) || 100,
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                    redirecting: dom.getAttribute("redirecting") === "true",
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        return [
            "div",
            {
                class: "snapshot-component",
                id: node.attrs.id,
                scan: node.attrs.scan,
                snapshot: node.attrs.snapshot,
                snapshot_link: node.attrs.snapshot_link,
                style: `width: ${node.attrs.width}%; justify-content: ${node.attrs.justify};`,
                caption: node.attrs.caption.toString(),
                caption_str: node.attrs.caption_str,
                redirecting: node.attrs.redirecting.toString(),
            },
            0, // This node doesn't have any direct content
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(SnapshotBlock);
    },
});
