import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";

import useImages from "assets/images";
import URL from "shared/constants/navigator";

import BarChartSquare02 from "@untitled-ui/icons-react/build/esm/BarChartSquare02";
import CheckDone01 from "@untitled-ui/icons-react/build/esm/CheckDone01";
import LayoutAlt03 from "@untitled-ui/icons-react/build/esm/LayoutAlt03";
import MessageChatCircle from "@untitled-ui/icons-react/build/esm/MessageChatCircle";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import useHasSRAccess from "shared/hooks/useHasSRAccess";
import SidebarItem from "../item";

const SidebarMenus = () => {
    const Images = useImages();
    const [openDropdown, setOpenDropdown] = useState("");
    const UserHasChatAccess = useHasPermission(Permissions.Can_Do_Chat);
    const hasScanRequestAccess = useHasSRAccess();

    const menus = [
        {
            label: "Dashboard",
            icon: <BarChartSquare02 height={22} width={22} />,
            url: URL.Dashboard,
        },
        {
            label: "Projects",
            icon: <LayoutAlt03 className="grey-icon" height={22} width={22} />,
            url: URL.Projects,
        },
        hasScanRequestAccess && {
            label: "Project Requests",
            icon: <CheckDone01 height={22} width={22} />,
            children: [
                {
                    label: "New",
                    icon: <Images.TimePlusIcon height={22} width={22} />,
                    url: "/requests/new",
                },
                {
                    label: "In Progress",
                    icon: <Images.TimeCircleIcon height={21} width={21} />,
                    url: "/requests/in-progress",
                },
                {
                    label: "Delivered",
                    icon: <Images.TimeTickIcon height={22} width={22} />,
                    url: "/requests/delivered",
                },
            ],
        },
        UserHasChatAccess && {
            label: "Team Chat",
            icon: <MessageChatCircle height={22} width={22} />,
            url: "/messages",
        },
    ].filter((val) => val) as any[];

    const currentPath = window.location.pathname;

    useEffect(() => {
        const currentMenu = menus.find((menu) => {
            if (menu.children) {
                return menu.children.find((child) => {
                    return matchPath(`${child.url}/*`, currentPath);
                });
            } else {
                return matchPath(`${(menu as any).url}/*`, currentPath);
            }
        });

        if (currentMenu) {
            setOpenDropdown(currentMenu.label);
        }
    }, [currentPath]);

    const generateMenus = (menus: any[]) => {
        return menus.map((menu) => {
            if (menu.children) {
                return (
                    <SidebarItem
                        label={menu.label}
                        icon={menu.icon}
                        key={menu.label}
                        isOpen={openDropdown === menu.label}
                        onClick={() => setOpenDropdown(openDropdown === menu.label ? "" : menu.label)}
                        containerClassName={menu.className}
                    >
                        <Collapse in={openDropdown === menu.label} timeout="auto" unmountOnExit>
                            {menu.children.map((child) => (
                                <SidebarItem
                                    key={child.label}
                                    label={child.label}
                                    icon={child.icon}
                                    url={child.url}
                                    containerClassName={child.className}
                                />
                            ))}
                        </Collapse>
                    </SidebarItem>
                );
            } else {
                return (
                    <SidebarItem
                        key={menu.label}
                        label={menu.label}
                        icon={menu.icon}
                        url={menu.url}
                        containerClassName={menu.className}
                    />
                );
            }
        });
    };

    return <>{generateMenus(menus)}</>;
};

export default SidebarMenus;
