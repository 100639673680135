import useImages from "assets/images";

import ThemeIconButton from "../../ThemeIconButton";
import { useThemeListCardContext } from "../context";

const AddBox = () => {
    const { options, props } = useThemeListCardContext();
    const { PlusWhite } = useImages();

    if (!options?.onAddClick) return null;

    return (
        <div>
            <ThemeIconButton
                variant="filled"
                color="primary"
                onClick={options.onAddClick}
                icon={<PlusWhite color="white" />}
                {...props?.addProps}
            />
        </div>
    );
};

export default AddBox;
