import { groupBy, pickBy } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import { GetScanRequestsQuery, useGetScanRequestsQuery } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { rtkHandler } from "shared/utils/handlers";

interface TypeRequests {
    requestor: {
        [key: string]: GetScanRequestsQuery["team_scan_requests"];
    };
    requestee: {
        [key: string]: GetScanRequestsQuery["team_scan_requests"];
    };
}

const useSRs = () => {
    const scanReq = useGetScanRequestsQuery();
    const UserHasRequesteeAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestee);
    const UserHasRequestorAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestor);
    const [requests, setRequests] = useState<GetScanRequestsQuery["team_scan_requests"]>([]);
    const { type } = useParams<{ type: string }>();
    const { user } = useAuth();
    const { search } = useLocation();
    const searchQUery = new URLSearchParams(search);
    const searchKey = searchQUery.get("search_key");
    const [requestByType, setRequestByType] = useState<TypeRequests>({
        requestor: {},
        requestee: {},
    });

    useEffect(() => {
        rtkHandler(scanReq, {
            onSuccess(response: GetScanRequestsQuery) {
                setRequests(response.team_scan_requests);
            },
        });
    }, [scanReq]);

    useEffect(() => {
        if (requests.length > 0 && type) {
            // Searching with title
            const AllRequests = requests.filter((req) =>
                req.title.toLowerCase().includes((searchKey || "").toLowerCase())
            );

            const reqs: TypeRequests = {
                requestor: {},
                requestee: {},
            };

            // Grouping by status as Requestor and Requestee
            const requestorRequests = UserHasRequestorAccess
                ? AllRequests.filter((req) => req.requestor.user_id === user?.user_id)
                : [];
            const requesteeRequests = UserHasRequesteeAccess
                ? AllRequests.filter((req) => req.requestor.user_id !== user?.user_id)
                : [];

            if (type === "new") {
                reqs.requestor["Active"] = requestorRequests.filter((req) => req.status === "New");
                reqs.requestor["Archived"] = requestorRequests.filter((req) => req.status === "Archived");

                reqs.requestee["Active"] = requesteeRequests.filter(
                    (req) =>
                        req.status === "New" &&
                        req.applications.find((app) => app.user.user_id === user?.user_id)?.status !==
                            "Awaiting Submission"
                );
                reqs.requestee["Archived"] = requesteeRequests.filter(
                    (req) =>
                        req.status === "Archived" && req.applications.find((app) => app.user.user_id === user?.user_id)
                );
            } else if (type === "in-progress") {
                reqs.requestor["Active"] = requestorRequests.filter((req) => req.status === "In Progress");
                reqs.requestee = groupBy(
                    requesteeRequests.filter((req) =>
                        !["Delivered", "Archived"].includes(req.status) &&
                        req.applications.find((app) => app.user.user_id === user?.user_id)
                            ? req.applications.find((app) => app.user.user_id === user?.user_id)?.status !== "Invited"
                            : false
                    ),
                    (req) => {
                        return req.applications.find((app) => app.user.user_id === user?.user_id)?.status;
                    }
                );
            }

            reqs.requestor = pickBy(reqs.requestor, (req) => req.length > 0);
            reqs.requestee = pickBy(reqs.requestee, (req) => req.length > 0);

            setRequestByType(reqs);
        } else {
            setRequestByType({
                requestor: {},
                requestee: {},
            });
        }
    }, [requests, type, searchKey, user]);

    return { requests, requestByType, scanReq };
};

export default useSRs;
