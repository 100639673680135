import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import ThemeRating from "shared/components/ui/ThemeRating";
import { useGiveRatingToOnboardingMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    rating: yup.number().notOneOf([0], "Please select a rating").required(),
    comment: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

const OnboardRating = () => {
    const { user } = useAuth();
    const [giveRating, ratingResponse] = useGiveRatingToOnboardingMutation();
    const [ratingModal, setRatingModal] = useState(false);
    const form = useForm<FormValues>({
        defaultValues: {
            comment: "",
            rating: 0,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormValues) => {
        giveRating({
            data: { rating: data.rating, comment: data.comment },
        });
    };

    useEffect(() => {
        rtkHandler(ratingResponse, {
            successMessage: "Thank you for your feedback!",
            showErrorToast: false,
            onSuccess() {
                setRatingModal(false);
            },
        });
    }, [ratingResponse]);

    return (
        <>
            <Button variant="outlined" onClick={() => setRatingModal(true)} color="inherit">
                How Was Your Onboarding?
            </Button>
            <ThemeModal
                open={ratingModal}
                icon={
                    <RenderImage
                        image={user?.profile_image.url || ""}
                        containerClassName="h-[56px] w-[56px] rounded-full"
                    />
                }
                onCloseModal={() => setRatingModal(false)}
                title="Review Your Onboarding"
                content={
                    "You have completed your onboarding successfully. Let us know below if you have any special comments?"
                }
                buttons={[
                    {
                        loading: ratingResponse.isLoading,
                        children: "Submit",
                        onClick: form.handleSubmit(onSubmit),
                    },
                ]}
            >
                <ThemeFormProvider form={form} onSubmit={() => {}}>
                    <Field
                        name="rating"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <ThemeRating
                                    className="mb-2"
                                    initialRating={value}
                                    onChange={(value) => onChange(value)}
                                />
                            );
                        }}
                    />
                    <div className="text-left">
                        <Field
                            formGroup={false}
                            name="comment"
                            inputTextProps={{
                                multiline: true,
                                rows: 4,
                            }}
                            label="Review"
                            placeholder="Write your reviews about your experience with the team member"
                        />
                    </div>
                </ThemeFormProvider>
            </ThemeModal>
        </>
    );
};

export default OnboardRating;
