import { yupResolver } from "@hookform/resolvers/yup";
import { omit, update } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useChangeProfileImageMutation, useEditAccountMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useFileUploader from "shared/hooks/useFileUploader";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    address: yup
        .object()
        .shape({
            country: yup.mixed().typeError("This field is required"),
            city: yup.string().nullable(),
            zip: yup.string().nullable(),
            street: yup.string().nullable(),
        })
        .nullable(),
    profile_image: yup.mixed(),
});

type FormValues = yup.InferType<typeof schema>;

const useUpdateAccountDetail = () => {
    const [updateUser, updateRes] = useEditAccountMutation();
    const [updateImage, imageRes] = useChangeProfileImageMutation();

    // Upload Image
    const { uploadFile, loading: uploadLoading } = useFileUploader();

    const { user } = useAuth();

    const methods = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: user?.name ?? "",
            email: user?.email,
            phone: user?.phone ?? "",
            address: {
                ...user?.address,
                country: {
                    label: user?.address?.country,
                    value: user?.address?.country,
                },
            },
            profile_image: user?.profile_image.url,
        },
    });

    const { setError, formState } = methods;

    const isModified = formState.isDirty;

    const onSubmit = async ({ profile_image, ...data }: FormValues) => {
        let promise: any = Promise.resolve();

        if (profile_image && typeof profile_image !== "string") {
            const res = await uploadFile(profile_image as File);
            if (res) {
                promise = updateImage({
                    id: res.file_id,
                });
            }
        }

        promise.then(() => {
            updateUser({
                data: omit(
                    update(data, "address.country", (country) => country?.value),
                    ["phone", "email"]
                ),
            });
        });
    };

    useEffect(() => {
        rtkHandler(updateRes, {
            onSuccess() {
                successToast("Account updated successfully");
            },
            setError,
        });
    }, [updateRes, setError]);

    return {
        uploadLoading,
        isLoading: updateRes.isLoading,
        methods,
        imageRes,
        onSubmit,
        isModified,
    };
};

export default useUpdateAccountDetail;
