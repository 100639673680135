import { yupResolver } from "@hookform/resolvers/yup";
import { Button, MobileStepper, TextField } from "@mui/material";
import useImages from "assets/images";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import { CreateTeamMutation, useCreateTeamMutation, useUpdateUserPreferencesMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import useSwitchProfileApi from "shared/hooks/useSwitchProfileApi";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";
import { twMerge } from "tailwind-merge";
import CPContent from "./components/headers";
import Roles from "./data/industry-roles";
import useUpdateProfileCompletion from "./hook/useUpdateProfileCompletion";
import FieldLabel from "shared/components/ui/FieldLabel";

const schema = yup.object().shape({
    step: yup.number().required(),
    industry: yup.string().when("step", {
        is: 1,
        then: (schema) => schema.required(),
    }),
    role: yup.string().when("step", {
        is: 2,
        then: (schema) => schema.required(),
    }),
    how_heard_about_us: yup.string().when("step", {
        is: 3,
        then: (schema) => schema.required(),
    }),
    otherIndustry: yup.boolean(),
});

const industryOptions = Object.keys(Roles);

const heardAboutUsOptions = ["Facebook", "Instagram", "Twitter", "LinkedIn", "Website", "Friend/Colleague", "Other"];

type FormValues = yup.InferType<typeof schema>;

const CompleteProfileIdentityScreen = () => {
    const Images = useImages();
    const navigate = useNavigate();
    const [createTeam, createRes] = useCreateTeamMutation();
    const [udpatePreference, res] = useUpdateUserPreferencesMutation();
    const { update: updateProfileCompletion } = useUpdateProfileCompletion({ doNavigate: false });
    const { user } = useAuth();
    const [otherIndustry, setOtherIndustry] = useState(false);
    const [loading, setLoading] = useState(false);
    const { doSwitchProfile } = useSwitchProfileApi({
        doNavigate: false,
    });

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            step: 1,
            industry: "",
            role: "",
            how_heard_about_us: "",
            otherIndustry,
        },
    });

    useEffect(() => {
        rtkHandler(createRes, {
            onSuccess: async (res: CreateTeamMutation) => {
                res.create_team.members[0].profile?.profile_id &&
                    doSwitchProfile(res.create_team.members[0].profile?.profile_id)?.then(() => {
                        updateProfileCompletion("project", 3).then(() => {
                            successToast("Welcome to XSpada!");
                            navigate(URL.Dashboard, { replace: true });
                        });
                        setLoading(false);
                    });
            },
        });
    }, [createRes]);

    useEffect(() => {
        rtkHandler(res, {
            onSuccess: async () => {
                await updateProfileCompletion("team", 2);
                await createTeam({
                    data: {
                        name: `${user?.name}'s Team`,
                        username: (user?.name ?? "").replace(/\s/g, "_").toLowerCase(),
                        bio: "Hey there! I'm using XSpada.",
                        brand_color: "#7680FF",
                    },
                });
            },
        });
    }, [res]);

    const currentStep = form.watch("step");
    const industryValue = form.watch("industry");

    const onSubmit = (data: FormValues) => {
        if (currentStep === 1) {
            form.setValue("step", 2);
        } else if (currentStep === 2) {
            form.setValue("step", 3);
        } else {
            setLoading(true);
            udpatePreference({
                data: {
                    role: data.role ?? "",
                    how_heard_about_us: data.how_heard_about_us ?? "",
                    industry: data.industry ?? "",
                },
            });
        }
    };

    return (
        <CPContent
            icon={currentStep === 1 ? Images.UserIcon : currentStep === 2 ? Images.Passcode : Images.UserIcon}
            title="Getting to Know You"
            leftHeader={
                currentStep !== 1 && (
                    <Button
                        className="!h-6 !rounded-[30px] text-[#5E5D5D] !text-[10px] !leading-5 !px-[14px] !min-w-0"
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                            form.setValue("step", currentStep - 1);
                        }}
                    >
                        Back
                    </Button>
                )
            }
            description={
                currentStep === 1
                    ? "Select your industry"
                    : currentStep === 2
                    ? "Select the title that best defines you"
                    : "How did you hear about us?"
            }
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="w-[500px] mx-auto text-left">
                {currentStep === 1 && (
                    <Field
                        name="industry"
                        containerClassName={twMerge(
                            "max-w-[400px] !mx-auto space-y-4",
                            !otherIndustry && "text-center"
                        )}
                        inputTextProps={{ className: "!bg-white" }}
                        render={({ field }) => {
                            return (
                                <div className="flex justify-center flex-wrap gap-3">
                                    {[...industryOptions, "Other"].map((role, index) => {
                                        const isChecked = role === "Other" ? otherIndustry : field.value === role;
                                        return (
                                            <Button
                                                variant={isChecked ? "contained" : "outlined"}
                                                color={isChecked ? "primary" : "inherit"}
                                                className={twMerge("!h-11 !rounded-xl", !isChecked ? "!bg-white" : "")}
                                                key={index}
                                                onClick={() => {
                                                    if (isChecked) {
                                                        field.onChange("");
                                                    } else {
                                                        if (role === "Other") {
                                                            setOtherIndustry(true);
                                                            field.onChange("");
                                                        } else {
                                                            setOtherIndustry(false);
                                                            field.onChange(role);
                                                        }
                                                    }
                                                }}
                                            >
                                                {role}
                                            </Button>
                                        );
                                    })}
                                    {otherIndustry && (
                                        <div className="w-full text-left border-t border-primary border-opacity-50 mt-4 pt-4">
                                            <FieldLabel>Industry</FieldLabel>
                                            <TextField
                                                value={field.value}
                                                className="!bg-white"
                                                onChange={(e) => field.onChange(e.target.value)}
                                                placeholder="Enter your industry"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    />
                )}
                {currentStep === 2 && (
                    <Field
                        name="role"
                        containerClassName={otherIndustry ? "max-w-[400px] !mx-auto" : "space-y-4"}
                        inputTextProps={{ className: "!bg-white" }}
                        render={({ field }) => {
                            return (
                                <div className="flex justify-center text-center flex-wrap gap-3">
                                    {industryValue && !otherIndustry ? (
                                        Roles[industryValue].map((role, index) => {
                                            const isChecked = field.value === role;
                                            return (
                                                <Button
                                                    variant={isChecked ? "contained" : "outlined"}
                                                    color={isChecked ? "primary" : "inherit"}
                                                    className={twMerge(
                                                        "!h-11 !rounded-xl",
                                                        !isChecked ? "!bg-white" : ""
                                                    )}
                                                    key={index}
                                                    onClick={() => {
                                                        if (isChecked) {
                                                            field.onChange("");
                                                        } else {
                                                            field.onChange(role);
                                                        }
                                                    }}
                                                >
                                                    {role}
                                                </Button>
                                            );
                                        })
                                    ) : otherIndustry ? (
                                        <div className="w-full text-left">
                                            <FieldLabel>Role</FieldLabel>
                                            <TextField
                                                className="!bg-white"
                                                onChange={(e) => field.onChange(e.target.value)}
                                                placeholder="Enter your role"
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        }}
                    />
                )}
                {currentStep === 3 && (
                    <Field
                        name="how_heard_about_us"
                        containerClassName="text-center space-y-4"
                        inputTextProps={{ className: "!bg-white" }}
                        render={({ field }) => {
                            return (
                                <div className="flex justify-center flex-wrap gap-3">
                                    {heardAboutUsOptions.map((platform, index) => {
                                        const isChecked = field.value === platform;
                                        return (
                                            <Button
                                                variant={isChecked ? "contained" : "outlined"}
                                                color={isChecked ? "primary" : "inherit"}
                                                className={twMerge(
                                                    "!h-11 !rounded-xl flex space-x-2",
                                                    !isChecked ? "!bg-white" : ""
                                                )}
                                                sx={{
                                                    "& path": {
                                                        stroke: isChecked ? "#fff" : "#667085",
                                                    },
                                                }}
                                                key={index}
                                                onClick={() => {
                                                    if (isChecked) {
                                                        field.onChange("");
                                                    } else {
                                                        field.onChange(platform);
                                                    }
                                                }}
                                            >
                                                {platform === "Facebook" && (
                                                    <Images.FacebookOutlinedIcon height={20} width={20} />
                                                )}
                                                {platform === "Instagram" && (
                                                    <Images.InstagramOutlinedIcon height={20} width={20} />
                                                )}
                                                {platform === "Twitter" && (
                                                    <Images.TwitterOutlinedIcon height={20} width={20} />
                                                )}
                                                {platform === "LinkedIn" && (
                                                    <Images.LinkedinOutlinedIcon height={20} width={20} />
                                                )}
                                                {platform === "Website" && (
                                                    <Images.Globe color="#667085" height={20} width={20} />
                                                )}
                                                {platform === "Friend/Colleague" && (
                                                    <Images.UsersIcon color="#667085" height={20} width={20} />
                                                )}
                                                {platform === "Other" && (
                                                    <Images.DoubleLayer color="#667085" height={20} width={20} />
                                                )}
                                                <span>{platform}</span>
                                            </Button>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                )}
                <div className="text-center">
                    <LoaderButton
                        className="h-[44px] max-w-[400px] !mt-[10px]"
                        fullWidth
                        variant="contained"
                        onClick={form.handleSubmit(onSubmit)}
                        loading={loading}
                    >
                        {currentStep === 3 ? "Finish" : "Continue"}
                    </LoaderButton>
                </div>
                <div>
                    <MobileStepper
                        sx={{
                            "& .MuiMobileStepper-dot": {
                                backgroundColor: "#EAECF0",
                            },
                            "& .MuiMobileStepper-dotActive": {
                                backgroundColor: "#7680FF",
                            },
                        }}
                        classes={{
                            root: "!p-0 !mt-6",
                            dots: "mx-auto !space-x-2",
                        }}
                        id="mobile-stepper"
                        nextButton={<></>}
                        backButton={<></>}
                        variant="dots"
                        steps={3}
                        position="static"
                        activeStep={currentStep - 1}
                    />
                </div>
            </ThemeFormProvider>
        </CPContent>
    );
};

export default CompleteProfileIdentityScreen;
