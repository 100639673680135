import { capitalize } from "lodash";
import { useMemo } from "react";

import useImages from "assets/images";
import { ISortingType } from "shared/types/utils/utils.type";

import ThemeIconButton from "../ThemeIconButton";
import { IThemeIconButtonProps } from "../ThemeIconButton/type";

export type SortBtnProps = Omit<IThemeIconButtonProps, "icon"> & {
    sort?: string | undefined;
    onSortChange?: (sort: ISortingType) => void;
};

const SortIconButton: React.FC<SortBtnProps> = ({ sort = "", onSortChange, ...rest }) => {
    const { SelectArrow, SelectArrowAscend, SelectArrowDescend } = useImages();

    const Icon = useMemo(() => {
        switch (sort) {
            case "ascending":
                return <SelectArrowAscend />;
            case "descending":
                return <SelectArrowDescend />;
            default:
                return <SelectArrow color="#9C9C9C" />;
        }
    }, [sort]);

    return (
        <ThemeIconButton
            tooltipTitle={capitalize(sort || "None")}
            icon={Icon}
            onClick={() => {
                if (sort === "descending") {
                    sort = "ascending";
                } else if (sort === "ascending") {
                    sort = "descending";
                } else {
                    sort = "ascending";
                }

                onSortChange && onSortChange(sort as ISortingType);
            }}
            {...rest}
        />
    );
};

export default SortIconButton;
