import { Skeleton } from "@mui/material";

interface I {
    label?: boolean;
}

const ScanRequestListItemLoader = (props: I) => {
    const { label = true } = props;

    return (
        <>
            {label && <Skeleton className="mb-2" variant="rounded" width={150} height={21} />}
            <div className="flex flex-col space-y-4">
                {[...Array(8)].map((_, index) => (
                    <Skeleton variant="rounded" width="100%" height={74} key={index} />
                ))}
            </div>
        </>
    );
};

export default ScanRequestListItemLoader;
