import { Navigate } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";
import ScanCreditSettings from "./utils/ScanCreditSettings";
import ScanCreditsUsage from "./utils/ScanCreditsUsage";

const ScanCreditsSettingScreen = () => {
    const { user } = useAuth();

    if (user) {
        return <Navigate to="/404" />;
    }

    return (
        <>
            <ScanCreditSettings />
            <ScanCreditsUsage />
        </>
    );
};

export default ScanCreditsSettingScreen;
