import { createSlice } from "@reduxjs/toolkit";

interface UtilsState {
    notificationSidebar: boolean;
    customerSupportModal: boolean;
    isSidebarCollapsed: boolean;
}

const initialState: UtilsState = {
    notificationSidebar: false,
    customerSupportModal: false,
    isSidebarCollapsed: false,
};

const utils = createSlice({
    name: "utils",
    initialState,
    reducers: {
        toggleNotificationSidebar: (state) => {
            state.notificationSidebar = !state.notificationSidebar;
        },
        toggleCustomerSupportModal: (state) => {
            state.customerSupportModal = !state.customerSupportModal;
        },
        expandSidebar: (state) => {
            state.isSidebarCollapsed = false;
        },
        compressSidebar: (state) => {
            state.isSidebarCollapsed = true;
        },
    },
});

export const { toggleNotificationSidebar, toggleCustomerSupportModal, expandSidebar, compressSidebar } = utils.actions;

export default utils.reducer;
