import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Profile, User } from "shared/graphql";

interface Props {
    current: User | null;
    profile: Profile | null;
}

const initialState: Props = {
    current: null,
    profile: null,
};

const user = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<Props["current"]>) => {
            state.current = action.payload;
        },
        setProfile: (state, action: PayloadAction<Props["profile"]>) => {
            state.profile = action.payload;
        },
        logout: (state) => {
            state.current = null;
            state.profile = null;
        },
    },
});

export default user.reducer;

export const { setProfile, setUser, logout } = user.actions;
