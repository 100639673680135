import useImages from "assets/images";
import { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useFormContext } from "react-hook-form";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";

interface Props {
    onClick: () => void;
}

const GoogleDriveUpload = (props: Props) => {
    const Images = useImages();
    const context = useFormContext();
    const type = context.watch("dataset_type");
    const [openPicker, authResponse] = useDrivePicker();
    const [file, setFile] = useState<any>(null);

    const handleOpenPicker = () => {
        openPicker({
            clientId: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID || "",
            developerKey: process.env.REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY || "",
            viewId: type === "images" ? "DOCS" : "DOCS_VIDEOS",
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: false,
            // customViews: customViewsArray, // custom view
            callbackFunction: async (data) => {
                if (data.action === "picked") {
                    setFile(data.docs[0]);
                }
            },
        });
    };

    useEffect(() => {
        if (file && authResponse) {
            context.setValue("input_videos", {
                ...file,
                authResponse,
            });
        }
    }, [file, authResponse]);

    return (
        <ThemeIconButton
            onClick={() => {
                props.onClick();
                handleOpenPicker();
            }}
            icon={<Images.GoogleDriveIcon />}
        />
    );
};

export default GoogleDriveUpload;
