import React from "react";

const EULATermsContent = () => {
    return (
        <>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "12pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    PLEASE READ THIS AGREEMENT CAREFULLY, WHICH CONTAINS AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT
                    INFORMATION REGARDING YOUR RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES
                    (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST XSPADA INC. TO BINDING AND FINAL
                    ARBITRATION, AND FURTHER, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST XSPADA INC. ON AN
                    INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
                    PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                    DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE
                    AGAINST XSPADA INC. RESOLVED BY A JURY OR IN A COURT OF LAW.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "12pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    BY ACCEPTING BELOW CUSTOMER AGREES TO THESE TERMS AND CONDITIONS:
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    The ____XSpada_______ platform (the “Platform”) and the content and data (the “Data”) included in
                    the Platform are owned by XSPADA Inc., a Delaware Corporation (the “Vendor”). The Platform and Data
                    are provided under this End User License Agreement (the “Agreement”) and are subject to the
                    following terms and conditions, which are agreed to by End User (“you” or “your” or the “Customer”),
                    on the one hand, and the Vendor, on the other hand (individually, a “Party,” and collectively, the
                    “Parties”).
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IMPORTANT: CAREFULLY READ THIS ENTIRE AGREEMENT BEFORE PURCHASING OR USING THIS PLATFORM.
                    PURCHASING, INSTALLING, COPYING, OR OTHERWISE USING THIS PLATFORM INDICATES YOUR ACKNOWLEDGEMENT
                    THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ITS TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO
                    THESE TERMS AND CONDITIONS, DO NOT INSTALL OR USE THE PLATFORM.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    RECITALS
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    WHEREAS, Vendor is the developer and owner of the Platform and Data, and may, from time to time,
                    develop additional proprietary software or data collection platforms; and
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    WHEREAS, Vendor provides the Platform and related content through Vendor’s website(s) located at
                    https://xspada.com
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    (the “Site”) and through Vendor’s mobile applications and related technologies (the “Mobile Apps,”)
                    (collectively, such Platform, Data, Site and Mobile Apps, including any updated or new features,
                    functionality and technology, the “Service”);
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    WHEREAS, Customer desires to obtain the right to use the Platform, Data and Service with respect to
                    geospatial data collection, geospatial data processing, and/or geospatial data viewing; and
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    WHEREAS, Vendor is willing to grant such rights to Customer on the terms and conditions set forth in
                    this Agreement.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    NOW, THEREFORE,
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    for and in consideration of the premises, representations, warranties, covenants, mutual agreements,
                    terms, and conditions contained herein, the adequacy of which consideration is hereby accepted and
                    acknowledged by the Parties, the Parties hereby agree as follows:&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "center",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    TERMS AND CONDITIONS
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "14pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;DEFINITIONS
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            The following capitalized terms shall have the following meanings whenever used in this
                            Agreement:
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Confidential Information
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means all business or technical information of the disclosing Party that is not
                                generally known to the public and that derives value from not being generally known,
                                whether such information is disclosed to the receiving Party orally or in writing.
                                Confidential Information may include, but is not limited to: any software,
                                documentation, algorithm, device, compilation of information, mobile application,
                                method, technique or process. The Parties expressly acknowledge that the Platform (in
                                any and all versions, including both source code and object code versions), Data,
                                Service, and all Documentation provided to Customer hereunder constitute Confidential
                                Information of Vendor.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Content
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means anything created, uploaded, submitted, stored, sent, received or shared by the
                                Customer using or onto the Platform or Service and that Customer grants to Vendor a
                                worldwide, non-exclusive, royalty-free license into perpetuity and on all platforms and
                                in all mediums now known or to be developed.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Documentation
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means all operator and user manuals, education materials, guides, listings,
                                specifications and other materials, including online information and materials, related
                                to the use of the Platform or Service and delivered to Customer hereunder.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Error
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means any failure of the Service to conform to its Documentation in any material
                                respect.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Feedback
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means any suggestion or idea for modifying any of Vendor’s products or services,
                                including without limitation, all intellectual property rights in any such suggestion or
                                idea.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                License Fee
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means the license fee specified in the Agreement, Site or Platform, payable by
                                Customer to Vendor.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Non-Company Apps
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means software applications that are not Vendor branded, including apps in any Vendor
                                app marketplace.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Person
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means any individual, corporation, limited liability company, partnership, trust or
                                other legal entity.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Platform
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means the programs and data in machine-readable form provided by Vendor to Customer
                                hereunder.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Robot
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means an unmanned aerial vehicle (“UAV”), drone or other robot that Customer or its
                                Users use in connection with the Platform or Service.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Schedule
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means any addendum to this Agreement describing the Platform, Service or any related
                                Documentation to be provided by Vendor to Customer under this Agreement and any other
                                terms and conditions specified therein.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Specifications
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means Vendor’s standard specifications for the Platform set forth in its then-current
                                Documentation and at ___https://xspada.com/_______.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Term
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” is defined in Section 9.1 below.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Third Party Content
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means information obtained by Vendor from publicly available sources, from third-party
                                content providers, or from third-party software providers, and made available to
                                Customer through the Platform or Service, including linked information.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Third Party
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means any Person other than Vendor or Customer.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Third Party Services
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means Non-Company Apps and Third Party Content.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                “
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Upgrade
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                ” means a new version, updates, or upgrades of the Platform, in object code format.
                            </span>
                        </p>
                    </li>
                </ol>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            LICENSE TERMS AND CONDITIONS
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Grant of License
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Vendor hereby grants Customer, and Customer accepts from Vendor, subject to the terms
                                and conditions of this Agreement, a limited, non-exclusive, non-transferable,
                                non-assignable, revocable license in the United States to use the object code version of
                                the Platform, Data, Service and Documentation solely:&nbsp;
                            </span>
                        </p>
                    </li>
                    <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    in accordance with the terms of this Agreement;
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    on the Designated Operating System(s), if any; and&nbsp;
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    for Customer’s own personal or internal business operations, and not for processing
                                    the data of any Third Party (whether on an outsourcing, service bureau, or other
                                    basis, except in connection with the performance of Customer’s regular business
                                    operations and not as a business itself), redistribution, remarketing or any other
                                    use.&nbsp;
                                </span>
                            </p>
                        </li>
                    </ol>
                </ol>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Vendor owns the Platform, Data, Service and Documentation and has the full power and authority to
                    grant to Customer the license rights herein granted without the consent of any other Person.
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Restrictions
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer’s use of the Platform, Data, Service and Documentation is subject to the
                            following additional restrictions:
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform, Data, Service or Documentation for any purpose other
                                than those expressly authorized under this Agreement.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Except as expressly authorized in this Agreement, Customer agrees not to sell, resell,
                                license, sublicense, make available, rent, lease, reproduce, copy, modify, decompile,
                                disassemble, reverse engineer, publicly display, publicly perform, create derivative
                                works of, or otherwise attempt to derive any of the source code of any portion of the
                                Platform, Data, Service or Documentation, and may not transfer or distribute them in any
                                form, for any purpose, except to the extent permitted by mandatory laws.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer is prohibited from renting, sublicensing or leasing the Platform, Data, Service
                                or Documentation to any other Person or Third Party.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer may not use the Platform, Data, Service or Documentation for service bureau or
                                time-sharing purposes, or in any other way allow Third Parties to exploit the Platform,
                                Data, Service or Documentation.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not abuse, harm, interfere with or disrupt the Platform, Data, Service or
                                Documentation or a Third Party Service, by for example, but not limited to: accessing or
                                using them in fraudulent or deceptive ways; introducing malware or spamming, hacking or
                                bypassing the Platform or Service’s systems or protective measures; transmitting
                                malicious code or other code files, scripts, agents, or programs intended to do harm,
                                including but not limited to, viruses, worms, time bombs and Trojan horses; or copying,
                                translating, redistributing, reverse engineering, decompiling or disassembling the
                                Platform or Service or any part of the Platform, Data, Service or Documentation to
                                develop any other computer program, mobile application, or platform, or for any other
                                reason.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform, Data, Service or Documentation to create or augment
                                any other geospatial data collection platform or geospatial data-related dataset for use
                                in a service that is a substitute for, or substantially similar to, the Platform or
                                Vendor’s product offerings, or otherwise use the Platform, Data, Service or
                                Documentation in a way outside of the intended permitted uses of the Platform, Data,
                                Service and Documentation as described in this Agreement and Vendor’s Terms of Service.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not copy nor duplicate by any means the Platform, Data, Service,
                                Documentation or any item included therein, without the prior written consent of Vendor.
                                Customer will cause all proprietary, confidential, copyright, trademark and/or patent
                                notices, markings or legends which appear on any item included in the Platform, Service
                                and Documentation to be placed upon each such copy or duplication that is approved by
                                prior written consent of Vendor. The original and any copies of the Platform, Service
                                and Documentation will at all times remain the sole property of Vendor.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer may not modify, copy or create derivative works of or based on the Platform,
                                Data, Documentation or Service, or any part, feature, function or user interface
                                thereof, whether through the services of itself, its own employees or of independent
                                contractors.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Vendor is headquartered in the United States. Whether inside or outside of the United
                                States, Customer is solely responsible for ensuring compliance with the laws of
                                Customer’s specific jurisdiction. Software available in connection with the Platform and
                                Service and the transmission of applicable data, if any, is subject to United States
                                export controls. In no event will Customer export the Platform, Data, Service or any
                                Documentation or use the Platform, Data, Service or any Documentation outside of the
                                United States. Customer agrees to comply with all export laws, restrictions, national
                                security controls and regulations of the United States or any other applicable foreign
                                agency or authority, at Customer’s sole expense, and not to export or re-export, or
                                allow the export or re-export of the Platform, Data, Service, Documentation or any of
                                Vendor’s Confidential Information or any copy or direct product thereof in violation of
                                any such restrictions, laws or regulations, or in violation of the embargo provisions of
                                the U.S. Export Administration Regulations (or any successor regulations or supplement),
                                except in compliance with, and with all licenses and approvals required hereunder,
                                applicable export laws and regulations, including without limitation, those of the U.S.
                                Department of Commerce.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform, Service, or a Third Party Service to store or
                                transmit illegal, harmful, threatening, abusive, harassing, violent, infringing,
                                defamatory, libelous, vulgar, obscene, pornographic, hateful, discriminatory or
                                otherwise objectionable, unlawful or tortious material, or material that violates Third
                                Party rights, including but not limited to privacy rights, or intentionally use the
                                Platform or Service to collect images of people or personal information (as defined by
                                applicable law and regulation).
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use or intend to use the Platform or Service for any harmful, illegal,
                                unsolicited or unauthorized advertising purpose; or hazardous purpose; and will not use
                                the Platform or Service to violate any applicable local, state, national or
                                international law, or any regulations having the force of law.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform or Service to impersonate any person or entity, or
                                falsely state or otherwise misrepresent Customer’s affiliation with a person or entity.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform, Service or a Third Party Service to store or
                                transmit any material that in the sole judgment of Vendor is objectionable or which
                                restricts or inhibits any other person from using or enjoying the Platform or Service,
                                or that in the sole judgment of Vendor may expose Vendor or its users to any harm or
                                liability of any type.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform or Service for or in connection with any military
                                purpose or harm or intimidation of any person or animal, including, but not limited to:
                                (i) direct or indirect use by armed forces, paramilitary force, police force or militia,
                                (ii) incorporation into weapons, or use in the production, maintenance or testing of
                                weapons or operations or activities of a military or police nature, or (iii) use as
                                parts or components of military items.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer and any one of Customer’s employees or ensigns using the Platform, Data,
                                Service or Documentation under the terms of this Agreement is at least 18 years of age,
                                or, if above the age of 13 years of age and under the age of 18 years of age, only uses
                                the Platform, Data, Service or Documentation with the approval of their parent or legal
                                guardian.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform or Service to solicit personal information from
                                anyone under the age of 18 years old.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not use the Platform or Service to promote any criminal activity or
                                enterprise or provide instructional information about illegal activities.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not attempt to gain unauthorized access to any part of the Platform or
                                Service or their related systems or networks.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Customer will not permit direct or indirect access to or use any part of the Platform or
                                Service in a way that circumvents subscriber, site or location limits, or use any part
                                of the Platform or Service to access or use any of Vendor’s intellectual property.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                If using the Platform, Data, Service or Documentation on a mobile device, and if charges
                                are incurred from Customer’s wireless provider for using the Platform, Data, Service or
                                Documentation on a mobile device, Customer is solely responsible for any charges.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Vendor may update the Platform, Service and Documentation and may automatically
                                electronically update the version of the Platform, Service and Documentation that
                                Customer has installed on a mobile device. Customer consents to all automatic upgrades,
                                and understands that this Agreement will apply to all updated versions of the Platform,
                                Service and Documentation.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Copies of the Platform, Data, Service or Documentation created or transferred pursuant
                                to this Agreement are licensed, not sold, and Customer receives no title to nor
                                ownership of any copy or of the Platform, Data, Service or Documentation. Furthermore,
                                Customer receives no rights to the Platform, Data, Service or Documentation other than
                                those specifically granted in{" "}
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Section 2.1
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                , above.
                            </span>
                        </p>
                    </li>
                </ol>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Intellectual Property
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Vendor retains ownership and any intellectual property rights to all of its content that
                            exists on or in the Platform, Data, Service and Documentation. Customer shall not remove,
                            obscure or alter any of Vendor’s content, branding, logo or legal notices.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Customer Submissions
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . The Platform allows Customer to upload, submit, store, send, receive or share content.
                            Customer has no obligation to provide any content to Vendor’s Platform or Service and is
                            free to choose the content it wants to provide. If Customer chooses to upload or share
                            content, Customer warrants that it has the necessary rights to do so and that the content is
                            lawful.
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Any Content uploaded, submitted, stored, sent, received or shared by the Customer onto the Platform
                    or Service remains Customer’s, which means that Customer retains any intellectual property rights it
                    has in the Content.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Customer represents and warrants to Vendor that any Content uploaded by Customer does not and will
                    not infringe on the trademark, service mark, copyright, right of publicity, or other intellectual
                    property or privacy rights of a Third Party.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Customer will be solely liable for any damage resulting from any infringement of intellectual
                    property rights, proprietary rights, privacy rights or any other harm resulting from any Content it
                    uploads.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Customer grants Vendor a worldwide, unlimited, non-exclusive, royalty-free, non-revocable,
                    transferable license into perpetuity and on all platforms and in all modes or mediums now known,
                    existing or to be developed to:&nbsp;
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            host, reproduce, distribute, communicate and use Customer’s Content for purposes including,
                            but not limited to: training; commercial redistribution; advertising; and analytical
                            analysis;&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            copy, publish, publicly perform or publicly display Customer’s Content for purposes
                            including, but not limited to: training; commercial redistribution; advertising; and
                            analytical analysis;&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            modify, edit and create derivative works based on Customer’s Content;&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            distribute or sell Customer’s Content for any purpose and without restriction or obligation
                            to Customer; and&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }} id="docs-internal-guid-cf098896-7fff-a9bc-6104-de456fb6a264">
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            sublicense the rights granted in{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 2.4
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            to:
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-roman",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "14.399999999999991pt",
                            paddingLeft: "7.200000000000003pt",
                        }}
                        aria-level={4}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                other Platform, Service or Data users to allow the Platform, Service or Data to work as
                                designed; and;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-roman",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "14.399999999999991pt",
                            paddingLeft: "7.200000000000003pt",
                        }}
                        aria-level={4}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Contractors of Vendor who have signed agreements with Vendor that are consistent with
                                this Agreement.
                            </span>
                        </p>
                    </li>
                </ol>
            </ol>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Take Down Right
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Vendor retains the right to remove or take down any Content that:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            breaches this Agreement, any Schedule or service-specific additional terms or policies;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            violates applicable law; or
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            could harm other customers, Third Parties or Vendor.
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.6.{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Customer Responsibility
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Customer is solely responsible for:
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Obtaining any software or products required for use of the Platform or Service, including
                            any operating system software, database software, or Third Party applications software.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Conversion of any of Customer’s existing data files for use with the Platform or Service.
                            Unless otherwise specified on an applicable Schedule, Vendor will not provide any data
                            conversion services to Customer.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            The accuracy, quality and legality of Customer’s Content; the means by which Customer
                            acquired Customer Content; Customer’s use of Customer Content with the Platform or Service;
                            the interoperation and functioning of any Third Party Services; complying with all
                            applicable laws, including, but not limited to, export control, sanctions and human
                            trafficking laws; and only using the Platform or Service in accordance with applicable laws
                            and regulations.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Respecting the rights of others, including privacy, real property and intellectual property
                            rights.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Obtaining consent to access, record, and display any areas in which a video is taken from
                            the respective property owner and, if applicable, lessee.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Not using the Platform, Service or Documentation in any manner to cause harm or threaten or
                            encourage such abuse or harm to persons, places or things, for example, but not limited to,
                            by misleading, defrauding, illegally impersonating, defaming, bullying, discriminating
                            against, harassing or stalking others.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={7}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Not using the Platform, Service or Documentation in any manner that is illegal or encourages
                            or advocates illegal activity or conspiracy to commit criminal activity.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={8}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Complying with the terms of service of any Third Party Service and with the terms and
                            conditions applicable to any Robot or any other hardware used in connection with the
                            Platform or Service as specified by the manufacturer or distributor of such Robot, camera or
                            other hardware.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={9}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            All activity occurring in Customer’s account(s) or through Customer’s use of the Platform or
                            Service.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={10}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Using commercially reasonable efforts to prevent unauthorized access to or use of the
                            Platform or Service and notifying Vendor promptly of any such unauthorized use.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={11}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Not submitting anything on the Platform or Service that contains a virus or other harmful
                            component or otherwise tampers with, impairs or damages the Platform, Data, Service or any
                            connected network, or otherwise interferes with any person or entity’s use or enjoyment of
                            the Platform or Service.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={12}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Indemnifying Vendor and its directors, officers, employees and contractors for any Third
                            Party legal proceedings (including actions by government authorities) arising out of or
                            relating to Customer’s unlawful use of the Platform or Service or violation of this
                            Agreement. This indemnity includes, but is not limited to, any liability or expense arising
                            from claims, losses, damages, judgments, fines, litigation costs and legal fees.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.7.{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Assumption of Risk
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Customer may find that actual conditions differ from the Platform, Service, Documentation or Data.
                    Customer shall exercise independent judgment. Customer uses the Platform, Service, Documentation and
                    Data at their own risk. Customer is responsible at all times for Customer’s conduct and its
                    consequences. XSPADA assumes no responsibility for any result or consequences related directly or
                    indirectly to any action or inaction that Customer or any Third Party takes based on the Platform,
                    Service, Documentation or Data, or any information available through or in connection with the
                    Platform, Service, Documentation or Data. The Platform, Service, Documentation and Data may assist
                    the Customer in performing various tasks in an inspection. However, unless explicitly specified by
                    the terms of use for a particular service, THE PLATFORM, SERVICE, DOCUMENTATION AND DATA ARE NOT
                    INTENDED to provide the Customer with any financial, real estate, or related advice of any kind.
                    Vendor assumes no responsibility for any result or consequences related directly or indirectly to
                    any action or inaction that Customer or any Third Party takes based on the Platform, Data, Service
                    or Documentation or any information available through or in connection with the Platform, Data,
                    Service or Documentation.&nbsp;&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.8.{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Ownership of Platform, Service and Documentation
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Except as otherwise expressly set forth in this agreement, Vendor retains all right, title and
                    interest in and to the Platform, Service, Data and all Documentation, in all forms, and all copies
                    and modifications of the thereof, including all worldwide rights to patents, copyrights, trademarks
                    and trade secrets in or relating to the same. Customer is not acquiring any right, title or interest
                    of any nature whatsoever in the Platform, Service, Data or any Documentation, except the license
                    rights affirmatively stated herein. Unless otherwise agreed, Customer agrees to return to Vendor or
                    destroy, as requested by Vendor, superseded versions of the Platform, Service, Data or any
                    Documentation.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    2.9{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Robot Operations
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Customer is solely responsible for ensuring compliance with all applicable legal requirements at
                    the local, state, national and international levels for the operation of any Robot, including, but
                    not limited to, any requirement to detect and avoid aircrafts and drones and to obtain proper
                    airspace authorizations. Customer must at all times comply with all applicable local, state,
                    national and international laws and regulations, including but not limited to, any and all laws and
                    regulations related to the operation of a Robot and use of the Platform or Service in the territory
                    of operation, and any applicable laws with regard to privacy, property rights, any applicable
                    product documentation, and the terms of the agreement under which Customer procured any Robot.
                    Customer, and not Vendor, is responsible for the safety and security of any Robot operations carried
                    out by Customer. Vendor assumes no liability, whether criminal, civil or otherwise, for the use of
                    any Robot in conjunction with the Platform or Service.&nbsp;
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HOSTING TERMS
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Unscheduled Maintenance; Scheduled Maintenance
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer recognizes that Vendor will outsource hosting of the online content delivery
                            networks (“CDN”) platform to a Third Party. “Unscheduled Maintenance” refers to an emergency
                            outage of the CDN that occurs without providing prior written notice to Customer. “Scheduled
                            Maintenance” refers to any pre-scheduled interruption in the CDN that will not exceed two
                            (2) hours per week and that occurs either: (1) between 8:00 p.m. EST and 5:00 a.m. EST
                            beginning on a Thursday or; (2) on either Saturday or Sunday based on the needs of the Third
                            Party provider. Vendor will notify Customer within twelve (12) hours before such Scheduled
                            Maintenance, which includes actions undertaken by the Third Party partner to perform
                            upgrades, apply software patches or day-to-day maintenance of any server and/or software in
                            the CDN that are required to perform online services. To the extent that the CDN become
                            unavailable to Customer, Vendor will notify Customer in writing at least 24 hours in advance
                            of any such Scheduled Maintenance.&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Modifications to Service
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . XSPADA reserves the right to modify or discontinue, temporarily or permanently, the
                            Platform, Data, Service or Documentation (or any part hereof) with or without notice and at
                            XSPADA’s sole discretion. You agree that XSPADA will not be liable to you or to any Third
                            Party for any modification, suspension or discontinuation of the Service, including but not
                            limited to, for Unscheduled Maintenance or Schedule Maintenance.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;FEES &amp; PAYMENT PROCESSING
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4.1
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Fees
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . To the extent the Service or any portion thereof is made available for any fee, you may be
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    required to select a payment plan and provide information regarding your credit card or other
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    payment instrument. You represent and warrant to XSPADA that such information is true and that
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    you are authorized to use the payment instrument. You will promptly update your account
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    information with Stripe of any changes (for example, a change in your billing address or credit card
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    expiration date) that may occur. You agree to pay XSPADA the amount that is specified in the
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    payment plan in accordance with the terms of such plan and these Terms of Service. If your&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    payment plan includes an ongoing subscription that is automatically renewed periodically, you
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    hereby authorize XSPADA (through Stripe) to bill your payment instrument in advance on such
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    periodic basis in accordance with the terms of the applicable payment plan until you terminate your
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    account, and you further agree to pay any charges so incurred. If you dispute any charges, you must
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    let XSPADA know within sixty (60) days after the date that XSPADA charges you, or within such
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    longer period of time as may be required under applicable law by emailing:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    [info@xspada.com]
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    .
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    We reserve the right to change XSPADA’s prices. If XSPADA does change prices, XSPADA will
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    provide notice of the change through the Service user interface, a pop-up notice, email, or through
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    other reasonable means, at XSPADA’s sole option, at least thirty (30) days before the change is to
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    take effect. Your continued use of the Service after the price change becomes effective constitutes
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    your agreement to pay the changed amount. You will be responsible for all taxes associated with
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    the Service, other than taxes based on XSPADA’s net income.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4.2
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Payment Processing
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Notwithstanding any amounts owed to XSPADA hereunder, XSPADA DOES NOT PROCESS PAYMENT FOR ANY
                    SERVICES. To facilitate payment for the Platform or Service via bank account, credit card, or debit
                    card, we use Stripe, Inc. (“Stripe”). These payment processing services are provided by Stripe and
                    are subject to the Stripe terms and conditions and other policies available at{" "}
                </span>
                <a href="https://stripe.com/legal" style={{ textDecoration: "none" }}>
                    <span
                        style={{
                            fontSize: "14px",

                            color: "#0563c1",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "underline",
                            WebkitTextDecorationSkip: "none",
                            textDecorationSkipInk: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        https://stripe.com/legal
                    </span>
                </a>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    and Stripe’s privacy policy available at{" "}
                </span>
                <a href="https://stripe.com/privacy" style={{ textDecoration: "none" }}>
                    <span
                        style={{
                            fontSize: "14px",

                            color: "#0563c1",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "underline",
                            WebkitTextDecorationSkip: "none",
                            textDecorationSkipInk: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        https://stripe.com/privacy
                    </span>
                </a>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    (collectively, the “Stripe Agreements”). By agreeing to these Terms of Service, users that use the
                    payment functions of the Platform or Service also agree to be bound by the Stripe Agreements, as the
                    same may be modified by Stripe from time to time. You hereby authorize Stripe to store and continue
                    billing your specified payment method even after such payment method has expired, to avoid
                    interruptions in payment for your use of the Service. Please contact Stripe for more information.
                    XSPADA assumes no liability or responsibility for any payments you make through the Platform or
                    Service.&nbsp;&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    4.3
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Refunds and Cancellations
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Payments made by you hereunder are final and non
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    refundable, unless otherwise determined by XSPADA. You may cancel your subscription
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    marginLeft: "36pt",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    online by emailing us at{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    [info@xspada.com
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    ].
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;INTELLECTUAL PROPERTY AND FEEDBACK
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Vendor’s Intellectual Property Rights in the Platform and Documentation
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Vendor retains all right, title, and interest in and to the Platform and
                                Documentation, including without limitation Upgrades, except to the extent of the
                                limited licenses specifically set forth in{" "}
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Sections 2.1
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Customer recognizes that the Platform and Documentation and their components are
                                protected by copyright and other laws.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Feedback
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Customer hereby grants Vendor a perpetual, irrevocable, worldwide license to use any
                                Feedback Customer communicates to Vendor during the Term, without compensation, without
                                any obligation to report on such use, and without any other restriction. Vendor’s rights
                                granted in the previous sentence include, without limitation, the right to exploit
                                Feedback in any and every way, as well as the right to grant sublicenses.
                                Notwithstanding the provisions of{" "}
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Article 6
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {" "}
                                (“Confidential Information”) below, Feedback will not be considered Customer’s
                                Confidential Information.
                            </span>
                        </p>
                    </li>
                </ol>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;CONFIDENTIAL INFORMATION
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .&nbsp;
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Limitation on Disclosure and Use of Confidential Information
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Customer, as the recipient of Confidential Information from Vendor, agrees that it
                                will not disclose, provide or otherwise make available any Confidential Information of
                                Vendor, without Vendor’s prior written consent. Customer shall protect Confidential
                                Information with the same degree of care it uses to protect its own confidential
                                information of similar nature and importance, but with no less than reasonable care. In
                                addition, Customer agrees that it will not:
                            </span>
                        </p>
                    </li>
                    <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    use the disclosing Party’s Confidential Information for any purpose beyond the scope
                                    of this Agreement;&nbsp;
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    copy any part of such Confidential Information or disclose any part of such
                                    Confidential Information to any Person other than Customer’s employees who need the
                                    information to perform their duties;
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    authorize or permit any such employee to use or disclose any part of such
                                    Confidential Information in violation of this Agreement;
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    reverse engineer, decompile or disassemble any of such Confidential Information nor
                                    use any of such Confidential Information for the purpose of reverse engineering,
                                    decompiling or disassembling the Platform or Service; or
                                </span>
                            </p>
                        </li>
                        <li
                            dir="ltr"
                            style={{
                                listStyleType: "lower-alpha",
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre",
                                marginLeft: "-64.80000000000001pt",
                                paddingLeft: "3.6000000000000014pt",
                            }}
                            aria-level={3}
                        >
                            <p
                                dir="ltr"
                                style={{
                                    lineHeight: "1.2",
                                    textAlign: "justify",
                                    marginTop: "0pt",
                                    marginBottom: "10pt",
                                }}
                                role="presentation"
                            >
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "#000000",
                                        backgroundColor: "transparent",
                                        fontWeight: 400,
                                        fontStyle: "normal",
                                        fontVariant: "normal",
                                        textDecoration: "none",
                                        verticalAlign: "baseline",
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    produce any product or offer any service of any nature whatsoever based in whole or
                                    in part on such Confidential Information or cause or assist any other Third Party to
                                    do so.&nbsp;
                                </span>
                            </p>
                        </li>
                    </ol>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "0pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Notification
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . Customer shall promptly notify Vendor of any misuse or misappropriation of
                                Confidential Information that comes to Customer’s attention.&nbsp;
                            </span>
                        </p>
                    </li>
                </ol>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Exclusions
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer’s obligations under this Agreement will not apply to any portion of the Vendor’s
                            Confidential Information that:
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            at the time of disclosure to Customer, was in the public domain or subsequently becomes part
                            of the public domain through no breach of this Agreement;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Customer had in its possession at the time of disclosure by Vendor, as established by
                            written documentation in existence at that time, and that was not acquired directly or
                            indirectly from Vendor or with knowledge of confidentiality restrictions; or&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "7.199999999999989pt",
                        paddingLeft: "3.6000000000000014pt",
                    }}
                    aria-level={3}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Customer subsequently acquires by lawful means from a Third Party who is under no obligation
                            of confidentiality or non-use owed to Vendor.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Disclosure Pursuant to Legal Process
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . If Customer is legally compelled to disclose any portion of Vendor’s Confidential
                            Information in connection with a lawsuit or similar proceeding or to any governmental
                            agency, Customer will give Vendor prompt notice of that fact, including in its notice the
                            legal basis for the required disclosure and the nature of the Confidential Information that
                            must be disclosed. Customer will cooperate fully with Vendor in obtaining a protective order
                            or other appropriate protection relating to the disclosure and subsequent use of such
                            Confidential Information. Customer will disclose only that portion of Vendor’s confidential
                            Information that is legally required to be disclosed.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Enforcement
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer acknowledges that Vendor would have no adequate remedy at law should Customer
                            breach its obligations under this{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 6
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            and agrees that Vendor will be entitled to enforce its rights under this{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 6
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            by obtaining appropriate equitable relief, including but not limited to, a temporary
                            restraining order and an injunction.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Return of Confidential Information
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Upon request by Vendor, Customer will return any portion of Vendor’s Confidential
                            Information that Customer no longer has the right to use, including but not limited to, all
                            copies of that Confidential Information, or, if so directed by Vendor in writing, Customer
                            will destroy all copies of that Confidential Information and will certify to Vendor in
                            writing that all copies have been destroyed.&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Retention of Rights
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . This Agreement does not transfer ownership of Confidential Information or grant a license
                            thereto. Vendor will retain all right, title, and interest in and to all Confidential
                            Information.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Exception &amp; Immunity
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Pursuant to the Defend Trade Secrets Act of 2016, 18 U.S.C. Section 1833(b) (the “DTSA”),
                            Customer is on notice and acknowledges that, notwithstanding the foregoing or any other
                            provision of this Agreement:
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "italic",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                IMMUNITY
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . An individual shall not be held criminally or civilly liable under any federal or
                                state trade secret law for the disclosure of a trade secret that: (i) is made (A) in
                                confidence to a federal, state, or local government official, either directly or
                                indirectly, or to an attorney; and (B) solely for the purpose of reporting or
                                investigating a suspected violation of law; or (ii) is made in a complaint or other
                                document filed in a lawsuit or other proceeding, if such filing is made under seal.
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "lower-alpha",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-28.80000000000001pt",
                            paddingLeft: "3.6000000000000014pt",
                        }}
                        aria-level={3}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "italic",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                USE OF TRADE SECRET INFORMATION IN ANTI-RETALIATION LAWSUIT
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . An individual who files a lawsuit for retaliation by an employer for reporting a
                                suspected violation of law may disclose the trade secret to the attorney of the
                                individual and use the trade secret information in the court proceeding, if the
                                individual: (i) files any document containing the trade secret under seal; and (ii) does
                                not disclose the trade secret, except pursuant to court order.
                            </span>
                        </p>
                    </li>
                </ol>
            </ol>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={7}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;SOFTWARE AUDIT
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            During the Term of this Agreement and at any time during the one (1) year thereafter, Vendor
                            may audit Customer’s use of the Platform and Documentation on three (3) days’ advance
                            written notice. Customer shall cooperate with the audit, including by providing access to
                            any books, computers, records, or other information that relate or may relate to use of
                            Platform and Documentation. Such audit shall not unreasonably interfere with Customer’s
                            business activities. If Vendor discovers unauthorized use, reproduction, distribution, or
                            other exploitation of the Platform and/or Documentation, Customer shall reimburse Vendor for
                            the reasonable cost of the audit, or of the next audit in case of discovery without an
                            audit, in addition to such other rights and remedies as Vendor may have. Vendor may not
                            conduct an audit more than once per year.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;INDEMNIFICATION; DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .&nbsp;
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Indemnification
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . To the extent permitted under applicable law, Customer agrees to defend, indemnify,
                                and hold harmless Vendor, its affiliates, and its and their respective officers,
                                employees, directors, service providers, licensors, and agents (collectively, the
                                “Vendor Parties”) from any and all losses, damages, expenses, including reasonable
                                attorneys’ fees, rights, claims, actions of any kind, and injury (including death)
                                arising out of or relating to Customer’s use of the Platform or Service, Customer’s
                                connection to the Platform or Service, Customer’s violation of this Agreement, or
                                Customer’s violation of any rights of another. Vendor will provide notice to Customer of
                                any such claim, suit or proceeding. Vendor reserves the right to assume the exclusive
                                defense and control of any matter which is subject to indemnification under this{" "}
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Section 8.1
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {" "}
                                and Customer agrees to cooperate with any reasonable requests assisting Vendor’s defense
                                of such matter. Customer may not settle nor compromise any claim against the Vendor
                                Parties without Vendor’s prior written consent.&nbsp;
                            </span>
                        </p>
                    </li>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Disclaimer of Warranties
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . CUSTOMER’S USE OF THE PLATFORM OR SERVICE IS AT CUSTOMER’S SOLE RISK. THE PLATFORM AND
                                SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. VENDOR EXPRESSLY DISCLAIMS
                                ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                                NON-INFRINGEMENT.
                            </span>
                        </p>
                    </li>
                </ol>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    THE VENDOR MAKES NO WARRANTY THAT: (A) THE PLATFORM OR SERVICE WILL MEET YOUR REQUIREMENTS; (B) THE
                    PLATFORM OR SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY
                    BE OBTAINED FROM THE USE OF THE PLATFORM OR SERVICE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY
                    OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
                    PLATFORM OR SERVICE WILL MEET YOUR EXPECTATIONS.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 25.2pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    8.3
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Limitation of Liability
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . CUSTOMER EXPRESSLY UNDERSTANDS AND AGREES THAT VENDOR WILL NOT BE LIABLE FOR ANY INDIRECT,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, INCLUDING
                    DAMAGES FOR LOSS OF GOODWILL, USE, OR DATA OR OTHER INTANGIBLE LOSSES (EVEN IF VENDOR HAS BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT
                    LIABILITY, OR OTHERWISE, RESULTING FROM (A) THE USE OR THE INABILITY TO USE THE PLATFORM OR SERVICE,
                    (B) THE COST OR PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
                    INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
                    THROUGH OR FROM THE PLATFORM OR SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF CUSTOMER’S
                    TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR € ANY OTHER
                    MATTER RELATING TO THE SERVICE. IN NO EVENT WILL VENDOR’S TOTAL LIABILITY TO CUSTOMER FOR ALL
                    DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED THE AMOUNT CUSTOMER HAS PAID VENDOR IN THE LAST SIX (6)
                    MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100). VENDOR SHALL HAVE NO OBLIGATION TO INDEMNIFY
                    CUSTOMER AGAINST ANY THIRD PARTY INFRINGEMENT CLAIMS OR ANY OTHER THIRD PARTY CLAIMS.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION
                    OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
                    LIMITATIONS SET FORTH MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE
                    DISSATISFIED WITH ANY PORTION OF THE PLATFORM OR SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE
                    AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE PLATFORM OR SERVICE.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IF APPLICABLE LAW LIMITS THE APPLICATION OF THE PROVISIONS OF THIS{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    SECTION 8.3
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    , VENDOR’S LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT POSSIBLE.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “INDEMNIFICATION,” “DISCLAIMER OF
                    WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE
                    LAWS OF THE STATE OFNEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE
                    LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION WILL NOT AFFECT THE VALIDITY OF THE
                    REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    FOR THE AVOIDANCE OF DOUBT, VENDOR’S LIABILITY LIMITS AND OTHER RIGHTS SET FORTH IN THIS{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    SECTION 8.3
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    APPLY LIKEWISE TO VENDOR’S AFFILIATES, LICENSORS, SUPPLIERS, ADVERTISERS, AGENTS, SPONSORS,
                    DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS AND OTHER REPRESENTATIVES.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={9}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;TERM AND TERMINATION.
                        </span>
                    </p>
                </li>
                <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                    <li
                        dir="ltr"
                        style={{
                            listStyleType: "decimal",
                            fontSize: "14px",

                            color: "#000000",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre",
                            marginLeft: "-54pt",
                            paddingLeft: "7.199999999999999pt",
                        }}
                        aria-level={2}
                    >
                        <p
                            dir="ltr"
                            style={{
                                lineHeight: "1.2",
                                textAlign: "justify",
                                marginTop: "0pt",
                                marginBottom: "10pt",
                            }}
                            role="presentation"
                        >
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "underline",
                                    WebkitTextDecorationSkip: "none",
                                    textDecorationSkipInk: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                Term
                            </span>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#000000",
                                    backgroundColor: "transparent",
                                    fontWeight: 400,
                                    fontStyle: "normal",
                                    fontVariant: "normal",
                                    textDecoration: "none",
                                    verticalAlign: "baseline",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                . This Agreement is effective until such time as Vendor terminates this Agreement for
                                any reason, including, but not limited to: (a) Customer materially or repeatedly
                                breaching the terms of this Agreement, any schedule hereto, service-specific additional
                                terms or policies; (b) Vendor being required to do so to comply with a legal requirement
                                or court order; (c) Customer’s conduct is causing or has caused harm or liability to a
                                Third Party or Vendor, such conduct for example, but not limited to: hacking, phishing,
                                spamming, discriminating against, harassing, or misleading others or scraping content
                                that doesn’t belong to Customer; (d) or for any other reason that Vendor decides in its
                                sole judgment and without Vendor first being required to give notice to Customer of said
                                termination. Customer’s rights under this Agreement will terminate automatically without
                                notice from Vendor if Customer fails to comply with any term(s) of this Agreement.&nbsp;
                            </span>
                        </p>
                    </li>
                </ol>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    In addition, this Agreement shall terminate immediately upon the termination of an agreement between
                    Vendor and any Third Party from whom Vendor obtains services or distribution necessary to support
                    the Platform. The Disclaimers of Warranty and Liability set out in{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Section 8
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    , above, shall continue in force even after any termination.&nbsp;
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Effect of Termination
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Upon termination of this Agreement, Customer shall cease all use of the Platform and
                            Service and delete, destroy, or return all copies of the applicable Platform, Service and
                            related Documentation in its possession or control, and destroy and document in writing such
                            destruction of any embodiments of these materials stored in or on a reusable electronic or
                            similar medium, including memory, disk packs, tapes, cloud storage or other peripheral
                            devices. Along with the Disclaimers of Warranty and Liability set out in{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 8
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , the following provisions will survive termination or expiration of this Agreement: (a) any
                            obligation of Customer to pay fees incurred before termination; (b){" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Articles
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            and{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Sections 2.2
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            (“Restrictions”),{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            5
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            (“Intellectual Property and Feedback”),{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            6
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            (“Confidential Information”),{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            7
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            (“Software Audit”), 10 (“Dispute Resolution by Binding Arbitration”) and (c) any other
                            provision of this Agreement that must survive to fulfill its essential purpose.
                        </span>
                    </p>
                </li>
            </ol>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={10}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;DISPUTE RESOLUTION BY BINDING ARBITRATION
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            .
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.1
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Agreement to Arbitrate
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . This Dispute Resolution by Binding Arbitration section is referred to in this Agreement as the
                    “Arbitration Agreement.” Customer agrees that any and all disputes or claims that have arisen or may
                    arise between Customer and Vendor, whether arising out of or relating to this Agreement (including,
                    but not limited to, any breach thereof), the Platform, Service, Data or Documentation, any
                    advertising, or any aspect of the relationship or transactions between Customer and Vendor, will be
                    resolved exclusively through final and binding arbitration, rather than a court, in accordance with
                    the terms of this Arbitration Agreement. Further, this Arbitration Agreement does not preclude
                    Customer from bringing issues to the attention of federal, state or local agencies, and such
                    agencies can, if the law allows, seek relief against Vendor on Customer’s behalf. Customer agrees
                    that by entering into this Agreement, Customer and Vendor are each waiving the right to a trial by a
                    jury or to participate in a class action. Customer’s rights will be determined by a neutral
                    arbitrator, not a judge nor jury. The Federal Arbitration Act governs the interpretation and
                    enforcement of this Arbitration Agreement.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.2
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Prohibition of Class and Representative Actions and Non-Individualized Relief
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . CUSTOMER AND VENDOR AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
                    BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
                    PROCEEDING. UNLESS BOTH CUSTOMER AND VENDOR AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR
                    JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                    CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
                    MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
                    AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S)
                    EXCEPT THAT CUSTOMER MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF
                    UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.3
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Pre-Arbitration Dispute Resolution
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Vendor is always interested in resolving disputes amicably and efficiently, and most customer
                    concerns can be resolved quickly and to the customer’s satisfaction by emailing customer support at:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    info@xspada.com
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    .The Parties shall notify each other of any such claim prior to the thirtieth (30
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>th</span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    ) day after termination or expiration of this Agreement. If such efforts prove unsuccessful, a Party
                    who intends to seek arbitration must first send to the other, by certified mail, a written Notice of
                    Dispute (the “Notice of Dispute”). The Notice of Dispute to Vendor should be sent to:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    info@xspada.com
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    (the “Notice Address’). The Notice of Dispute must: (i) describe the nature and basis of the claim
                    or dispute and (ii) set forth the specific relief sought. If Vendor and Customer do not resolve the
                    claim within sixty (60) calendar days after the Notice of Dispute is received, Customer or Vendor
                    may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer
                    made by Vendor or Customer will not be disclosed to the arbitrator until after the arbitrator
                    determines the amount, if any, to which Vendor or Customer is entitled.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.4
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Arbitration Procedures
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Arbitration will be conducted by a neutral arbitrator in accordance with the American Arbitration
                    Association’s (“AAA’s”) rules and procedures, including the AAA’s Consumer Arbitration Rules
                    (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. If there is any
                    inconsistency between any term of the AAA Rules and any term of this Arbitration Agreement, the
                    applicable terms of the Arbitration Agreement will control unless the arbitrator determines that the
                    application of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair
                    arbitration. The arbitrator must also follow the provisions of these Terms of Service as a court
                    would. All issues are for the arbitrator to decide, including issues related to the scope,
                    enforceability, and arbitrability of this Arbitration Agreement. Although arbitration proceedings
                    are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator
                    can award the same damages and relief on an individual basis that a court can award to an individual
                    under these Terms of Service and applicable law. Decisions by the arbitrator are enforceable in
                    court and may be overturned by a court only for very limited reasons.
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    marginLeft: "36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Unless Vendor agrees otherwise, any arbitration hearings will take place in California. If
                    Customer’s claim is for $10,000 or less, Vendor agrees that Customer may choose whether the
                    arbitration will take place by an in-person hearing in California as established by the AAA Rules,
                    or conducted solely on the basis of documents submitted to the arbitrator or through a telephone
                    hearing. If Customer’s claim exceeds $10,000, the right to a hearing will be determined by the AAA
                    Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator will issue a
                    reasoned decision sufficient to explain the essential findings and conclusions on which the award is
                    based.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Judgment upon the award rendered may be entered in any court of competent jurisdiction.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.5
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Costs of Arbitration
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”)
                    will be governed by the AAA Rules, unless otherwise provided in this Arbitration Agreement. To the
                    extent any Arbitration Fees are not specifically allocated to either Vendor or Customer under the
                    AAA Rules, Vendor and Customer shall split them equally; provided that if Customer is able to
                    demonstrate to the arbitrator that Customer is economically unable to pay Customer’s portion of such
                    Arbitration Fees or if the arbitrator otherwise determines for any reason that Customer should not
                    be required to pay Customer’s portion of any Arbitration Fees, Vendor will pay Customer’s portion of
                    such fees. In addition, if Customer demonstrates to the arbitrator that the costs of arbitration
                    will be prohibitive as opposed to the costs of litigation, Vendor will pay as much of the
                    Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being
                    cost-prohibitive. Any payment of attorneys’ fees will be governed by the AAA Rules.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.6
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Confidentiality
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . All aspects of the arbitration proceeding, and any ruling, decision or award by the arbitrator,
                    will be strictly confidential for the benefit of all parties.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.7
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Severability
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . If a court or arbitrator decides that any term or provision of this Arbitration Agreement (other
                    than{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Section 10.2
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    , above) is invalid or unenforceable, the Parties agree to replace such term or provision with a
                    term or provision that is valid and enforceable and that comes closest to expressing the intention
                    of the invalid or unenforceable term or provision, and this Arbitration Agreement will be
                    enforceable as so modified. If a court or arbitrator decides that any of the provisions of{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Section 10.2
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    , above, are invalid or unenforceable, then the entirety of this Arbitration Agreement will be null
                    and void, unless such provisions are deemed to be invalid and unenforceable solely with respect to
                    claims for public injunctive relief. The remainder of this Agreement will continue to apply.&nbsp;
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.8
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Future Changes to Arbitration Agreement
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Notwithstanding any provision in this Agreement to the contrary, Vendor agrees that if it makes
                    any future change to this Arbitration Agreement (other than a change to the Notice Address) while
                    Customer is a user of the Platform or Service, Customer may reject any such change by sending Vendor
                    written notice within thirty (30) calendar days of the change to the Notice Address provided above.
                    By rejecting any future change, Customer is in agreement that Customer will arbitrate any dispute
                    between the Parties in accordance with the language of this Arbitration Agreement as of the date
                    Customer first accepted this Agreement (or accepted any subsequent changes to this Agreement).
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.9
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Exceptions
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . Nothing in this{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Section 10
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {" "}
                    will prevent either Party from (a) seeking interim or permanent injunctive relief or taking any
                    other action in any court to enforce or protect its intellectual property rights, or (b) filing an
                    action against the other Party in the courts having jurisdiction over it in order to enforce an
                    arbitral award granted pursuant to a proceeding under this{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Section 10
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    .
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    textIndent: "-36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                    padding: "0pt 0pt 0pt 36pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    10.10
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Governing Law
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    . This Agreement shall be governed by, construed and enforced in accordance with the laws of the
                    State of California, without giving effect to the conflicts of law principles of such State. Each
                    Party hereby consents to the exclusive jurisdiction of any federal or state court within the State
                    of California.&nbsp;
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={11}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;&nbsp;MISCELLANEOUS&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Compliance with Laws
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Each Party will comply with all international, federal, state and local laws, statutes,
                            rules and regulations applicable to the performance of their obligations under this
                            Agreement.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Third Party Services
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Vendor or third parties may make Third Party Services available to Customer. Customer’s
                            acquisition or use of Third Party Services, including any exchange of data between Customer
                            and any Third Party Service, is solely between Customer and the applicable Third Party.
                            Vendor does not warrant or support Third Party Services, whether or not they are available
                            within the Platform or Service, unless expressly provided otherwise in an agreement between
                            the Parties. Vendor is not responsible for any disclosure, modification or deletion of
                            Customer Content or Third Party Content resulting from a Third Party Service.&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Integration with Third Party Services
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . The Platform or Service may interoperate with and allow Customer to export or integrate
                            data to Third Party Services. Vendor cannot guarantee the continued availability of such
                            functionality and may cease providing it without entitling Customer to any refund, credit or
                            other compensation (for example and without limitation, if the provider of a Third Party
                            Service ceases to support interoperation in a manner acceptable to Vendor).&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Copyright Policy
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Vendor respects the intellectual property rights of others. It is Vendor’s policy, at its
                            discretion and when appropriate, to terminate the accounts of users who may infringe or
                            repeatedly infringe the copyrights of Third Parties.
                        </span>
                    </p>
                </li>
            </ol>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "25.2pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    To submit a copyright infringement notification to Vendor, please submit a notification pursuant to
                    the Digital Millennium Copyright Act (“DMCA”) by providing the below-specified Copyright Agent with
                    the following information in writing:
                </span>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            A physical or electronic signature of a person authorized to act on behalf of the owner of
                            an exclusive right that is allegedly infringed.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={2}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",
                        fontFamily: "Calibri,sans-serif",
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Identification of the copyrighted work claimed to have been infringed, or, if multiple
                            copyrighted works at a single online site are covered by a single notification, a
                            representative list of such works at that site.
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                fontFamily: "Calibri,sans-serif",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            &nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={3}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Identification of the material that is claimed to be infringing or to be the subject of
                            infringing activity and that is to be removed or access to which is to be disabled, and
                            information reasonably sufficient to permit the service provider to locate the
                            material.&nbsp;
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={4}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Information reasonably sufficient to permit the service provider to contact the complaining
                            party, such as an address, telephone number, and, if available, an electronic mail address
                            at which the complaining party may be contacted.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            A statement that the complaining party has a good faith belief that use of the material in
                            the manner complained of is not authorized by the copyright owner, its agent, or the law.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={6}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "lower-alpha",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                    }}
                    aria-level={1}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "0pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            A statement that the information in the notification is accurate, and under penalty of
                            perjury, that the complaining party is authorized to act on behalf of the owner on an
                            exclusive right that is allegedly infringed.
                        </span>
                    </p>
                </li>
            </ol>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Customer acknowledges that if it fails to comply with all of the requirements in the preceding
                    paragraph, Customer’s DMCA notice may not be valid. Please see 17 U.S.C. § 512 for more information
                    regarding the DMCA and DMCA notifications.
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "36pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Vendor’s Agent to Receive Notification of Claimed Copyright Infringement can be reached as follows:
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.2",
                    marginLeft: "18pt",
                    textAlign: "justify",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    By Email:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    info@xspada.com
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    .
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    marginLeft: "18pt",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    By Mail:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    127 Maritime Dr.
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    marginLeft: "18pt",
                    marginTop: "0pt",
                    marginBottom: "10pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Port Angeles, WA
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "18pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    98362
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "18pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Attn:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Patrick Kidwell
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
            </p>
            <p
                dir="ltr"
                style={{
                    lineHeight: "1.3800000000000001",
                    textIndent: "18pt",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                }}
            >
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    Phone:{" "}
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    (210) 526 1810
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
                <span
                    style={{
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "underline",
                        WebkitTextDecorationSkip: "none",
                        textDecorationSkipInk: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                        {" "}
                    </span>
                </span>
            </p>
            <p>
                <b style={{ fontWeight: "normal" }}>
                    <br />
                </b>
            </p>
            <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }} start={5}>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Force Majeure
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Under no circumstances will Vendor be liable for any delay or failure in performance due
                            in whole or in part to any acts of God or natural disaster (such as, but not limited to,
                            violent storm, cyclone, typhoon, hurricane, tornado, blizzard, earthquake, volcanic
                            activity, landslide, tidal wave, tsunami, flood, damage or destruction by lightning, fire,
                            drought, etc.); explosion, fire, destruction of machines, equipment, factories and of any
                            kind of installation; prolonged breakdown of transport, telecommunication or electric
                            current; unavoidable accidents; acts of war (declared or not); armed conflict or serious
                            threat of the same (including, but not limited to, hostile attack, blockade, military
                            embargo); act of terrorism; hostilities; invasion; act of a foreign enemy; extensive
                            military mobilization; civil war; riot; rebellion; revolution; military or usurped power;
                            insurrection; civil commotion or disorder; mob violence; act of civil disobedience; sabotage
                            or piracy; plague, epidemic, pandemic, outbreaks of infectious disease or any other public
                            health crisis, including but not limited to, quarantine or other employee restrictions; act
                            of authority whether lawful or unlawful, compliance with any law or governmental order,
                            rule, regulation or direction, curfew restriction, expropriation, compulsory acquisition,
                            seizure of works, requisition, nationalization; general labor disturbance, such as but not
                            limited to, boycott, strike and lock-out, go-slow, occupation of factories and premises;
                            shortage or inability to obtain critical material or supplies to the extent not subject to
                            the reasonable control of Vendor; civil disturbances; embargoes; or any other event or cause
                            beyond the reasonable control of Vendor.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Relationship of the Parties
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Nothing contained herein will be deemed to constitute a joint venture, partnership,
                            employer-employee relationship or other agency relationship. No Party is, nor will either
                            Party hold itself out to be, vested with any power or right to contractually bind or act on
                            behalf of the other Party.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Assignment &amp; Successors
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer may not assign this Agreement or any of its rights or obligations hereunder
                            without Vendor’s express written consent. Except to the extent forbidden in this{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 11
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            , this Agreement will be binding upon and inure to the benefit of the Parties’ respective
                            successors and assigns.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Export Control
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer shall not: (a) permit any Third Party to access or use the Platform, Service or
                            Documentation in violation of any U.S. law or regulation; or (b) export the Platform,
                            Service or Documentation or otherwise remove it from the United States except in compliance
                            with all applicable U.S. laws and regulations. Without limiting the generality of the
                            foregoing, Customer shall not permit any Third Party to access or use the Platform, Service
                            or Documentation in, or export it to, a country subject to a United States embargo.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Indemnity
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Customer agrees to indemnify, defend and hold Vendor and its respective licensors, service
                            providers, channel partners, suppliers, assignees, subsidiaries, affiliated companies, and
                            the respective officers, directors, employees, shareholders, agents and representatives of
                            Vendor and its licensors, free and harmless from and against any liability, loss, injury
                            (including injuries resulting in death), demand, action, cost, expense, or claim of any kind
                            or character, including but not limited to attorney’s fees, arising out of or in connection
                            with any use or possession by Customer of the Platform, Service, Documentation or
                            Content.&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Severability
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . To the extent permitted by applicable law, the Parties hereby waive any provision that
                            would render any clause of this Agreement invalid or otherwise unenforceable in any respect.
                            If any provision of this Agreement is held to be unenforceable, the remaining provisions of
                            this Agreement will remain in full force and effect.&nbsp;
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            No Waiver
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . Neither Party will be deemed to have waived any of its rights under this Agreement by
                            lapse of time or by any statement or representation other than by an authorized
                            representative in an explicit written waiver. No waiver of a breach of this Agreement will
                            constitute a waiver of any other breach of this Agreement.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            U.S. Government Restricted Rights
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . The Platform, Service and Documentation are commercial items, as that term is defined in
                            48 C.F.R. 2.101, consisting of commercial computer software and commercial computer software
                            documentation, as those terms are used in 48 C.F.R. 12.212. If the Platform, Service or
                            Documentation is acquired by or on behalf of the U.S. government or by a U.S. government
                            contractor (including without limitation prime contractors and subcontractors at any tier),
                            then in accordance with 48 C.F.R. 227.7202-4 (for Department of Defense licenses only) and
                            48 C.F.R. 12.212 (for licenses with all federal government agencies), the government’s
                            rights to the Platform, Service and Documentation are limited to the commercial rights
                            specifically granted in this Agreement, as restricted by this Agreement. The rights limited
                            by the preceding sentence include, without limitation, any rights to reproduce, modify,
                            perform, display, disclose, release, or otherwise use the Platform, Service or
                            Documentation. This{" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Section 11.12
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                            does not grant Customer any rights not specifically set forth in this Agreement.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Conflicts
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . In the event of any conflict between a Schedule hereto
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "italic",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {" "}
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            and this main body of this Agreement, this main body of the Agreement will govern.
                        </span>
                    </p>
                </li>
                <li
                    dir="ltr"
                    style={{
                        listStyleType: "decimal",
                        fontSize: "14px",

                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre",
                        marginLeft: "-18pt",
                        paddingLeft: "7.199999999999999pt",
                    }}
                    aria-level={2}
                >
                    <p
                        dir="ltr"
                        style={{
                            lineHeight: "1.2",
                            textAlign: "justify",
                            marginTop: "0pt",
                            marginBottom: "10pt",
                        }}
                        role="presentation"
                    >
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            Interpretation of Agreement
                        </span>
                        <span
                            style={{
                                fontSize: "14px",
                                color: "#000000",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            . The following rules of interpretation must be applied in interpreting this Agreement: (a)
                            the section and subsection headings used in this Agreement are for reference and convenience
                            only, and will not enter into the interpretation of this Agreement, (b) the provisions of
                            the Schedules are incorporated in this Agreement, and (c) as used in this Agreement, the
                            term “including” will always be deemed to mean “including without limitation.”
                        </span>
                    </p>
                </li>
            </ol>
        </>
    );
};

export default EULATermsContent;
