import useImages from "assets/images";
import React from "react";
import { NavLink } from "react-router-dom";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { twMerge } from "tailwind-merge";

interface Props extends React.PropsWithChildren {
    label: string | React.ReactNode;
    icon?: React.ReactNode;
    url?: string;
    onClick?: () => void;
    className?: string;
    isStatic?: boolean;
    isOpen?: boolean;
    containerClassName?: string;
}

const SidebarItem = (props: Props) => {
    const Images = useImages();
    const { isTeamExpired } = useAuth();

    const sidebarDisabled = isTeamExpired && props.label !== "Dashboard";

    const isSidebarCollapsed = useAppSelector((state) => state.utils.isSidebarCollapsed);

    const Element = props.children ? "div" : NavLink;
    const ElementClass = twMerge(
        "flex items-center justify-between min-h-[40px] cursor-pointer rounded-md text-[#9E9E9E]",
        props.className,
        isSidebarCollapsed ? "justify-center group-hover:justify-between" : "",
        sidebarDisabled && "opacity-50"
    );

    return (
        <div
            className={twMerge(
                `mt-2 first:mt-0 group/item sidebar-item`,
                props.children && "dropdown-item",
                props.containerClassName
            )}
        >
            <Element
                className={
                    (props.children
                        ? ElementClass
                        : ({ isActive }) => {
                              return twMerge(
                                  ElementClass,
                                  isActive && !props.isStatic && !sidebarDisabled
                                      ? "bg-primary text-white sidebar-item-active"
                                      : "",
                                  "group-[.dropdown-item]/item:pl-9",
                                  isSidebarCollapsed
                                      ? "!pl-0 justify-center group-hover:group-[.dropdown-item]/item:!pl-9 group-hover:justify-between"
                                      : ""
                              );
                          }) as any
                }
                onClick={!sidebarDisabled ? props.onClick : undefined}
                to={sidebarDisabled ? window.location.pathname : props.url || ""}
            >
                <div className="flex items-center space-x-2 text-md">
                    <div className="min-w-[35px] flex-center">{props.icon}</div>
                    <h5 className={"" + (isSidebarCollapsed && "hidden group-hover:block")}>{props.label}</h5>
                </div>
                {props.children && (
                    <span className={"" + (isSidebarCollapsed && "hidden group-hover:inline-block")}>
                        <ThemeIconButton icon={<Images.ArrowDown className={props.isOpen ? "rotate-180" : ""} />} />
                    </span>
                )}
            </Element>
            {props.children && <div className="mt-2">{props.children}</div>}
        </div>
    );
};

export default SidebarItem;
