import { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { UserPermission } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { errorToast } from "shared/utils/toast";

interface Props {
    children: React.ReactNode;
    permission?: string;
    condition?: boolean;
    redirect?: boolean;
}

const HasPermission = (props: Props) => {
    const { isTeamOwner, isTeamManager, isTeamMember } = useAuth();
    const permissions = useAppSelector((state) => state.team.current?.setting.permissions);

    const hasPermission = useMemo(() => {
        if (permissions) {
            let userPermission: UserPermission;
            if (isTeamOwner) {
                userPermission = permissions.admin;
            } else if (isTeamManager) {
                userPermission = permissions.manager || ({} as UserPermission);
            } else if (isTeamMember) {
                userPermission = permissions.member || ({} as UserPermission);
            } else {
                return false;
            }

            if (!userPermission) {
                return false;
            }

            const pathArray = props.permission?.split(".") || [];

            for (const path of pathArray) {
                if (userPermission[path]) {
                    userPermission = userPermission[path];
                } else {
                    return false;
                }
            }

            return typeof userPermission === "boolean" ? userPermission : false;
        }

        return false;
    }, [props.permission, isTeamOwner, isTeamManager, isTeamMember]);

    useEffect(() => {
        if (props.redirect && !hasPermission) {
            errorToast("You don't have permission to access this page");
        }
    }, [props.redirect]);

    if (hasPermission) {
        return <>{props.children}</>;
    }

    if (props.redirect) {
        return <Navigate to={URL.Dashboard} />;
    }

    return <></>;
};

export default HasPermission;
