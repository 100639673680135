import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import { useAddSubmissionToScanRequestMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const useAddSubmissionScreen = () => {
    const { request } = useSingleSR();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [scanningDevices, setScanningDevices] = useState<ISelectMenuList[]>([]);

    const [addSubmission, addRes] = useAddSubmissionToScanRequestMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            dataset_type: request?.dataset_type || "video",
            filePreview: null,
            scanning_device_id: "",
            method: "nerfacto",
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(addRes, {
            successMessage:
                "Your scan has been uploaded to the request. Please submit your application once you are done adding scans.",
            toastOptions: {
                duration: 5000,
            },
            onSuccess() {
                navigate(-1);
            },
        });
    }, [addRes]);

    const onSubmit = (data: FormValues) => {
        addSubmission({
            data: {
                input_file_id: data.scan_video_id,
                name: data.name,
                scan_request_id: request!.scan_request_id,
                scanning_device_id: data.scanning_device_id ? parseInt(data.scanning_device_id) : null,
                method: data.method,
            },
        });
    };

    useEffect(() => {
        if (user?.scanning_devices && user.scanning_devices.length > 0) {
            const scanning_device: ISelectMenuList[] = [];

            user.scanning_devices.forEach((device) => {
                if (!scanning_device.find((i) => i.value === device.category)) {
                    scanning_device.push({
                        value: device.category,
                        label: device.category,
                        isGroup: true,
                    });
                }
                scanning_device.push({
                    value: device.scanning_device_id.toString(),
                    label: device.name,
                });
            });

            setScanningDevices(scanning_device);
        }
    }, [user]);

    return { form, scanningDevices, onSubmit, addRes };
};

export default useAddSubmissionScreen;

type FormValues = yup.InferType<typeof schema>;

const schema = yup.object().shape({
    name: yup.string().required(),
    dataset_type: yup.string().required().oneOf(["images", "video", "video360"]),
    filePreview: yup
        .object()
        .shape({
            name: yup.string().required(),
            size: yup.number().required(),
            url: yup.string().required(),
        })
        .nullable(),
    scan_video_id: yup.number().typeError("This field is required").required(),
    scanning_device_id: yup.string(),
    input_videos: yup.mixed().required(),
    method: yup.string().required(),
});
