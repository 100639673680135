import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Youtube from "./Youtube";

export default Node.create({
    name: "youtubeComponent",

    group: "block",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            link: {
                default: null,
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            caption: {
                default: true,
            },
            caption_str: {
                default: null,
            },
            redirecting: {
                default: true,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.youtube-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    link: dom.getAttribute("link"),
                    width: parseInt(dom.getAttribute("width")) || 100,
                    justify: dom.getAttribute("justify"),
                    caption: dom.getAttribute("caption") === "true",
                    caption_str: dom.getAttribute("caption_str"),
                    redirecting: dom.getAttribute("redirecting") === "true",
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        const customAttributes = {
            class: "youtube-component",
            id: node.attrs.id,
            link: node.attrs.link,
            style: `width: ${node.attrs.width}%; justify-content: ${node.attrs.justify};`,
            caption: node.attrs.caption.toString(),
            caption_str: node.attrs.caption_str,
            redirecting: node.attrs.redirecting.toString(),
        };

        return ["div", customAttributes, 0]; // This node doesn't have any direct content
    },

    addNodeView() {
        return ReactNodeViewRenderer(Youtube);
    },
});
