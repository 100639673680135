import { useState } from "react";

import useImages from "assets/images";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import ThemeModal from "shared/components/ui/ThemeModal";
import { ScanningDeviceCategory } from "shared/utils/enums";

import useScanningDevices from "./hook";
import ScanDevicePostModalContent from "./partials/ScanDevicePostModalContent";
import ScanningDeviceDetails from "./partials/ScanningDeviceDetails";
import { useSearchParams } from "react-router-dom";

const ScanningDevices = () => {
    const Images = useImages();
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<ScanningDeviceCategory>(ScanningDeviceCategory.MobilePhone);
    const hook = useScanningDevices();

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    return (
        <>
            <SettingPageCard
                title="Scanning Devices"
                subTitle="Add and update your scanning devices for your community and team profiles."
                collapseAble
                open={tab ? (tab === "scanning_devices" ? true : undefined) : undefined}
                containerClassName="relative"
            >
                <div className="grid grid-cols-2 gap-x-3 gap-y-5">
                    {Object.values(ScanningDeviceCategory).map((item, index) => (
                        <ScanningDeviceDetails
                            key={index}
                            type={item}
                            onAdd={() => {
                                setType(item);
                                setOpen(true);
                            }}
                            onDelete={(id) => hook.setOpenDeleteModal(id)}
                            devices={
                                hook.devices
                                    ?.filter((i) => i.category === item)
                                    ?.map((a) => ({
                                        value: a.scanning_device_id.toString(),
                                        label: a.name,
                                    })) ?? []
                            }
                        />
                    ))}
                </div>
            </SettingPageCard>
            <ThemeModal
                open={open}
                icon={<Images.VideoCameraLayerIcon />}
                title="Scanning Devices"
                content="Add and update scanning devices in your account."
                onClose={() => setOpen(false)}
                contentComponent={<ScanDevicePostModalContent type={type} onClose={() => setOpen(false)} />}
            />
            <ThemeModal
                open={Boolean(hook.openDeleteModal)}
                icon={<Images.DeleteLayerIcon />}
                title="Remove Scanning Device"
                content="Are you sure you want to remove this scanning device?"
                onCloseModal={() => hook.setOpenDeleteModal("")}
                buttons={[
                    {
                        children: "Remove It",
                        onClick: () => hook.onDeleteScanningDevice(hook.openDeleteModal),
                        color: "error",
                        loading: hook.removeRes.isLoading,
                    },
                ]}
            />
        </>
    );
};

export default ScanningDevices;
