import { capitalize } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
    AcceptScanRequestMutation,
    useAcceptScanRequestMutation,
    useDeleteScanRequestMutation,
    useEndScanRequestMutation,
    useRejectScanRequestMutation,
} from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";

const useSRActionButton = () => {
    const request = useAppSelector((state) => state.scan_request.selected);
    const [deleteReq, deleteRes] = useDeleteScanRequestMutation();
    const [endReq, endRes] = useEndScanRequestMutation();
    const [acceptReq, acceptRes] = useAcceptScanRequestMutation();
    const [rejectReq, rejectRes] = useRejectScanRequestMutation();
    const navigate = useNavigate();

    const requestHasSubmissions = request?.applications.some((item) => ["Accepted", "Invited"].includes(item.status));

    useEffect(() => {
        rtkHandler(endRes, {
            successMessage: requestHasSubmissions
                ? "Your request has ended, no more team members will be able to accept or reject it. You can visit the applications anytime in IN PROGRESS."
                : "Your request has ended, no more team members will be able to accept or reject it. We have moved the request to ARCHIVED because of no applications in it.",
            toastOptions: {
                duration: 5000,
            },
            onSuccess: () => {
                if (requestHasSubmissions) {
                    navigate(`/requests/in-progress`, {
                        replace: true,
                    });
                }
            },
        });
    }, [endRes, requestHasSubmissions]);

    useEffect(() => {
        rtkHandler(acceptRes, {
            onSuccess(res: AcceptScanRequestMutation) {
                successToast(
                    `You have accepted Project Request: ${capitalize(
                        res.accept_scan_request.title
                    )}. You can view the project request & submit your media in the "In Progress" tab.`
                );
                navigate(`/requests/in-progress/detail/${request?.scan_request_id}`, {
                    replace: true,
                });
            },
        });
    }, [acceptRes]);

    useEffect(() => {
        rtkHandler(rejectRes, {
            successMessage: "This project request has been rejected. You can visit this request anytime in ARCHIVED.",
            toastOptions: {
                duration: 5000,
            },
        });
    }, [rejectRes]);

    useEffect(() => {
        rtkHandler(deleteRes, {
            successMessage:
                "Your request is deleted, all the current applications are stopped. You can visit this request anytime in ARCHIVED.",
            toastOptions: {
                duration: 5000,
            },
            onSuccess: () => {
                navigate("/requests/new", {
                    replace: true,
                });
            },
        });
    }, [deleteRes]);

    return { request, deleteRes, endRes, endReq, deleteReq, rejectRes, acceptReq, acceptRes, rejectReq };
};

export default useSRActionButton;
