import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import NestedEditor from "./NestedEditor";

export const Box = Node.create({
    name: "box",

    group: "block",

    content: "block*",

    addAttributes() {
        return {
            id: {
                default: null,
            },
            color: {
                default: "#F9FAFB",
            },
            width: {
                default: 100,
            },
            justify: {
                default: "left",
            },
            editor: {
                default: null,
            },
            editor_content: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.editor-box",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    color: dom.getAttribute("color"),
                    width: parseInt(dom.getAttribute("width")) || 100, // Providing a default value in case of parsing failure
                    justify: dom.getAttribute("justify"),
                    editor_content: JSON.parse(dom.getAttribute("editor_content") || "null"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        return [
            "div",
            {
                class: "editor-box",
                id: node.attrs.id,
                color: node.attrs.color,
                justify: node.attrs.justify,
                width: node.attrs.width,
                editor_content: JSON.stringify(node.attrs.editor_content), // Convert the editor content back to JSON format for the attribute
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(NestedEditor);
    },
});
