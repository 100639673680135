import { useAppSelector } from "shared/hooks/useRedux";
import ThemeModal from "../ui/ThemeModal";
import useCommonModal from "./hook";

const CommonModal = () => {
    const modalState = useAppSelector((state) => state.commonModal);
    const { open, closeModal } = useCommonModal();

    return <ThemeModal {...modalState} open={open} onCloseModal={closeModal} />;
};

export default CommonModal;
