import { yupResolver } from "@hookform/resolvers/yup";
import { pickBy } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SocialAccountsInput, UpdateTeamMutation, useUpdateTeamMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    facebook: yup.string().nullable().url(),
    linkedin: yup.string().nullable().url(),
    twitter: yup.string().nullable().url(),
    instagram: yup.string().nullable().url(),
});

const useSocialMediaHandler = () => {
    const { team } = useAuth();
    const [updateTeam, updateTeamResponse] = useUpdateTeamMutation();

    const form = useForm<SocialAccountsInput>({
        defaultValues: {
            ...team?.social_accounts,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: SocialAccountsInput) => {
        updateTeam({
            data: {
                social_accounts: {
                    ...pickBy(data, (i) => i),
                },
            },
        });
    };

    useEffect(() => {
        rtkHandler(updateTeamResponse, {
            onSuccess: ({ update_team }: UpdateTeamMutation) => {
                form.reset({
                    ...update_team?.social_accounts,
                });
            },
            successMessage: "Social media handles updated successfully",
        });
    }, [updateTeamResponse]);

    return { form, onSubmit, updateTeamResponse };
};

export default useSocialMediaHandler;
