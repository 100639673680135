import { SxProps, Theme } from "@mui/material";
import { ITextIconButtonProps } from "./type";
import COLOR_PALLETE from "shared/utils/colorPallete";

const useTextIconButton = (props: ITextIconButtonProps) => {
    const { variant = "default", color = "default", bgColor } = props;

    const styles: SxProps<Theme> = {
        bgcolor: variant === "filled" ? (bgColor ? bgColor : `${color}.main`) : "transparent",
        border: `${
            variant === "filled"
                ? `1px solid ${color}.main`
                : variant === "outlined"
                ? `1px solid ${COLOR_PALLETE.border2}`
                : ""
        }`,
        boxShadow: variant !== "default" ? "0px 1px 2px rgba(16, 24, 40, 0.05)" : "none",
        borderRadius: "7px",
        width: 32,
        height: 32,
        ":hover": {
            bgcolor: variant === "filled" ? `${color}.main` : "transparent",
        },
    };

    const badegStyles: SxProps<Theme> = {
        "& > .MuiBadge-badge": {
            height: 12,
            minWidth: 12,
            padding: 0,
            fontSize: 9,
            right: 5,
        },
    };

    return { styles, badegStyles };
};

export default useTextIconButton;
