import { Autocomplete, TextField } from "@mui/material";

import ChipBox from "shared/components/ChipBox";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { ScanningDeviceCategory } from "shared/utils/enums";

import useScanDevicePostModalContent from "./hook";

const ScanDevicePostModalContent = (props: { onClose: () => void; type: ScanningDeviceCategory }) => {
    const { devices, selectedDevices, setSelectedDevices, submitScanningDevices } =
        useScanDevicePostModalContent(props);

    return (
        <div className="text-left">
            <Autocomplete
                multiple
                id="tags-filled"
                options={
                    devices.map((item) => {
                        return {
                            label: item.name,
                            value: item.scanning_device_id.toString(),
                        };
                    }) as ISelectMenuList[]
                }
                freeSolo
                disableCloseOnSelect
                renderTags={() => <></>}
                value={selectedDevices as any}
                getOptionLabel={(option: string) => (option as any).label}
                isOptionEqualToValue={(option: any, value: any) => {
                    return option.value == value.value;
                }}
                onChange={(_, newValue: any) => {
                    let devices = newValue.map((item: any) => {
                        if (typeof item === "string") {
                            return {
                                label: item,
                                value: item,
                                new: true,
                            };
                        } else {
                            return {
                                ...item,
                                label: item.label,
                                value: item.value,
                            };
                        }
                    });

                    devices = devices.filter(
                        (item: any, index: number, self: any) =>
                            index === self.findIndex((t: any) => t.value === item.value)
                    );
                    setSelectedDevices(devices);
                }}
                renderInput={(params) => <TextField variant="outlined" placeholder="Search for devices" {...params} />}
            />

            {selectedDevices.length ? (
                <div className="flex flex-wrap items-center gap-[10px] mt-6">
                    {selectedDevices.map((item: any, index) => (
                        <ChipBox
                            label={item.label}
                            onDelete={() => {
                                setSelectedDevices(selectedDevices.filter((item, i) => i !== index));
                            }}
                            key={index}
                        />
                    ))}
                </div>
            ) : (
                <></>
            )}

            <CommonModalActionButton
                cancelBtnProps={{
                    onClick: props.onClose,
                }}
                className="mt-4"
                buttons={[
                    {
                        children: "Update",
                        variant: "contained",
                        id: "add-scanning-device",
                        onClick: submitScanningDevices,
                    },
                ]}
            />
        </div>
    );
};

export default ScanDevicePostModalContent;
