import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Carousel from "./Carousel";

export default Node.create({
    name: "carousel",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            images: {
                default: [],
            },
            image_switch: {
                default: true,
            },
            justify_arrows: { default: "center" },
            current: { default: -1 },
            uploading: { default: -1 },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.carousel-component", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    // Extract attributes from the DOM element and turn them into node attributes
                    id: dom.getAttribute("id"),
                    images: JSON.parse(dom.getAttribute("images") || "[]"), // Assuming images is an array in JSON format
                    image_switch: dom.getAttribute("image_switch") === "true",
                    justify_arrows: dom.getAttribute("justify_arrows"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        return [
            "div",
            {
                class: "carousel-component",
                id: node.attrs.id,
                images: JSON.stringify(node.attrs.images), // Convert the array back to JSON format for the attribute
                image_switch: node.attrs.image_switch.toString(),
                justify_arrows: node.attrs.justify_arrows,
            },
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Carousel);
    },
});
