import { MenuItem, MenuItemProps, Tooltip } from "@mui/material";
import useImages from "assets/images";
import { useMemo } from "react";

interface IEditorCustomDropDownProps extends MenuItemProps {
    empty_placeholder?: string;
    dropdown_data?: {
        [key: string]: {
            Icon?: any;
            image: string;
            label: string;
            is_public?: boolean;
            value: string;
        };
    };
}

const EditorCustomDropDown = ({ dropdown_data, empty_placeholder, ...props }: IEditorCustomDropDownProps) => {
    const Images = useImages();

    const data = useMemo(() => {
        if (!dropdown_data) return null;

        return dropdown_data![props["data-value"] as string];
    }, [dropdown_data, props]);

    return (
        <MenuItem
            {...props}
            value={(data && data?.value) ?? ""}
            disabled={(data && data.is_public != undefined && !data["is_public"]) ?? props.disabled ?? false}
        >
            {dropdown_data && Object.keys(dropdown_data).length == 0 ? (
                <div className="text-md font-medium text-[#5e5d5d]">{empty_placeholder}</div>
            ) : (
                <div className="w-[160px] flex items-center gap-x-3 justify-between">
                    <div className="flex-1 flex items-center gap-x-3">
                        {data?.Icon}
                        {data?.image && (
                            <img src={data?.image ?? ""} className="!w-6 !h-6 rounded-[4px] object-cover" />
                        )}
                        <span className="flex-1 whitespace-break-spaces text-md font-medium text-[#101828]">
                            {data?.label ?? ""}
                        </span>
                    </div>
                    {data?.is_public != undefined &&
                        (data?.is_public ? (
                            <span
                                style={{
                                    filter: "invert(54%) sepia(51%) saturate(4616%) hue-rotate(215deg) brightness(103%) contrast(101%)",
                                }}
                            >
                                <Tooltip title="Public Scan">
                                    <Images.UsersPrimaryIcon width={18} height={18} />
                                </Tooltip>
                            </span>
                        ) : (
                            <Tooltip title="Private Scan">
                                <Images.UsersPrimaryIcon width={18} height={18} color="#98A2B3" />
                            </Tooltip>
                        ))}
                </div>
            )}
        </MenuItem>
    );
};

export default EditorCustomDropDown;
