import React from "react";
import { useFormContext } from "react-hook-form";
import Map from "shared/components/Map";
import { validCoordinates } from "shared/utils/helpers";

const ScanRequestLocation = () => {
    const { watch } = useFormContext();
    const location = watch("location");

    return (
        <div className="w-full h-full min-h-[300px] border border-[#D0D5DD] rounded-md p-4">
            <Map
                autoCurrentLocation={true}
                showLocationMarker={true}
                isInteract={true}
                center={validCoordinates(location)}
                name="location"
            />
        </div>
    );
};

export default ScanRequestLocation;
