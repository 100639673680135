import { CircularProgress, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import EditorCustomDropDown from "shared/components/EditorCustomDropDown/EditorCustomDropDown";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import useFileUploader from "shared/hooks/useFileUploader";
import { useAppSelector } from "shared/hooks/useRedux";
import { v4 as uuidv4 } from "uuid";
import { ICarouselProps } from "./Carousel";

const CarouselProperties = ({ editor, props }: { editor: Editor | null; props: ICarouselProps }) => {
    const { id, images, image_switch, justify_arrows, current } = props;
    const {
        EditorCarouselImageRemoveIcon,
        EditorDividerAddIcon,
        EditorAlignLeftIcon,
        EditorAlignCenterIcon,
        EditorAlignRightIcon,
        UpdateImageGrayIcon,
        ArrowDown,
    } = useImages();

    const { uploadFile, loading, loader_id } = useFileUploader();

    const isInit = useRef(false);
    const uploadRef = useRef(null);

    const project = useAppSelector((state) => state.projects.selected);

    const { control, watch, setValue } = useForm<{
        id: string;
        images: any[];
        image_switch: string;
        justify_arrows: "left" | "center" | "right";

        selected_image: number;
        is_add_image: boolean;
        type: "upload" | "asset" | "snapshot";
        scan: string | null;
        snapshot: string | null;
        redirecting: "yes" | "no";
        selected_asset: string | null;
    }>({
        defaultValues: {
            id,
            images,
            image_switch: image_switch ? "yes" : "no",
            justify_arrows,

            selected_image: images.findIndex((image) => image.url == ""),
            is_add_image: images.findIndex((image) => image.url == "") != -1,
            type: "upload",
            scan: null,
            snapshot: null,
            redirecting: "yes",
            selected_asset: null,
        },
    });

    const images_val = watch("images");
    const image_switch_val = watch("image_switch");
    const justify_arrows_val = watch("justify_arrows");

    const is_add_image_val = watch("is_add_image");
    const selected_image_val = watch("selected_image");
    const type_val = watch("type");
    const selected_asset_val = watch("selected_asset");
    const scan_val = watch("scan");
    const snapshot_val = watch("snapshot");
    const redirecting_val = watch("redirecting");

    const handleImageUpload = (e: any) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            let pos = 0;
            let nodeAttrs: any = null;

            parentEditor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    nodeAttrs = node.attrs;
                    return false;
                }
            });

            const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
                ...nodeAttrs,
                uploading: selected_image_val,
            });

            parentEditor!.view.dispatch(transaction);

            uploadFile(file, "editor-carousel-image-upload").then((res: any) => {
                const updated_images_val = [...images_val];
                updated_images_val[selected_image_val] = {
                    id: images_val[selected_image_val].id,
                    url: res.url,
                    type: "upload",
                    redirecting_url: null,
                    is_redirecting: false,
                };
                setValue("images", updated_images_val);
            });
        };
        reader.readAsText(file);
    };

    const onRemoveImageHandler = (img_id: string) => {
        setValue(
            "images",
            images_val.filter((image) => image.id != img_id)
        );
        setValue("is_add_image", false);
        setValue("selected_image", -1);
    };

    const assetOptions = useMemo(() => {
        if (!project) return [];

        let assets: any[] = [];

        project.folders.forEach((folder) => {
            assets = [...assets, ...folder.asset.filter((asset) => asset.file.type == "Image")];
        });

        return assets.map((asset) => ({
            label: asset.name,
            value: asset.file.url,
        }));
    }, [project]);

    const assetsData = useMemo(() => {
        if (!project) return [];

        let assets: any[] = [];

        project.folders.forEach((folder) => {
            assets = [...assets, ...folder.asset.filter((asset) => asset.file.type == "Image")];
        });

        const assetsMap = {};

        assets.forEach((asset) => {
            assetsMap[asset.file.url] = {
                image: asset.file.url,
                label: asset.name,
                value: asset.file.url,
            };
        });

        return assetsMap;
    }, [project]);

    const scanOptions = useMemo(() => {
        if (!project) return [];

        let scans: any[] = [];

        project.folders.forEach((folder) => {
            scans = [...scans, ...folder.scans.filter((scan) => scan.method == "gaussian-splatting")];
        });

        return scans.map((scan) => ({
            label: scan.name,
            value: "" + scan.scan_id,
        }));
    }, [project]);

    const scanData = useMemo(() => {
        if (!project) return [];

        let scans: any[] = [];

        project.folders.forEach((folder) => {
            scans = [...scans, ...folder.scans.filter((scan) => scan.method == "gaussian-splatting")];
        });

        const scansMap = {};

        scans.forEach((scan) => {
            scansMap["" + scan.scan_id] = {
                image: scan.input_file.thumbnail,
                label: scan.name,
                is_public: scan.visibility,
                value: `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan.scan_id}`,
            };
        });

        return scansMap;
    }, [project]);

    const snapshotOptions = useMemo(() => {
        if (!project || !scan_val || scan_val == "") return [];

        let selectedScan: any = null;

        project.folders.forEach((folder) => {
            folder.scans.forEach((scan) => {
                if (scan.scan_id == parseInt(scan_val)) {
                    selectedScan = scan;
                }
            });
        });

        if (!selectedScan) return [];

        return selectedScan.snapshots.map((snapshot) => ({
            label: snapshot.name,
            value: "" + snapshot.snapshot_id,
        }));
    }, [project, scan_val]);

    const snapshotsData = useMemo(() => {
        if (!project || !scan_val || scan_val == "") return [];

        let selectedScan: any = null;

        project.folders.forEach((folder) => {
            folder.scans.forEach((scan) => {
                if (scan.scan_id == parseInt(scan_val)) {
                    selectedScan = scan;
                }
            });
        });

        if (!selectedScan) return [];

        const snapshotsMap = {};

        selectedScan.snapshots.forEach((snapshot) => {
            snapshotsMap["" + snapshot.snapshot_id] = {
                image: snapshot.image.url,
                label: snapshot.name,
                value: `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan_val}&snapshot_id=${snapshot.snapshot_id}`,
            };
        });

        return snapshotsMap;
    }, [project, scan_val]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const handleImageClickedHandler = (index: number) => {
        let selectedImage = images_val[index];

        if (typeof selectedImage == "string") {
            selectedImage = JSON.parse(selectedImage);
        }

        setValue("scan", null);
        setValue("snapshot", null);
        setValue("redirecting", "yes");
        setValue("selected_asset", null);

        setValue("type", selectedImage["type"]);
        setValue("selected_image", index);
        if (selectedImage["type"] == "asset") {
            setValue("selected_asset", selectedImage["url"]);
        } else if (selectedImage["type"] == "snapshot") {
            const scanUrl = new URL(selectedImage["redirecting_url"]);
            const scanId = scanUrl.searchParams.get("scan_id");
            const snapshotId = scanUrl.searchParams.get("snapshot_id");

            setValue("selected_image", index);
            setValue("scan", scanId ?? null);
            setValue("snapshot", snapshotId ?? null);
            setValue("redirecting", selectedImage["is_redirecting"] ? "yes" : "no");
        }

        let pos = 0;
        let nodeAttrs: any = null;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                nodeAttrs = node.attrs;
                return false;
            }
        });

        const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
            ...nodeAttrs,
            current: index,
        });

        parentEditor!.view.dispatch(transaction);
    };

    const onUpdate = () => {
        const data = {
            id,
            images: images_val,
            image_switch: image_switch_val == "yes",
            justify_arrows: justify_arrows_val,
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const node = parentEditor!.view.state.schema.nodes.carousel.create(data);

        const transaction = parentEditor!.view.state.tr.replaceWith(pos, pos + 1, node);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [images_val, image_switch_val, justify_arrows_val]);

    useEffect(() => {
        if (
            type_val == "asset" &&
            selected_image_val >= 0 &&
            selected_asset_val &&
            selected_asset_val != images_val[selected_image_val]["url"]
        ) {
            const updated_images_val = [...images_val];
            updated_images_val[selected_image_val] = {
                id: images_val[selected_image_val].id,
                url: selected_asset_val,
                type: "asset",
                redirecting_url: null,
                is_redirecting: redirecting_val == "yes",
            };
            setValue("images", updated_images_val);
        }
    }, [type_val, selected_asset_val, selected_image_val, images_val]);

    useEffect(() => {
        if (
            type_val == "snapshot" &&
            selected_image_val >= 0 &&
            ((snapshot_val && snapshotsData[snapshot_val].image != images_val[selected_image_val]["url"]) ||
                redirecting_val != images_val[selected_image_val]["is_redirecting"])
        ) {
            const updated_images_val = [...images_val];
            updated_images_val[selected_image_val] = {
                id: images_val[selected_image_val].id,
                url: snapshot_val ? snapshotsData[snapshot_val].image : null,
                type: "snapshot",
                redirecting_url: snapshot_val
                    ? `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${scan_val}&snapshot_id=${snapshot_val}&embed=true`
                    : null,
                is_redirecting: redirecting_val == "yes",
            };
            setValue("images", updated_images_val);
        }
    }, [type_val, snapshot_val, redirecting_val, selected_image_val, images_val]);

    useEffect(() => {
        if (selected_image_val >= 0 && type_val != images_val[selected_image_val]["type"]) {
            const updated_images_val = [...images_val];
            updated_images_val[selected_image_val] = {
                id: images_val[selected_image_val].id,
                url: "",
                type: type_val,
                is_redirecting: false,
            };
            setValue("images", updated_images_val);
            setValue("scan", null);
            setValue("snapshot", null);
            setValue("redirecting", "yes");
            setValue("selected_asset", null);
        }
    }, [type_val, selected_image_val, images_val]);

    useEffect(() => {
        if (is_add_image_val && selected_image_val >= 0 && images_val[selected_image_val].url != "") {
            setValue("is_add_image", false);
        }
    }, [is_add_image_val, images_val, selected_image_val]);

    useEffect(() => {
        if (current != -1) {
            handleImageClickedHandler(current);
        }
    }, [current]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Images</div>
                    <div className="flex flex-col gap-y-2">
                        {images_val.map((image, index) => (
                            <div
                                key={`image_${index}`}
                                className={
                                    "h-[34px] w-full bg-white rounded-lg border border-[#D0D5DD] flex items-center cursor-pointer " +
                                    (selected_image_val == index && "!border-[#7680FF] cursor-auto")
                                }
                                onClick={() => handleImageClickedHandler(index)}
                            >
                                <div className="h-full flex-1 p-2">
                                    {image.url ? (
                                        <img src={image.url} className="w-full h-full object-cover rounded-[4px]" />
                                    ) : (
                                        <div className="w-full h-full bg-[#F2F3FD] rounded-[4px]"></div>
                                    )}
                                </div>
                                <div
                                    className={
                                        "h-full cursor-pointer px-3 border-l border-[#D0D5DD] flex items-center justify-center " +
                                        (selected_image_val == index && "!border-[#7680FF]")
                                    }
                                    onClick={() => onRemoveImageHandler(image.id)}
                                >
                                    <EditorCarouselImageRemoveIcon width={13} height={13} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex justify-center mt-2 ">
                        {loading && loader_id == "editor-carousel-image-upload" ? (
                            <CircularProgress variant="indeterminate" size="20px" thickness={2} color="primary" />
                        ) : (
                            <EditorDividerAddIcon
                                width={22}
                                height={22}
                                className="cursor-pointer hover:brightness-90"
                                onClick={() => {
                                    if (!is_add_image_val) {
                                        setValue("images", [
                                            ...images_val,
                                            {
                                                id: uuidv4(),
                                                url: "",
                                                type: "upload",
                                                redirecting_url: null,
                                                is_redirecting: false,
                                            },
                                        ]);
                                        setValue("is_add_image", true);
                                        setValue("selected_image", images_val.length);

                                        setTimeout(() => {
                                            let pos = 0;
                                            let nodeAttrs: any = null;

                                            parentEditor!.view.state.doc.descendants((node, position) => {
                                                if (node.attrs.id == id) {
                                                    pos = position;
                                                    nodeAttrs = node.attrs;
                                                    return false;
                                                }
                                            });

                                            const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, {
                                                ...nodeAttrs,
                                                current: images_val.length,
                                            });

                                            parentEditor!.view.dispatch(transaction);
                                        }, 0);
                                    }
                                }}
                            />
                        )}
                        <input
                            ref={uploadRef}
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleImageUpload}
                        />
                    </div>
                </div>
                {selected_image_val != -1 && (
                    <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                        <div className="text-sm text-[#667085] mb-2">Type</div>
                        <ToggleButtonGroup
                            value={type_val}
                            exclusive
                            fullWidth
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setValue("type", newValue);
                                }
                            }}
                            sx={{
                                // Applying styles using the sx prop
                                ".MuiToggleButtonGroup-grouped": {
                                    height: 34,
                                    flexGrow: 1, // Makes each toggle button grow to fill the available space
                                    borderRadius: "8px", // Rounded corners (you can adjust this value)
                                    border: "1px solid #D0D5DD", // Removes the border
                                    color: "#667085",
                                    fontSize: "12px",
                                    textTransform: "capitalize",

                                    "&.Mui-selected": {
                                        color: "#7680FF",
                                        background: "#F2F3FD",
                                    },
                                },
                            }}
                        >
                            <ToggleButton value={"upload"} aria-label="Yes">
                                Upload
                            </ToggleButton>
                            <ToggleButton value={"asset"} aria-label="No">
                                Asset
                            </ToggleButton>
                            <ToggleButton value={"snapshot"} aria-label="No">
                                Snapshot
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {type_val == "upload" && (
                            <div
                                className={
                                    "w-full h-[34px] mt-4 border border-[#D0D5DD] rounded-lg bg-[#FFFFFF] shadow-[0px_0.7px_1.5px_0px_#1018280D] flex items-center justify-center gap-x-1 cursor-pointer hover:brightness-90 " +
                                    (loading && loader_id == "editor-carousel-image-upload" && "pointer-events-none")
                                }
                                onClick={() => {
                                    (uploadRef.current as any).click();
                                }}
                            >
                                {loading && loader_id == "editor-carousel-image-upload" ? (
                                    <CircularProgress
                                        variant="indeterminate"
                                        size="20px"
                                        thickness={2}
                                        color="primary"
                                    />
                                ) : (
                                    <>
                                        <UpdateImageGrayIcon width={14} height={14} />
                                        <div className="text-sm font-medium text-[#667085]">Upload Image</div>
                                    </>
                                )}
                            </div>
                        )}
                        {type_val == "asset" && (
                            <div className="w-full mt-4">
                                <Field
                                    formGroup={false}
                                    name="selected_asset"
                                    type="select"
                                    options={assetOptions}
                                    selectFieldProps={{
                                        className: "!h-[34px]",
                                        sx: {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "8px !important",
                                            },
                                        },
                                        IconComponent: ArrowDown,
                                        CustomMenuItem: EditorCustomDropDown,
                                        dropdown_data: assetsData,
                                        empty_placeholder: "No Assets Found",
                                    }}
                                />
                            </div>
                        )}
                        {type_val == "snapshot" && (
                            <>
                                <div className="text-sm text-[#667085] mt-4 mb-2">Scan</div>
                                <Field
                                    formGroup={false}
                                    name="scan"
                                    type="select"
                                    options={scanOptions}
                                    selectFieldProps={{
                                        className: "!h-[34px]",
                                        sx: {
                                            ".MuiOutlinedInput-notchedOutline": {
                                                borderRadius: "8px !important",
                                            },
                                        },
                                        IconComponent: ArrowDown,
                                        CustomMenuItem: EditorCustomDropDown,
                                        dropdown_data: scanData,
                                        empty_placeholder: "No Scans Found",
                                    }}
                                />

                                {scan_val && (
                                    <>
                                        <div className="text-sm text-[#667085] mt-4 mb-2">Snapshot</div>
                                        <Field
                                            formGroup={false}
                                            name="snapshot"
                                            type="select"
                                            options={snapshotOptions}
                                            selectFieldProps={{
                                                className: "!h-[34px]",
                                                sx: {
                                                    ".MuiOutlinedInput-notchedOutline": {
                                                        borderRadius: "8px !important",
                                                    },
                                                },
                                                IconComponent: ArrowDown,
                                                CustomMenuItem: EditorCustomDropDown,
                                                dropdown_data: snapshotsData,
                                                empty_placeholder: "No Snapshots Found",
                                            }}
                                        />
                                    </>
                                )}

                                <div className="text-sm text-[#667085] mt-4 mb-2">Redirecting</div>
                                <ToggleButtonGroup
                                    value={redirecting_val}
                                    exclusive
                                    fullWidth
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            setValue("redirecting", newValue);
                                        }
                                    }}
                                    sx={{
                                        // Applying styles using the sx prop
                                        ".MuiToggleButtonGroup-grouped": {
                                            height: 34,
                                            flexGrow: 1, // Makes each toggle button grow to fill the available space
                                            borderRadius: "8px", // Rounded corners (you can adjust this value)
                                            border: "1px solid #D0D5DD", // Removes the border
                                            color: "#667085",
                                            fontSize: "12px",
                                            textTransform: "capitalize",

                                            "&.Mui-selected": {
                                                color: "#7680FF",
                                                background: "#F2F3FD",
                                            },
                                        },
                                    }}
                                >
                                    <ToggleButton value={"yes"} aria-label="Yes">
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton value={"no"} aria-label="No">
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </>
                        )}
                    </div>
                )}
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Images Switch</div>
                    <ToggleButtonGroup
                        value={image_switch_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("image_switch", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Justify Arrows</div>
                    <ToggleButtonGroup
                        value={justify_arrows_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify_arrows", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderRadius: "8px", // Rounded corners (you can adjust this value)
                                border: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default CarouselProperties;
