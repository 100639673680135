import { Editor, Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

import { v4 as uuidv4 } from "uuid";

function findSelectedNode(editor: Editor): Element | null {
    // Assuming your editor has a unique class or ID you can target
    const editorDom = editor.view.dom as HTMLElement; // This should be the DOM element of your editor

    if (document.querySelector(".editor-preview")) {
        return null;
    }

    // Find the selected node within the editor
    let selectedNode = editorDom.querySelector(".editor-block-selected:not(.no-further-block, .editor-placeholder)");

    if (!selectedNode?.getAttribute("id")) {
        selectedNode = selectedNode?.parentElement ?? null;
    }

    if (
        selectedNode &&
        selectedNode.parentElement?.nextSibling?.nextSibling &&
        (selectedNode.parentElement?.nextSibling?.nextSibling as any).classList.contains("node-placeholder")
    ) {
        return null;
    }

    return selectedNode;
}

function createButtonDecorations(doc: any, editor: Editor): Decoration[] {
    const decorations: Decoration[] = [];

    const selectedNode = findSelectedNode(editor);

    doc.descendants((node, pos) => {
        if (node.type.isBlock) {
            const endPos = pos + node.nodeSize;
            const button = createPlusButton(
                editor,
                endPos,
                (selectedNode && selectedNode?.getAttribute("id") == node.attrs.id) ?? false
            );
            const decoration = Decoration.widget(endPos, button, { side: -1 });
            decorations.push(decoration);
        }
    });

    return decorations;
}

function createPlusButton(editor: Editor, position: number, is_visible: boolean = true): HTMLElement {
    const button = document.createElement("button");
    button.type = "button";
    button.className = "plus-button";

    button.addEventListener("click", () => {
        const nodeType = editor.schema.nodes.placeholder;
        const transaction = editor.state.tr.insert(
            position,
            (nodeType as any).createAndFill({
                id: uuidv4(),
            })
        );

        editor.view.dispatch(transaction);

        const decoration_transaction = editor.state.tr.setMeta("updateDecorations", true);
        editor.view.dispatch(decoration_transaction);
    });

    const dividerLeft = document.createElement("div");
    dividerLeft.className = "plus-button-divider plus-button-divider-left";

    const dividerRight = document.createElement("div");
    dividerRight.className = "plus-button-divider plus-button-divider-right";

    const wrapper = document.createElement("div");
    wrapper.contentEditable = "false"; // Make the wrapper non-editable
    wrapper.className = "plus-button-wrapper";

    wrapper.style.display = is_visible ? "" : "none";

    wrapper.appendChild(dividerLeft);
    wrapper.appendChild(dividerRight);
    wrapper.appendChild(button);

    return wrapper;
}

export const AddBlockExtension = Extension.create({
    name: "plusButtonBetweenNodes",

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey("plusButton"),
                state: {
                    init: (_, { doc }) => {
                        return DecorationSet.create(doc, createButtonDecorations(doc, this.editor));
                    },
                    apply: (tr, oldState) => {
                        if (tr.docChanged || tr.getMeta("updateDecorations")) {
                            return DecorationSet.create(tr.doc, createButtonDecorations(tr.doc, this.editor));
                        }
                        return oldState;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});
