import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetMyScans: {
            providesTags: (result) =>
                result
                    ? [
                          ...result.scans.map(({ scan_id }) => ({ type: "MyScans", id: scan_id } as const)),
                          { type: "MyScans", id: "LIST" },
                      ]
                    : [{ type: "MyScans", id: "LIST" }],
        },
        GetMyScansByID: {
            providesTags: (result, error, { id }) => [{ type: "MyScans", id }],
        },
        SubmitScan: {
            invalidatesTags: (success, error) => {
                return error
                    ? []
                    : [
                          "MyScans",
                          { type: "Projects", id: "LIST" },
                          { type: "Projects", id: success?.submit_scan.project_id },
                          "Credits",
                          "Notification",
                          "Storage",
                      ];
            },
        },
        UpdateScan: {
            invalidatesTags: (_, error, { id, data: { project_id } }) => [
                { type: "MyScans", id },
                { type: "Projects", id: "LIST" },
                { type: "Projects", id: project_id! },
                "Notification",
            ],
        },
        DeleteSnapshot: {
            invalidatesTags: (_, error, { id }) => [{ type: "MyScans", id }, "Notification"],
        },
        DeleteScan: {
            invalidatesTags: (success, error) =>
                error
                    ? []
                    : [
                          { type: "MyScans", id: "LIST" },
                          { type: "Projects", id: "LIST" },
                          { type: "Projects", id: success?.delete_scan.project_id ?? "0" },
                          "Notification",
                          "Storage",
                      ],
        },
        RetrainScan: {
            invalidatesTags: (_, error) => (error ? [] : [{ type: "MyScans", id: "LIST" }, "Notification"]),
        },
    },
});

export const {
    useGetMyScansQuery,
    useGetMyScansByIDQuery,
    useSubmitScanMutation,
    useUpdateScanMutation,
    useDeleteScanMutation,
    useAddScanInviteesMutation,
    useRemoveScanInviteesMutation,
    useDownloadModelQuery,
    useRetrainScanMutation,
} = api;
