import { useMemo, useState } from "react";

import SRAccept from "screens/Dashboard/ScanRequests/common/components/SRAccept";
import SRFeedback from "screens/Dashboard/ScanRequests/common/components/SRFeedback";
import SRReminder from "screens/Dashboard/ScanRequests/common/components/SRReminder";
import SRRevision from "screens/Dashboard/ScanRequests/common/components/SRRevision";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ChipBox from "shared/components/ChipBox";
import RenderIf from "shared/components/RenderIf";
import DataTable from "shared/components/ui/DataTable";
import { DataTableHeaderProps } from "shared/components/ui/DataTable/type";
import ThemeRating from "shared/components/ui/ThemeRating";
import UserProfileCard from "shared/components/UserProfileCard";
import { GetScanRequestByIdQuery } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";

import SRSingleSubmission from "./Single";

type Submission = GetScanRequestByIdQuery["scan_request"]["applications"][0];

const SRSubmissionsTab = () => {
    const { request } = useSingleSR();
    const { user } = useAuth();
    const isRequestor = request?.requestor.user_id === user?.user_id;
    const [submissionId, setSubmissionId] = useState("");

    const Headers = useMemo<DataTableHeaderProps[]>(() => {
        return [
            {
                title: "Team Members",
                selector(item: Submission) {
                    return (
                        <UserProfileCard
                            image={item.user.profile_image.url}
                            name={item.user.name}
                            email={item.user.email}
                        />
                    );
                },
            },
            {
                title: "Request Status",
                align: "center",
                selector(item: Submission) {
                    return <ChipBox className="!text-xs" label={item.status} color="primary" />;
                },
            },
            {
                title: "Scans",
                align: "center",
                selector(item: Submission) {
                    return item.submissions.length;
                },
            },
            ...(isRequestor && request?.status !== "Archived"
                ? [
                      {
                          title: "Action",
                          align: "center",
                          selector(item: Submission) {
                              const awaiting = item.status === "Awaiting Submission";
                              const accepted = item.status === "Accepted";
                              const InReview = item.status === "In Review";
                              const Completed = item.status === "Completed";

                              return (
                                  <div className="flex-center space-x-2">
                                      <RenderIf condition={InReview}>
                                          <SRRevision data={item} />
                                          <SRAccept data={item} />
                                      </RenderIf>
                                      <RenderIf condition={awaiting}>
                                          <SRReminder data={item} />
                                      </RenderIf>
                                      <RenderIf condition={accepted}>
                                          <SRFeedback data={item} />
                                      </RenderIf>
                                      <RenderIf condition={Completed}>
                                          <ThemeRating
                                              initialRating={item.review?.rating}
                                              iconSize={20}
                                              readonly
                                              className="!-space-x-[6px] tracking-tight"
                                          />
                                      </RenderIf>
                                  </div>
                              );
                          },
                      },
                  ]
                : []),
        ] as DataTableHeaderProps[];
    }, []);

    return (
        <div className="relative">
            {submissionId ? (
                <SRSingleSubmission submissionId={submissionId} onBack={() => setSubmissionId("")} />
            ) : (
                <DataTable
                    headers={Headers}
                    data={request?.applications.filter((item) => item.status !== "Invited") || []}
                    onRowClick={(item) => setSubmissionId(item.application_id)}
                    hasActionColumn={true}
                    classes={{
                        root: "submission-table",
                        bodyRow: "cursor-pointer",
                    }}
                />
            )}
        </div>
    );
};

export default SRSubmissionsTab;
